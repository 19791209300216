import React, { Component } from 'react'
import israelFlag from '../../images/flags/israel.png'
import usaFlag from '../../images/flags/usa.png'
import { generateStringId_NoNumbers } from '../../Utils/UtilMethods'
import { withTranslation } from 'react-i18next';

export class LanguageSelectDropDownComponent extends Component {

    dropDownId = generateStringId_NoNumbers()
    targetId = generateStringId_NoNumbers()

    state = {
        formLanguage: this.props.formLanguage,
        flag: this.getFlagForName(this.props.formLanguage)
    }

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll(`.${this.dropDownId}`);
        M.Dropdown.init(elems, { constrainWidth: false, coverTrigger: false });
        // M.Dropdown.init(elems, { constrainWidth: false });
    }

    handleClick(language, e) {
        e.preventDefault()
        this.setState({
            formLanguage: language,
            flag: this.getFlagForName(language)
        })
        this.props.selectedLanguage(language)
    }

    getFlagForName(flagName) {
        if (!flagName) {
            return usaFlag
        }
        if (flagName === "heb") {
            return israelFlag
        }
        else {
            return usaFlag
        }

    }

    componentWillReceiveProps(newProps) {
        console.log("new props");

    }

    render() {
        const { t } = this.props;

        return (
            <div className="edit_button_card_subtitle" dir="auto">
                <span style={{ verticalAlign: "top" }}>
                    {
                        t ? t("FormLanguage") : this.props.translate("CardLanguage")
                    }

                </span>

                <a className={"" + this.dropDownId} href='#' data-target={this.targetId}>
                    <img src={this.state.flag} style={{ width: 30, height: 25, marginLeft: 4, marginRight: 4 }} alt={"כרטיס ביקור דיגיטלי"} />
                </a>

                <ul id={this.targetId} className='dropdown-content'>
                    <li>
                        <a href="#" onClick={(e) => this.handleClick("heb", e)} >
                            <img src={israelFlag} style={{ width: 30, height: 30 }} alt={"כרטיס ביקור דיגיטלי"} />
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={(e) => this.handleClick("en", e)} >
                            <img src={usaFlag} style={{ width: 30, height: 30 }} alt={"כרטיס ביקור דיגיטלי"} />
                        </a>
                    </li>

                </ul>
            </div >
        )
    }
}
export default withTranslation()(LanguageSelectDropDownComponent)
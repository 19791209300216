import React, { Component } from 'react'

export default class ModalImageView extends Component {

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
    }

    componentWillReceiveProps(newProps) {
        console.log("new props");

    }
    render() {
        const { url } = this.props;

        return (
            <div className="fullDiv">

                <div >

                    <div className="btn-floating btn-small floatingRightButton"
                        style={{
                            backgroundColor: "transparent",
                        }}
                        onClick={() => this.props.rightClick()}>
                        <i className="fa fa-caret-right " aria-hidden="true" style={{ fontSize: 40 }}></i>
                    </div>
                    <div className="btn-floating btn-small floatingLeftButton"
                        onClick={() => this.props.leftClick()}
                        style={{
                            backgroundColor: "transparent",
                        }}
                    >
                        <i className="fa fa-caret-left " aria-hidden="true" style={{ fontSize: 40 }}></i>
                    </div>
                    <img className="centerImage responsive-img" src={url} alt={"כרטיס ביקור דיגיטלי"} />
                    <i className="fa fa-times floatingLefTopButton"
                        aria-hidden="true"
                        style={{ color: "white", fontSize: "30px", margin: 10 }}
                        onClick={() => this.props.closeClick()} ></i>
                </div>

            </div>



        )
    }
}

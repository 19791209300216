import React from "react";

import { getRectangleContactDesignedButton } from '../../../Utils/UtilMethods'

const RectangleButtons = ({ buttons, buttonClicked, cardID, animations }) => {

    return (

        <div style={{ paddingTop: 0, marginTop: buttons.marginTopBlock ? buttons.marginTopBlock : 0 }}>

            <ul style={{ border: 0, margin: 0 }}>
                {buttons && buttons.list.map((button, index) => {
                    return (
                        getRectangleContactDesignedButton(button, () => {
                            buttonClicked(button.id)
                        }, cardID, animations, index)
                    )
                })
                }

            </ul>

        </div >

    );
};



export default RectangleButtons;


import React, { useEffect, useState } from "react";
import RoundButtonBorderTextIcon from "../../../Buttons/RoundButtonBorderTextIcon";
import TextInputComponent from '../../../InputComponents/TextInputComponent'
import DragAndDropImageComponent from '../../DragAndDropImageComponent';
import { useTranslation } from 'react-i18next';
import { ITEM_ROUNDED_CORNERS } from '../../../../Utils/Constants'
import ShopItemEditorGlobalPanelSettings from "./ShopItemEditorGlobalPanelSettings";
//this.setState({ [e.target.id]: e.target.value })

export default function ShopItemEditor({ index, item, handleChangeCategoryItem, itemDelete, uploadImages }) {

    const { t, } = useTranslation();

    function dropzoneUpdate(selectedFilesUrl) {
        uploadImages(selectedFilesUrl)
    }

    return (
        <div className="edit_button_card_container_gray"
            style={{
                backgroundColor: item.backgroundColor ? item.backgroundColor : "#a4a4a4",
                borderStyle: "solid",
                borderWidth: item.borderWidth,
                borderColor: item.borderColor,
                borderRadius: ITEM_ROUNDED_CORNERS
            }
            }>



            <div>

                <div>
                    {t("ItemImages")}
                </div>

                {item.images && item.images.length > 0 ?
                    <img src={item.images[0]}
                        style={{ height: 200 }}
                        className="responsive-img"
                        alt={"כרטיס ביקור דיגיטלי"}
                    />
                    : null
                }
            </div>


            <DragAndDropImageComponent filesSelected={selectedFilesUrl => {
                // set grid view callback
                console.log("selection");
                if (selectedFilesUrl) {
                    dropzoneUpdate(selectedFilesUrl)
                }
            }
            }
                limit={1}
            />

            <div style={{ margin: 10 }}>
                <TextInputComponent
                    title={t("Title")} //{this.props.t("ShopCategoryName")}
                    value={item.title}
                    textId="title"
                    handleChange={(e) => handleChangeCategoryItem(e)}
                />
            </div>

            <div style={{ margin: 10 }}>
                <TextInputComponent
                    title={t("Description")} //{this.props.t("ShopCategoryName")}
                    value={item.description}
                    textId="description"
                    handleChange={(e) => handleChangeCategoryItem(e)}
                />
            </div>


            <div style={{ margin: 10 }}>
                <TextInputComponent
                    title={t("Price")} //{this.props.t("ShopCategoryName")}
                    value={item.price}
                    textId="price"
                    handleChange={(e) => handleChangeCategoryItem(e)}
                />
            </div>

            <div style={{ margin: 10 }}>
                <TextInputComponent
                    title={t("OldPrice")} //{this.props.t("ShopCategoryName")}
                    value={item.oldPrice}
                    textId="oldPrice"
                    handleChange={(e) => handleChangeCategoryItem(e)}
                />
            </div>
            <ShopItemEditorGlobalPanelSettings
                item={item}
                handleChangeCategoryItem={(e) => handleChangeCategoryItem(e)}

            />


            <li style={{ width: "50px" }}>
                <div>
                    <RoundButtonBorderTextIcon
                        text={t("DeleteItem")}
                        iconName={"fa fa-trash-o"}
                        color={"red"}
                        buttonClick={(e) => itemDelete()}
                    />
                </div>
            </li>
            <div className="edit_button_card_title" dir="auto"> {t("Index")} {index} </div>

        </div>
    )
}

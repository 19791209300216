const _data = [];

const User = {
    //   add: item => _data.push(item),
    //   get: id => _data.find(d => d.id === id),
    setSelectedCard: (card) => _data.card = card,
    getSelectedCard: () => _data.card
}

Object.freeze(User);

export default User;
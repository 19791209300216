import React from 'react'

export default function LongRoundedButtonIconAndText({ text, textColor, iconName, iconColor, buttonColor, borderColor, buttonClick }) {

    let iconStyle = {
        color: iconColor ? iconColor : "blue",
        fontSize: 30,
        textAlign: "center",
        // backgroundColor:"blue"
    }

    let buttonStyle = {
        margin: 5,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: borderColor ? borderColor : "green",
        borderRadius: 8,
        textTransform: "none",
        height: 40,
        backgroundColor: buttonColor ? buttonColor : "white",
        fontFamily: "Varela Round",
    }

    return (
        <button

            className="waves-effect waves-light btn "
            style={buttonStyle}
            onClick={(e) => buttonClick(e)}>

            <i className={`${iconName} `}
                aria-hidden="true"
                style={iconStyle}>
            </i>

            <span
                style={{
                    color: textColor ? textColor : "black",
                    fontSize: 20,
                    margin: 5,
                    // backgroundColor: "pink"
                }}>
                {text}
            </span>

            {/* <i className={`${iconName} `}
                aria-hidden="true"
                style={iconStyle}>
            </i> */}

        </button>

    )
}

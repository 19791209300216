import { copyObject } from '../../Utils/UtilMethods'
import i18n from 'i18next';

const initState =
{

    dashboardComponentState: {
        isLoading: false,
        loadingTitle: "",
        error: null,
        cards: null,
    },

    tabsEditorComponent: {
        selectedCard: null
    },

    gallaryComponent: {
        isLoading: false,
        loadingTitle: "",
        error: null
    },

    shopComponent: {
        isLoading: false,
        loadingTitle: "",
        error: null
    },

    descriptionComponent: {
        isLoading: false,
        loadingTitle: i18n.t("Updating"),
        error: null
    },

    logoImageEditComponent: {
        isLoading: false,
        loadingTitle: i18n.t("Updating"),
        error: null
    },

    backgroundEditComponent: {
        isLoading: false,
        loadingTitle: i18n.t("Updating"),
        error: null
    },
    sharesEditComponent: {
        isLoading: false,
        loadingTitle: i18n.t("ShareButtonsUpdating"),
        error: null,
    },
    headerImageEditComponent: {
        isLoading: false,
        loadingTitle: "",
        error: null
    },
    visualCard: {
        selectedCard: null,
        didSearch: false
    }



}

const reduxGlobalReducer = (state = initState, action) => {

    var elementPos;
    switch (action.type) {
        case 'SIGNOUT_SUCCESS': {
            state = initState
            return state
        }
        case 'GOT_THE_CARD':
            return {
                ...state,
                visualCard: {
                    selectedCard: action.card,
                    didSearch: true,
                }
            }


        case 'NO_CARD':
            return {
                ...state,
                visualCard: {
                    selectedCard: action.card,
                    didSearch: true,
                }
            }


        case "DASH_BOARD_LOADING":
            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    loadingTitle: i18n.t("DownloadingYourV-Cards") + "...",
                    isLoading: true,
                }
            }

        case "DASH_BOARD_DELETE_CARD":
            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    loadingTitle: i18n.t("DeletingCard") + "...",
                    isLoading: true,
                }
            }

        case "DASH_BOARD_DELETE_RESPONSE":
            {

                let cards = copyObject(state.dashboardComponentState.cards);
                var filteredArray = cards.filter(function (card) {
                    return card.id != action.cardId;
                });

                // var item = myArray.find(item => item.id === 2);

                // cards.unshift(action.card);
                // return {
                //     ...state,

                //     dashboardComponentState: {
                //         ...state.dashboardComponentState,
                //         cards: cards,
                //     },
                // }

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: filteredArray,
                        loadingTitle: "",
                        isLoading: false,
                    }
                }
            }
        case "DASH_BOARD_DELETE_ERROR":
            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    loadingTitle: "",
                    isLoading: false,
                    error: action.error
                }
            }

        case "DASH_BOARD_CARDLIST_RESPONSE":

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    isLoading: false,
                    cards: action.cards
                }
            }

        case "DASH_BOARD_STATISTICS_RESPONSE":

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    statistics: action.statistics
                }
            }

        case "DASH_BOARD_CARDLIST_RESPONSE_ERROR":
            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    isLoading: false,
                    error: action.error
                }
            }

        // TABS EDITOR

        case "SET_SELECTED_CARD":
            return {
                ...state,
                tabsEditorComponent: {
                    selectedCard: action.card
                }
            }

        case "CLEAR_SELECTED_CARD":
            return {
                ...state,
                tabsEditorComponent: {
                    selectedCard: null
                },
                visualCard: {
                    selectedCard: null,
                    didSearch: false
                }
            }

        // Gallary 

        case "GALLARY_LOADING":
            return {
                ...state,
                gallaryComponent: {
                    isLoading: true,
                    loadingTitle: action.title,
                    error: null
                }
            }

        case "SHOP_LOADING":
            return {
                ...state,
                shopComponent: {
                    isLoading: true,
                    loadingTitle: action.title,
                    error: null
                }
            }
        case "UPDATE_GALLERYS_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.gallerys = action.gallerys

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    gallaryComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }

                }
            }

        case "UPDATE_SHOP_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.shop = action.shop

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    shopComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }

                }
            }

        case "CREATE_NEW_GALLERY_SUCCESS":
            {
                var gallerys = {};

                if (state.tabsEditorComponent.selectedCard.gallerys) {
                    gallerys = JSON.parse(JSON.stringify(state.tabsEditorComponent.selectedCard.gallerys));
                }
                gallerys = Object.assign({}, gallerys, action.gallery);


                let card = JSON.parse(JSON.stringify(state.tabsEditorComponent.selectedCard));
                card.gallerys = gallerys;

                let cards = JSON.parse(JSON.stringify(state.dashboardComponentState.cards));
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);


                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    gallaryComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        case "CREATE_NEW_GALLERY_FAILED":
        case "UPDATE_GALLERYS_FAILED":
            return {
                ...state,
                gallaryComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }

        case "UPDATE_SHOP_FAILED":
            return {
                ...state,
                shopComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }

        case "DELETE_GALLERY_SUCCESS":
            {
                let card = JSON.parse(JSON.stringify(state.tabsEditorComponent.selectedCard));
                card.gallerys = action.gallerys;

                let cards = JSON.parse(JSON.stringify(state.dashboardComponentState.cards));
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);


                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    gallaryComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        case "UPDATE_GALLERY_SUCCESS":
            {
                let card = JSON.parse(JSON.stringify(state.tabsEditorComponent.selectedCard));
                card.gallerys[action.galleryId] = action.gallery;

                let cards = JSON.parse(JSON.stringify(state.dashboardComponentState.cards));
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);


                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    gallaryComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }
        case "DELETE_GALLERY_FAILED":
            return {
                ...state,
                gallaryComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }

        case 'CREATE_CARD_SUCCESS':
            {
                let cards = copyObject(state.dashboardComponentState.cards);
                cards.unshift(action.card);
                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },
                }
            }
        case "ADD_URL_TO_GALLARY_LIST":
            {
                let gallerys = copyObject(state.tabsEditorComponent.selectedCard.gallerys);
                gallerys[action.galleryId] = action.gallery

                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.gallerys = gallerys;

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },


                }
            }

        case "UPDATE_GALLARY_LIST_NAME":
            {
                let gallerys = copyObject(state.tabsEditorComponent.selectedCard.gallerys);
                gallerys[action.galleryId] = action.gallery

                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.gallerys = gallerys;

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)
                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },


                }
            }

        case "REMOVE_URL_FROM_GALLARY_LIST":
            {
                let gallerys = copyObject(state.tabsEditorComponent.selectedCard.gallerys);
                gallerys[action.galleryId].list = action.list


                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.gallerys = gallerys;

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },


                }
            }
        case "IS_LOADING_ANIMATION":
            return {
                ...state,
                descriptionComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingAnimation"),
                    error: null
                }
            }
        case "ANIMATION_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.animation = action.animation

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    descriptionComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }
        case "IS_LOADING_DESCRIPTION":
            return {
                ...state,
                descriptionComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingDescription"),
                    error: null
                }
            }

        case "DESCRIPTION_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.description = action.data

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,

                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },

                    descriptionComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        case "DESCRIPTION_UPDATE_FAILED":
            return {
                ...state,
                descriptionComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }

        case "IS_LOADING_LOGO_IMAGE":
            return {
                ...state,
                logoImageEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingLogo"),
                    error: null
                },
            }

        case "LOGO_IMAGE_UPDATE_SUCCESS":

            let card = copyObject(state.tabsEditorComponent.selectedCard);
            card.logo.url = action.url

            let cards = copyObject(state.dashboardComponentState.cards);
            elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

            cards.splice(elementPos, 1, card)

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    cards: cards,
                },

                tabsEditorComponent: {
                    selectedCard: card
                },
                logoImageEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: null
                }
            }

        case "LOGO_IMAGE_UPDATE_FAILED":
        case "LOGO_SETTING_UPDATE_FAILED":
            return {
                ...state,
                logoImageEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }
        case "IS_LOADING_LOGO_SETTINGS":
            return {
                ...state,
                logoImageEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingLogoSettings"),
                    error: null
                }
            }

        case "LOGO_SETTING_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.logo = action.logo

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    logoImageEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        //header
        case "IS_LOADING_HEADER_IMAGE":
            return {
                ...state,
                headerImageEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingHeaderImage"),
                    error: null
                },
            }

        case "HEADER_IMAGE_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.header.url = action.url

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    headerImageEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }
        case "HEADER_IMAGE_UPDATE_FAILED":
        case "HEADER_SETTING_UPDATE_FAILED":
            return {
                ...state,
                headerImageEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }
        case "IS_LOADING_HEADER_SETTINGS":
            return {
                ...state,
                headerImageEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingHeaderSettings"),
                    error: null
                }
            }

        case "HEADER_SETTING_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.header = action.header

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    headerImageEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        // backgground

        case "BACKGROUND_SETTING_UPDATE_FAILED":
            return {
                ...state,
                backgroundEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }
        case "IS_LOADING_BACKGROUND_SETTINGS":
            return {
                ...state,
                backgroundEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingBackgroundSettings"),
                    error: null
                }
            }

        case "BACKGROUND_SETTING_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.background = action.background

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    backgroundEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }
        case "BACKGROUND_IMAGE_SETTING_UPDATE_SUCCESS": {
            let card = copyObject(state.tabsEditorComponent.selectedCard);
            card.background.url = action.url

            let cards = copyObject(state.dashboardComponentState.cards);
            elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

            cards.splice(elementPos, 1, card)

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    cards: cards,
                },

                tabsEditorComponent: {
                    selectedCard: card
                },
                backgroundEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: null
                }
            }
        }
        //

        case "SEO_SETTING_UPDATE_FAILED":

            return {
                ...state,
                sharesEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }
        case "SHARES_SETTING_UPDATE_FAILED":

            return {
                ...state,
                sharesEditComponent: {
                    isLoading: false,
                    loadingTitle: "",
                    error: action.error
                }
            }
        case "IS_LOADING_SHARES_SETTINGS":
            return {
                ...state,
                sharesEditComponent: {
                    isLoading: true,
                    loadingTitle: i18n.t("UpdatingShareSettings"),
                    error: null
                }
            }

        case "SHARES_SETTING_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.shares = action.shares

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    sharesEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }

        case "SEO_SETTING_UPDATE_SUCCESS":
            {
                let card = copyObject(state.tabsEditorComponent.selectedCard);
                card.seo = action.seo

                let cards = copyObject(state.dashboardComponentState.cards);
                elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

                cards.splice(elementPos, 1, card)

                return {
                    ...state,
                    dashboardComponentState: {
                        ...state.dashboardComponentState,
                        cards: cards,
                    },

                    tabsEditorComponent: {
                        selectedCard: card
                    },
                    sharesEditComponent: {
                        isLoading: false,
                        loadingTitle: "",
                        error: null
                    }
                }
            }
        //
        case "UPDATE_VIDEOS_LIST": {
            let card = copyObject(state.tabsEditorComponent.selectedCard);
            card.videos = action.videos

            let cards = copyObject(state.dashboardComponentState.cards);
            elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

            cards.splice(elementPos, 1, card)

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    cards: cards,
                },

                tabsEditorComponent: {
                    selectedCard: card
                }

            }
        }

        case "UPDATE_RECTANGLE_LIST": {
            let card = copyObject(state.tabsEditorComponent.selectedCard);
            card.rectangleButtons = action.rectangleButtons

            let cards = copyObject(state.dashboardComponentState.cards);
            elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

            cards.splice(elementPos, 1, card)

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    cards: cards,
                },

                tabsEditorComponent: {
                    selectedCard: card
                }

            }
        }
        case "UPDATE_ROUNDED_LIST": {
            let card = copyObject(state.tabsEditorComponent.selectedCard);
            card.roundedButtons = action.roundedButtons

            let cards = copyObject(state.dashboardComponentState.cards);
            elementPos = cards.map(function (x) { return x.id; }).indexOf(card.id);

            cards.splice(elementPos, 1, card)

            return {
                ...state,
                dashboardComponentState: {
                    ...state.dashboardComponentState,
                    cards: cards,
                },

                tabsEditorComponent: {
                    selectedCard: card
                }

            }
        }
        default:
            return state;
    }
}

export default reduxGlobalReducer
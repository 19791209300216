import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import Navbar from "../../layout/Navbar";
import Tabs from './Tabs'
import EditRoundedContactButtons from './RoundedButtons/EditRoundedContactButtons'
import EditRectangleContactButtons from './RectangleButtons/EditRectangleContactButtons'
import GallerysComponent from './GalleryEditorComponents/GallerysComponent'
import AudioVideoEditorComponent from './AudioVideoEditors/AudioVideoEditorComponent'
import ShareEditComponent from './Shares/ShareEditComponent';
import TopAreaEditorContainer from './TopArea/TopAreaEditorContainer';
import ShopCreateCatAndItems from './ShopEditor/ShopCreateCatAndItems';

export class TabsLayout extends Component {

    componentWillMount() {
        console.info("componentWillMount");
        if (!this.props.reduxSelectedCard) {
            this.props.history.push('/');
        }
    }

    render() {

        const { auth, reduxSelectedCard } = this.props;
        if (!reduxSelectedCard) return <Redirect to='/dashboard' />
        if (auth.uid != reduxSelectedCard.authorId) return <Redirect to='/dashboard' />

        if (!reduxSelectedCard) {
            return <div></div>
        }

        return (
            <div >
                <Navbar />

                <div className="  navbar_padding ">
                    <div className="myTabs center">
                        <Tabs cardId={reduxSelectedCard.id}>
                            <Tab label="Top Area" id="1">
                                <div className="container">
                                    <TopAreaEditorContainer />
                                    {/* <EditRoundedContactButtons roundedButtons={this.props.location.state.cardDetails.roundedButtons} cardID={this.props.match.params.id} /> */}
                                </div>
                            </Tab>

                            <Tab label="Rounded" id="2">
                                {
                                    reduxSelectedCard ?
                                        <EditRoundedContactButtons roundedButtons={reduxSelectedCard.roundedButtons} cardID={reduxSelectedCard.id} />
                                        : null
                                }
                            </Tab>
                            <Tab label="Rectangle" id="3">
                                {
                                    reduxSelectedCard ?
                                        <EditRectangleContactButtons rectangleButtons={reduxSelectedCard.rectangleButtons} cardID={reduxSelectedCard.id} />
                                        : null
                                }
                            </Tab>
                            <Tab label="Gallerys" id="4">
                                <GallerysComponent gallerys={reduxSelectedCard.gallerys} cardID={reduxSelectedCard.id} />
                            </Tab>

                            <Tab label="Video" id="5">
                                <div>
                                    {
                                        reduxSelectedCard ?
                                            <AudioVideoEditorComponent videos={reduxSelectedCard.videos} cardID={reduxSelectedCard.id} />
                                            : null
                                    }

                                </div>
                            </Tab>
                            <Tab label="Share" id="6">
                                <div>
                                    {
                                        reduxSelectedCard ?
                                            <ShareEditComponent />
                                            : null
                                    }

                                </div>
                            </Tab>

                            {
                                reduxSelectedCard && reduxSelectedCard.info.allowShop ?
                                    <Tab label="Shop" id="7">
                                        <div>
                                            {
                                                reduxSelectedCard ?
                                                    <ShopCreateCatAndItems shop={reduxSelectedCard.shop} cardID={reduxSelectedCard.id} />
                                                    : null
                                            }

                                        </div>
                                    </Tab>
                                    : null

                            }


                        </Tabs>
                    </div>

                </div>

            </div >
        );
    }


}


const Tab = (props) => {
    return <React.Fragment>{props.children}</React.Fragment>;
};


const mapDispatchToProps = (dispatch) => {
    return (
        {
        })
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsLayout)

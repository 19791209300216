import React from 'react'

export default function CenterText({ textColor, fontSize, fontFamily, text, marginTop }) {
    return (
        <div
            className="center"
            dir="auto"
            style={{
                color: textColor,
                fontSize: fontSize,
                fontFamily: fontFamily,
                marginTop: marginTop ? marginTop : 0
            }}>
            {text}
        </div>
    )
}

import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from "../layout/Navbar";

import cardUdisLogo from '../../images/Logos/cardudis_logo.png'
import PriceListComponent from './PriceListComponent'
import Favicon from 'react-favicon';

import { incrementCardUdisNewVisitors, } from '../../Utils/UtilMethods'
import ContactCardUdisWhatsapp from '../ContactCardUdisWhatsapp';
import WhatWeHaveInCard from './WhatWeHaveInCard';
import ContactCardudisFormComponent from './ContactCardudisFormComponent'
import HomeTopView from './HomeTopView';
import AOS from 'aos';
import "aos/dist/aos.css"

class Home extends Component {

    constructor(props, context) {
        super(props, context);
        AOS.init();
    }

    saveUserSeenPage = () => {
        localStorage.setItem("cardUdis_newVisitor", true);
    }

    didUserVisitThisCardIdBefore() {
        return localStorage.getItem("cardUdis_newVisitor")
    }

    componentDidMount() {

        if (!this.didUserVisitThisCardIdBefore()) {
            this.saveUserSeenPage()
            incrementCardUdisNewVisitors()
        }
    }

    render() {
        const { auth } = this.props;
        if (auth.uid) return <Redirect to='/dashboard' />
        return (
            <div>
                <Favicon url={cardUdisLogo} />
                <Navbar />
                <HomeTopView />
                <WhatWeHaveInCard />
                <PriceListComponent />
                <ContactCardudisFormComponent />
                <ContactCardUdisWhatsapp />

                {/* <footer id="footer" className="center-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="socail_bookmark">
                                    <a href=""><i className="fa fa-twitter"></i></a>
                                    <a href=""><i className="fa fa-facebook"></i></a>
                                    <a href=""><i className="fa fa-linkedin"></i></a>
                                    <a href=""><i className="fa fa-google-plus"></i></a>
                                    <a href=""><i className="fa fa-instagram"></i></a>
                                    <a href=""><i className="fa fa-pinterest-p"></i></a>
                                    <a href=""><i className="fa fa-dribbble"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer> */}
            </div>

        )
    }
}
// HOC
// map state from store to props
const mapStateToProps = (state) => {
    return {

        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps)
)(Home)

const initState =
{
    isLoading: false,
    isLoadingTabs: false,
    createdNewCard: false
}

const cardReducer = (state = initState, action) => {

    switch (action.type) {
        case 'CLEAR_CREATED_NEW_CARD':
            return {
                isLoading: false,
                createdNewCard: false
            }
        case 'CREATE_CARD_SUCCESS':
            console.log('create project success');
            return {
                isLoading: false,
                createdNewCard: true
            };
        case 'CREATE_CARD_ERROR':
            console.log('create project error');
            return {
                isLoading: false,
                createdNewCard: false
            };
        case "IS_LOADING_NEW_CARD":
            return {
                ...state,
                isLoading: true,
            };

        case "IS_LOADING_TABS":
            return {
                ...state,
                isLoadingTabs: true,
            };

        case "SELECTED_EDIT_CARD":
            return {
                ...state,
                selectedEditCard: action.card
            }
        case 'TEST':
            console.log(' CARD REDUCER TESTING TEST')
            return {
                ...state,
                justTestValue: "THIS IS A TEST VALUE"
            }
        case 'SAVE_CARD_INFO':
            console.log("in SAVE_CARD_INFO");

            return {
                ...state,
                [action.key]: action.value
            }

        case 'SELECTED_CARD':
            console.log("SELECTED_CARD");

            return action.state


        case "USER_CARD_LIST":

            return {
                isLoading: false,
                cardList: action.cards
            }

        default:
            return state;
    }
}

export default cardReducer
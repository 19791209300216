import React from 'react'

export default function Divider({ color, height }) {
    return (
        <hr className="solid"
            style={{
                margin: 0,
                borderColor: color ? color : null,
                borderWidth: height ? height : null
            }}>

        </hr>
    )
}

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import { getPackageList } from './Utils/UtilMethods'
i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                "AutoPlay": "Auto play",
                "AnimationSetting": "Animation Setting",
                "UpdatingAnimation": "Updating Animation settings...",
                "Logout": "Log Out",
                "SureLogout?": "Are you sure to log out ?",
                "Add": "Add",
                "Preview": "Preview",
                "HideBGI": "Hide Background image",
                "ClickToPayment": "Click to pass to payment",
                "CardInfo": "Card info",
                "ClickWatchCard": "Click to Watch card",
                "TotalEntries": "Total of entries:",
                "TotalEntriesMonth": "Entries this month",
                "NumberDifferentVisitors": "New visitors this month",
                "Rect_Click": "Rectangle Buttons Clicks",
                "Shop_Buttons_Click": "Rectangle Buttons Clicks",
                "RoundedClick": "Rounded Buttons Clicks",
                "DaysForTrail": "Days left for trail",
                "Paid": "Paid:",
                "No": "No",
                "Type": "Type:",
                "CreatedAt": "Created at",
                "CardId": "Card Id",
                "LogIn": "Log In",
                "SignUp": "SignUp",
                "FirstName": "First Name",
                "lName": "Last Name",
                "Email": "Email",
                "Subject": "Subject",
                "Message": "Message",
                "SendMessage": "Send Message",
                "PhoneNumber": "Phone Number",
                "Password": "Password",
                "ClickSignUp": "Click to sign Up",
                "SignIn": "Sign In",
                "ClickLogin": "Login",
                "Clicks": "Clicks",
                "Rounded": "Rounded",
                "TopArea": "Top Area",
                "Rectangles": "Rectangles",
                "Gallerys": "Gallerys",
                "Video": "Video",
                "Shares": "Shares",
                "Shop": "Shop",
                "DigitalCard": "Digital Business Card",
                "DigitalCardHeader": "For marketing and business promotion.",
                "Welcome": "Welcome, ",
                "AboutCardUdis": "CardUdis is a system that mainly allows you to create digital business cards to describe your business and make quick contact with it. The card can contain great content from the business owner such as high quality photo gallery, visual links to YouTube videos, business text, hours of operation, menus and so on. However, you can attach navigation buttons to the business, create a phone call or Whatsapp message, send email or digital form, add social networking links, share buttons that easily sends your card via s.m.s, e-mail, whatsapp and so on. However, you can create birthday invitation cards, wedding invitations with the option of arrival certificates and lots of other cool features!",
                "WhatDoWeHaveInCard": "What Do We Have In The Card?",
                "NavButton": "Navigation",
                "SaveContactButton": "Save Contact",
                "DialButton": "Quick Dial",
                "SendEmailButton": "Send Email",
                "WhatsAppButton": "Whatsapp",
                "WebLinkButton": "Redirect Link",
                "ShareLinkNetworsButton": "Network Share",
                "PersonalDesignButton": "Design",
                "YouTubeShareVideos": "Youtue Videos",
                "ImageGallerys": "Image Gallery",
                "MainTopImage": "Main Top Image",
                "LogoBusiness": "Business Logo",

                "NavButtonDescription": "A button that opens the Waze app and starts instant navigation to your business!",
                "SaveContactButtonDescription": "Button to download business contact information, straight to your device's contact list.",
                "DialButtonDescription": "A button that opens a direct dial to the business.",
                "SendEmailButtonDescription": "A directed button or form that allows you to send an email to the business, upon request or contact with the customer.",
                "WhatsAppButtonDescription": "A button that opens a chat conversation between the customer and a representative from the business.",
                "WebLinkButtonDescription": "Buttons that open all web pages as requested, such as personal website, Facebook, Instagram etc...",
                "ShareLinkNetworsButtonDescription": "Buttons with caption and business card routing, ready to send and share via Wetsap, SMS and Email.",
                "PersonalDesignButtonDescription": "CardUdis is built so you can design the card as you like, colors, images, backgrounds, sizes, margins.",
                "YouTubeShareVideosDescription": "Allows viewing of linked YouTube videos, with the option to run and display in full screen.",
                "GallerysDescription": "The ability to open galleries with different names with different content of images displayed in the carousel one after another.",
                "MainTopImageDescription": "Option for top image that represents the business.",
                "LogoBusinessDescription": "Logo design is added to the business card.",


                "GetInTouch": "Get in Touch",
                "GetInTouchDescription": "Want samples of business cards straight to your mobile? Want us to make you a business card (no obligation) ? interests you? Send us a message and we will be happy to assist you as soon as posible.",


                "ContactWhatsappBtnSendText": "Hi Udi, I am intrested with creating my own digital busines card, please contact me!",
                "CardName": "Card Name",
                "CheckAvailable": "Check availability",
                "CreateNewCard": "Create New Card",
                "CardTitle": "Card title",
                "CardSubtitle": "Card subtitle",
                "CreateNew": "Card New",
                "CardLanguage": "Card Language",
                "TitleAndSubtitle": "Title & Subtitle",
                "Subtitle": "Subtitle",
                "Title": "Title",
                "SaveChanges": "Save Changes",
                "MarginTop": "Margin Top",
                "OfAllBlock": "of all the block",
                "FontSize": "Font Size",
                "Hide": "Hide",
                "HideButton": "Hide button",
                "Text": "Text",

                "Background": "Background",
                "SelectBgColor": " Select card background color",
                "SelectBgImage": " Select card background image",
                "Update": "Update",
                "BackgroundColor": "Background Color",
                "HideImage": "Hide image",
                "SelectNewImage": "Select new image",


                "SetAll?": "Set all?",
                "SetAll": "Set all",
                "setAllButtons?": "Do you want to set all buttons",
                "colorWithSelectedColor?": "color with the selected color?",
                "withShape?": "with the selected shape?",
                "Square": "Square",
                "Circle": "Circle",
                "Shape": "Shape",
                "Ok": "Ok",
                "Cancel": "Cancel",
                "Yes": "Yes",
                "CardExpired": "Card expired",
                "TimeExpired": "Time of card expired, If you still want to join us, pass to payment",
                "Payment": "Payment",
                "CardLocked": "Card Locked",
                "Font": "Font",
                "NewButton": "Add new button",
                "MissingName!": "Missing Name!",
                "enterGalleryName.": "Please enter a name for your new gallery.",
                "UpdatingGallery": "Updating gallery ...",
                "NameMissing!": "Name Missing!",
                "ChangeToValid": "Please change to a valid name and save!",
                "GalleryName": "Gallery Name",
                "HideTitle": "Hide title",
                "Border": "Border",
                "SelectGallery": "Select Gallery",
                "DeleteGallery?": "Delete Gallery?",
                "DeletingGallery": "Deleting gallery...",
                "SureDeleteGallery?": "Are you sure to delete this gallery?",
                "CreateNewGalleryWithName": "Create new gallery with name",
                "CreateNewGallery": "Create New gallery",
                "DeleteGallery": "Delete Gallery",
                "NoGallerys": "No gallerys yet :-( ",
                "TitleOfNewGallery": "Title of new gallery",
                "DeletingImages": "Deleting images...",
                "NoImagesYet": "No images yet",
                "DeleteSelectedFiles": "Delete selected files",
                "Gallery": "Gallery",
                "UploadNewImages": "Upload new images",
                "UploadingImages": "Uploading images...",
                "UploadAll": "Upload all",
                "Drag_N_Drop": "Drag 'n' drop images, or click to select files",
                "LogoImage": "Logo Image",
                "SelectNewLogo": "Select New Logo",
                "UpdateNewLogo": "Update new logo image",
                "BorderWidth": "Border Width",
                "BottomBorderWidth": "Border Width",

                'Button': "Button",
                "DeleteButton?": "Delete button?",
                "SureDelete?": "Are you sure to delete this button ?",
                "Delete": "Delete",
                "Icon": "Icon",

                "ButtonTitle": "Button Title",
                "Description": "Description",
                "Keywords": "Keyworkds",

                "Index": "Index",

                "AddTitle": "Add title",
                "AddDescription": "Add description",

                "EnterE-Mail": "Enter E-Mail",
                "WillSendE_Mail": "Will send customer email to your email",
                "Address": "Address",
                "City": "City",
                "WillOpenBrowser": "Will open browser with Url",
                "VCardTitle": "Contact card title",
                "EnterAnyPhone": "Enter any phone number to call",
                "FormLanguage": "Form Language",

                "WillOpenChat": "Will open whatsapp chat with number",
                "PhoneWithCountry": "Phone number with country code example :972544112121",
                "Waze.comPath": "Waze.com Path",
                "WillOpenEmail": "Will open email to send mail to",
                "WillCall": "Phone number to call to",
                "WillSend_sms": "Phone number to send SMS",
                "AddNewCard": "New card",
                "CellPhoneNumber": "Cell phone number",

                "Call": "Call",
                "Whatsapp": "Whatsapp",
                "WhatsappFast": "Fast whatsapp respond",
                "Navigate": "Navigate",

                "SaveToContacts": "Save to Contacts",
                "AboutMe": "About me",
                "SendMessageForm": "Send message Form",
                "sms": "sms",
                "txtAboutMeDesc": "This is a text about me and my company",
                "Hey": "Hey",
                "ShareWithFriends": "Share with friends",
                "ClickTheLink": "Click on the following link to view the business card of ",
                "CreatingCard!": "Creating Card!",
                "ImageStyle": "Image Style",
                "UpdateNewHeader": "Update new header image",
                "DeleteVideo?": "Delete video?",
                "DeleteVideoSure": "Are you sure to delete this video link ?",
                "VideoTitle": "Video title",
                "VideoLink": "Video link",
                // "YouTubeId": "YouTube Video ID",
                "ShareArea": "Share Area",

                "YourShareLink": "Your share link is",
                "EnterShareButtonsTitle": "Enter share buttons title",
                "TextForShare": "Text for share",
                "ButtonColor": "Button Color",
                "YouTubeVideoInstruction": "Please notice! you tube video should start like this:",
                "HideShareArea": "Hide share area from card ",
                "AddVideo": "Add video",
                "AddGallery": "Add gallery",
                "SelectAll": "Select all",
                "SelectedImages": "Selected images",
                "Sort": "Sort",
                "Off": "Off",
                "On": "On",
                "SigningIn": "Signing in",
                "CreatingNewUser": "Creating new user",
                "DownloadingYourV-Cards": "Downloading your V-Cards",
                "SigningOut": "Signing out",
                "DayForPaidCard": "Days left",
                "Statistics": "statistics",
                "Payments": "Payments",
                "PaymentDate": "Payment Date",
                "ExparationDate": "Exparation Date",
                "User": "User",
                "MailValidation!": "Mail Validation!",
                "PleaseCheckEmail": "Please, Check your email, copy the CODE, Paste it here, and start enjoying CardUdis.",
                "EnterYourCode!": "ENTER YOUR CODE!",
                "MissingPassword": "Missing password",
                "WrongCode!": "Wrong code!",
                "Hello": "Hello",
                "Dashboard": "Dashboard",
                "Continue": "Continue",
                "UpdatingDescription": "Updating Description...",

                "Updating": "Updating...",
                "ShareButtonsUpdating": "Share buttons updating...",
                "UpdatingLogo": "Updating Logo...",
                "UpdatingLogoSettings": "Updating logo settings...",
                "UpdatingHeaderImage": "Updating header image...",
                "UpdatingHeaderSettings": "Updating header settings...",
                "UpdatingBackgroundSettings": "Updating background settings...",
                "UpdatingShareSettings": "Updating share settings",
                "CouldNotEmail": "Could not send email.\nPlease try again later.",
                "Fonts": "Fonts",
                "WithYourChoosenStyle": "with your chosen one?",
                "WithYourChoosenSize": "with your chosen size?",

                "Small": "Small",
                "Medium": "Medium",
                "Large": "Large",
                "ILS": "ILS",

                "AllUnlimited": "All options are unlimited",
                "PersonalSupport": "Personal support via mail or whatsapp",
                "PersonalDomain": "Personal domain name",
                "LicenseToAndroidApp": "License to android app",
                "UpToPhotoUpload": "Up to {{value}} photos upload",
                "UpToWide": "Up to {{value}} wide buttons",
                "UpToRounded": "Up to {{value}} rounded small buttons",
                "UpToGallery": "Up to {{value}} gallery",
                "UpToDays": "Period of {{value}} days",
                "": "",
                "UnlimitedWide": "Unlimited wide buttons",
                "UnlimitedRounded": "Unlimited rounded small buttons",
                "UnlimitedGallerys": "Unlimited gallerys",
                "SendingEmailTo": "Sending email to {{value}}",
                "CardUdis": "CardUdis",
                "TryFree": "Try for FREE!",
                "RegisterAndTry": "{{value}} trail days!",
                "RegisterNow": "Register Now",
                "Profile": "Profile",
                "PlusVat": "+VAT",
                "CreatingGallery": "Creating new gallery",
                "UpdatingGalery": "Updating galery",
                "DeleteCard?": "Delete card?",
                "DeleteCardSure?": "Are you sure you want todelete the card?",
                "DeletingCard": "Deleting card...",
                "EmailExist": "This email already exist!.",
                "CantEmail": "We could not email you. Please contact support.",
                "CardFitsToPackage": "According to our calculation, it seems that the package that best suits your card is\n",
                "ClickSendEmailForm": "Filling out a form to send mail to the business",
                "InfoButton": "A button that expands and displays long text about business",
                "InfoButtonCustom": "A button that expands and displays long custom design text about business ",
                "CallTo": "A button that dials a number to business",
                "SMS_Message": "Button that opens the device's messages\nto send a text message to business",
                "WhatsAppMessage": "Button that opens WhatsApp to send a text message to business",
                "WazeButton": "Button that opens Waze and starts navigation to business",
                "MailButton": "Button that opens mailer in device to send email to business",
                "ContactButton": "Button that downloads the business contact to the client contacts",
                "LinkButton": "Button that opens new browser page with the given url",
                "Number_OpenGallerys": "Number of gallerys opened",
                "Number_ImageUpload": "Number of images uploaded",
                "Number_WideButtons": "Number of wide buttons",
                "Number_RoundedButtons": "Number of rounded buttons",
                "Number_VideoShares": "Number of video shares",
                "SmallBc": "Small size business card",
                "MediumBc": "Medium size business card",
                "LargeBc": "Large size business card",
                "Price": "Price",
                "LoadingPaypal": "Loading Secure paypal payment system",
                "CreateOrder": "Creating your order",
                "OrderApproved_fetchInfo": "Order approved!\n Fetching Transaction data",
                "UpdateCardInfo": "Updating card info",
                "YouTubeVideoOnly": "YouTube url Only!",
                "BottomBorderColor": "Bottom Border Color",
                "Carusel": "Carusel",
                "GridList": "Collection",
                "DisplayStyle": "Display Style",
                "Copyright": "Copyrights",
                "userShowVideo": "Show video",
                "AddShop": "Add shop category",
                "AddItem": "Add item",
                "ShopCategoryName": "Category name",
                "DeleteItem": "Delete item",
                "SureDeleteItem": "Are you sure to delete item",
                "DeleteCategory": "Delete category",
                "SureDeleteCategory": "are you sure to delete category",
                "OldPrice": "Old price",
                "WhatsappNumber": "Whatsapp Number",
                "SmsNumber": "Sms Number",
                "EnableShopListWithSms": "Enable send shop list with SMS",
                "EnableShopListWhatsapp": "Enable send shop list with Whatsapp",
                "EnableShopListEmail": "Enable send shop list with Email",
                "ItemImages": "Item images",
                "Shipping": "Shipping",
                "ShippingTitle": "Shipping title",
                "EnableShipping": "Enable Shipping",
                "ShippingPrice": "Shipping Price",
                "ShippingFreeFromPrice": "Shipping is free for resevastion start from:",

            }
        },
        heb: {
            translations: {
                "AutoPlay": "גלגל אוטומטית",
                "AnimationSetting": "מאפייני אנימציה",
                "UpdatingAnimation": "מעדכן מאפייני אנימציה",
                Logout: "התנתק",
                "Keywords": "מילות מפתח",
                "SureLogout?": "אתה בטוח שתרצה להתנתק?",
                "Add": "הוסף",
                "Preview": "תצוגה מקדימה",
                "CardLocked": "כרטיס נעול",
                "ClickToPayment": "לחץ למעבר לתשלום",
                "CardInfo": "פרטי כרטיס",
                "ClickWatchCard": "לחץ לצפיה בכרטיס",
                "TotalEntries": "סה״כ כניסות לכרטיס",
                "TotalEntriesMonth": "סה״כ כניסות החודש",
                "NumberDifferentVisitors": "סה״כ מבקרים חדשים החודש",
                "RectangleButtonsClicks:": "לחיצות על לחצני מלבן:",
                "Rect_Click": "לחיצות על לחצני מלבן",
                "Shop_Buttons_Click": "לחיצות בחנות",
                "RoundedClick": "לחיצות על לחצני עיגול/ריבוע",
                "DaysForTrail": "מספר ימי הניסיון שנותרו",
                "Paid": "שולם",
                "No": "לא",
                "Type": "סוג",
                "CreatedAt": "נוצר ב",
                "CardId": "מזהה כרטיס",
                "LogIn": "כניסה",
                "SignUp": "הרשמה",
                "FirstName": "שם פרטי",
                "lName": "שם משפחה",
                "Email": "אימייל",
                "Subject": "נושא",
                "PhoneNumber": "מספר טלפון",
                "WhatsappNumber": "מספר וואטסאפ",
                "Message": "הודעה",
                "SendMessage": "שלח הודעה",
                "Password": "סיסמא",
                "ClickSignUp": "לחץ להרשמה",
                "SignIn": "כניסה",
                "ClickLogin": "לחץ לכניסה",
                "Clicks": "לחיצות",
                "Rounded": "מעוגלים",
                "TopArea": "חלק עליון",
                "Rectangles": "מלבנים",
                "Gallerys": "גלריות",
                "Video": "וידאו",
                "Shares": "שיתופים",
                "Shop": "חנות",
                "Welcome": "ברוכים הבאים,",
                "DigitalCard": "כרטיס ביקור דיגיטלי",
                "AboutCardUdis": "קארדודיז הינה מערכת המאפשרת בעיקר יצירת כרטיסי ביקור דיגטלי לעסק. הכרטיס יכול להכיל תוכן רב של בעל העסק כגון גלריית תמונות באיכות גבוהה,קישורים ויזואלים לסרטוני יוטיוב,  טקסט אודות העסק, שעות פעילות, תפריטים וכיו״ב. עם זאת, ניתן לצרף לחצני ניווט אל העסק, יצירת שיחת טלפון או הודעת וואטסאפ , אפשרות לשליחת טופס או מייל, אפשרות להוספת לינקים לרשתות חברתיות ולחצני שיתוף כרטיס הביקור בקלות ובמהירות וכדומה. עם זאת, ניתן ליצר כרטיסים להזמנה לימי הולדת, הזמנה לחתונה עם אפשרות לאישורי הגעה ועוד המון אפשרויות ופיצ׳רים מגניבים!",
                "WhatDoWeHaveInCard": "אז מה יש לנו בכרטיס?",
                "DigitalCardHeader": "לשיווק וקידום העסק.",
                "NavButton": "ניווט",
                "SaveContactButton": "שמירת איש קשר",
                "DialButton": "התקשרות מהירה",
                "SendEmailButton": "אימייל",
                "WhatsAppButton": "וואטסאפ",
                "WebLinkButton": "קישור לאתרים",
                "ShareLinkNetworsButton": "שיתוף הכרטיס",
                "PersonalDesignButton": "עיצוב אישי",
                "YouTubeShareVideos": "סרטוני יוטיוב",
                "ImageGallerys": "גלריות תמונות",
                "MainTopImage": "תמונה ראשית",
                "LogoBusiness": "תמונת לוגו",
                "PersonalDesc": "כותרת ראשית ומשנית",

                "NavButtonDescription": "לחצן שפותח את אפליקציית וויז ומתחיל ניווט באופן מיידי אל העסק שלך!",
                "SaveContactButtonDescription": "לחצן להורדת פרטי ההתקשרות לעסק, ישר לרשימת אנשי הקשר שבמכשיר.",
                "DialButtonDescription": "לחצן שפותח חיוג טלפוני ישר אל העסק.",
                "SendEmailButtonDescription": "לחצן או טופס מכוון, המאפשר שליחת מייל אל העסק, עם בקשה או פניה של הלקוח.",
                "WhatsAppButtonDescription": "לחצן שפותח שיחת צ׳ט בין הלקוח ונציג מהעסק.בעזרת שימוש באפליקציית וואטסאפ.",
                "WebLinkButtonDescription": "לחצנים אשר יפתחו כל דפי אינטרנט כמבוקש, לדגמא אתר אישי, פייסבוק, אינסטגרם וכיו״ב.",
                "ShareLinkNetworsButtonDescription": "לחצנים עם כיתוב וניתוב לכרטיס הביקור, מוכנים לשליחה ושיתוף בוואטסאפ, סמס ואימייל.",
                "PersonalDesignButtonDescription": "מערכת קארדודיז בנויה כך שתוכל לעצב את הכרטיס כרצונך, צבעים, תמונות, רקעים, וגדלים.",
                "YouTubeShareVideosDescription": "מאפשר תצוגה של סרטוני יוטיוב מקושרים, עם אפשרות להרצה והצגה על מסך מלא.",
                "GallerysDescription": "אפשרות לפתיחת גלריות בשמות שונים עם תוכן שונה של תמונות שמוצגות בקרוסלה זו אחר זו.",
                "MainTopImageDescription": "אפשרות לתמונה ראשית עליונה המייצגת את העסק.",
                "LogoBusinessDescription": "הוספה של תמונות לוגו מעוצבת לכרטיס הביקור.",

                "GetInTouch": "תהיה בקשר!",
                "GetInTouchDescription": "רוצה לקבל דוגמאות של כרטיסי ביקור ישר לנייד?\n רוצה שנעשה לך כרטיס ביקור (ללא התחייבות)?\n מעניין אותך?\n שלח לנו הודעה ונשמח לעמוד לשירותך בכל רגע.",

                "ContactWhatsappBtnSendText": "הי אודי, אני מעוניין ליצור כרטיס ביקור דיגיטלי משלי, אנא צור איתי קשר!",
                "CardName": "שם הכרטיס",

                "CheckAvailable": "בדוק זמינות",
                "CreateNewCard": "צור כרטיס חדש",
                "CardTitle": "כותרת ראשית לכרטיס",
                "CardSubtitle": "כותרת משנית לכרטיס",
                "CreateNew": "צור כרטיס",
                "CardLanguage": "שפת הכרטיס",

                "Subtitle": "תת כותרת",
                "Title": "כותרת",
                "SaveChanges": "שמור שינויים",
                "MarginTop": "ריווח עליון",
                "OfAllBlock": "של כל הבלוק",
                "FontSize": "גודל פונט",
                "Hide": "הסתר",
                "HideButton": "הסתר כפתור",
                "Text": "טקסט",
                "Fonts": "פונט",

                "Background": "רקע",
                "SelectBgColor": " בחר צבע רקע",
                "SelectBgImage": " בחר תמונת רקע",
                "Update": "עדכן",
                "BackgroundColor": "צבע רקע",
                "HideImage": "הסתר תמונה",
                "SelectNewImage": "בחר תמונה חדשה",
                "SetAll?": "הגדר בכולם?",
                "SetAll": "הגדר בכולם",
                "setAllButtons?": "האם אתה רוצה להגדיר בכל הלחצנים,\n",
                "colorWithSelectedColor?": "עם הצבע שבחרת?",
                "withShape?": "עם הצורה האחרונה שבחרת?",
                "Square": "ריבוע",
                "Circle": "עיגול",
                "Shape": "צורה",
                "Ok": "טוב",
                "Cancel": "ביטול",
                "Yes": "כן",
                "CardExpired": "פג תוקף הכרטיס",
                "TimeExpired": "זמן תוקף הכרטיס פג, אם אתה עדיין רוצה להצטרף אלינו, עבור לתשלום.",
                "Payment": "תשלום",

                "Font": "גופן",
                "NewButton": "הוסף כפתור חדש",
                "MissingName!": "חסר שם!",
                "enterGalleryName.": "אנא הכנס שם לגלריה החדשה שלך.",
                "UpdatingGallery": "מעדכן גלריה...",
                "NameMissing!": "חסר שם!",
                "ChangeToValid": "אנא שנה לשם חוקי ושמור.",
                "GalleryName": "שם הגלריה",
                "HideTitle": "הסתר כותרת",
                "Border": " מסגרת",
                "SelectGallery": "בחר גלריה",

                "DeleteGallery?": "למחוק גלריה?",
                "SureDeleteGallery?": "האם אתה בטוח שאתה רוצה למחוק את הגלריה הזו?",
                "CreateNewGalleryWithName": "צור גלריה חדשה בשם",
                "CreateNewGallery": "צור גלריה",
                "DeleteGallery": "מחק גלריה",
                "NoGallerys": "אין עדיין גלריות :-(",
                "TitleOfNewGallery": "כותרת לגלריה חדשה",
                "DeletingGallery": "מוחק גלריה...",
                "DeletingImages": "מוחק תמונות",
                "NoImagesYet": "עדיין אין תמונות",
                "DeleteSelectedFiles": "מחק קבצים נבחרים",
                "Gallery": "גלריה",
                "UploadNewImages": "העלה תמונות חדשות",
                "UploadingImages": "מעלה תמונות...",
                "UploadAll": "העלה הכל",
                "Drag_N_Drop": "גרור לכאן תמונות או לחץ להוספה",
                "LogoImage": "תמונת לוגו",
                "SelectNewLogo": "בחר תמונת לוגו חדשה",
                "UpdateNewLogo": "עדכן תמונה חדשה",
                "BorderWidth": "עובי מסגרת",
                "BottomBorderWidth": "עובי תוחם תחתון",
                'Button': "כפתור",
                "DeleteButton?": "למחוק כפתור?",
                "SureDelete?": "האם אתה בטוח שאתה רוצה למחוק את הכפתור?",
                "Delete": "מחק",
                "Icon": "אייקון",

                "ButtonTitle": "כותרת כפתור",
                "Description": "תיאור",
                "Index": "אינדקס",

                "AddTitle": "הוסף כותרת",
                "AddDescription": "הוסף תיאור",
                "EnterE-Mail": "הכנס אי-מייל",
                "WillSendE_Mail": "ישלח את הודעת הלקוח אל המייל שיצויין",
                "Address": "כתובת",
                "City": "עיר",
                "WillOpenBrowser": "יפתח את הדף שיצויין",
                "VCardTitle": "כותרת לכרטיס הביקור שישמר במכשיר הלקוח",
                "EnterAnyPhone": "הכנס כל מספר לחיוג",
                "FormLanguage": "שפת הטופס",
                "WillOpenChat": "יפתח שיחת וואטסאפ עם המספר שיצויין",
                "PhoneWithCountry": "מספר טלפון עם קידומת מדינה חובה",
                "Waze.comPath": "מסלול מאתר waze.com",
                "WillOpenEmail": "יפתח את האימייל לשליחת מייל לנמען שיצויין",
                "WillCall": "מספר טלפון לחיוג",
                "WillSend_sms": "מספר טלפון לשליחת sms",
                "AddNewCard": "כרטיס חדש",
                "CellPhoneNumber": "מספר טלפון נייד",
                "Call": "התקשר",
                "Whatsapp": "וואטסאפ",
                "WhatsappFast": "מענה מהיר בוואטסאפ",
                "Navigate": "ניווט",

                "SaveToContacts": "שמור באנשי קשר",
                "AboutMe": "קצת עלי",
                "SendMessageForm": "שלח הודעה בטופס",
                "sms": "SMS",
                "txtAboutMeDesc": "זה טקסט עלי ועל החברה שלי.",
                "Hey": "שלום רב",
                "ShareWithFriends": "שתפו עם חברים",
                "ClickTheLink": "לחץ על הקישור הבא\nכדי לצפות בכרטיס הביקור של\n",
                "CreatingCard!": "יוצר כרטיס",
                "ImageStyle": "סגנון תמונה",
                "UpdateNewHeader": "עדכן תמונה עליונה חדשה",
                "DeleteVideo?": "למחוק וידאו?",
                "DeleteVideoSure": "האם אתה בטוח? למחוק את הקישור לוידאו הזה?",
                "VideoTitle": "כותרת וידאו",
                "VideoLink": "לינק לוידאו",
                // "YouTubeId": "קוד מזהה לוידאו ב youtube",

                "ShareArea": "איזור שיתוף",
                "YourShareLink": "הלינק שלך לשיתוף",
                "EnterShareButtonsTitle": "הכנס כותרת לכפתור שיתוף",
                "TextForShare": "טקסט לשיתוף",
                "ButtonColor": "צבע כפתור",
                "YouTubeVideoInstruction": "שם לב! - וידאו של youtube צריך להתחיל ככה :",
                "HideShareArea": "הסתר שיתופים מהכרטיס ",
                "AddVideo": "הוסף וידאו",
                "AddGallery": "הוסף גלריה",
                "SelectAll": "בחר הכל",
                "SelectedImages": "תמונות נבחרות",
                "Sort": "מיין",
                "Off": "כבוי",
                "On": "דלוק",
                "Off_flip": "דלוק",
                "On_flip": "כבוי",
                "SigningIn": "נרשם",
                "CreatingNewUser": "יוצר משתמש חדש",
                "DownloadingYourV-Cards": "מוריד כרטיסי ביקור",
                "SigningOut": "מתנתק",
                "DayForPaidCard": "ימים נותרו",
                "Statistics": "סטטיסטיקה",
                "Payments": "תשלומים",
                "PaymentDate": "שולם בתאריך",
                "ExparationDate": "פג תוקף בתאריך",
                "User": "משתמש",
                "MailValidation!": "אימות דואר",
                "PleaseCheckEmail": "בדוק את המייל שלך, העתק את הקוד שנשלח אליך,הדבק כאן, ותתחיל להינות מ כארדודיז",

                "EnterYourCode!": "הכנס את הקוד שלך",
                "MissingPassword": "חסר סיסמה",
                "WrongCode!": "קוד שגוי!",
                "Hello": "שלום",
                "Dashboard": "מסך ראשי",
                "Continue": "המשך",
                "UpdatingDescription": "מעדכן תיאור...",
                "": "",
                "Updating": "מעדכן...",
                "ShareButtonsUpdating": "מעדכן לחצני שיתוף",
                "UpdatingLogo": "מעדכן לוגו...",
                "UpdatingLogoSettings": "מעדכן הגדרות לוגו...",
                "UpdatingHeaderImage": "מעדכן תמונה עליונה...",
                "UpdatingHeaderSettings": "מעדכן הגדרות תמונה עליונה...",
                "UpdatingBackgroundSettings": "מעדכן הגדרות רקע...",
                "UpdatingShareSettings": "מעדכן הגדרות שיתוף...",
                "CouldNotEmail": "השליחה נכשלה\nאנא נסה שוב מאוחר יותר.",
                "WithYourChoosenStyle": "עם הסגנון האחרון שבחרת?",
                "WithYourChoosenSize": "עם הגודל האחרון שבחרת?",
                "ILS": "₪",
                "Small": "קטן",
                "Medium": "בינוני",
                "Large": "גדול",
                "AllUnlimited": "ללא הגבלת סוגי הלחצנים",
                "PersonalSupport": "תמיכה אישית דרך מייל או וואטסאפ",
                "PersonalDomain": "שם דף אישי ופרטי לבחירה",
                "LicenseToAndroidApp": "רשיון לאפליקצית אנדרואיד",
                "UpToPhotoUpload": "העלאה של עד {{value}} תמונות",
                "UpToWide": "עד {{value}} לחצנים רחבים",
                "UpToRounded": "עד {{value}} לחצנים קטנים",
                "UpToGallery": "עד {{value}} גלריות שונות",
                "UpToDays": "לתקופה של {{value}} ימים",
                "UnlimitedWide": "ללא הגבלת לחצנים רחבים",
                "UnlimitedRounded": "ללא הגבלת לחצנים קטנים",
                "UnlimitedGallerys": "ללא הגבלת פתיחת גלריות",
                "SendingEmailTo": "שולח מייל אל {{value}}",
                "CardUdis": "כארדודיז",
                "TryFree": "נסה בחינם!",
                "RegisterAndTry": " {{value}} ימי ניסיון!",
                "RegisterNow": "הרשם עכשיו",
                "Profile": "פרופיל",
                "PlusVat": "+מע״מ",
                "CreatingGallery": "יוצר גלריה חדשה...",
                "UpdatingGalery": "מעדכן גלריה",
                "DeleteCard?": "למחוק כרטיס?",
                "DeleteCardSure?": "האם אתה בטוח, למחוק כרטיס?",
                "DeletingCard": "מוחק כרטיס",
                "CantEmail": "לא הצלחנו לשלוח לך מייל לאימות - אנא פנה לתמיכה.",
                "EmailExist": "המייל הזה כבר קיים במערכת",
                "CardFitsToPackage": "לפי החישוב שלנו,\nנראה שהחבילה שהכי מתאימה לכרטיס שלך היא\n",
                "ClickSendEmailForm": "מילוי טופס לשליחת דואר לעסק",
                "InfoButton": "לחצן שמתרחב ומציג טקסט ארוך אודות העסק",
                "InfoButtonCustom": "לחצן שמתרחב ומציג טקסט מקוסטם ארוך אודות העסק",
                "CallTo": "לחצן שיוצר חיוג למספר העסק",
                "SMS_Message": "לחצן שפותח את אפליקצית ההודעות במכשיר לשליחת הודעה סמס לעסק",
                "WhatsAppMessage": "לחצן שפותח את וואטסאפ לשליחת הודעה לעסק",

                "WazeButton": "לחצן שפותח את אפליקציית waze ומתחיל ניווט לכתובת העסק.",
                "MailButton": "לחצן שפותח את הדואר במכשיר לשיחת דואר לעסק",
                "ContactButton": "לחצן שמוריד איש קשר לרשימת אנשי הקשר של הלקוח",
                "LinkButton": "לחצן שפותח דף חדש בדפדפן לכתובת שצויינה.",
                "Number_OpenGallerys": "מספר גלריות שפתחת",
                "Number_ImageUpload": "מספר תמונות שהעלת",
                "Number_WideButtons": "מספר לחצנים רחבים",
                "Number_RoundedButtons": "מספר לחצנים עגולים",
                "Number_VideoShares": "מספר שיתופי וידאו",
                "SmallBc": "כרטיס ביקור דיגיטלי קטן",
                "MediumBc": "כרטיס ביקור דיגיטלי בינוני",
                "LargeBc": "כרטיס ביקור דיגיטלי גדול",
                "Price": "מחיר",
                "LoadingPaypal": "טוען מערכת תשלום מאובטח של Paypal",
                "OrderApproved_fetchInfo": "תשלום אושר\nמייבא נתוני תשלום",
                "UpdateCardInfo": "מעדכן פרטי כרטיס",
                "YouTubeVideoOnly": "קישור לסרטון יוטיוב בלבד!",
                "BottomBorderColor": "צבע גבול תחתון",
                "Carusel": "קרוסלה",
                "GridList": "אוסף",
                "DisplayStyle": "סגנון תצוגה",
                "Copyright": "זכויות יוצרים",
                "HideBGI": "הסתר תמונת רקע",
                "userShowVideo": "הצג וידאו",


                "AddShop": "הוסף קטגוריית מכירה",
                "AddItem": "הוסף פריט",
                "ShopCategoryName": "שם מחלקה",
                "DeleteItem": "מחק פריט",
                "SureDeleteItem": "אתה בטוח רוצה למחוק את הפריט",
                "DeleteCategory": "מחק מחלקה",
                "SureDeleteCategory": "אתה בטוח רוצה למחוק את המחלקה",
                "OldPrice": "מחיר ישן",
                "SmsNumber": "מספר ל ס.מ.ס",
                "EnableShopListWithSms": "אפשר שליחת הזמנה עם SMS",
                "EnableShopListWhatsapp": "אפשר שליחת הזמנה עם Whatsapp",
                "EnableShopListEmail": "אפשר שליחת הזמנה עם Email",
                "ItemImages": "תמונות מוצר",
                "Shipping": "משלוחים",
                "ShippingTitle": "כותרת להערת משלוח",
                "EnableShipping": "אפשר משלוח",
                "ShippingPrice": "מחיר עבור משלוח",
                "ShippingFreeFromPrice": "משלוח חינם החל מהזמנה של:",

            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// // not like to use this?
// // have a look at the Quick start guide 
// // for passing in lng and translations on init
// const Languages = ["en", "heb"]

// i18n
//     // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
//     // learn more: https://github.com/i18next/i18next-xhr-backend
//     .use(Backend)
//     // detect user language
//     // learn more: https://github.com/i18next/i18next-browser-languageDetector
//     .use(LanguageDetector)
//     // pass the i18n instance to react-i18next.
//     .use(initReactI18next)
//     // init i18next
//     // for all options read: https://www.i18next.com/overview/configuration-options
//     .init({
//         fallbackLng: 'en',
//         debug: true,
//         whitelist: Languages,
//         interpolation: {
//             escapeValue: false, // not needed for react as it escapes by default
//         }
//     });


// export default i18n;
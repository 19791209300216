import React, { Component } from 'react'
import CenterText from '../../View_Text/CenterText';

export default class IFrameComponent extends Component {

    state = {
        width: 0, height: 0,
        divWidth: 0, divHeight: 0,
        isFromDashboard: this.props.isFromDashboard ? true : false
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        var element = document.getElementById('videoBorder');
        if (element != null) {
            var positionInfo = element.getBoundingClientRect();
            var height = positionInfo.height;
            var width = positionInfo.width;
            this.setState({

                divWidth: width, divHeight: height
            });
        }

        // console.log("div size changed" + width);
        this.setState({
            width: window.innerWidth, height: window.innerHeight,

        });
    };
    render() {
        const { video } = this.props

        var urlToShow = ""
        if (video.url) {
            urlToShow = video.url
        }

        return (
            <div style={{ marginTop: video.marginTop ? video.marginTop : 0 }}>
                <div>
                    {
                        video.hideTitle ? null :
                            <CenterText
                                textColor={video.titleColor}
                                fontSize={video.fontSize ? video.fontSize : 32}
                                fontFamily={video.fontName}
                                text={video.title}
                            />
                    }
                </div>

                <div className=""
                    id="videoBorder"
                    style={{

                        marginLeft: 10,
                        marginRight: 10,
                    }}>
                    {/* width: this.state.isFromDashboard ? "80%" : "100%",
height: this.state.isFromDashboard ? null : this.state.width / 2, */}
                    <iframe title="MyFrame"
                        style={{
                            overflow: "hidden",
                            borderWidth: video.borderWidth ? video.borderWidth : 2,
                            borderStyle: "solid",
                            borderColor: video.borderColor,
                            width: "100%",

                            height: video.videoSquare ? this.state.divWidth : this.state.divWidth / 2
                        }}
                        src={urlToShow}
                        scrolling="no"
                        allowFullScreen
                        allow='autoplay; encrypted-media'></iframe>
                </div>
            </div>
        );

    }
}


// import React from "react";


// const IFrameComponent = ({ video }) => {
    // var urlToShow = ""
    // if (video.url) {
    //     urlToShow = video.url
    // }
    // return (
    //     <div>
    //         <div className="center">
    //             {
    //                 video.hideTitle ? null :
    //                     <div style={{ color: video.titleColor, fontSize: "32px", fontFamily: video.fontName }}>
    //                         {video.title}
    //                     </div>
    //             }
    //         </div>



    //         <div className="" style={{
    //             marginBottom: "10px", marginBottom: 10,
    //             marginLeft: 10,
    //             marginRight: 10,
    //             border: "2px solid",
    //             borderColor: video.borderColor,
    //         }}>
    //             <iframe title="MyFrame"
    //                 style={{
    //                     marginBottom: "10px", marginBottom: 10,
    //                     marginLeft: 10,
    //                     marginRight: 10,
    //                     border: "2px solid",
    //                     borderColor: "red",
    //                     width: "100%"
    //                 }}
    //                 src={urlToShow}
    //                 frameBorder="10"

    //                 allowFullScreen
    //                 allow='autoplay; encrypted-media'></iframe>
    //         </div>
    //     </div>
    // );
// };



// export default IFrameComponent;
export var buildID = "1.88"
export var SERVER_SIDE_BASE_URL = "https://cardudis.co.il/"
export var FREE_TRAIL_DAYS = 7
export var SALE_ITEM_HEIGHT = 370

export var ITEM_ROUNDED_CORNERS = "10px"

export var ALERT_DELETE_CART = "ALERT_DELETE_CART"

export var EMITTER_CART_SEND_WITH_CLICKED = "CART_SEND_WITH_CLICKED"

export var ICON_MAIL_ENVELOPE = "fa fa-envelope-o"
export var ICON_SMS_BUBBLE = "fa fa-commenting-o"
export var ICON_WHATSAPP = "fa fa-whatsapp"

export var COLOR_ICON_MAIL_ENVELOPE = "blue"
export var COLOR_ICON_SMS_BUBBLE = "black"
export var COLOR_ICON_WHATSAPP = "green"

export var USER_DID_CLICK_SEND_SHOP_RESERVATION = "USER_DID_CLICK_SEND_SHOP_RESERVATION"
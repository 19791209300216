import React from 'react'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import SaleItemCard from './SaleItemCard';
import { useState, useEffect } from 'react';
import SaleItemList from './CartList/SaleItemList';
import SaleItemDetails from './SaleItemDetails'
import { cartHasItems, getCart, userClickedForReservation } from '../../Utils/ShopUtils'
import { EMITTER_CART_SEND_WITH_CLICKED, ITEM_ROUNDED_CORNERS, SALE_ITEM_HEIGHT } from '../../Utils/Constants'

import Emitter from '../../Utils/emitter'
import LongRoundedButtonIconAndText from '../Buttons/LongRoundedButtonIconAndText';
import CenterText from '../View_Text/CenterText';
import User from '../../Singleton/UserAndCard';
export default function SaleGrid({ category, isFromDashboard, buttonClicked }) {

    const [divWidth, setDivWidth] = useState(0);
    const [itemDetails, setItemToShowDetails] = useState(null);
    const [shoppingList, showShoppingList] = useState(false);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            console.log("width:", window.innerWidth,);

            var element = document.getElementById('itemGridView');
            if (element != null) {
                var positionInfo = element.getBoundingClientRect();

                var width = positionInfo.width;
                console.log("width for div:", width,);
                setDivWidth(width);
            }

        }
        // Anything in here is fired on component mount.
        setEmitterOn()
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => {
            // Anything in here is fired on component unmount.
            Emitter.off('REFRESH_CART_LIST');
            Emitter.off(EMITTER_CART_SEND_WITH_CLICKED);
            console.log("Emmiter is OFF ");
            window.removeEventListener("resize", handleResize)
        };
        // bla();
    }, [setEmitterOn])

    function setEmitterOn() {
        console.log("Emmiter is ON ");
        Emitter.on('REFRESH_CART_LIST', (newValue) => {
            setRefresh(!refresh)
        });

        Emitter.on(EMITTER_CART_SEND_WITH_CLICKED, (clickWith) => {
            buttonClicked(clickWith)
            userClickedForReservation(true, User.getSelectedCard().id)
        });
    }

    var cols = divWidth > 500 ? 3 : 2
    return (


        <div id="itemGridView">

            {
                itemDetails ?
                    <SaleItemDetails
                        item={itemDetails}
                        close={() => setItemToShowDetails(null)} />
                    :
                    null
            }
            {
                shoppingList && getCart() != null ?
                    <SaleItemList
                        close={() => showShoppingList(false)}
                    />
                    :
                    // if we get her that means that the cart got deleted while list is open.
                    shoppingList && getCart() == null ?
                        showShoppingList(false)
                        :
                        null


            }

            {
                cartHasItems() && !isFromDashboard ?
                    <div className="floatingShopingCart">
                        <LongRoundedButtonIconAndText
                            text={"לרשימת קניות וביצוע הזמנה"}
                            textColor={"black"}
                            iconName={"fa fa-shopping-cart"}
                            iconColor="green"
                            buttonColor={"white"}
                            borderColor="black"
                            buttonClick={() => showShoppingList(true)}
                        />
                    </div>
                    :
                    null
            }


            <div
                style={{ marginTop: category.marginTop, marginBottom: 20 }}>
                {
                    // video.hideTitle ? null :
                    <CenterText
                        textColor={category.color}
                        fontSize={category.fontSize ? category.fontSize : 32}
                        fontFamily={category.fontName}
                        text={category.title}
                    />
                }


                <div
                    style={{
                        marginLeft: 10,
                        marginRight: 10,
                        overflow: "hidden",
                        // borderRadius: 8,
                        borderRadius: ITEM_ROUNDED_CORNERS,
                        // backgroundColor: "white",
                        borderWidth: category.borderWidth !== undefined ? category.borderWidth : 2,
                        borderStyle: "solid",
                        borderColor: category.borderColor,
                    }}>

                    <GridList cellHeight={SALE_ITEM_HEIGHT} spacing={4} cols={cols}>
                        {category.items.map((item, index) => (
                            <GridListTile key={index} cols={1}
                                onClick={() => setItemToShowDetails(item)}>
                                <SaleItemCard
                                    item={item} />
                                {/* <img src={imgStringUrl} alt="כרטיס ביקור דיגיטלי" /> */}
                            </GridListTile>
                        ))}

                    </GridList>
                </div>

            </div>



        </div>
    )
}

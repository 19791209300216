import React, { Component } from 'react'
import { connect } from "react-redux"
import { createNewGallery, deleteGallery, updateGallerys } from '../../../../store/actions/cardActions'
import EditCardImages from './EditCardImages'
import EditCardImages_Server from './EditCardImages_Server'
import arrayMove from 'array-move';
import LoaderView from '../../../SpinnerLoader/LoaderView';
import { AlertOkComponent, AlertOkCancelComponent } from '../../../AlertsComponents/AlertsComponent'
import {
    getBasicGalleryListObjectWithTitle,
    GalleryStyleDict
} from '../../../../Utils/UtilMethods'
import GalleryNameComponent from './GalleryNameComponent'
import DropDownComponent from '../DropDownComponent'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import TopGlobalCustomVideoEditor from '../AudioVideoEditors/TopGlobalCustomVideoEditor'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import CheckboxComponent from '../../CheckboxComponent'

export class GallerysComponent extends Component {

    alertTitle = ""
    alertSubTitle = ""

    state = {
        ...this.props,
        galleryName: "",
        selectedGalleryId: null,
        selectedGalleryIndex: null,
        showAlert: false,
        showAlertOkCancel: false,
        didMakeChange: false,
        disableSorting: true,
    }

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });
    SortableItem = sortableElement(({ value, myIndex }) => this.getGalleyEditLayout(value, myIndex));

    setSettingsToGallery = (gallery, id, index) => {
        if (gallery.settings !== undefined) {
            gallery.settings.style = id
        } else {
            gallery.settings = {
                "style": id
            }
        }

        this.updateGalleryItemInList(gallery, index)
    }

    setAnimatedCarusel = (gallery, animated, index) => {
        gallery.autoPlay = animated
        this.updateGalleryItemInList(gallery, index)
    }

    getGalleyEditLayout(gallery, index) {

        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "3px solid",
            borderColor: "red",
        }

        var galleryStyleId = "Carusel"
        if (gallery.settings != null) {
            galleryStyleId = gallery.settings.style
        }

        return (
            <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                <div className="edit_button_card_container " style={cardStyle}>
                    <div className="row">
                        <div >
                            <div className="edit_button_card_container" style={{ margin: 10 }}>
                                <GalleryNameComponent
                                    gallery={this.state.gallerys.list[index]}
                                    galleryId={this.state.gallerys.list[index].id}
                                    deleteGalleryClicked={() => {
                                        this.deleteGalleryClicked(index)
                                    }}
                                    saveChangesClicked={(gallery) => {
                                        this.saveChangeForItemInList(gallery, index, null)
                                    }}

                                    updateGalleryValues={(gallery) => {
                                        this.updateGalleryItemInList(gallery, index)
                                    }}
                                />
                            </div>

                            <div dir="auto"

                                style={{ maxWidth: 200, margin: "0 auto" }}>
                                {this.props.t("DisplayStyle")}

                                <DropDownComponent
                                    // list={["Carusel", "GridList"]}
                                    title={GalleryStyleDict()[galleryStyleId]}
                                    dict={GalleryStyleDict()}
                                    selectedIndex={(index) => {

                                    }}
                                    selectedId={(event, id) => {
                                        event.preventDefault()
                                        this.setSettingsToGallery(gallery, id, index)
                                    }}
                                />

                                {
                                    galleryStyleId === "Carusel" ?
                                        <div style={{ marginTop: 10 }}>
                                            <CheckboxComponent title={this.props.t("AutoPlay")}
                                                checked={gallery.autoPlay ? gallery.autoPlay : false}
                                                selected={(checked) => {
                                                    this.setAnimatedCarusel(gallery, checked, index)
                                                }} />
                                        </div>

                                        :
                                        null
                                }
                            </div>

                            <div className="col s12 m6" >
                                <div className="edit_button_card_container" style={{ margin: 10 }}>
                                    <EditCardImages galleryId={this.state.gallerys.list[index].id}
                                        gallery={this.state.gallerys.list[index]}
                                        galleryIndex={index}
                                        updateGalleryList={(gallery) => {
                                            this.saveChangeForItemInList(gallery, index, false)
                                        }} />
                                </div>
                            </div>

                            <div className="col s12 m6" >
                                <div className="edit_button_card_container" style={{ margin: 10 }}>
                                    {
                                        this.state.gallerys.list ?
                                            <EditCardImages_Server gallery={this.state.gallerys.list[index]}
                                                galleryId={this.state.gallerys.list[index].id}
                                                galleryIndex={index}
                                                updateGalleryList={(gallery) => {
                                                    this.updateGalleryItemInList(gallery, index)
                                                }}
                                                saveChangesClicked={(gallery) => {
                                                    this.saveChangeForItemInList(gallery, index, null)
                                                }}
                                            />
                                            :
                                            null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </li >
        )
    }

    saveChangeForItemInList = (gallery, index, showLoader) => {
        let listCopy = [...this.state.gallerys.list];
        listCopy[index] = gallery

        this.setState({
            gallerys: { ...this.state.gallerys, list: listCopy },
        });

        this.props.updateGallerys({ ...this.state.gallerys, list: listCopy }, showLoader)
    }

    updateGalleryItemInList = (gallery, index) => {
        let listCopy = [...this.state.gallerys.list];
        listCopy[index] = gallery

        this.setState({
            gallerys: { ...this.state.gallerys, list: listCopy },
            didMakeChange: true
        });
    }

    createNewGalleryInListClicked() {

        let newGallery = getBasicGalleryListObjectWithTitle()
        console.log("create new gallery clicked");
        // 1. Make a shallow copy of the items
        var listCopy = [];
        if (!this.state.gallerys || !this.state.gallerys.list) {
            listCopy = [newGallery];
        }
        else {
            listCopy = [newGallery, ...this.state.gallerys.list];
        }

        // 2. Make a shallow copy of the item you want to mutate        
        this.setState({
            gallerys: { ...this.state.gallerys, list: listCopy },
            didMakeChange: true
        });

    }

    deleteGalleryClicked(index) {
        // if not empty 
        this.alertTitle = this.props.t("DeleteGallery?")
        this.alertSubTitle = this.props.t("SureDeleteGallery?")
        this.setState({
            showAlertOkCancel: true,
            selectedGalleryIndex: index
        })
    }

    saveChanges = (object) => {
        this.props.updateGallerys(this.state.gallerys, null)
        this.setState({
            didMakeChange: false
        })
    }

    handleChangeFor_All_ItemInList = (e) => {
        // this.saveLastGlobalSelectionForRectangle(e)
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value);
        // 1. Make a shallow copy of the items
        if (this.state.gallerys) {
            let listCopy = [...this.state.gallerys.list];

            for (let index = 0; index < listCopy.length; index++) {
                var item = listCopy[index];
                item = {
                    ...item,
                    [e.target.id]: e.target.value
                }
                listCopy[index] = item
            }

            this.setState({
                gallerys: {
                    ...this.state.gallerys,
                    lastGlobalSelections: {
                        ...this.state.gallerys.lastGlobalSelections,
                        [e.target.id]: e.target.value
                    },
                    list: listCopy
                },
                didMakeChange: true
            });
        }
    }
    render() {
        const { gallaryComponent, auth, t, } = this.props;
        const bootomFixedForUpdateButton = {
            position: 'fixed',
            zIndex: '2',
            backgroundColor: "red",
            height: "40px",
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div className="container" style={{ marginBottom: "45px" }}>

                {gallaryComponent.isLoading ?
                    <LoaderView text={gallaryComponent.loadingTitle} />
                    : null}

                {this.state.showAlert ?
                    < AlertOkComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={() => {
                        this.setState({
                            showAlert: false
                        })
                    }} />
                    : null}

                {this.state.showAlertOkCancel ?
                    < AlertOkCancelComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={(confirm) => {
                        if (confirm) {
                            var gallerys = {};
                            if (this.props.reduxSelectedCard.gallerys) {
                                gallerys = JSON.parse(JSON.stringify(this.props.reduxSelectedCard.gallerys));
                            }
                            //  delete gallerys[this.state.selectedGalleryId]
                            let galleryID = this.state.gallerys.list[this.state.selectedGalleryIndex].id
                            let path = auth.email + "/" + this.props.reduxSelectedCard.id + "/gallerys/" + galleryID
                            gallerys.list.splice(this.state.selectedGalleryIndex, 1)
                            this.setState({
                                ...this.state,
                                showAlertOkCancel: false,
                                gallerys: gallerys,
                            });
                            this.props.deleteGallery(gallerys, path)
                        }
                        else {
                            this.setState({
                                ...this.state,
                                showAlertOkCancel: false
                            });
                        }
                    }} />
                    : null}

                <TopGlobalCustomVideoEditor
                    addTitle={t("AddGallery")}
                    setAllWithData={(data) => {
                        console.log("sent data", data);
                        data.target.id = data.target.id == "textColor" ? "color" : data.target.id
                        this.handleChangeFor_All_ItemInList(data)
                    }}
                    createNew={() => {
                        this.createNewGalleryInListClicked()
                    }}
                    enterSortMode={checked => {
                        this.setState({
                            ...this.state,
                            disableSorting: !checked
                        })
                    }}
                    lastGlobalSelections={this.state.gallerys.lastGlobalSelections}
                />


                {this.state.didMakeChange ?
                    <div style={bootomFixedForUpdateButton}>
                        <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")}
                            onClick={() => this.saveChanges(null)} >{t("SaveChanges")}
                            <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                        </button>
                    </div> : null}


                <this.SortableContainer onSortEnd={this.onSortEnd} >
                    {this.state.gallerys && this.state.gallerys.list && this.state.gallerys.list.map((value, index) => (
                        <this.SortableItem key={`item-${index}`} index={index} value={value}
                            myIndex={index}
                            disabled={this.state.disableSorting} />
                    ))}
                </this.SortableContainer>
            </div>
        )
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        var myList = [...this.state.gallerys.list];
        var newList = arrayMove(myList, oldIndex, newIndex)
        console.log("list", myList);
        console.log("newList", newList);
        this.setState({
            gallerys: { ...this.state.gallerys, list: newList },
            didMakeChange: true
        });
    };
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            createNewGallery: (title, cardId) => dispatch(createNewGallery(title, cardId)),
            updateGallerys: (gallerys, showLoader) => dispatch(updateGallerys(gallerys, showLoader)),
            deleteGallery: (newGallerys, path) => dispatch(deleteGallery(newGallerys, path)),
        })
}

const mapStateToProps = (state, ownProps) => {
    console.log("mapStateToProps", state);
    // here we have card.cards array
    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        gallaryComponent: state.redux.gallaryComponent,
        auth: state.firebase.auth,
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(GallerysComponent)

import React, { Component } from 'react'
import AddVideosLinkComponent from './AddVideosLinkComponent';
export default class AudioVideoEditorComponent extends Component {
    render() {
        // videos={reduxSelectedCard.videos} cardID={reduxSelectedCard.id}
        const { videos, cardID } = this.props

        return (
            <div>
                <AddVideosLinkComponent videos={videos} cardID={cardID} />
            </div>
        )
    }
}

/* eslint-disable jsx-a11y/media-has-caption, class-methods-use-this */
import React, { PureComponent } from 'react';

import { saveHeaderImageCrop, updateHeaderSettings } from '../../../../store/actions/cardActions'
import { connect } from "react-redux"
import TopImage from '../../v_card_components/TopImage'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import ColorPickerComponent from '../ColorPickerComponent'
import CheckboxComponent from '../../CheckboxComponent';
import { getTitleForCode, getTopHeaderImageStyleList } from '../../../../Utils/UtilMethods'
import NumberStepperComponent from '../../NumberStepperComponent';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import DragAndDropImageComponent from '../../DragAndDropImageComponent';

export class HeaderImageEditComponent extends PureComponent {

    imageRef = ""
    blobCropedImage = null
    minSize = 50
    state = {
        src: null,
        file: null,
        didMakeChange: false,
        pickerShow: false,
        header: this.props.reduxSelectedCard.header,
        didMakeChangeProperties: false,
    };

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.dropdown-trigger-imageStyle');
        M.Dropdown.init(elems, { constrainWidth: false });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.reduxSelectedCard.header == undefined || newProps.reduxSelectedCard.header.url == undefined) {
            return
        }

        if (newProps.reduxSelectedCard.header.url != this.state.header.url) {
            this.setState({
                ...this.state,
                header: newProps.reduxSelectedCard.header,
            })

        }
    }


    dropzoneUpdate = (selectedFilesUrl) => {

        const image = selectedFilesUrl[0];
        this.setState({
            src: URL.createObjectURL(image),
            file: image,
            didMakeChange: true
        });
    }

    saveNewImageCrop() {
        this.props.saveHeaderImageCrop(this.state.file)
        this.setState({
            src: null,
            didMakeChange: false
        });
    }

    saveHeaderSettings() {
        this.props.updateHeaderSettings(this.state.header)
        this.setState({
            didMakeChangeProperties: false
        });
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            header: {
                ...this.state.header,
                [e.target.id]: e.target.value,
            },
            didMakeChangeProperties: true
        });
    }
    render() {

        const { headerImageEditComponent, t, } = this.props

        return (
            <div>
                {headerImageEditComponent.isLoading ?
                    <LoaderView text={headerImageEditComponent.loadingTitle} />
                    : null}


                <div className="edit_button_card_container">
                    <h5 className="" style={{ marginBottom: 20 }}>{t("MainTopImage")}</h5>
                    <div className="row " >
                        <div className="col s12 m6 l6 " style={{ padding: 20 }} >
                            {this.getImageStyleSelectorDropDowm(this.state.header)}
                            <TopImage header={this.state.header} isFromEditor={true} />
                            <CheckboxComponent checked={this.state.header.hide} title={t("HideImage")} selected={(checked) => {
                                this.setState({
                                    ...this.state,
                                    header: {
                                        ...this.state.header,
                                        hide: checked
                                    },
                                    didMakeChangeProperties: true

                                })
                            }} />
                            <div className="roundedButtons">
                                <ul>
                                    <li>
                                        {this.getColorPicker()}
                                    </li>
                                    <li>
                                        <NumberStepperComponent
                                            value={this.state.header.bottomBorderWidth ? this.state.header.bottomBorderWidth : 0}
                                            max={200}
                                            min={-200}
                                            step={1}
                                            title={t("BottomBorderWidth")}
                                            didMakeChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    header: {
                                                        ...this.state.header,
                                                        bottomBorderWidth: value
                                                    },
                                                    didMakeChangeProperties: true

                                                })
                                            }} />
                                    </li>
                                    <li>
                                        <NumberStepperComponent
                                            value={this.state.header.marginTop ? this.state.header.marginTop : 0}
                                            max={200}
                                            min={-200}
                                            step={1}
                                            title={t("MarginTop")}
                                            didMakeChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    header: {
                                                        ...this.state.header,
                                                        marginTop: value
                                                    },
                                                    didMakeChangeProperties: true

                                                })
                                            }} />
                                    </li>
                                </ul>
                            </div>

                            {this.state.pickerShow ?
                                <ColorPickerComponent
                                    color={this.state.header.bottomBorderColor}
                                    closePicker={() => {
                                        this.setState({
                                            pickerShow: false
                                        })
                                    }}
                                    pickerChanged={(color) => {
                                        this.setState({
                                            header: {
                                                ...this.state.header,
                                                bottomBorderColor: color,
                                            },

                                            didMakeChangeProperties: true
                                        })
                                    }}
                                />
                                :
                                null}

                            {this.state.didMakeChangeProperties ?
                                <div >
                                    <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChangeProperties ? "" : "disabled")}
                                        onClick={() => this.saveHeaderSettings()}>{t("SaveChanges")}
                                        <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")}
                                            style={{ marginLeft: 10 }} ></i>
                                    </button>
                                </div> : null}
                        </div>

                        <div className="col s12 m6 l6 flexCol" style={{ backgroundColor: "#fafafa" }}>

                            {t("SelectNewImage")}
                            <DragAndDropImageComponent filesSelected={selectedFilesUrl => {
                                // set grid view callback
                                console.log("selection");
                                if (selectedFilesUrl) {
                                    this.dropzoneUpdate(selectedFilesUrl)
                                }
                            }
                            }
                                limit={1}
                            />
                            {this.state.src ?
                                <img src={this.state.src} className="responsive-img" alt={"כרטיס ביקור דיגיטלי"} />
                                : null
                            }

                            {this.state.src && (
                                <>
                                    {/* <ReactCrop
                                        // style={{ width: "400px" }}
                                        // renderComponent={this.renderVideo()}
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        ruleOfThirds
                                        // circularCrop
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                        onDragStart={this.onDragStart}
                                        onDragEnd={this.onDragEnd}
                                        // renderSelectionAddon={this.renderSelectionAddon}
                                        minWidth={this.minSize}
                                        minHeight={this.minSize}
                                    /> */}
                                    {/* <button onClick={this.onChangeToIncompleteCropClick}>Change to incomplete aspect crop</button> */}
                                </>
                            )}
                            {/* {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />} */}


                            {/* {
                                this.state.didMakeChange ?
                                    <button className="curdudis_button" onClick={() => this.saveNewImageCrop()}>Update new top image</button>
                                    :
                                    null
                            } */}
                            {this.state.didMakeChange ?
                                <div >
                                    <button className={"btn btnUpdateServer z-depth-0 fullWidth  "} onClick={() => this.saveNewImageCrop()}> {this.props.t("UpdateNewHeader")}
                                        <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                    </button>
                                </div> : null}
                        </div>
                    </div>

                    {
                        <div>
                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto">{t("YouTubeVideoOnly")}</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='videoUrl' onChange={this.handleChange} required
                                    value={this.state.header.videoUrl} />
                            </div>
                            <div className="center">
                                <TopImage
                                    header={this.state.header}
                                    isFromDashboard={true}
                                    hideTopImage={true}
                                    isFromEditor={false} />
                            </div>

                            <CheckboxComponent checked={this.state.header.userShowVideo} title={t("userShowVideo")} selected={(checked) => {
                                this.setState({
                                    ...this.state,
                                    header: {
                                        ...this.state.header,
                                        userShowVideo: checked
                                    },
                                    didMakeChangeProperties: true

                                })
                            }} />
                        </div>

                        // show the video


                    }

                    <div className="center red-text">
                        {this.props.headerImageEditComponent.error ? <p>{this.props.headerImageEditComponent.error} </p> : null}
                    </div>

                </div>
            </div>
        );
    }

    getColorPicker() {

        var style = {
            backgroundColor: this.state.header.bottomBorderColor,
            width: "38px",
            height: "38px",
        }

        return (
            <div className="center">
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.handlePickerOpen(e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />

                <span style={{ color: "black", display: "block", marginTop: "4px" }}>{this.props.t("BottomBorderColor")}</span>
            </div>
        )
    }
    getImageStyleSelectorDropDowm(header) {
        return (
            <div>
                <a className='dropdown-trigger-imageStyle btn'
                    dir="auto"
                    data-target='imageStyle'
                    style={{ textTransform: "none" }}>
                    {this.props.t("ImageStyle")}: {getTitleForCode(header.style)}
                    <i className="fa fa-angle-down " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}></i>
                </a>
                <ul id='imageStyle' className='dropdown-content'>
                    {
                        getTopHeaderImageStyleList().map((value, index) => {
                            return <li ><a href="#" onClick={() =>
                                this.setState({
                                    ...this.state,
                                    header: {
                                        ...this.state.header,
                                        style: value.code
                                    },
                                    didMakeChangeProperties: true

                                })}>{value.title}</a></li>
                        })
                    }
                </ul>
            </div>
        )
    }


    handlePickerOpen = (e) => {
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }
    // picker handler

}
const mapDispatchToProps = (dispatch) => {
    return (
        {
            saveHeaderImageCrop: (blob) => dispatch(saveHeaderImageCrop(blob)),
            updateHeaderSettings: (header) => dispatch(updateHeaderSettings(header))
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        headerImageEditComponent: state.redux.headerImageEditComponent
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(HeaderImageEditComponent)


import React, { Component } from 'react'
import { ChromePicker } from 'react-color';
import { AlertOkCancelComponent } from '../../../AlertsComponents/AlertsComponent'
import { rgba2hex, getTargetNameDesctiption } from '../../../../Utils/UtilMethods'
import CreateNewRoundedButtonComponent from './CreateNewRoundedButtonComponent'
import FontListSelectComponent from '../../FontListSelectComponent'
import SwitchButton from '../../SwitchButton';
import NumberStepperComponent from '../../NumberStepperComponent';

import { withTranslation } from 'react-i18next';

export class TopGlobalCustomButtonsColor extends Component {

    selectedTargetToChange = ""
    state = {

        color: this.props.lastGlobalSelections && this.props.lastGlobalSelections.color ? this.props.lastGlobalSelections.color : "black",
        borderColor: this.props.lastGlobalSelections && this.props.lastGlobalSelections.borderColor ? this.props.lastGlobalSelections.borderColor : "black",
        iconColor: this.props.lastGlobalSelections && this.props.lastGlobalSelections.iconColor ? this.props.lastGlobalSelections.iconColor : "black",
        textColor: this.props.lastGlobalSelections && this.props.lastGlobalSelections.textColor ? this.props.lastGlobalSelections.textColor : "black",
        borderWidth: this.props.lastGlobalSelections && this.props.lastGlobalSelections.borderWidth ? this.props.lastGlobalSelections.borderWidth : 2,
        fontName: this.props.lastGlobalSelections && this.props.lastGlobalSelections.fontName ? this.props.lastGlobalSelections.fontName : "Varela Round",
        shape: this.props.lastGlobalSelections && this.props.lastGlobalSelections.shape ? this.props.lastGlobalSelections.shape : "",
        fontSize: this.props.lastGlobalSelections && this.props.lastGlobalSelections.fontSize ? this.props.lastGlobalSelections.fontSize : 20,


        colorPicker: {
            lastSelectedColor: "#fff",
            isOpen: false,
            targetId: ""
        },
    }


    componentWillReceiveProps(np) {
        console.log("new top global props ", np);
    }
    render() {
        const { i18n, t, isForRectangle } = this.props

        const popover = {
            position: 'fixed',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div>
                {this.state.showingAlert ?
                    < AlertOkCancelComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={(confirm) => {

                        if (confirm) {
                            this.props.setAllWithData(
                                {
                                    target:
                                    {
                                        id: this.selectedTargetToChange,
                                        value: this.state[this.selectedTargetToChange]
                                    }
                                })
                            //    update futher component
                        }

                        this.setState({
                            showingAlert: false
                        })

                    }} />
                    : null}

                {this.state.colorPicker.isOpen ?
                    <div style={popover}>
                        <div style={cover} onClick={this.handlePickerClose} />
                        <ChromePicker

                            color={this.state.colorPicker.lastSelectedColor}
                            onChangeComplete={this.handlePickerChangeComplete}
                            onChange={this.handlePickerChange("color")} />
                    </div> : null}

                <div className=" edit_card_top_panel_buttons">
                    <ul style={{ marginBottom: 0, marginTop: 0 }}>
                        <li style={{ marginTop: 6 }}>
                            <NumberStepperComponent
                                value={this.props.marginTopBlockValue}
                                max={100}
                                min={0}
                                step={2}
                                title={this.props.t("MarginTop") + " " + this.props.t("OfAllBlock")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "marginTopBlock",
                                            value: value
                                        }
                                    }
                                    this.props.marginTopBlock(newValue)
                                    // this.handleChangeForItemInList(newValue, index)

                                }} />
                        </li>

                        <CreateNewRoundedButtonComponent isForRectangle={isForRectangle} newButton={button => {
                            // this.addNewItemInList(button)
                            this.props.newButton(button)
                        }} />
                        <li style={{ marginTop: 6 }}>
                            <NumberStepperComponent
                                value={this.state.borderWidth}
                                max={20}
                                min={0}
                                step={1}
                                title={t("BorderWidth")}
                                didMakeChange={(value) => {
                                    this.setState(state => {
                                        state.borderWidth = value
                                        return state
                                    })


                                }} />
                            <a href="#"
                                className=" "
                                onClick={(e) => this.showSetAllAlert(e, "borderWidth")}
                            >
                                {t("SetAll")}
                            </a>
                        </li>
                        <li style={{ marginTop: 6 }}>
                            <NumberStepperComponent
                                value={this.state.fontSize}
                                max={60}
                                min={10}
                                step={1}
                                title={t("FontSize")}
                                didMakeChange={(value) => {
                                    this.setState(state => {
                                        state.fontSize = value
                                        return state
                                    })
                                }} />
                            <a href="#"
                                className=" "
                                onClick={(e) => this.showSetAllAlert(e, "fontSize")}
                            >
                                {t("SetAll")}
                            </a>
                        </li>



                        <li >
                            <div style={{ marginTop: 6 }}>
                                <FontListSelectComponent fontName={this.state.fontName} selectedFont={(fontName) => {
                                    this.setState(state => {
                                        state.fontName = fontName
                                        return state
                                    })
                                }} />
                                <a href="#"
                                    className=" "
                                    onClick={(e) => this.showSetAllAlert(e, "fontName")}
                                >
                                    {t("SetAll")}
                                </a>
                            </div>
                        </li>

                        {this.getCutomizeAllButtons("fa fa-paint-brush", this.state.color, "color")}
                        {this.getCutomizeAllButtons("fa fa-paint-brush", this.state.borderColor, "borderColor")}
                        {this.getCutomizeAllButtons("fa fa-paint-brush", this.state.iconColor, "iconColor")}
                        {this.getCutomizeAllButtons("fa fa-paint-brush", this.state.textColor, "textColor")}
                        {isForRectangle ? null : this.getCutomizeAllButtons_Shape(this.state.shape, "shape")}




                    </ul>
                    <SwitchButton selected={(selected) => {
                        this.props.enterSortMode(selected)
                    }} />
                </div>
            </div>

        )
    }

    getCutomizeAllButtons(iconName, color, targetName) {
        var style = {
            backgroundColor: color,
            width: "38px",
            height: "38px",
        }

        var description = getTargetNameDesctiption(targetName, this.props.t);

        return (
            <li >
                <div style={{ marginTop: 6 }}>
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light "
                        style={style}
                        onClick={(e) => this.handlePickerOpen(color, targetName, e, true)}
                    >
                        <i className={iconName + " iconCenter"} aria-hidden="true"
                            style={{ color: "white", fontSize: "16px" }}></i>
                    </a>
                    <br />

                    <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{description}</span>
                    <a href="#"
                        className=" "
                        onClick={(e) => this.showSetAllAlert(e, targetName)}
                    >
                        {this.props.t("SetAll")}
                    </a>
                </div>
            </li>

        )
    }

    getCutomizeAllButtons_Shape(shape, targetName) {
        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "black"
        }
        var iconName = "";

        if (shape === "") {

            iconName = "fa fa-square"
        }
        else {

            iconName = "fa fa-circle"
        }


        return (
            <li >
                <div style={{ marginTop: 6 }} >
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light dropdown-trigger-shape"
                        style={style}
                        data-target={'select_shapeGlobal'}
                    >
                        <i className={iconName + " iconCenter"} aria-hidden="true"
                            style={{ color: "black", fontSize: "16px" }}></i>
                    </a>
                    <br />

                    <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.t("Shape")}</span>
                    <a href="#"
                        className=" "
                        onClick={(e) => this.showSetAllAlertShape(e, targetName)}>
                        {this.props.t("SetAll")}
                    </a>

                    <ul id='select_shapeGlobal' className='dropdown-content'>

                        <li className="">
                            <a href="#" style={{ width: "100%", margin: 0 }}
                                onClick={(e) => this.shapeSelected("btn-floating btn ")}>
                                <i className={"fa fa-circle-thin  "} aria-hidden="true"
                                    style={{ color: "red", fontSize: "20px", width: "100%", margin: 0 }}> {this.props.t("Circle")}</i>
                            </a>

                        </li>
                        <li>
                            <a href="#" style={{ width: "100%", margin: 0 }}
                                onClick={(e) => this.shapeSelected("")}>
                                <i className={"fa fa-square-o  "} aria-hidden="true"
                                    style={{ color: "red", fontSize: "20px", width: "100%", margin: 0 }}> {this.props.t("Square")}</i>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    shapeSelected(shape) {
        var id = this.state.colorPicker.targetId;
        this.setState({
            shape: shape
        });
    }

    // color picker
    handlePickerChangeComplete = (color) => {
        console.log(" handlePickerChangeComplete", color.hex);
    };

    handlePickerChange = (name) => (color) => {
        var hex = "#" + rgba2hex(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a},)`)
        var id = this.state.colorPicker.targetId;
        this.setState({
            [id]: hex,
            colorPicker: { ...this.state.colorPicker, lastSelectedColor: hex }
        });
    }

    handlePickerOpen = (color, targetName, e) => {
        e.preventDefault()
        this.setState({
            colorPicker:
            {
                isOpen: true,
                lastSelectedColor: color,
                targetId: targetName
            }
        }
        );
    }

    handlePickerClose = () => {
        this.setState({
            colorPicker:
            {
                isOpen: false,
                lastSelectedColor: "",
                targetId: ""
            }
        }
        );
    }

    showSetAllAlert(e, targetName) {
        e.preventDefault()
        this.selectedTargetToChange = targetName
        this.alertTitle = this.props.t("SetAll?")

        if (targetName == "fontName") {
            this.alertSubTitle = `${this.props.t("setAllButtons?")} ${getTargetNameDesctiption(targetName, this.props.t)} ${this.props.t("WithYourChoosenStyle")}`
        }
        else if (targetName == "fontSize") {
            this.alertSubTitle = `${this.props.t("setAllButtons?")} ${getTargetNameDesctiption(targetName, this.props.t)} ${this.props.t("WithYourChoosenSize")}`
        }
        else {
            this.alertSubTitle = `${this.props.t("setAllButtons?")} ${getTargetNameDesctiption(targetName, this.props.t)} ${this.props.t("colorWithSelectedColor?")}`
        }


        this.setState({
            showingAlert: true
        })
    }


    showSetAllAlertShape(e, targetName) {
        e.preventDefault()
        this.selectedTargetToChange = targetName
        this.alertTitle = this.props.t("SetAll?")
        this.alertSubTitle = `${this.props.t("setAllButtons?")}  ${this.props.t("withShape?")}`

        this.setState({
            showingAlert: true
        })
    }
}

export default withTranslation()(TopGlobalCustomButtonsColor)
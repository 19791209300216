import React, { Component } from 'react'
import ColorPickerComponent from '../ColorPickerComponent'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import CheckboxComponent from '../../CheckboxComponent';
import { connect } from "react-redux"
import { updateBackgroundSettings, saveBackgroundImage } from '../../../../store/actions/cardActions'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import DragAndDropImageComponent from '../../DragAndDropImageComponent';
import { getTitleForCode, getTopHeaderImageStyleList } from '../../../../Utils/UtilMethods'

export class BackgroundEditComponent extends Component {

    state = {
        background: this.props.reduxSelectedCard.background,
        didMakeChange: false,
        didAddFileBg: false,
        pickerShow: false
    }

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.dropdown-trigger-imageStyle1');
        M.Dropdown.init(elems, { constrainWidth: false });
    }

    componentWillReceiveProps(newProps) {
        // if (newProps.reduxSelectedCard.background.url != this.state.logo.url) {
        console.log("updating the url state");
        this.setState({
            background: newProps.reduxSelectedCard.background
        })

    }

    dropzoneUpdate = (selectedFilesUrl) => {

        const image = selectedFilesUrl[0];
        this.setState({
            src: URL.createObjectURL(image),
            file: image,
            didAddFileBg: true
        });
    }

    saveChanges() {
        this.props.updateBackgroundSettings(this.state.background)
        this.setState({
            didMakeChange: false
        });
    }

    saveNewImageCrop() {
        this.props.saveBackgroundImage(this.state.file)
        this.setState({
            src: null,
            didAddFileBg: false
        });
    }

    render() {
        const { t, backgroundEditComponent } = this.props
        return (
            <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                {backgroundEditComponent.isLoading ?
                    <LoaderView text={backgroundEditComponent.loadingTitle} />
                    : null}

                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.state.background.color}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {
                            this.setState({
                                background: {
                                    ...this.state.background,
                                    color: color,
                                },

                                didMakeChange: true
                            })
                        }}
                    />
                    :
                    null}

                <div className="edit_button_card_container"
                    style={{ backgroundColor: this.state.background.color }}>


                    <h5 className="">{t("Background")}</h5>

                    <div className="row">
                        <div className="col s12 m3 l3 "  >
                            {t("SelectBgColor")}
                            {this.getColorPicker()}
                        </div>

                        <div className="col s12 m4 l4 "  >

                            {
                                this.state.background.url && this.state.background.url !== "" ?

                                    <CheckboxComponent title={t("HideBGI")}
                                        checked={this.state.background.hideImage}
                                        selected={(checked) => {
                                            this.setState(state => {
                                                state.background.hideImage = checked
                                                state.didMakeChange = true
                                                return state
                                            })
                                        }} />
                                    :
                                    null
                            }
                            <img src={this.state.background.url}
                                className="responsive-img"
                                style={{ maxHeight: 300 }}
                                alt={"כרטיס ביקור דיגיטלי"} />

                            {this.getImageStyleSelectorDropDowm(this.state.background)}
                        </div>

                        <div className="col s12 m5 l5 "  >
                            {t("SelectBgImage")}

                            <DragAndDropImageComponent filesSelected={selectedFilesUrl => {
                                // set grid view callback
                                console.log("selection");
                                if (selectedFilesUrl) {
                                    this.dropzoneUpdate(selectedFilesUrl)
                                }
                            }
                            }
                                limit={1}
                            />
                            {this.state.src ?
                                <img src={this.state.src} className="responsive-img" alt={"כרטיס ביקור דיגיטלי"} />
                                : null
                            }
                        </div>
                        {this.state.didAddFileBg ?
                            <div >
                                <button className={"btn btnUpdateServer z-depth-0 fullWidth  "} onClick={() => this.saveNewImageCrop()}> {this.props.t("UpdateNewHeader")}
                                    <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                </button>
                            </div> : null}

                        {this.state.didMakeChange ?
                            <div >
                                <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")} onClick={() => this.saveChanges()} >{t("SaveChanges")}
                                    <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                </button>
                            </div> : null}
                    </div>
                </div>
            </div>
        )
    }

    getImageStyleSelectorDropDowm(header) {
        return (
            <div>
                <a className='dropdown-trigger-imageStyle1 btn'
                    dir="auto"
                    data-target='imageStyle1'
                    style={{ textTransform: "none" }}>
                    {this.props.t("ImageStyle")}: {getTitleForCode(header.style)}
                    <i className="fa fa-angle-down " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}></i>
                </a>
                <ul id='imageStyle1' className='dropdown-content'>
                    {
                        getTopHeaderImageStyleList().map((value, index) => {
                            return <li ><a href="#" onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    ...this.state,
                                    background: {
                                        ...this.state.background,
                                        style: value.code
                                    },

                                    didMakeChange: true

                                })
                            }
                            }>{value.title}</a></li>
                        })
                    }
                </ul>
            </div>
        )
    }
    getColorPicker() {

        var style = {
            backgroundColor: this.state.background.color,
            width: "38px",
            height: "38px",
        }

        return (
            <div className="center">
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.handlePickerOpen(this.state.background.color, e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.t("BackgroundColor")}</span>
            </div>
        )
    }

    handlePickerOpen = (color, e) => {
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            saveBackgroundImage: (blob) => dispatch(saveBackgroundImage(blob)),
            updateBackgroundSettings: (background) => dispatch(updateBackgroundSettings(background))
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        backgroundEditComponent: state.redux.backgroundEditComponent
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(BackgroundEditComponent)

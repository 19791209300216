import React, { Component } from 'react'
import { connect } from "react-redux"
import { getFirebase } from 'react-redux-firebase'
import ImageGalleryWithSelection from '../../../ImageSelectorComponent/ImageGalleryWithSelection'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import { updateGalleryInfo } from '../../../../store/actions/cardActions'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import ImageGallerySort from './ImageGallerySort'
import SwitchButton from '../../SwitchButton'

export class EditCardImages extends Component {
    deleteCounter = 0;
    removeIndex = -1

    state = {
        ...this.props,
        selectedImageFilesGalleryIndexsToDelete: [],
        isUploadingNow: false,
        sortMode: false
    }

    deleteImageFromGallaryUrlList = (callBack) => {
        var gallery = this.props.reduxSelectedCard.gallerys.list[this.props.galleryIndex]
        var list = gallery.list
        list.splice(this.removeIndex, 1)
        gallery.list = list

        this.props.saveChangesClicked(gallery)

        var indexs = [...this.state.selectedImageFilesGalleryIndexsToDelete];
        indexs.pop();
        this.setState({ selectedImageFilesGalleryIndexsToDelete: indexs });
        callBack(true)
    }

    deleteSelectedFiles = () => {

        let indexs = [...this.state.selectedImageFilesGalleryIndexsToDelete]
        this.removeIndex = indexs[indexs.length - 1];

        const firebase = getFirebase();
        var desertRef = firebase.storage().refFromURL(this.props.reduxSelectedCard.gallerys.list[this.props.galleryIndex].list[this.removeIndex])

        if (!this.state.isUploadingNow && indexs.length > 0) {
            this.setState({
                isUploadingNow: true
            })
        }

        // Delete the file
        desertRef.delete().then(() => {
            this.continueToUrlDelete();
        }).catch((error) => {
            this.continueToUrlDelete();
        });
    }

    continueToUrlDelete = () => {
        this.deleteImageFromGallaryUrlList((success) => {
            if (success) {
                if (this.state.selectedImageFilesGalleryIndexsToDelete.length > 0) {
                    this.deleteSelectedFiles()
                }
                else {
                    this.setState({ isUploadingNow: false })
                }
            }
            else {
                this.setState({ isUploadingNow: false })
            }
        });
    }

    render() {
        const { t, galleryId, reduxSelectedCard, gallery } = this.props;
        return (
            <div>
                {this.state.isUploadingNow ?
                    <LoaderView text={t("DeletingImages")} />
                    : null}

                {
                    gallery ?
                        <div>
                            <h5> {gallery.title} {t("Gallery")}</h5>
                            {
                                gallery && gallery.list.length == 0 ?
                                    <div>
                                        {t("NoImagesYet")}
                                    </div>
                                    :
                                    <div>
                                        <SwitchButton selected={(selected) => {
                                            this.setState({
                                                sortMode: selected
                                            })
                                        }} />
                                        {
                                            this.state.sortMode ?
                                                <ImageGallerySort gallery={gallery}
                                                    galleryId={galleryId}
                                                    updateChanges={(gallery) => {
                                                        this.props.updateGalleryList(gallery)
                                                    }} />
                                                :
                                                <div>
                                                    <ImageGalleryWithSelection images={gallery.list} imagesSelected={this.state.selectedImageFilesGalleryIndexsToDelete}
                                                        selectedIndexes={selected => {
                                                            this.setState({
                                                                selectedImageFilesGalleryIndexsToDelete: selected
                                                            })
                                                        }} />

                                                    {
                                                        this.state.selectedImageFilesGalleryIndexsToDelete && this.state.selectedImageFilesGalleryIndexsToDelete.length ?
                                                            <button className="btn red lighten-1 z-depth-0" onClick={this.deleteSelectedFiles}>{t("DeleteSelectedFiles")}</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                        :
                        <div>
                            <h5> {reduxSelectedCard.gallerys[galleryId].title} {t("Gallery")}</h5>
                            {
                                reduxSelectedCard.gallerys[galleryId] && reduxSelectedCard.gallerys[galleryId].list.length == 0 ?
                                    <div>
                                        {t("NoImagesYet")}
                                    </div>
                                    :
                                    <div>
                                        <SwitchButton selected={(selected) => {
                                            this.setState({
                                                sortMode: selected
                                            })
                                        }} />
                                        {
                                            this.state.sortMode ?
                                                <ImageGallerySort gallery={reduxSelectedCard.gallerys[galleryId]}
                                                    galleryId={galleryId}
                                                    updateChanges={(gallery) => {
                                                        // this.updateGalleryInfo(galleryId, gallery)
                                                        this.props.updateGalleryList(gallery)
                                                    }} />
                                                :
                                                <div>
                                                    <ImageGalleryWithSelection images={reduxSelectedCard.gallerys[galleryId].list} imagesSelected={this.state.selectedImageFilesGalleryIndexsToDelete}
                                                        selectedIndexes={selected => {
                                                            this.setState({
                                                                selectedImageFilesGalleryIndexsToDelete: selected
                                                            })
                                                        }} />

                                                    {
                                                        this.state.selectedImageFilesGalleryIndexsToDelete && this.state.selectedImageFilesGalleryIndexsToDelete.length ?
                                                            <button className="btn red lighten-1 z-depth-0" onClick={this.deleteSelectedFiles}>{t("DeleteSelectedFiles")}</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                        }


                                    </div>

                            }

                        </div>
                }
                {/* OLD WAY */}



            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateGalleryInfo: (galleryId, gallery) => dispatch(updateGalleryInfo(galleryId, gallery)),
            removeUrlFromGalleryList: (galleryId, list) => { dispatch({ type: "REMOVE_URL_FROM_GALLARY_LIST", galleryId, list }) }
        })
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
    }
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EditCardImages)

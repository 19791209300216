import React from 'react'

export default function TextInputAreaComponent({ title, value, handleChange, textId }) {

    return (
        <div className="input-field">
            <div className="edit_button_card_subtitle" dir="auto">{title}</div>
            <textarea dir="auto"

                className="browser-default edit_button_card_input"
                type="text" id={textId}
                style={{ backgroundColor: "white", height: "100px" }}   // style={{ textAlign: "center" }}
                onChange={(e) => handleChange(e)}
                placeholder="" value={value} />
        </div>
    )
}

import React from 'react'
import { useHistory } from 'react-router-dom';
import cardUdisLogo from '../../images/Logos/cardudis_logo.png'
import { useTranslation } from 'react-i18next';

export function AlertOkComponent(props) {
    const { t } = useTranslation();

    function close() {
        props.closeAlert()
    }
    const { title, subtitle } = props
    return (
        <div className="fullDiv">
            <div className="centerDivAlert" >
                <h5 className="alertTitle" dir="auto">{title}</h5>
                <div className="alertSubTitle" dir="auto"
                    style={{ whiteSpace: "pre-wrap" }}>
                    {subtitle}
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col s12 center">
                        <div className="btn pink alertButton" onClick={() => close(true)}>{t("Ok")}</div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export function AlertOkCancelComponent(props) {
    const { t } = useTranslation();
    function close(confirm) {
        props.closeAlert(confirm)
    }
    const { title, subtitle } = props
    return (
        <div className="fullDiv">
            <div className="centerDivAlert" >
                <h5 className="alertTitle" dir="auto">{title}</h5>
                <div className="alertSubTitle"
                    dir="auto"
                    style={{ whiteSpace: "pre-wrap" }}>
                    {subtitle}
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col s6">
                        <div className="btn pink alertButton" onClick={() => close(true)}>{t("Yes")}</div>
                    </div>
                    <div className="col s6">
                        <div className="btn pink alertButton" onClick={() => close(false)}> {t("Cancel")}</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export function AlertMissingPayment(props) {
    const { t, i18n } = useTranslation();
    function close(confirm) {
        props.closeAlert(confirm)
    }
    const { title, subtitle } = props
    return (
        <div className="fullDiv">
            <div className="centerDivAlert" >
                <h5 className="alertTitle" dir="auto">{t("CardExpired")}</h5>
                <div className="alertSubTitle" dir="auto">{t("TimeExpired")}</div>
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col s12">
                        <div className="btn pink  fullWidth" onClick={() => close(true)}>{t("Payment")}</div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export function AlertLayerLockMissingPayment(props) {
    const history = useHistory()
    const { title, subtitle } = props
    const { t, i18n } = useTranslation();
    return (
        <div className="fullDivNoBg"
            onClick={() => {
                history.push('/');
            }}>

            <div className="center" >
                <div id="infoi">
                    <i className="fa fa-lock"
                        aria-hidden="true"
                        style={{ marginTop: 80, fontSize: 180, color: "red" }}
                    ></i>
                    <div style={{ color: "white", fontSize: 30, padding: 5 }}>
                        {t("CardLocked")}
                        <br />
                        <a href="/"> CardUdis <br /> {t("DigitalCard")} </a>
                        <br />
                        <img src={cardUdisLogo} alt={"כרטיס ביקור דיגיטלי"} />
                    </div>
                </div>


            </div>

        </div>
    );
}
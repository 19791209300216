import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"; // For linking
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { useTranslation } from 'react-i18next';
import israelFlag from '../../images/flags/israel.png'
import usaFlag from '../../images/flags/usa.png'
import { AlertOkCancelComponent } from '../AlertsComponents/AlertsComponent'
import LoaderView from '../SpinnerLoader/LoaderView';
import LanguageSelectorComponent from "../LanguageSelectorComponent";

const SignedInLinks = (props) => {
    const { t } = useTranslation();
    const [showingAlert, setShowAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function signOutAlert() {
        setShowAlert(true);
    }


    function signOut() {
        props.signOut();
    }

    return (
        <span>

            {showingAlert ?
                < AlertOkCancelComponent title={t("Logout") + "?"} subtitle={t("SureLogout?")} closeAlert={(confirm) => {

                    if (confirm) {
                        // do the code
                        console.log("user confirm");
                        setShowAlert(false);
                        setIsLoading(true);
                        signOut()
                        // signout;
                    }
                    else {
                        setShowAlert(false);
                    }

                }} />
                : null}

            {isLoading ?
                <LoaderView text={t("SigningOut") + "..."} />
                : null}

            <ul className="right " >

                {/* <li>
                    <NavLink to="/create">Add/Edit Card</NavLink>
                </li> */}

                <li style={{ height: 60 }}><a className="hide-on-med-and-down" onClick={signOutAlert}> {t("Logout")}</a></li>

                {/* <li style={{ height: 60 }}>
                    <NavLink to={{
                        pathname: '/dashboard',
                        state: {
                            fromInside: true
                        }
                    }} className="btn btn-floating pink lighten-1">
                        {props.profile.initials}
                    </NavLink>
                </li> */}
                <li style={{ height: 60 }}>
                    <LanguageSelectorComponent />
                </li>

            </ul>

        </span>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { getPackageList } from '../../Utils/UtilMethods'
import { NavLink } from "react-router-dom"; // For linking

export class PriceCardComponent extends Component {
    render() {
        const { title, index, t, buttonText } = this.props
        return (
            <div className="card box grey lighten-5 z-depth-4">
                <div className="card-content center " style={{ padding: 0 }}>
                    <h1 className='priceGrad1' style={{ margin: 0, paddingTop: 30, fontFamily: "Heebo-ExtraBold" }}>{title}</h1>
                </div>
                <div className="card-content center" style={{ padding: 0, minHeight: 100, maxHeight: 150 }}>

                    <div className='blue-text '
                        style={{ marginBottom: 0 }}
                        dir="auto">
                        <span style={{ fontSize: 50 }}> {getPackageList()[index].price}</span>
                        <span className="center" dir="auto" style={{ fontSize: 30 }}>{t("ILS")}</span>
                        <span style={{ fontSize: 14 }}>{t("PlusVat")}</span> </div>


                    <div style={{ fontSize: 20 }}>
                        <b>{t("UpToDays", { value: getPackageList()[index].period })}</b>
                    </div>

                </div>

                <ul className='collection center ' >
                    <b>
                        <li className='collection-item  grey lighten-5 ' >
                            {t("AllUnlimited")}
                        </li>
                        <li className='collection-item  grey lighten-5 ' >
                            {t("PersonalSupport")}
                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {t("PersonalDomain")}
                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {t("LicenseToAndroidApp")}
                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {t("UpToPhotoUpload", { value: getPackageList()[index].maxFileUploads })}
                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {
                                index == 2 ?
                                    t("UnlimitedWide")
                                    :
                                    t("UpToWide", { value: getPackageList()[index].maxRectangleButtons })
                            }

                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {
                                index == 2 ?
                                    t("UnlimitedRounded")
                                    :
                                    t("UpToRounded", { value: getPackageList()[index].maxRoundedButtons })
                            }
                        </li>
                        <li className='collection-item  grey lighten-5 '>
                            {
                                index == 2 ?
                                    t("UnlimitedGallerys")
                                    :
                                    t("UpToGallery", { value: getPackageList()[index].maxVideoShare })
                            }
                        </li>
                    </b>
                </ul>

                <div className="card-content center">
                    <div className="row">
                        <div className="col s12">
                            <NavLink to="/signup" >
                                <button className='curdudis_button'
                                    style={{ fontFamily: "Heebo-ExtraBold", fontSize: 30, whiteSpace: "pre-wrap" }}>
                                    {buttonText}
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
export default withTranslation()(PriceCardComponent)
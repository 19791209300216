import React, { useEffect } from "react";
import { SERVER_SIDE_BASE_URL } from '../../../Utils/Constants'
import AOS from 'aos';
import "aos/dist/aos.css"

// data-aos="flip-right"
// data-aos-duration="7000"
// data-aos-offset="-50"
// data-aos-once="false"


const encodeStr = (str) => {
    let encoded = encodeURIComponent(str)
    return encoded
}

const ShareComponent = ({ cardID, shares, title, subtitle, animations }) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    });

    console.log("RoundedContactButtons");

    const iconsColor = "#ffffff";
    const buttonHeight = 58;
    const iconSize = "30px";
    var counter = 0
    // calculate how big the share button should be for 1 row
    for (var key in shares) {
        // check if the property/key is defined in the object itself, not in parent
        switch (key) {
            case "email": case "facebook": case "sms": case "whatsapp":
                if (!shares[key].hideButton) {
                    if (animations) {
                        shares[key].delayAnimation = counter * animations.delay
                    }
                    counter++;
                }
                break;

            default:
                break;
        }
    }
    let width = 100 / counter

    var animation = {
        animation: "",
        // delay: (index) * 200,
        duration: 1000,
        offset: "-50",
        once: "true",
    }
    if (animations) {
        animation.animation = animations.animation
        animation.duration = animations.duration
    }

    return (

        <div >
            {
                shares.hideAllShare ? null :
                    <div>
                        {!shares.hideTitle ?
                            <div className="center"
                                dir="auto"
                                style={{ width: "100%", fontFamily: shares.fontName, color: shares.textColor, fontSize: shares.fontSize }}>
                                {shares.title}
                            </div>
                            : null}
                        <div
                        >
                            {
                                !shares.email.hideButton ?
                                    <span className=" " style={{ padding: 0 }}>
                                        {/* {"mailto:?subject=" + card.firstName + " " + card.lastName + "&body=" + " לחץ על הקישור על מנת להיכנס לכרטיס הביקור של " + card.firstName + " " + card.lastName + " " + window.location.href} */}
                                        <a href={"mailto:?subject=" + title + "-" + subtitle + "&body=" + encodeStr(shares.email.text)}
                                            // <a href="mailto:?subject=מרפאת שיניים אריה&body=לחץ על הקישור על מנת להיכנס לכרטיס הביקור של מרפאת שיניים אריה https://www.appcard.co.il/arie/"
                                            className=" waves-effect waves-light a_transparent"
                                            style={{
                                                backgroundColor: shares.email.buttonColor,
                                                border: "2px solid",
                                                // borderColor: String(borderColor),
                                                width: width + "%",
                                                height: Number(buttonHeight),
                                            }}
                                            data-aos={animation.animation}
                                            data-aos-duration={animation.duration}
                                            data-aos-offset={animation.offset}
                                            data-aos-once={animation.once}
                                            data-aos-delay={shares.email.delayAnimation}
                                        >
                                            <i className="fa fa-envelope iconCenter" aria-hidden="true"
                                                style={{ color: String(iconsColor), fontSize: String(iconSize) }}></i>
                                        </a>
                                    </span>
                                    : null}
                            {
                                !shares.facebook.hideButton ?
                                    <span className="" style={{ padding: 0 }}>
                                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeStr(SERVER_SIDE_BASE_URL + "vip/" + cardID)}  //</span>https%3A%2F%2Fcardudis.com%2F
                                            className=" waves-effect waves-light a_transparent"
                                            style={{
                                                backgroundColor: shares.facebook.buttonColor,
                                                border: "2px solid",
                                                // borderColor: String(borderColor),
                                                width: width + "%",
                                                height: Number(buttonHeight),
                                            }}
                                            data-aos={animation.animation}
                                            data-aos-duration={animation.duration}
                                            data-aos-offset={animation.offset}
                                            data-aos-once={animation.once}
                                            data-aos-delay={shares.facebook.delayAnimation}
                                        >
                                            <i className="fa fa-facebook iconCenter" aria-hidden="true"
                                                style={{ color: String(iconsColor), fontSize: String(iconSize) }}></i>
                                        </a>
                                    </span>
                                    : null
                            }

                            {
                                !shares.sms.hideButton ?
                                    <span className="" style={{ padding: 0 }}>

                                        <a href={"sms://?&body=" + encodeStr(shares.sms.text)}
                                            className=" waves-effect waves-light a_transparent"
                                            style={{
                                                backgroundColor: shares.sms.buttonColor,
                                                border: "2px solid",
                                                // borderColor: String(borderColor),
                                                width: width + "%",
                                                height: Number(buttonHeight),
                                            }}
                                            data-aos={animation.animation}
                                            data-aos-duration={animation.duration}
                                            data-aos-offset={animation.offset}
                                            data-aos-once={animation.once}
                                            data-aos-delay={shares.sms.delayAnimation}
                                        >
                                            <i className="fa fa-comments iconCenter" aria-hidden="true"
                                                style={{ color: String(iconsColor), fontSize: String(iconSize) }}></i>
                                        </a>
                                    </span>
                                    : null
                            }

                            {
                                !shares.whatsapp.hideButton ?
                                    <span className="" style={{ padding: 0 }}>
                                        <a href={"whatsapp://send?text=" + encodeStr(shares.whatsapp.text)}
                                            className=" waves-effect waves-light a_transparent"
                                            style={{
                                                backgroundColor: shares.whatsapp.buttonColor,
                                                border: "2px solid",
                                                width: width + "%",
                                                height: Number(buttonHeight),
                                            }}
                                            data-aos={animation.animation}
                                            data-aos-duration={animation.duration}
                                            data-aos-offset={animation.offset}
                                            data-aos-once={animation.once}
                                            data-aos-delay={shares.whatsapp.delayAnimation}
                                        >
                                            <i className="fa fa-whatsapp iconCenter" aria-hidden="true"
                                                style={{ color: String(iconsColor), fontSize: String(iconSize) }}></i>
                                        </a>
                                    </span>
                                    : null
                            }


                        </div>
                    </div>
            }

        </div >
    );
};

export default ShareComponent;


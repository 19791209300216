import React, { Component } from 'react'
import RoundButtonBorderTextIcon from '../../../Buttons/RoundButtonBorderTextIcon'
import TextInputComponent from '../../../InputComponents/TextInputComponent'
import ShopItemEditor from './ShopItemEditor'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import FontListSelectComponent from '../../FontListSelectComponent';
import CenterText from '../../../View_Text/CenterText';
import ColorPickerComponent from "../ColorPickerComponent";
import NumberStepperComponent from "../../NumberStepperComponent";
import ShopItemEditorGlobalPanelSettings from "./ShopItemEditorGlobalPanelSettings";
import arrayMove from 'array-move';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';


export class ShopCategoryEditor extends Component {

    selectedTargetName = ""

    state = {
        ...this.props,
        didMakeChange: false,
        isUploadingNow: false,
        showingAlert: false,
        pickerShow: false,
        disableSorting: true,
    }

    handleChangeCategoryItem(e, indexItem) {
        var cat = this.state.category
        var item = cat.items[indexItem]
        item[e.target.id] = e.target.value
        cat.items[indexItem] = item

        this.props.updateCategory(cat)
    }

    handlePickerOpen = (target, e) => {
        e.preventDefault()
        this.selectedTargetName = target
        this.setState({
            pickerShow: true
        }
        );
    }

    handleChangeFor_All_ShopItemsInList = (e) => {
        // this.saveLastGlobalSelectionForRectangle(e)
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value);
        // 1. Make a shallow copy of the items
        if (this.state.category) {
            let listCopy = [...this.state.category.items];

            for (let index = 0; index < listCopy.length; index++) {
                var item = listCopy[index];
                item = {
                    ...item,
                    [e.target.id]: e.target.value
                }
                listCopy[index] = item
            }
            var category = {
                ...this.state.category,
                items: listCopy,
            }
            this.setState({
                ...this.state,
                category: category
            })
            // this.props.category.items = listCopy
            this.props.updateCategory(category)
            // last global selection
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        var cat = this.state.category
        var myList = [...cat.items];
        var newList = arrayMove(myList, oldIndex, newIndex)
        cat.items = newList
        this.setState({
            ...this.state,
            category: cat
        })
        this.props.updateCategory(cat)

    };

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });

    SortableItem = sortableElement(({ value, myIndex }) => this.getShopItemEditLayout(value, myIndex));

    getShopItemEditLayout(item, indexItem) {

        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "3px solid",
            margin: 1,
            borderColor: "red",
        }

        return (
            <div className="col s12 m6 l6">
                <div style={cardStyle}>
                    <ShopItemEditor item={item}
                        handleChangeCategoryItem={(e) => {
                            this.handleChangeCategoryItem(e, indexItem)
                        }}
                        itemDelete={
                            (e) => this.props.itemDelete(indexItem)
                        }
                        uploadImages={(listImages) => {
                            this.props.uploadImages(listImages, indexItem)
                        }}
                        index={indexItem}
                    />
                </div>
            </div>
        )
    }
    render() {
        const { category,
            handleChangeCategorySetting,
            createNewItem,
            categoryDeleteAlert, t } = this.props

        return (
            <div>
                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={category[this.selectedTargetName]}
                        closePicker={() => {
                            this.setState({
                                ...this.state,
                                pickerShow: false
                            })
                        }}

                        pickerChanged={(color) => {
                            var event = {
                                target: {
                                    id: this.selectedTargetName,
                                    value: color
                                }
                            }
                            handleChangeCategorySetting(event)
                        }}
                    />
                    :
                    null}

                <div>
                    {
                        // video.hideTitle ? null :
                        <CenterText
                            textColor={category.color}
                            fontSize={category.fontSize ? category.fontSize : 32}
                            fontFamily={category.fontName}
                            text={category.title}
                        />
                    }
                </div>

                <div className="edit_button_card_container" style={{}}>
                    <TextInputComponent
                        title={t("ShopCategoryName")}
                        value={category.title}
                        textId="title"
                        handleChange={(e) => handleChangeCategorySetting(e)}
                    />
                </div>


                <div className="roundedButtons">
                    <ul >
                        <li style={{ width: "50px" }}>
                            <div >
                                <RoundButtonBorderTextIcon
                                    text={t("Text")}
                                    iconName={"fa fa-font"}
                                    color={category.color}
                                    iconColor="white"
                                    backgroundColor={category.color}
                                    buttonClick={(e) => this.handlePickerOpen("color", e)}
                                />
                            </div>
                        </li>
                        <li style={{ width: "50px" }}>
                            <div >
                                <RoundButtonBorderTextIcon
                                    text={t("Border")}
                                    iconName={"fa fa-square-o"}
                                    color={category.borderColor}
                                    iconColor="white"
                                    backgroundColor={category.borderColor}
                                    buttonClick={(e) => this.handlePickerOpen("borderColor", e)}
                                />
                            </div>
                        </li>

                        <li style={{ width: "50px", marginTop: 6 }}>
                            <FontListSelectComponent
                                fontName={category.fontName}
                                selectedFont={(fontName) => {
                                    var event = {
                                        target: {
                                            id: "fontName",
                                            value: fontName
                                        }
                                    }
                                    handleChangeCategorySetting(event)
                                }} />
                        </li>

                        <li style={{ width: "50px" }}>
                            <div>
                                <RoundButtonBorderTextIcon
                                    text={t("DeleteCategory")}
                                    iconName={"fa fa-trash-o"}
                                    color={"red"}
                                    buttonClick={(e) => categoryDeleteAlert()}
                                />
                            </div>
                        </li>

                        <li>
                            <NumberStepperComponent
                                value={category.fontSize ? category.fontSize : 32}
                                max={70}
                                min={0}
                                step={1}
                                title={t("FontSize")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "fontSize",
                                            value: value
                                        }
                                    }
                                    handleChangeCategorySetting(newValue)
                                }} />
                        </li>
                        <li>
                            <NumberStepperComponent
                                value={category.marginTop ? category.marginTop : 0}
                                max={200}
                                min={-200}
                                step={1}
                                title={t("MarginTop")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "marginTop",
                                            value: value
                                        }
                                    }
                                    handleChangeCategorySetting(newValue)
                                }} />
                        </li>
                        <li>
                            <NumberStepperComponent
                                value={category.borderWidth !== undefined ? category.borderWidth : 2}
                                max={20}
                                min={0}
                                step={1}
                                title={t("BorderWidth")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "borderWidth",
                                            value: value
                                        }
                                    }
                                    handleChangeCategorySetting(newValue)
                                }} />
                        </li>
                    </ul>
                </div>

                <div >
                    <RoundButtonBorderTextIcon
                        text={t("AddItem")}
                        iconName={"fa fa-plus"}
                        color={"blue"}
                        buttonClick={() => createNewItem()}
                    />
                </div>

                {
                    category.items.length > 0 ?
                        <ShopItemEditorGlobalPanelSettings
                            handleChangeFor_All_ShopItemsInList={(e) => this.handleChangeFor_All_ShopItemsInList(e)}
                            lastGlobalSelections={{}}
                            setDisableSort={(selected) => {
                                this.setState({
                                    ...this.state,
                                    disableSorting: !selected
                                })
                            }} />
                        :
                        null
                }

                <this.SortableContainer onSortEnd={this.onSortEnd} key="bla">
                    {category.items && category.items.map((value, index) => (
                        <this.SortableItem key={`itembla-${index}`} index={index} value={value}
                            myIndex={index}
                            disabled={this.state.disableSorting} />
                    ))}
                </this.SortableContainer>

                {/* {
                    category.items.map((item, indexItem) => {
                        return (

                            <div className="col s12 m6 l6">
                                <ShopItemEditor item={item}
                                    handleChangeCategoryItem={(e) => {
                                        this.handleChangeCategoryItem(e, indexItem)
                                    }}
                                    itemDelete={
                                        (e) => itemDelete(indexItem)
                                    }
                                    uploadImages={(listImages) => {
                                        uploadImages(listImages, indexItem)
                                    }}
                                    index={indexItem}
                                />

                            </div>
                        )
                    })
                } */}
            </div>
        )
    }
}
export default compose(withTranslation())(ShopCategoryEditor)
// sfc shortcut
import { getResizedImage, getResizedImageWithSetting, getResizedImageForForShopItem, generateStringId_NoNumbers, getResizedImageBackground } from '../../Utils/UtilMethods'
import i18n from 'i18next';
import { getFirebase } from 'react-redux-firebase';

export const getUserCards = () => {

    return (dispatch, getState, { getFirestore }) => {

        dispatch({ type: 'DASH_BOARD_LOADING' });

        const firestore = getFirestore();
        const authorId = getState().firebase.auth.uid;
        firestore.collection("cards")
            .where("authorId", "==", authorId)
            .get()
            .then(function (querySnapshot) {
                if (querySnapshot.size) {
                    var cardsList = [];
                    querySnapshot.forEach(function (doc) {
                        cardsList.push(doc.data());
                    });
                    var cards = 0
                    var statisticsDict = {}

                    cardsList.forEach(function (card) {

                        //    cardActions.getUserStatistics(card.id
                        firestore.collection("userVisitors")
                            .doc(card.id)
                            .get()
                            .then(function (doc) {
                                cards++
                                if (doc.exists) {
                                    console.log("card is not avialable");
                                    statisticsDict[card.id] = doc.data()
                                    // dispatch({ type: 'DASH_BOARD_STATISTICS_RESPONSE', statistics: doc.data(), cardId: card.id });
                                }
                                else {
                                    // console.log("card is avialable");
                                    // dispatch({ type: 'NO_CARD' });
                                }
                                if (cards == cardsList.length) {
                                    dispatch({ type: 'DASH_BOARD_STATISTICS_RESPONSE', statistics: statisticsDict });
                                }
                            })
                            .catch(function (err) {
                                dispatch({ type: 'DASH_BOARD_STATISTICS_RESPONSE_ERROR', error: err });
                                cards++;
                            });

                    });
                    cardsList.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        // return new Date(b.info.createdAt) > new Date(a.info.createdAt);
                        var key1 = b.info.createdAt.seconds
                        var key2 = a.info.createdAt.seconds
                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                    dispatch({ type: 'DASH_BOARD_CARDLIST_RESPONSE', cards: cardsList });
                }
                else {
                    dispatch({ type: 'DASH_BOARD_CARDLIST_RESPONSE', cards: [] });
                }
            })
            .catch(function (err) {
                dispatch({ type: 'DASH_BOARD_CARDLIST_RESPONSE_ERROR', error: err });
            });
    }
}

export const getUserStatistics = (cardId) => {

    return (dispatch, getState, { getFirestore }) => {

        // dispatch({ type: 'DASH_BOARD_LOADING' });

        const firestore = getFirestore();
        firestore.collection("userVisitors")
            .doc(cardId)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    console.log("card is not avialable");
                    dispatch({ type: 'DASH_BOARD_STATISTICS_RESPONSE', statistics: doc.data() });
                }
                else {
                    // console.log("card is avialable");
                    // dispatch({ type: 'NO_CARD' });
                }
            })
            .catch(function (err) {
                dispatch({ type: 'DASH_BOARD_STATISTICS_RESPONSE_ERROR', error: err });
            });
    }
}

export const createNewGallery = (gallery) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'GALLARY_LOADING', title: i18n.t("CreatingGallery") });

        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).set({
            "gallerys": gallery,
        }, { merge: true }).then(() => {
            dispatch({ type: 'CREATE_NEW_GALLERY_SUCCESS', gallery: gallery });
        }).catch(err => {
            dispatch({ type: 'CREATE_NEW_GALLERY_FAILED', error: err });
        });
    }
}

export const updateGallerys = (gallerys, showLoader) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (showLoader == null) {
            dispatch({ type: 'GALLARY_LOADING', title: i18n.t("UpdatingGalery") });
        }


        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        updateGalleryInfoNew(getFirestore(), cardId, gallerys, (err) => {
            if (err === null) {
                dispatch({ type: 'UPDATE_GALLERYS_SUCCESS', gallerys: gallerys });
            }
            else {
                dispatch({ type: 'UPDATE_GALLERYS_FAILED', error: err });
            }
        })
    }
}

export const updateShop = (shop, showLoader) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // if (showLoader == null) {
        //     dispatch({ type: 'GALLARY_LOADING', title: i18n.t("UpdatingGalery") });
        // }

        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        getFirestore().collection('cards').doc(cardId).update({
            "shop": shop,
        }).then(() => {
            dispatch({ type: 'UPDATE_SHOP_SUCCESS', shop: shop });

        }).catch(err => {
            dispatch({ type: 'UPDATE_SHOP_FAILED', error: err });
        });



    }
}

const updateGalleryInfoNew = (firestore, id, gallerys, callBack) => {

    firestore.collection('cards').doc(id).update({
        "gallerys": gallerys,
    }).then(() => {
        callBack(null)

    }).catch(err => {
        callBack(err)
    });

}

export const deleteFolderContents = (path, firebase) => {
    const ref = firebase.storage().ref(path);
    ref.listAll()
        .then(dir => {
            dir.items.forEach(fileRef => {
                deleteFile(ref.fullPath, fileRef.name, firebase);
            });
            dir.prefixes.forEach(folderRef => {
                deleteFolderContents(folderRef.fullPath, firebase);
            })
        })
        .catch(error => {
            console.log(error);
        });
}

export const deleteFile = (pathToFile, fileName, firebase) => {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete()
}

export const deleteCard = (card) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const email = getState().firebase.auth.email;
        let path = email + "/" + card.id
        dispatch({ type: 'DASH_BOARD_DELETE_CARD' });

        deleteFolderContents(path, getFirebase())
        getFirebase().firestore().collection('cards').doc(card.id).delete()
            .then(() => {
                dispatch({ type: 'DASH_BOARD_DELETE_RESPONSE', cardId: card.id });
            })
            .catch((error) => {
                console.log("Error removing document:", error)
                dispatch({ type: 'DASH_BOARD_DELETE_ERROR', error: error });
            })

        getFirebase().firestore().collection('userVisitors').doc(card.id).delete()
            .then(() => {

            })
            .catch((error) => {
                console.log("Error removing document:", error)

            })
    }
}

export const deleteGallery = (gallerys, path) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'GALLARY_LOADING', title: i18n.t("DeletingGallery") });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        let firebase = getFirebase()
        let ref = firebase.storage().ref(path);
        ref.listAll().then(dir => {
            var deleteCounter = dir.items.length;

            if (deleteCounter > 0) {
                dir.items.forEach(fileRef => {
                    var dirRef = firebase.storage().ref(fileRef.fullPath);
                    dirRef.getDownloadURL().then(function (url) {
                        var imgRef = firebase.storage().refFromURL(url);
                        imgRef.delete().then(function () {
                            deleteCounter--;
                            if (deleteCounter == 0) {
                                console.log("all storage ile delete");
                                updateGalleryInfoNew(getFirestore(), cardId, gallerys, (err) => {
                                    if (err == null) {
                                        dispatch({ type: 'UPDATE_GALLERYS_SUCCESS', gallery: gallerys });
                                    }
                                    else {
                                        dispatch({ type: 'UPDATE_GALLERYS_FAILED', error: err });
                                    }
                                })
                                // deleteGalleryData(dispatch, getFirestore, cardId, newGallerys);
                                //all files deleted
                                // update server data 
                            }
                            // File deleted successfully 
                        }).catch(function (error) {
                            // There has been an error    
                            dispatch({ type: 'DELETE_GALLERY_FAILED', error: error });

                        });
                    });
                });
            }
            else {
                deleteGalleryData(dispatch, getFirestore, cardId, gallerys);
            }
        }).catch(error => {
            dispatch({ type: 'DELETE_GALLERY_FAILED', error: error });
        });
    }
}

function deleteGalleryData(dispatch, getFirestore, cardId, newGallerys) {
    const firestore = getFirestore();
    firestore.collection('cards').doc(cardId).update({
        "gallerys": newGallerys,
    }).then(() => {

        dispatch({ type: 'DELETE_GALLERY_SUCCESS', gallerys: newGallerys });
        console.log("succes in dispatch");
    }).catch(err => {
        dispatch({ type: 'DELETE_GALLERY_FAILED', error: err });
        // this.props.reloadParentState()
        console.log("error in dispatch");

    });

}



export const updateGalleryInfo = (galleryId, gallery) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'GALLARY_LOADING', title: i18n.t("UpdatingGalery") });

        const firestore = getFirestore();
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        firestore.collection('cards').doc(cardId).update({
            [`gallerys.${galleryId}`]: gallery,
        }).then(() => {

            dispatch({ type: 'UPDATE_GALLERY_SUCCESS', gallery: gallery, galleryId: galleryId });
            console.log("succes in dispatch");
        }).catch(err => {
            dispatch({ type: 'CREATE_NEW_GALLERY_FAILED', error: err });
            // this.props.reloadParentState()
            console.log("error in dispatch");

        });
        // updateGallaryInfo = (gallery, callback) => {

        //     const firestore = getFirestore();
        //     firestore.collection('cards').doc(this.props.reduxSelectedCard.id).update({
        //         [`gallerys.${this.props.galleryId}`]: gallery,
        //     }).then(() => {
        //         callback(true)
        //         console.log("succes in dispatch");
        //     }).catch(err => {
        //         callback(true)
        //         // this.props.reloadParentState()
        //         console.log("error in dispatch");
        //     });
        // }
    }
}

export const updateDescription = (cardId, data) => {
    console.log("update description");
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'IS_LOADING_DESCRIPTION' });
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "description": data,
        }).then(() => {
            dispatch({ type: 'DESCRIPTION_UPDATE_SUCCESS', data });
        }).catch(err => {
            dispatch({ type: 'DESCRIPTION_UPDATE_FAILED', error: err });
        });
    }
}
export const updateAnimation = (animation) => {
    console.log("update card animation");
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'IS_LOADING_ANIMATION' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            animation
        }).then(() => {
            dispatch({ type: 'ANIMATION_UPDATE_SUCCESS', animation });
        }).catch(err => {
            dispatch({ type: 'DESCRIPTION_UPDATE_FAILED', error: err });
        });
    }
}

export const createCardNew = (card) => {
    console.log("creating card");
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'IS_LOADING_NEW_CARD' });
        console.log("creating card dispatch");
        const firestore = getFirestore();
        firestore.collection('cards').doc(card.id).set({
            ...card,
        }).then(() => {
            console.log("succes in dispatch");
            dispatch({ type: 'CREATE_CARD_SUCCESS', card });
        }).catch(err => {
            dispatch({ type: 'CREATE_CARD_ERROR' }, err);
        });
    }
}

export const deleteStorageDirectory = (getFirebase, getState, folderName, callback) => {


    const storage = getFirebase().storage();
    const email = getState().firebase.auth.email;
    const cardId = getState().redux.tabsEditorComponent.selectedCard.id;


    let ref = storage.ref(`${email}/${cardId}/${folderName}`);
    ref.listAll().then(dir => {
        var deleteCounter = dir.items.length;

        if (deleteCounter > 0) {
            dir.items.forEach(fileRef => {
                var dirRef = storage.ref(fileRef.fullPath);
                dirRef.getDownloadURL().then(function (url) {
                    var imgRef = storage.refFromURL(url);
                    imgRef.delete().then(function () {
                        deleteCounter--;
                        if (deleteCounter == 0) {
                            console.log("all storage ile delete");

                            callback(true)
                            //all files deleted
                            // update server data 
                        }
                        // File deleted successfully 
                    }).catch(function (error) {
                        // There has been an error    
                        callback(false)

                    });
                });
            });
        }
        else {
            callback(true)
        }
    }).catch(error => {
        callback(false)
    });

}

// logo image
export const saveLogoImageCrop = (blobCropedImage) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_LOGO_IMAGE' });

        const storage = getFirebase().storage();
        const email = getState().firebase.auth.email;
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        deleteStorageDirectory(getFirebase, getState, "logo", (success) => {
            if (success) {
                getResizedImageForForShopItem(blobCropedImage, (blob, imageName) => {
                    const uploadTask = storage.ref(`${email}/${cardId}/logo/${imageName}`).put(blob);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            // console.log("progress", progress);
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            console.log("progress", progress);
                            //  this.setState({ progress });
                        },
                        error => {
                            // Error function ...
                            dispatch({ type: 'LOGO_IMAGE_UPDATE_FAILED', error: error });
                        },
                        () => {
                            // complete function ...
                            storage
                                .ref(`${email}/${cardId}/logo`)
                                .child(imageName)
                                .getDownloadURL()
                                .then(url => {
                                    console.log("url", url);

                                    const firestore = getFirestore();
                                    firestore.collection('cards').doc(cardId).update({
                                        [`logo.url`]: url,
                                    }).then(() => {
                                        dispatch({ type: 'LOGO_IMAGE_UPDATE_SUCCESS', url: url });
                                    }).catch(err => {
                                        dispatch({ type: 'LOGO_IMAGE_UPDATE_FAILED', error: err });
                                    });


                                });
                        }
                    );
                }, 300, 300);
            }
            else {
                dispatch({ type: 'LOGO_IMAGE_UPDATE_FAILED', error: "Failed delete old logo storage" });
            }

        })
        return

    }
}

export const updateLogoSettings = (logo) => {
    console.log("creating card");
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_LOGO_SETTINGS' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "logo": logo,
        }).then(() => {
            dispatch({ type: 'LOGO_SETTING_UPDATE_SUCCESS', logo });
        }).catch(err => {
            dispatch({ type: 'LOGO_SETTING_UPDATE_FAILED', error: err });
        });
    }
}

export const updateBackgroundSettings = (background) => {
    console.log("background setting");
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_BACKGROUND_SETTINGS' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "background": background,
        }).then(() => {
            dispatch({ type: 'BACKGROUND_SETTING_UPDATE_SUCCESS', background });
        }).catch(err => {
            dispatch({ type: 'BACKGROUND_SETTING_UPDATE_FAILED', error: err });
        });
    }
}
export const updateShareButtonsSettings = (shares) => {
    console.log("share button setting");
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_SHARES_SETTINGS' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "shares": shares,
        }).then(() => {
            dispatch({ type: 'SHARES_SETTING_UPDATE_SUCCESS', shares });
        }).catch(err => {
            dispatch({ type: 'SHARES_SETTING_UPDATE_FAILED', error: err });
        });
    }
}

export const updateSeoData = (seo) => {
    console.log("seo button setting");
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_SHARES_SETTINGS' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "seo": seo,
        }).then(() => {
            dispatch({ type: 'SEO_SETTING_UPDATE_SUCCESS', seo });
        }).catch(err => {
            dispatch({ type: 'SEO_SETTING_UPDATE_FAILED', error: err });
        });
    }
}

// header image
export const saveHeaderImageCrop = (blobCropedImage) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_HEADER_IMAGE' });

        const storage = getFirebase().storage();
        const email = getState().firebase.auth.email;
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        deleteStorageDirectory(getFirebase, getState, "header", (success) => {
            if (success) {
                getResizedImageWithSetting(blobCropedImage, (blob, imageName) => {
                    const uploadTask = storage.ref(`${email}/${cardId}/header/${imageName}`).put(blob);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            // console.log("progress", progress);
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            console.log("progress", progress);
                            //  this.setState({ progress });
                        },
                        error => {
                            // Error function ...
                            dispatch({ type: 'HEADER_IMAGE_UPDATE_FAILED', error: error });
                        },
                        () => {
                            // complete function ...
                            storage
                                .ref(`${email}/${cardId}/header`)
                                .child(imageName)
                                .getDownloadURL()
                                .then(url => {
                                    console.log("url", url);

                                    const firestore = getFirestore();
                                    firestore.collection('cards').doc(cardId).update({
                                        [`header.url`]: url,
                                    }).then(() => {
                                        dispatch({ type: 'HEADER_IMAGE_UPDATE_SUCCESS', url: url });
                                    }).catch(err => {
                                        dispatch({ type: 'HEADER_IMAGE_UPDATE_FAILED', error: err });
                                    });


                                });
                        }
                    );
                }
                );
            }
            else {
                dispatch({ type: 'HEADER_IMAGE_UPDATE_FAILED', error: "Failed delete old header storage" });
            }

        })
        return

    }
}

// header image
export const saveBackgroundImage = (blobCropedImage) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_BACKGROUND_SETTINGS' });

        const storage = getFirebase().storage();
        const email = getState().firebase.auth.email;
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;

        deleteStorageDirectory(getFirebase, getState, "background", (success) => {
            if (success) {
                getResizedImageBackground(blobCropedImage, (blob, imageName) => {
                    const uploadTask = storage.ref(`${email}/${cardId}/background/${imageName}`).put(blob);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            // console.log("progress", progress);
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            console.log("progress", progress);
                            //  this.setState({ progress });
                        },
                        error => {
                            // Error function ...
                            dispatch({ type: 'BACKGROUND_SETTING_UPDATE_FAILED', error: error });
                        },
                        () => {
                            // complete function ...
                            storage
                                .ref(`${email}/${cardId}/background`)
                                .child(imageName)
                                .getDownloadURL()
                                .then(url => {
                                    console.log("url", url);

                                    const firestore = getFirestore();
                                    firestore.collection('cards').doc(cardId).update({
                                        [`background.url`]: url,
                                    }).then(() => {
                                        dispatch({ type: 'BACKGROUND_IMAGE_SETTING_UPDATE_SUCCESS', url: url });
                                    }).catch(err => {
                                        dispatch({ type: 'BACKGROUND_SETTING_UPDATE_FAILED', error: err });
                                    });


                                });
                        }
                    );
                });
            }
            else {
                dispatch({ type: 'BACKGROUND_SETTING_UPDATE_FAILED', error: "Failed delete old background storage" });
            }

        })
        return

    }
}

export const updateHeaderSettings = (header) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        dispatch({ type: 'IS_LOADING_HEADER_SETTINGS' });
        const cardId = getState().redux.tabsEditorComponent.selectedCard.id;
        const firestore = getFirestore();
        firestore.collection('cards').doc(cardId).update({
            "header": header,
        }).then(() => {
            dispatch({ type: 'HEADER_SETTING_UPDATE_SUCCESS', header });
        }).catch(err => {
            dispatch({ type: 'HEADER_SETTING_UPDATE_FAILED', error: err });
        });
    }
}
export const isCardNameAvailable = (cardName) => {
    console.log("card name -", cardName);
    return (dispatch, getState, { getFirestore }) => {

        const firestore = getFirestore();
        dispatch({ type: 'IS_LOADING_CARD_ID_AVAILABILITY' });

        firestore.collection("cards").doc(cardName)
            .get()
            .then(function (querySnapshot) {
                if (querySnapshot.exists) {
                    console.log("card is not avialable");
                    dispatch({ type: 'CARD_NAME_NOT_AVAILABLE' });
                }
                else {
                    console.log("card is avialable");
                    dispatch({ type: 'CARD_NAME_AVAILABLE' });
                }

            })
            .catch(function (err) {
                console.log("Error getting documents: ", err);
                dispatch({ type: 'ERROR' }, err);
            });
    }


}

export const isCardNameAvailable_SetToNo = () => {

    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'CARD_NAME_NOT_AVAILABLE' });
    }
}

export const getUserCardName = (cardName) => {
    console.log("card name -", cardName);
    return (dispatch, getState, { getFirestore }) => {

        const firestore = getFirestore();

        firestore.collection("cards").doc(cardName)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    console.log("card is not avialable");
                    dispatch({ type: 'GOT_THE_CARD', card: doc.data() });
                }
                else {
                    console.log("card is avialable");
                    dispatch({ type: 'NO_CARD' });
                }

            })
            .catch(function (err) {
                console.log("Error getting documents: ", err);
                dispatch({ type: 'ERROR' }, err);
            });

    }
}



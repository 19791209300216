import React, { Component } from 'react'
import { getRoundedButtonsTypeList, getBasicButtonsObjectForType } from '../../../../Utils/UtilMethods'
import { withTranslation } from 'react-i18next';
class CreateNewRoundedComponent extends Component {

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.dropdown-trigger-me');
        M.Dropdown.init(elems, { constrainWidth: false, coverTrigger: false });
    }

    selectButtonType = (button) => {
        this.props.newButton(getBasicButtonsObjectForType(button, this.props.isForRectangle))
    }
    render() {

        const { isForRectangle, t, } = this.props

        var style = {
            backgroundColor: "white",
            width: "35px",
            height: "35px",
            border: "2px solid",
            borderColor: "blue",
        }


        return (
            <li >
                <div style={{ marginTop: 6 }}>
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light dropdown-trigger-me "
                        data-target='select_button_type'
                        style={style}
                    //     onClick={(e) => this.handlePickerOpen(color, targetName, e, true)}
                    >
                        <i className={"fa fa-plus iconCenter"} aria-hidden="true"
                            style={{ color: "blue", fontSize: "16px" }}></i>
                    </a>
                    <br />
                    <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{t("NewButton")}</span>
                    <ul id='select_button_type' className='dropdown-content'>
                        {getRoundedButtonsTypeList(isForRectangle).map(button => {
                            return (

                                <li className=" bottom_border  " onClick={() => this.selectButtonType(button)}>
                                    <div className="row  links_row" >
                                        <div className="col s2 m2 center  " >
                                            <i className={button.icon} aria-hidden="true"
                                                style={{ color: "black", fontSize: "30px" }}></i>
                                        </div>
                                        <div className="col s10 m10 ">
                                            <a href="#" className="buttonTypeListText font16"

                                            >
                                                {button.type} : {button.description}

                                            </a>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </li>

        )

    }
}

export default withTranslation()(CreateNewRoundedComponent);
import React from 'react'

export default function RoundButtonBorderTextAndCenterText({ color, text, textCenter, backgroundColor, buttonClick }) {

    var style = {
        backgroundColor: backgroundColor ? backgroundColor : "white",
        width: "35px",
        height: "35px",
        border: "2px solid",
        color: color,
        marginTop: 6
    }

    return (
        <div>
            <button href="#"
                className="btn-floating btn waves-effect waves-light "
                style={style}
                onClick={(e) => buttonClick(e)}
            >
                <b>
                    <div
                        style={{
                            lineHeight: "33px",
                            fontFamily: "Varela Round",
                            fontSize: 20
                        }}
                        className="center"
                    >
                        {textCenter}
                    </div>
                </b>
            </button>
            <br />
            <span style={{ fontSize: "12px", style: "bold", color: "black", display: "block", marginTop: "4px" }}>
                {text}
            </span>
        </div>
    )
}


import React, { useEffect, useState } from "react";

import { Bar } from 'react-chartjs-2';
import { DropDownComponent } from '../components/user_cards/Editors/DropDownComponent';

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const getLastYearValueInStatisticsData = (data) => {
  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];
  var lastYear = Object.keys(statDict.entriesToPage)[Object.keys(statDict.entriesToPage).length - 1];
  return lastYear;
}

const getYearList = (data) => {
  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];

  var yearList = [];
  for (const [yearKey, daysValues] of Object.entries(statDict.entriesToPage)) {
    yearList.push(yearKey);
  }
  return yearList.reverse();
}


const getRoundedButtonsData = (data) => {
  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];

  var card = data.location.state.card;
  var labels = [];
  var clicksAmount = []
  card.roundedButtons.list.forEach(button => {
    labels.push(button.title);
    clicksAmount.push(statDict.roundedButtons.buttonsClicks[button.id])

    // button.id
  });
  const dataToShow = {
    labels: labels,
    datasets: [{
      label: 'מספר לחיצות ',
      data: clicksAmount,
      backgroundColor: 'rgb(75, 192, 192)',
    },
      // {
      //   label: 'מספר מבקרים חדשים החודש',
      //   data: newVisitorsToPageByMonth,
      //   // backgroundColor: 'rgb(255, 99, 132)',
      //   backgroundColor: 'rgb(54, 162, 235)',// blue
      // }
    ]
  };
  return dataToShow;
  // card.roundedButtons.list && card.roundedButtons.list.map(function (button, index) {

  //   console.log(button);
  //   return button;
  // })

}

const getRectangleButtonsData = (data) => {
  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];

  var card = data.location.state.card;
  var labels = [];
  var clicksAmount = []
  card.rectangleButtons.list.forEach(button => {
    labels.push(button.title);
    clicksAmount.push(statDict.rectangleButtons.buttonsClicks[button.id])

    // button.id
  });

  const dataToShow = {
    labels: labels,
    datasets: [{
      label: 'מספר לחיצות ',
      data: clicksAmount,
      backgroundColor: 'rgb(75, 192, 192)',
    },

    ]
  };
  return dataToShow;
}


const getShopButtonsData = (data) => {
  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];

  var labels = [];
  var clicksAmount = []
  for (const [ketType, value] of Object.entries(statDict.shopButtons.buttonsClicks)) {

    labels.push(ketType);
    clicksAmount.push(value)

    // button.id
  };

  const dataToShow = {
    labels: labels,
    datasets: [{
      label: 'מספר לחיצות ',
      data: clicksAmount,
      backgroundColor: 'rgb(75, 192, 192)',
    },

    ]
  };
  return dataToShow;
}

const getDataForCard = (data, selectedYear) => {

  var statistics = data.location.state.statistics;
  var cardId = data.location.state.card.id;
  var statDict = statistics[cardId];
  var entriesToPage = statDict.entriesToPage
  var newVisitors = statDict.newVisitors

  var labels = [];
  var entriesToPageByMonth = [];
  var newVisitorsToPageByMonth = [];


  for (const [monthKey, daysValues] of Object.entries(entriesToPage[selectedYear])) {
    // console.log(monthKey, daysValues);
    var sumForMonth = 0;
    for (const [dayKey, dayValue] of Object.entries(daysValues)) {
      // console.log(dayKey, dayValue);
      sumForMonth = sumForMonth + dayValue;
    }
    entriesToPageByMonth.push(sumForMonth);
    labels.push(months[parseInt(parseInt(monthKey)) - 1]);
  }

  for (const [monthKey, daysValues] of Object.entries(newVisitors[selectedYear])) {
    // console.log(monthKey, daysValues);
    sumForMonth = 0;
    for (const [dayKey, dayValue] of Object.entries(daysValues)) {
      console.log(dayKey, dayValue);
      sumForMonth = sumForMonth + dayValue;
    }
    newVisitorsToPageByMonth.push(sumForMonth);
    // labels.push(months[parseInt(parseInt(monthKey)) - 1]);
  }


  const dataToShow = {
    labels: labels,
    datasets: [{
      label: 'מספר כניסות לדף החודש',
      data: entriesToPageByMonth,
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'מספר מבקרים חדשים החודש',
      data: newVisitorsToPageByMonth,
      // backgroundColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgb(54, 162, 235)',// blue
    }]
  };
  return dataToShow;
}


export default function StackedBar({ history }) {
  const [selectedYear, setSelectedYear] = useState(getLastYearValueInStatisticsData(history));
  getRoundedButtonsData(history);
  return (<>
    <div className='header center'>
      <h1 className='' style={{
        padding: 0,
        margin: 0
      }}>{history.location.state.card.description.title.text}  </h1>
      <h4 className=''
        style={{
          padding: 0,
          margin: 0
        }}>{history.location.state.card.description.subtitle.text}</h4>
      <h5 className=''>סטטיסטיקה לשנה</h5>
      <DropDownComponent
        title={selectedYear}
        list={getYearList(history)}
        selectedIndex={(index) => {
          setSelectedYear(getYearList(history)[index])
        }}
        selectedId={(event, id) => {
        }} />
    </div>


    <h5 className='center' style={{ color: "blue" }}>כניסות לאתר</h5>
    <div className="row" style={{ marginTop: "10px" }}>
      <div className="container" style={{}}>
        <Bar data={getDataForCard(history, selectedYear)} options={options} />
      </div>
    </div>


    <div className='center'>
      <h5 style={{ color: "blue" }}>לחיצות לחצנים עגולים</h5>
      <div>מאז שימוש באתר או בכפתור</div>
    </div>
    <div className="row" style={{ marginTop: "10px" }}>
      <div className="container" style={{}}>
        <Bar data={getRoundedButtonsData(history)} options={options} />
      </div>
    </div>



    <div className='center'>
      <h5 style={{ color: "blue" }}>לחיצות לחצנים מלבניים</h5>
      <div>מאז שימוש באתר או בכפתור</div>
    </div>
    <div className="row" style={{ marginTop: "10px" }}>
      <div className="container" style={{}}>
        <Bar data={getRectangleButtonsData(history)} options={options} />
      </div>
    </div>

    <div className='center'>
      <h5 style={{ color: "blue" }}>לחיצות לחצנים חנות הזמנות</h5>
      <div>מאז שימוש באתר או בכפתור</div>
    </div>

    <div className="row" style={{ marginTop: "10px" }}>
      <div className="container" style={{}}>
        <Bar data={getShopButtonsData(history)} options={options} />
      </div>
    </div>

  </>);


}

// export default StackedBar;

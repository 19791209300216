import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import Navbar from "../../components/layout/Navbar";
import LoaderView from '../SpinnerLoader/LoaderView';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import ContactCardUdisWhatsapp from '../ContactCardUdisWhatsapp';
export class SignIn extends Component {
    state = {
        email: "",
        password: "",
        isLoading: false,
        error: null,

    }

    //When using ES6 code in React always use arrow functions, because the this context is automatically binded with it
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({
            isLoading: true,
            error: null
        })

        this.props.signIn(this.state)
    }

    render() {
        const { i18n, t, authError, auth } = this.props;
        if (auth.uid) return <Redirect to='/dashboard' />

        return (

            <div>
                <Navbar />


                {this.state.isLoading ?
                    <LoaderView text={t("SigningIn") + "..."} />
                    : null}

                <div className="container  navbar_padding ">

                    <form className="white whiteForm rounded" onSubmit={this.handleSubmit}>
                        <h5 className="grey-text text-darken-3 center">{t("SignIn")}</h5>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Email")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="email" id='email' onChange={this.handleChange} required />
                        </div>
                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Password")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="password" id='password' onChange={this.handleChange} required />
                        </div>
                        <div className="input-field">
                            <button className="curdudis_button">{t("ClickLogin")}</button>
                            <div className="center red-text">
                                {this.state.error ? <p>{this.state.error} </p> : null}
                            </div>
                        </div>
                    </form>
                </div>

                <ContactCardUdisWhatsapp />
            </div>
        )
    }

    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }

    componentWillReceiveProps(np) {
        if (!np.login.isLoading) {
            this.setState({
                isLoading: false,
                error: np.login.authError
            })
        }
    }

}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        login: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

SignIn.defaultProps = {
    login: { isLoading: false }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SignIn)
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

export class ModalViewWithTextFieldComponent extends Component {

    state = {
        input: null,
        error: null
    }
    modalViewInstance = null;

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelector('.modal');
        this.modalViewInstance = M.Modal.init(elems, { dismissible: false });
        this.modalShowOrHide(this.props.show)
    }

    componentWillReceiveProps(np) {
        this.modalShowOrHide(np)
    }

    modalShowOrHide(np) {
        if (np.show == true) {
            this.modalViewInstance.open()
        }
        else {
            this.modalViewInstance.close()
        }
    }

    continue() {
        if (!this.state.input) {
            this.setState({ error: "Missing Code!" })
        }
        else if (this.props.code != this.state.input) {
            this.setState({ error: "Wrong code!" })
        }
        else {
            this.props.textResponse(this.state.input)
            this.modalViewInstance.close()
        }
    }

    render() {
        const { i18n, t, title, description, subtitle } = this.props
        return (
            <div id="modal1" className="modal">
                <div className="modal-content">
                    <h4 style={{ color: "red" }} dir="auto">{title}</h4>
                    <p dir="auto">{description}</p>
                    <h5 style={{ color: "green" }} dir="auto"> {subtitle} </h5>
                    <input dir="auto"
                        className="browser-default edit_button_card_input"
                        type="text" id='input'
                        placeholder={"Enter your code"}
                        onChange={e => this.setState({ [e.target.id]: e.target.value })} />

                    <div className="center red-text" dir="auto">
                        {this.state.error ? <p>{this.state.error} </p> : null}
                    </div>
                </div>

                <div className="modal-footer">
                    <a href="#!"
                        style={{
                            border: "2px solid",
                            borderColor: "red",
                            margin: 10

                        }}
                        className="modal-close waves-effect waves-green btn-flat"
                        onClick={() => this.props.canceled()}>
                        {t("Cancel")}
                    </a>
                    <a href="#!"
                        style={{
                            border: "2px solid",
                            borderColor: "green",
                            margin: 10
                        }}
                        className="waves-effect waves-green btn-flat"
                        onClick={() => this.continue()}>
                        {t("Continue")}
                    </a>
                </div>
            </div>
        )
    }
}
export default withTranslation()(ModalViewWithTextFieldComponent)
import React, { useEffect, useState } from "react";
import RoundButtonBorderTextIcon from '../../../Buttons/RoundButtonBorderTextIcon'
import NumberStepperComponent from '../../NumberStepperComponent'
import ColorPickerComponent from '../ColorPickerComponent';
import { useTranslation } from 'react-i18next';
import { AlertOkCancelComponent } from "../../../AlertsComponents/AlertsComponent";

import SwitchButton from "../../SwitchButton";
var selectedTargetName, selectedTargetToChange, alertSubTitle = "", alertTitle = "";

export default function ShopItemEditorGlobalPanelSettings({ item,
    handleChangeCategoryItem,
    handleChangeFor_All_ShopItemsInList,
    lastGlobalSelections,
    setDisableSort }) {

    const [pickerShow, setShowPicker] = useState(false);
    const [alertShow, setShowAlert] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [lastSelections, setLastSelections] = useState(lastGlobalSelections);

    const { t, } = useTranslation();

    const handlePickerOpen = (target, e) => {
        // e.preventDefault()
        selectedTargetName = target
        setShowPicker(true)
    }

    const showSetAllAlert = (e, targetName) => {
        e.preventDefault()
        // this.selectedTargetToChange = targetName == "textColor" ? "titleColor" : targetName
        selectedTargetToChange = targetName
        alertTitle = t("SetAll?")
        // this.alertSubTitle = `${this.props.t("setAllButtons?")} ${getTargetNameDesctiption(targetName, this.props.t)} ${this.props.t("colorWithSelectedColor?")}`
        setShowAlert(true)
    }

    return (
        <div>
            { pickerShow ?
                <ColorPickerComponent
                    color={item ? item[selectedTargetName] : lastSelections[selectedTargetName]}
                    closePicker={() => {
                        setShowPicker(false)
                    }}

                    pickerChanged={(color) => {
                        if (item) {
                            var event = {
                                target: {
                                    id: selectedTargetName,
                                    value: color
                                }
                            }
                            handleChangeCategoryItem(event)
                        }
                        else {
                            var selections = lastSelections
                            console.log("colors selection is :", color);
                            selections[selectedTargetName] = color
                            setLastSelections(selections)
                            setRefresh(!refresh)
                        }


                    }}
                />
                :
                null}

            {alertShow ?

                < AlertOkCancelComponent title={alertTitle}
                    subtitle={alertSubTitle} closeAlert={(confirm) => {

                        if (confirm) {
                            var e = {
                                target:
                                {
                                    id: selectedTargetToChange,
                                    value: lastSelections[selectedTargetToChange]
                                }
                            }
                            handleChangeFor_All_ShopItemsInList(e)
                        }
                        setShowAlert(false)
                    }} />
                : null}
            <div className="roundedButtons">
                {
                    item ?
                        <ul >
                            <li style={{ width: "50px" }}>
                                <RoundButtonBorderTextIcon
                                    text={t("BackgroundColor")}
                                    iconName={"fa fa-paint-brush"}
                                    color={item.backgroundColor}
                                    iconColor="white"
                                    backgroundColor={item.backgroundColor}
                                    buttonClick={(e) => handlePickerOpen("backgroundColor", e)}
                                />
                            </li>
                            <li style={{ width: "50px" }}>
                                <div >
                                    <RoundButtonBorderTextIcon
                                        text={t("Border")}
                                        iconName={"fa fa-square-o"}
                                        color={item.borderColor}
                                        iconColor="white"
                                        backgroundColor={item.borderColor}
                                        buttonClick={(e) => handlePickerOpen("borderColor", e)}
                                    />
                                </div>
                            </li>
                            <li>

                                <NumberStepperComponent
                                    value={item.borderWidth ? item.borderWidth : 2}
                                    max={20}
                                    min={0}
                                    step={1}
                                    title={t("BorderWidth")}
                                    didMakeChange={(value) => {
                                        let newValue = {
                                            target: {
                                                id: "borderWidth",
                                                value: value
                                            }
                                        }
                                        handleChangeCategoryItem(newValue)
                                    }} />
                            </li>
                        </ul>
                        :
                        <div>

                            <ul >
                                <li style={{ width: "50px" }}>
                                    <div >
                                        <RoundButtonBorderTextIcon
                                            text={t("BackgroundColor")}
                                            iconName={"fa fa-paint-brush"}
                                            color={lastSelections.backgroundColor ? lastSelections.backgroundColor : "black"}
                                            iconColor="white"
                                            backgroundColor={lastSelections.backgroundColor ? lastSelections.backgroundColor : "black"}
                                            buttonClick={(e) => handlePickerOpen("backgroundColor", e)}
                                        />

                                        <a href="#"
                                            className=" "
                                            onClick={(e) => showSetAllAlert(e, "backgroundColor")}
                                        >
                                            {t("SetAll")}
                                        </a>

                                    </div>
                                </li>
                                <li style={{ width: "50px" }}>
                                    <div >
                                        <RoundButtonBorderTextIcon
                                            text={t("Border")}
                                            iconName={"fa fa-square-o"}
                                            color={lastSelections.borderColor ? lastSelections.borderColor : "black"}
                                            iconColor="white"
                                            backgroundColor={lastSelections.borderColor ? lastSelections.borderColor : "black"}
                                            buttonClick={(e) => handlePickerOpen("borderColor", e)}
                                        />

                                        <a href="#"
                                            className=" "
                                            onClick={(e) => showSetAllAlert(e, "borderColor")}
                                        >
                                            {t("SetAll")}
                                        </a>
                                    </div>
                                </li>
                                <li>

                                    <NumberStepperComponent
                                        value={lastSelections.borderWidth ? lastSelections.borderWidth : 2}
                                        max={20}
                                        min={0}
                                        step={1}
                                        title={t("BorderWidth")}
                                        didMakeChange={(value) => {
                                            var selections = lastSelections
                                            //  console.log("colors selection is :", color);
                                            selections.borderWidth = value
                                            setLastSelections(selections)
                                            setRefresh(!refresh)
                                        }} />
                                    <button
                                        className=" "
                                        onClick={(e) => showSetAllAlert(e, "borderWidth")}
                                    >
                                        {t("SetAll")}
                                    </button>
                                </li>
                            </ul>

                            <SwitchButton selected={(selected) => {
                                setDisableSort(selected)
                            }} />

                        </div>

                }

            </div>


        </div>
    )
}

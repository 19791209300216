import React, { Component } from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { getFirestore } from 'redux-firestore'
import { AlertOkCancelComponent } from '../../../AlertsComponents/AlertsComponent'
import IFrameComponent from '../../v_card_components/IFrameComponent'
import arrayMove from 'array-move';
import ColorPickerComponent from '../ColorPickerComponent'
import CheckboxComponent from '../../CheckboxComponent'
import FontListSelectComponent from '../../FontListSelectComponent'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import NumberStepperComponent from '../../NumberStepperComponent';
import TopGlobalCustomVideoEditor from './TopGlobalCustomVideoEditor';

export class AddVideosLinkComponent extends Component {
    selectedIndexToDelete = -1
    selectedColorPickerIndex = -1
    selectedTargetName = ""
    alertTitle = ""
    alertSubTitle = ""

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });

    SortableItem = sortableElement(({ value, myIndex }) => this.getVideoEditLayout(value, myIndex));

    state = {
        ...this.props,
        didMakeChange: false,
        isUploadingNow: false,
        showingAlert: false,
        pickerShow: false,
        disableSorting: true,
    }

    componentWillReceiveProps(np) {
        console.log("componentWillReceiveProps", this.props);
        if (np) {
            this.setState({
                ...this.state,
                videos: np.videos,

            })
        }
    }

    handleChangeFor_All_ItemInList = (e) => {
        // this.saveLastGlobalSelectionForRectangle(e)
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value);
        // 1. Make a shallow copy of the items
        if (this.state.videos) {
            let listCopy = [...this.state.videos.list];

            for (let index = 0; index < listCopy.length; index++) {
                var item = listCopy[index];
                item = {
                    ...item,
                    [e.target.id]: e.target.value
                }
                listCopy[index] = item
            }

            this.setState({
                videos: {
                    ...this.state.videos,
                    lastGlobalSelections: {
                        ...this.state.videos.lastGlobalSelections,
                        [e.target.id]: e.target.value
                    },
                    list: listCopy
                },
                didMakeChange: true
            });
        }
    }

    handleChangeForItemInList = (e, index) => {
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value, index);
        // 1. Make a shallow copy of the items
        let listCopy = [...this.state.videos.list];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...listCopy[index] };

        item = {
            ...item,
            [e.target.id]: e.target.value,
        }
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        listCopy[index] = item;
        // 5. Set the state to our new copy

        // this.setState({ list: listCopy });
        this.setState({
            videos: { ...this.state.videos, list: listCopy },
            didMakeChange: true
        });
        console.log("the list is ", listCopy);

    }

    addNewItemInList = () => {

        let newVideo = {
            url: "",
            title: "",
            videoId: "",
            borderColor: "black",
            titleColor: "black",
            hideTitle: false
        }

        console.log("create new video clicked");
        // 1. Make a shallow copy of the items
        var listCopy = [];
        if (!this.state.videos || !this.state.videos.list) {
            listCopy = [newVideo];
        }
        else {
            listCopy = [newVideo, ...this.state.videos.list];
        }

        // 2. Make a shallow copy of the item you want to mutate        
        this.setState({
            videos: { ...this.state.videos, list: listCopy },
            didMakeChange: true
        });
    }

    deleteVideo(index) {
        var array = [...this.state.videos.list];
        array.splice(index, 1);
        this.setState({ videos: { ...this.state.videos, list: array } });
        this.saveChanges({ ...this.state.videos, list: array });
    }

    saveChanges = (object) => {
        var sendValue = object ? object : this.state.videos
        this.setState({
            isUploadingNow: true
        });

        const firestore = getFirestore();
        firestore.collection('cards').doc(this.props.cardID).update({
            "videos": sendValue,

        }).then(() => {
            console.log("succes in dispatch");
            this.props.updateReduxVideosList(sendValue)
            this.setState({
                didMakeChange: false,
                isUploadingNow: false
            });

        }).catch(err => {
            console.log("error in dispatch");
            this.setState({
                didMakeChange: false,
                isUploadingNow: false
            });
        });
    }


    showDeleteAlert(e, buttonIndex) {
        e.preventDefault()
        this.selectedIndexToDelete = buttonIndex
        this.alertTitle = this.props.t("DeleteButton?")
        this.alertSubTitle = this.props.t("DeleteVideoSure")
        this.setState({
            showingAlert: true
        })
    }

    render() {

        const bootomFixedForUpdateButton = {
            position: 'fixed',
            zIndex: '2',
            backgroundColor: "red",
            height: "40px",
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        const { t, auth, } = this.props;
        if (!auth.uid) return <Redirect to='/' />
        return (
            <div className="container" style={{ marginBottom: "45px" }}>

                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.state.videos.list[this.selectedColorPickerIndex][this.selectedTargetName]}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {
                            let value = {
                                target: {
                                    id: this.selectedTargetName,
                                    value: color
                                }
                            }
                            this.handleChangeForItemInList(value, this.selectedColorPickerIndex)
                        }}
                    />
                    :
                    null}

                {this.state.showingAlert ?
                    < AlertOkCancelComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={(confirm) => {

                        if (confirm) {
                            this.deleteVideo(this.selectedIndexToDelete)
                        }

                        this.setState({
                            showingAlert: false
                        })
                    }} />
                    : null}


                <TopGlobalCustomVideoEditor
                    addTitle={t("AddVideo")}
                    setAllWithData={(data) => {
                        console.log("sent data", data);
                        data.target.id = data.target.id == "textColor" ? "titleColor" : data.target.id
                        this.handleChangeFor_All_ItemInList(data)
                    }}
                    createNew={() => {
                        this.addNewItemInList()
                    }}
                    enterSortMode={checked => {
                        this.setState({
                            ...this.state,
                            disableSorting: !checked
                        })
                    }}
                    lastGlobalSelections={this.state.videos.lastGlobalSelections}
                />


                {this.state.didMakeChange ?
                    <div style={bootomFixedForUpdateButton}>
                        <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")}
                            onClick={() => this.saveChanges(null)} >{t("SaveChanges")}
                            <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                        </button>
                    </div> : null}

                <this.SortableContainer onSortEnd={this.onSortEnd} >
                    {this.state.videos && this.state.videos.list.map((value, index) => (
                        <this.SortableItem key={`item-${index}`} index={index} value={value}
                            myIndex={index}
                            disabled={this.state.disableSorting} />
                    ))}
                </this.SortableContainer>

            </div>

        )
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        var myList = [...this.state.videos.list];
        var newList = arrayMove(myList, oldIndex, newIndex)
        console.log("list", myList);
        console.log("newList", newList);
        this.setState({
            videos: { ...this.state.videos, list: newList },
            didMakeChange: true
        });
    };

    handlePickerOpen = (target, index, e) => {
        console.log("handle picker open!!!");

        this.selectedColorPickerIndex = index
        this.selectedTargetName = target

        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }

    getVideoEditLayout(video, index) {
        // imported react for this //      
        var styleButton = {
            backgroundColor: video.titleColor,
            width: "38px",
            height: "38px",

        }
        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "3px solid",
            borderColor: "red",
        }
        return (
            <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                <div className="edit_button_card_container " style={cardStyle}>

                    <div className="row" >
                        <div style={{ maxWidth: 500, margin: "auto" }}>
                            <IFrameComponent video={video} isFromDashboard={true} />
                        </div>
                        <div className="roundedButtons">
                            <ul>
                                <li style={{ width: "50px" }}>
                                    <div style={{ width: "50px" }}>
                                        <a href="#"
                                            className="btn-floating btn waves-effect waves-light "
                                            style={styleButton}
                                            onClick={(e) => this.handlePickerOpen("titleColor", index, e)}
                                        >
                                            <i className="fa fa-font iconCenter" aria-hidden="true"
                                                style={{ color: "white", fontSize: "16px" }}></i>
                                        </a>
                                        <br />
                                        <span style={{ fontSize: "12px", style: "bold", color: video.titleColor, display: "block", marginTop: "4px" }}>
                                            {this.props.t("Text")}
                                        </span>
                                    </div>
                                </li>

                                <li style={{ width: "50px" }}>
                                    <div style={{ width: "50px" }}>
                                        <a href="#"
                                            className="btn-floating btn waves-effect waves-light "
                                            style={{
                                                backgroundColor: video.borderColor,
                                                width: "38px",
                                                height: "38px",

                                            }}
                                            onClick={(e) => this.handlePickerOpen("borderColor", index, e)}
                                        >
                                            <i className="fa fa-square-o iconCenter" aria-hidden="true"
                                                style={{ color: "white", fontSize: "16px" }}></i>
                                        </a>
                                        <br />
                                        <span style={{ fontSize: "12px", style: "bold", color: video.titleColor, display: "block", marginTop: "4px" }}>
                                            {this.props.t("Border")}
                                        </span>
                                    </div>
                                </li>

                                <li style={{ width: "50px" }}>
                                    <FontListSelectComponent fontName={video.fontName} selectedFont={(fontName) => {
                                        console.log("font selected");
                                        let value = {
                                            target: {
                                                id: "fontName",
                                                value: fontName
                                            }
                                        }
                                        this.handleChangeForItemInList(value, index)
                                    }} />
                                </li>
                                <li style={{ width: "50px" }}>
                                    {this.getDesignedDeleteButton(index)}
                                </li>
                                <li>

                                    <NumberStepperComponent
                                        value={video.fontSize ? video.fontSize : 32}
                                        max={70}
                                        min={0}
                                        step={1}
                                        title={this.props.t("FontSize")}
                                        didMakeChange={(value) => {
                                            let newValue = {
                                                target: {
                                                    id: "fontSize",
                                                    value: value
                                                }
                                            }
                                            this.handleChangeForItemInList(newValue, index)
                                        }} />
                                </li>
                                <li>
                                    <NumberStepperComponent
                                        value={video.marginTop ? video.marginTop : 0}
                                        max={200}
                                        min={-200}
                                        step={1}
                                        title={this.props.t("MarginTop")}
                                        didMakeChange={(value) => {
                                            let newValue = {
                                                target: {
                                                    id: "marginTop",
                                                    value: value
                                                }
                                            }
                                            this.handleChangeForItemInList(newValue, index)
                                        }} />
                                </li>
                                <li>
                                    <NumberStepperComponent
                                        value={video.borderWidth ? video.borderWidth : 2}
                                        max={20}
                                        min={0}
                                        step={1}
                                        title={this.props.t("BorderWidth")}
                                        didMakeChange={(value) => {
                                            let newValue = {
                                                target: {
                                                    id: "borderWidth",
                                                    value: value
                                                }
                                            }
                                            this.handleChangeForItemInList(newValue, index)


                                        }} />
                                </li>

                            </ul>
                            <CheckboxComponent checked={video.videoSquare} title={this.props.t("Square")} selected={(checked) => {
                                let value = {
                                    target: {
                                        id: "videoSquare",
                                        value: checked
                                    }
                                }
                                this.handleChangeForItemInList(value, index)
                            }} />
                        </div>


                        <div >
                            <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("VideoTitle")}:</div>
                            <input dir="auto"
                                className="browser-default edit_button_card_input"
                                type="text" id='title'
                                placeholder="Title of the video link"
                                onChange={(e) => this.handleChangeForItemInList(e, index)} value={video.title} />

                            <CheckboxComponent checked={video.hideTitle} title={this.props.t("HideTitle")} selected={(checked) => {
                                let value = {
                                    target: {
                                        id: "hideTitle",
                                        value: checked
                                    }
                                }
                                this.handleChangeForItemInList(value, index)
                            }} />

                        </div>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{this.props.t("VideoLink")}:</div>
                            <input dir="auto"
                                className="browser-default edit_button_card_input"
                                type="text" id='url'
                                placeholder={this.props.t("VideoLink")} onChange={(e) => this.handleChangeForItemInList(e, index)}
                                value={video.url} />
                        </div>

                        <div className="input-field" style={{ overflow: "hidden" }}>
                            <div className="" dir="auto" style={{ color: "gray" }}>{this.props.t("VideoLink")}:</div>

                            <div style={{ color: "gray" }}>
                                {video.url}
                            </div>
                            <br />
                            <div dir="auto" style={{ color: "red", whiteSpace: "pre-line" }}>
                                {this.props.t("YouTubeVideoInstruction")}
                            </div>
                            https://www.youtube.com/embed/****video_id***
                            <br />
                            https://www.facebook.com/plugins/video.php?href=
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </li >

        )
    }

    getDesignedDeleteButton(buttonIndex) {
        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "red"
        }
        return (

            <div style={{ width: "50px" }}>
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.showDeleteAlert(e, buttonIndex)}
                >
                    <i className="fa fa-trash-o iconCenter" aria-hidden="true"
                        style={{ color: "red", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", style: "bold", color: "red", display: "block", marginTop: "4px" }}>{this.props.t("Delete")}</span>
            </div>

        )
    }



}



const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateReduxVideosList: (videos) => { dispatch({ type: "UPDATE_VIDEOS_LIST", videos: videos }) }
        })
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        auth: state.firebase.auth,
    }
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AddVideosLinkComponent)

import React, { Component } from 'react'

export default class LogoCenterImage extends Component {
    state = {
        show: false
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        console.log("LOGO updateWindowDimensions");
        console.log("Height for logo ", window.innerWidth / 2.2)
        console.log("WIDTH for logo ", window.innerWidth)
        this.setState({ width: document.body.clientWidth, height: window.innerHeight });
        // console.log(window.innerWidth, window.innerHeight);
        // var w = window.innerWidth
        console.log("CLIENT WIDTH", document.documentElement.clientWidth)
        console.log("BODY WIDTH", document.body.clientWidth)
        // || document.body.clientWidth;
    };

    componentDidUpdate() {
        console.log("LOGO componentDidUpdate");
        console.log("Height for logo ", window.innerWidth / 2.2)
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleImageLoaded = (e) => {
        // console.log("image loaded", e.target);
        this.setState({
            show: true
        })
    }
    render() {
        const { logo, animated } = this.props

        var style;
        if (!animated) {
            style = { opacity: 1, marginTop: logo.marginTop ? logo.marginTop : -25, display: "block", transition: "all 2s" }
        }
        else {
            if (!this.state.show) {
                style = { opacity: 0, marginTop: 15, display: "block" }
            }
            else {
                style = { opacity: 1, marginTop: logo.marginTop ? logo.marginTop : -25, display: "block", transition: "all 2s" }
            }
        }


        var shape = null
        if (logo.shape === undefined) {
            shape = "circle"
        }
        else {
            shape = logo.shape
        }

        return (
            <div >
                {
                    logo.hide ?
                        null
                        :
                        <div className={"center "} style={style}>

                            <img src={logo.url}
                                className={" " + shape}
                                onLoad={this.handleImageLoaded}
                                alt={"כרטיס ביקור דיגיטלי"}
                                style={{
                                    // border: "2px solid",
                                    backgroundColor: logo.borderColor,
                                    padding: logo.borderWidth !== undefined ? logo.borderWidth : 2,
                                    borderColor: logo.borderColor,
                                    height: this.props.isFromDashboard ? 150 : this.state.width / 2.2,
                                    width: this.props.isFromDashboard ? 150 : this.state.width / 2.2,
                                    maxWidth: 280,
                                    maxHeight: 280,
                                    minWidth: 50,
                                    minHeight: 50,
                                    objectFit: "fill",
                                    // fill - This is default. The replaced content is sized to fill the element's content box. If necessary, the object will be stretched or squished to fit
                                    // contain - The replaced content is scaled to maintain its aspect ratio while fitting within the element's content box
                                    // cover - The replaced content is sized to maintain its aspect ratio while filling the element's entire content box. The object will be clipped to fit
                                    // none - The replaced content is not resized
                                    // scale-down - The content is sized as if none or contain were specified (would result in a smaller concrete object size)
                                }} />

                        </div >

                }
            </div>


        );
    }
}


import { generateStringId } from './UtilMethods'
import User from '../Singleton/UserAndCard'
import { USER_DID_CLICK_SEND_SHOP_RESERVATION } from './Constants'

export function getBasicShopCategoryObject() {
    return {
        title: "",
        items: [],

        hide: false,
        borderColor: "black",
        borderWidth: 2,
        color: "black",
        fontName: "Heebo-Regular",
        fontSize: 32,

        id: generateStringId()

    }
}


export function getBasicShopItemObject() {
    return {
        title: "",
        description: "",
        price: "",
        oldPrice: "",
        backgroundColor: "pink",
        hide: false,
        borderColor: "black",
        borderWidth: 2,
        color: "white",
        fontName: "Heebo-Regular",
        fontSize: 32,
        id: generateStringId()
    }
}

export function addToCart(item) {
    // localStorage.removeItem("userCart")
    delete item["sum"] // if has this key than remove it 

    var cart = getLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id)

    if (cart) {
        cart.items.push(item)
    }
    else {
        cart = {
            items: [item]
        }
    }
    setLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id, cart)
}

export function removeFromCart(item) {
    var cart = getLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id)
    var idx = cart.items.findIndex(p => p.id === item.id);
    cart.items.splice(idx, 1);
    setLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id, cart)
}

export function getCart() {
    console.log("function get cart ", getLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id));
    return getLocalStorageDict("userCart_forCardId:" + User.getSelectedCard().id)
}

export function deleteCart() {
    localStorage.removeItem("userCart_forCardId:" + User.getSelectedCard().id)
    localStorage.removeItem(USER_DID_CLICK_SEND_SHOP_RESERVATION + User.getSelectedCard().id);
}


export function isItemInCart(itemFind) {
    console.log("item to find:", itemFind)
    try {
        var cart = getCart()
        var items = cart.items.filter(item => item.id === itemFind.id);
        console.log("items:", items)
        return items

    } catch (error) {
        return []
    }
}

export function cartHasItems() {

    try {
        var cart = getCart()
        if (cart.items.length > 0) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

export function sumerizeCart() {
    var cart = getCart()
    var cartDict = {}

    for (let index = 0; index < cart.items.length; index++) {
        const item = cart.items[index];
        if (item.id in cartDict) {
            cartDict[item.id].sum++
        }
        else {
            cartDict[item.id] = item
            cartDict[item.id].sum = 1
        }
    }
    return cartDict
}

export function getTotalCartPrice() {
    var totalSum = 0;
    Object.keys(sumerizeCart()).forEach(function (key) {
        totalSum += sumerizeCart()[key].sum * sumerizeCart()[key].price
    });
    return totalSum
}

export function getTextCartList() {
    var text = "היי, אני רוצה להזמין את הפריטים הבאים:\n\n";
    Object.keys(sumerizeCart()).forEach(function (key) {
        text += ` ${sumerizeCart()[key].title}  * ${sumerizeCart()[key].sum} \n`
    });
    text += "\n"
    text += " תודה רבה."
    return text
}

export function getLocalStorageDict(key) {
    return JSON.parse(window.localStorage.getItem(key));
}
//JSON.parse(window.localStorage.getItem(key)
export function setLocalStorageDict(key, dict) {
    localStorage.setItem(key, JSON.stringify(dict));
}

export function userClickedForReservation(click, cardId) {
    // we need to remember he clicked to ask if clear cart 
    localStorage.setItem(USER_DID_CLICK_SEND_SHOP_RESERVATION + cardId, click);
}

export function userMadeClickReservation(cardId) {
    // we need to remember he clicked to ask if clear cart 
    console.log("userMadeClickReservation", localStorage.getItem(USER_DID_CLICK_SEND_SHOP_RESERVATION + cardId));
    return localStorage.getItem(USER_DID_CLICK_SEND_SHOP_RESERVATION + cardId);

}

export function allStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        console.log("kestorage key", keys[i]);
        values.push(localStorage.getItem(keys[i]));
    }

    return values;
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFirestore } from 'redux-firestore'
import UserCardClassicComponent from './UserCardClassicComponent'
import { getUserCardName } from '../../store/actions/cardActions'
import { incrementUserNewVisitors, incrementUserNumberOfEntriesToCard } from '../../Utils/UtilMethods'
import { UserCardClassicAosComponent } from './UserCardClassicAosComponent'
import Emitter from '../../Utils/emitter'
import "aos/dist/aos.css"
import User from '../../Singleton/UserAndCard'
import { ALERT_DELETE_CART } from '../../Utils/Constants'
import { deleteCart, userMadeClickReservation } from '../../Utils/ShopUtils'
import { AlertOkCancelComponent } from '../AlertsComponents/AlertsComponent'


export class CardSelectorComponentTest extends Component {

    alert = {
        title: "",
        subtitle: "",
        isFor: ""
    }

    state = {
        isFromDashboard: this.props.card ? true : false,
        card: this.props.card,
        sidebar: 'Default sidebar',
        showShopingList: false,
        showAlert: false
    }
    getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

    constructor(props) {
        super(props);

        props.getUserCardName(this.getLastItem(props.match.path))
    }
    componentDidMount() {

        if ((this.state.isFromDashboard === undefined || this.state.isFromDashboard === false)
            && userMadeClickReservation(this.props.match.params.id)) {

            console.log("Made reservation");
            this.showDeleteAlert("הזמנה בוצעה?", "קיימים פריטים בסל הקניות,\nונראה שכבר שלחת הזמנה.\nהאם לרוקן את העגלה?")

        }

        Emitter.on(ALERT_DELETE_CART, () => {
            // deleteCart()
            this.showDeleteAlert("לרוקן סל?", "האם אתה בטוח, לרוקן את כל הפריטים מהסל?")
        });
    }

    showDeleteAlert(title, subtitle) {
        this.alert.isFor = ALERT_DELETE_CART
        this.alert.title = title
        this.alert.subtitle = subtitle
        this.setState({ showAlert: true })
    }

    componentWillUnmount() {
        Emitter.off(ALERT_DELETE_CART)
    }

    componentWillReceiveProps(newProps) {
        if (newProps.reduxSelectedCard.selectedCard) {
            if (newProps.reduxSelectedCard.didSearch && !newProps.reduxSelectedCard.selectedCard) {
                // redirect to home page
                window.location = window.origin;

            }
            else if (newProps.reduxSelectedCard.didSearch && newProps.reduxSelectedCard.selectedCard) {
                document.body.style.backgroundColor = newProps.reduxSelectedCard.selectedCard.background.color;
                this.setState({
                    card: newProps.reduxSelectedCard.selectedCard
                })
                User.setSelectedCard(newProps.reduxSelectedCard.selectedCard)
                this.updatePageNumberOfEntries(newProps.reduxSelectedCard.selectedCard)
                // this.updatePageNumberOfEntries(newProps.reduxSelectedCard.selectedCard.id)
            }

        }
        else {
            // loaded card
            User.setSelectedCard(newProps.card)
            this.setState({
                card: newProps.card
            })
        }

    }

    updateButtonCounter = (listName, buttonId) => {
        const firestore = getFirestore();
        firestore.collection('userVisitors').doc(this.state.card.id).update(
            `${listName}.buttonsClicks.${buttonId}`, firestore.FieldValue.increment(1)
        ).then(() => {
            console.log("succes in dispatch");
        }).catch(err => {
            console.log("error in dispatch", err);
        });
    }

    updatePageNumberOfEntries = (card) => {
        // check if is first load or not - new user browser or not

        if (!this.didUserVisitThisCardIdBefore(card)) {
            this.saveUserSeenPage(card)
            incrementUserNewVisitors(card.id)
        }
        incrementUserNumberOfEntriesToCard(card.id)
    }

    // local storage 

    saveUserSeenPage = (card) => {
        localStorage.setItem(card.uniqueId + "newVisitors", true);
    }

    didUserVisitThisCardIdBefore(card) {
        return localStorage.getItem(card.uniqueId + "newVisitors")
    }

    showShopingList() {
        this.setState({
            showShopingList: true
        })
    }

    closeShopList() {
        this.setState({
            showShopingList: false
        })
    }


    selectCardStyle() {
        if (this.state.card) {

            switch (this.state.card.info.cardStyle) {
                case "Classic":
                    if (this.state.card.animation &&
                        this.state.card.animation.style !== "None" &&
                        !this.state.isFromDashboard) {
                        return (
                            <UserCardClassicAosComponent
                                isFromDashboard={this.state.isFromDashboard}
                                theCard={this.state.card}
                                didClick={(listName, buttonId) => {
                                    this.updateButtonCounter(listName, buttonId)
                                }}
                                passToPayment={() => {
                                    this.props.history.push({
                                        pathname: '/payment/' + this.state.card.id,
                                        state: { card: this.state.card }
                                    })
                                }} />
                        )
                    }
                    else {
                        return (
                            <div>
                                {/* hello
                                <div className="floatingShopingCart">
                                    <RoundButtonBorderTextIcon
                                        iconName={"fa fa-shopping-cart"}
                                        color={"green"}
                                        buttonClick={() => this.showShopingList()}
                                    />
                                </div> */}




                                {/* {this.state.sidebar} */}
                                {/* <input type="button" value="Send to other components" onClick={this.handleOnClick} /> */}
                                <UserCardClassicComponent isFromDashboard={this.state.isFromDashboard}
                                    theCard={this.state.card}
                                    didClick={(listName, buttonId) => {
                                        this.updateButtonCounter(listName, buttonId)
                                    }}
                                    passToPayment={() => {
                                        this.props.history.push({
                                            pathname: '/payment/' + this.state.card.id,
                                            state: { card: this.state.card }
                                        })
                                    }} />
                            </div>

                        )
                    }

                default:
                    return (
                        <div>
                            no card type error
                        </div>
                    )

            }
        }
        else {
            return (
                <div>
                    {/* loading */}
                </div>
            )
        }
    }


    render() {
        // const { card } = this.props
        return (
            <div>

                {this.state.showAlert ?
                    <div className="topOfAll">
                        < AlertOkCancelComponent title={this.alert.title} subtitle={this.alert.subtitle} closeAlert={(confirm) => {

                            if (confirm) {
                                switch (this.alert.isFor) {
                                    case ALERT_DELETE_CART:
                                        deleteCart()
                                        break
                                    default:
                                        break;
                                }

                            }

                            this.setState({
                                showAlert: false
                            })

                        }} />
                    </div>

                    : null}

                { this.selectCardStyle()}
            </div>
        )


    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        // theCard: state.card.doc,
        reduxSelectedCard: state.redux.visualCard,
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            getUserCardName: (cardName) => dispatch(getUserCardName(cardName)),
        })
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSelectorComponentTest)
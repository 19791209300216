const initState = {
    isLoading: false,
    authError: null
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log('login error', state);
            return {
                ...state,
                isLoading: false,
                authError: action.err.message
            }
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                authError: null
            }

        case 'SIGNUP_SUCCESS':
            console.log('signup success')
            return {
                ...state,
                authError: null
            }

        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.err.message
            }

        case 'TEST':
            console.log('AUTH TESTING TEST')
            return {
                ...state,
                justTestValue: "THIS IS A TEST VALUE"
            }

        default:
            return state
    }
};

export default authReducer;
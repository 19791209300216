import React, { Component } from 'react'
import { generateStringId_NoNumbers } from '../../../Utils/UtilMethods'
import CopyrightsImage from '../../../images/icons/copyrights.png'
import CenterText from '../../View_Text/CenterText';
export default class CaruselImageComponent extends Component {

    carousel = null;
    lastSelectedIndex = -1
    targetId = generateStringId_NoNumbers()
    stopAutoPlay = false
    delayAutoPlay = Math.floor(Math.random() * 3000) + 3500; // 3500 -> 6500

    state = {
        url: null,
        width: 0, height: 0,
        isFromDashboard: this.props.isFromDashboard
    }

    componentDidMount() {
        var showIndicators = this.props.gallery.list.length <= 15 ? true : false
        const M = window.M;
        var elems = document.querySelector(`#${this.targetId}`);
        this.carousel = M.Carousel.init(elems, { fullWidth: true, indicators: showIndicators });
        if (this.props.gallery.autoPlay) {
            this.autoplay()
        }
        // this.carousel.on("dragged", this.caruselDraged);
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        console.log("Delay auto play is:", this.delayAutoPlay);
    }

    // caruselDraged = () => {
    //     this.setState({ width: window.innerWidth, height: window.innerHeight });
    // };

    componentWillMount() {

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    autoplay = () => {
        if (!this.stopAutoPlay) {
            if (this.props.gallery.list && this.props.gallery.list.length > 0) {
                this.carousel.next();

                setTimeout(this.autoplay, this.delayAutoPlay);
            }
        }

    }

    openImage(index) {
        this.stopAutoPlay = true
        this.props.showImage(this.props.gallery.list, index)
    }


    render() {


        return (
            <div id='foo'>
                <div className="center">
                    {
                        this.props.gallery.hide ? null :
                            <CenterText
                                textColor={this.props.gallery.color}
                                fontSize={this.props.gallery.fontSize}
                                fontFamily={this.props.gallery.fontName}
                                text={this.props.gallery.title}
                            />
                    }
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, }}>
                    <div>
                        {
                            this.props.gallery.list && this.props.gallery.list.length > 0 ?
                                <div className="carousel carousel-slider"
                                    id={this.targetId}
                                    data-indicators="true"
                                    style={{
                                        maxHeight: 500,
                                        // height: this.state.isFromDashboard ? 200 : this.state.width / 2,
                                        border: `${this.props.gallery.borderWidth}px solid`,
                                        borderColor: this.props.gallery.borderColor,
                                    }}
                                >
                                    {this.props.gallery.list.map((imageUrl, index) => {
                                        // {this.getImageList().map((imageUrl, index) => {
                                        return (
                                            <a className="carousel-item" onClick={() => this.openImage(index)} ><img src={imageUrl} style={{ maxHeight: 500, height: 400, objectFit: "cover" }} alt={"כרטיס ביקור דיגיטלי"} /></a>
                                        )
                                    })
                                    }
                                    <i className="fa fa-caret-left floatingLeftButton"
                                        aria-hidden="true"
                                        style={{ fontSize: 40, color: "white" }}
                                        onClick={(e) => {
                                            this.carousel.prev()
                                            this.stopAutoPlay = true
                                        }

                                        }></i>
                                    <i className="fa fa-caret-right floatingRightButton"
                                        aria-hidden="true"
                                        style={{ fontSize: 40, color: "white" }}
                                        onClick={(e) => {
                                            this.carousel.next()
                                            this.stopAutoPlay = true
                                        }
                                        }></i>
                                </div>
                                :
                                <div className="center">
                                    {/* Missing images! */}
                                </div>
                        }

                        {this.props.gallery.Copyright && this.props.gallery.Copyright !== "" ?
                            < div dir="auto" className="center"
                                style={{ color: "red", fontSize: 10 }}>
                                <span>
                                    <img src={CopyrightsImage} style={{ width: 10, height: 10, marginTop: 4 }} alt={"כרטיס ביקור דיגיטלי"} />
                                </span>

                                <span style={{ marginLeft: 5, marginRight: 5 }}>
                                    {this.props.gallery.Copyright}
                                </span>
                            </div>
                            : null
                        }

                    </div>



                </div>

            </div >

        )
    }
}

import React from "react";
import { NavLink } from "react-router-dom"; // For linking
import LanguageSelectorComponent from "../LanguageSelectorComponent";
import { useTranslation } from 'react-i18next';

const SignedOutLinks = () => {
    const { t, i18n } = useTranslation();
    return (
        <ul className="right " >
            <span className="hide-on-med-and-down">
                <li >
                    <NavLink to="/signin" style={{ height: 60 }}>{t("LogIn")}</NavLink>
                </li>

                <li>
                    <NavLink to="/signup" style={{ height: 60 }}>{t("SignUp")}</NavLink>
                </li>
            </span>


            <li style={{ height: 60 }}>
                <LanguageSelectorComponent />
            </li>
        </ul>
    );
};

export default SignedOutLinks;

/* eslint-disable jsx-a11y/media-has-caption, class-methods-use-this */
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop'
import { saveLogoImageCrop, updateLogoSettings } from '../../../../store/actions/cardActions'
import { connect } from "react-redux"
import 'react-image-crop/dist/ReactCrop.css'
import LogoCenterImage from '../../v_card_components/LogoCenterImage'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import ColorPickerComponent from '../ColorPickerComponent'
import CheckboxComponent from '../../CheckboxComponent';
import NumberStepperComponent from '../../NumberStepperComponent';
import ChangeShapeListComponent from '../RoundedButtons/ChangeShapeListComponent';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import DragAndDropImageComponent from '../../DragAndDropImageComponent';

const mp4Url = 'http://techslides.com/demos/sample-videos/small.mp4';

/**
 * Load the image in the crop editor.
 */
const cropEditor = document.querySelector('#crop-editor');

export class LogoImageEditComponent extends PureComponent {
    imageRef = ""
    blobCropedImage = null
    minSize = 50
    compressFormat = null
    state = {
        src: null,
        crop: {
            unit: 'px',
            width: 50,
            height: 50,
            // minWidth: this.minSize,
            // minHeight: this.minSize,
            // aspect: 1 / 1,
        },
        didMakeChange: false,
        pickerShow: false,
        logo: this.props.reduxSelectedCard.logo,
        didMakeChangeProperties: false,
    };



    componentWillReceiveProps(newProps) {
        if (newProps.reduxSelectedCard.logo.url != this.state.logo.url) {
            console.log("updating the url state");
            this.setState({
                logo: {
                    ...this.state.logo,
                    url: newProps.reduxSelectedCard.logo.url
                }
            })

        }
    }


    dropzoneUpdate = (selectedFilesUrl) => {
        const image = selectedFilesUrl[0];
        this.compressFormat = image.type == "image/png" ? "png" : "jpeg"

        this.setState({

            src: URL.createObjectURL(image)
        });
    }

    onLogoImageLoaded = image => {
        this.imageRef = image;
        this.setState({ didMakeChange: true })
        // this.setState({ crop: { unit: 'px', width: 50, height: 50 } });
        // return false;
    };

    onLogoCropComplete = (crop, percentCrop) => {
        console.log('onCropComplete', crop, percentCrop);
        this.makeClientCrop(crop);
    };

    onLogoCropChange = (crop, percentCrop) => {
        // onCropChange = (crop, percentCrop) => {
        // if (crop.width < this.minSize || crop.height < this.minSize) {
        //     return false;
        // }
        this.setState({ crop });
    };

    onLogoDragStart = () => {
        console.log('onDragStart');
    };

    onLogoDragEnd = () => {
        console.log('onDragEnd');
    };

    getCroppedLogoImg(image, crop, fileName) {
        console.log('getCroppedLogoImg', crop);
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise(resolve => {
            canvas.toBlob(blob => {
                blob.name = fileName; // eslint-disable-line no-param-reassign
                this.blobCropedImage = blob
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/' + this.compressFormat);
        });
    }

    makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedLogoImg(this.imageRef, crop, 'newFile.' + this.compressFormat).then(croppedImageUrl =>
                this.setState({ croppedImageUrl })
            );
        }
    }

    renderVideo = () => (
        <video
            autoPlay
            loop
            style={{ display: 'block', maxWidth: '100%' }}
            onLoadStart={e => {
                // You must inform ReactCrop when your media has loaded.
                e.target.dispatchEvent(new Event('medialoaded', { bubbles: true }));
            }}
        >
            <source src={mp4Url} type="video/mp4" />
        </video>
    );

    renderSelectionAddon = () => <input placeholder="Type something" />;

    saveNewImageCrop() {
        this.props.saveLogoImageCrop(this.blobCropedImage)
        this.setState({
            src: null,
            didMakeChange: false
        });
        // console.log("crop state", this.state);
    }

    saveLogoSettings() {
        this.props.updateLogoSettings(this.state.logo)
        this.setState({
            didMakeChangeProperties: false
        });
    }

    render() {
        const { croppedImageUrl } = this.state;
        const { i18n, t, reduxSelectedCard, logoImageEditComponent } = this.props

        var shape = null
        if (this.state.logo.shape == undefined) {
            shape = "circle"
        }
        else {
            shape = this.state.logo.shape
        }

        return (
            <div>

                {logoImageEditComponent.isLoading ?
                    <LoaderView text={logoImageEditComponent.loadingTitle} />
                    : null}

                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.state.logo.borderColor}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {
                            this.setState({
                                logo: {
                                    ...this.state.logo,
                                    borderColor: color,
                                },

                                didMakeChangeProperties: true
                            })
                        }}
                    />
                    :
                    null}
                <div className="edit_button_card_container">
                    <h5 className="" style={{ marginBottom: 20 }}>{t("LogoImage")}</h5>

                    <div className="row ">

                        <div className="col s12 m6 l6 " style={{ padding: 20 }} >
                            <LogoCenterImage logo={this.state.logo} animated={false} />
                            <CheckboxComponent checked={this.state.logo.hide} title={t("HideImage")} selected={(checked) => {
                                this.setState({
                                    ...this.state,
                                    logo: {
                                        ...this.state.logo,
                                        hide: checked
                                    },
                                    didMakeChangeProperties: true
                                })
                            }} />
                            <div className="roundedButtons">
                                <ul>
                                    <li style={{ width: 50 }}>
                                        {this.getColorPicker()}
                                    </li>
                                    <li style={{ width: 50 }}>
                                        <ChangeShapeListComponent selectedButton={{ shape: shape }} selectedIndex={0}
                                            selectedShape={(shape) => {
                                                var shapeSelected = shape.target.value == "" ? "" : "circle"
                                                this.setState({
                                                    ...this.state,
                                                    logo: {
                                                        ...this.state.logo,
                                                        shape: shapeSelected
                                                    },
                                                    didMakeChangeProperties: true
                                                })
                                            }
                                            } />
                                    </li>
                                    <li >
                                        <NumberStepperComponent
                                            value={this.state.logo.marginTop ? this.state.logo.marginTop : 0}
                                            max={200}
                                            min={-200}
                                            step={1}
                                            title={t("MarginTop")}
                                            didMakeChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    logo: {
                                                        ...this.state.logo,
                                                        marginTop: value
                                                    },
                                                    didMakeChangeProperties: true

                                                })
                                            }} />
                                    </li>
                                    <li >
                                        <NumberStepperComponent
                                            value={this.state.logo.borderWidth != undefined ? this.state.logo.borderWidth : 2}
                                            max={20}
                                            min={0}
                                            step={1}
                                            title={t("BorderWidth")}
                                            didMakeChange={(value) => {
                                                this.setState({
                                                    ...this.state,
                                                    logo: {
                                                        ...this.state.logo,
                                                        borderWidth: value
                                                    },
                                                    didMakeChangeProperties: true

                                                })
                                            }} />
                                    </li>
                                </ul>

                            </div>






                            {this.state.didMakeChangeProperties ?
                                <div >
                                    <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChangeProperties ? "" : "disabled")} onClick={() => this.saveLogoSettings()} >{t("SaveChanges")}
                                        <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                    </button>
                                </div> : null}
                        </div>

                        <div className="col s12 m6 l6 flexCol" style={{ backgroundColor: "#fafafa" }}>

                            {t("SelectNewLogo")}

                            <DragAndDropImageComponent filesSelected={selectedFilesUrl => {
                                // set grid view callback
                                console.log("selection");
                                if (selectedFilesUrl) {
                                    this.dropzoneUpdate(selectedFilesUrl)
                                }
                            }
                            }
                                limit={1}
                            />
                            {this.state.src && (
                                <>
                                    <ReactCrop
                                        style={{ width: "400px" }}
                                        // renderComponent={this.renderVideo()}
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        ruleOfThirds
                                        // circularCrop
                                        onImageLoaded={this.onLogoImageLoaded}
                                        onComplete={this.onLogoCropComplete}
                                        onChange={this.onLogoCropChange}
                                        onDragStart={this.onLogoDragStart}
                                        onDragEnd={this.onLogoDragEnd}
                                        // renderSelectionAddon={this.renderSelectionAddon}
                                        minWidth={this.minSize}
                                        minHeight={this.minSize}
                                    />
                                    {/* <button onClick={this.onChangeToIncompleteCropClick}>Change to incomplete aspect crop</button> */}
                                </>
                            )}
                            {/* {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />} */}

                            {this.state.didMakeChange ?
                                <div >
                                    <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")} onClick={() => this.saveNewImageCrop()} >{t("UpdateNewLogo")}
                                        <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                    </button>
                                </div> : null}
                        </div>
                    </div>


                    <div className="center red-text">
                        {this.props.logoImageEditComponent.error ? <p>{this.props.logoImageEditComponent.error} </p> : null}
                    </div>

                </div>

            </div>
        );
    }

    // color picker

    getColorPicker() {

        var style = {
            backgroundColor: this.state.logo.borderColor, //this.props.reduxSelectedCard.logo.borderColor,
            width: "38px",
            height: "38px",
        }

        return (
            <div style={{ width: "50px" }}>
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.handlePickerOpen(this.state.logo.borderColor, "borderColor", e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.t("Border")}</span>
            </div>
        )
    }

    handlePickerOpen = (color, targetName, e) => {
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }
    // picker handler

}
const mapDispatchToProps = (dispatch) => {
    return (
        {
            saveLogoImageCrop: (blob) => dispatch(saveLogoImageCrop(blob)),
            updateLogoSettings: (logo) => dispatch(updateLogoSettings(logo))
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        logoImageEditComponent: state.redux.logoImageEditComponent
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LogoImageEditComponent)

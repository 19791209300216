export const saveCurrentCardInfo = (key, value) => {
    console.log("saveCurrentCardInfo key", key);
    console.log("saveCurrentCardInfo value", value);

    return {
        type: 'SAVE_CARD_INFO',
        key,
        value
    }
}

export const saveSelectedCard = (state) => {
    console.log("saveSelectedCard ", state);

    return {
        type: 'SELECTED_CARD',
        state
    }
}

// mFirebaseFirestore
//   .collection("users")
//   .document(mFirebaseAuth.getUid())
//   .update(userMap)

// db.collection("users")
//   .document("frank")
//   .update({
//     "age": 13,
//     "favorites.color": "Red"
//   });
import React, { Component } from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { withTranslation } from 'react-i18next';

export class ImageGallerySort extends Component {

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });
    SortableItem = sortableElement(({ value, myIndex }) => this.getImageLayout(value, myIndex));

    state = {
        ...this.props,
        disableSorting: false,
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        var myList = [...this.state.gallery.list];
        var newList = arrayMove(myList, oldIndex, newIndex)
        this.props.updateChanges({ ...this.state.gallery, list: newList })
        this.setState({
            gallery: { ...this.state.gallery, list: newList },
        });
    };

    getImageLayout(imgUrl, index) {

        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "1px solid",
            borderColor: "black",
        }
        return (
            <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                <div className="edit_button_card_container " style={cardStyle} >

                    <div className="row center" style={{ margin: 0 }}>

                        <img
                            style={{ maxHeight: 300 }}
                            className="responsive-img"
                            src={imgUrl} alt={"כרטיס ביקור דיגיטלי"} />

                    </div>
                </div>
            </li >
        )
    }

    render() {
        return (
            <div>
                <this.SortableContainer onSortEnd={this.onSortEnd} >
                    {this.state.gallery && this.state.gallery.list.map((value, index) => (
                        <this.SortableItem key={`item-${index}`} index={index} value={value}
                            myIndex={index}
                            disabled={this.state.disableSorting} />
                    ))}
                </this.SortableContainer>
            </div>
        )
    }
}

export default
    withTranslation()
        (ImageGallerySort)
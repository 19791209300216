import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import Home from './components/Home/Home'
import Dashboard from "./components/dashboard/Dashboard";
import CopyCard from "./components/user_cards/CopyCard"
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import CreateNewCard from './components/user_cards/CreateNewCard/CreateNewCard'
import CardSelectorComponent from './components/user_cards/CardSelectorComponent'
import TabsLayout from "./components/user_cards/Editors/TabsLayout";
import PaymentComponent from "./components/Payment/PaymentComponent";
import assutaTest from './components/assutaTest'
import ThanksPaymentComponent from "./components/Payment/ThanksPaymentComponent";
import { withTranslation } from 'react-i18next';
import CardSelectorComponentTest from "./components/user_cards/CardSelectorComponentTest";
import VerticalBar from './charts/VerticalBar'
import HorizontalBar from './charts/HorizontalBar'
import StackedBar from './charts/StackedBar'
import GroupedBar from './charts/GroupedBar'
import Line from './charts/Line'
import MultiAxisLine from './charts/MultiAxisLine'
import Scatter from './charts/Scatter'
import Doughnut from './charts/Doughnut'
import Pie from './charts/Pie'
import Polar from './charts/Polar'
import Radar from './charts/Radar'
import Dynamic from './charts/Dynamic'
import MultiType from './charts/MultiType'
import Crazy from './charts/Crazy'
import ClickEvents from './charts/ClickEvents'
class App extends Component {
  constructor(props, context) {
    super(props, context);

    // const query = new URLSearchParams(this.props.location.search);
    // const lan = query.get('lan')

    // if (lan != null) {
    //     this.props.i18n.changeLanguage(lan);
    // }

    // ready for query "?lan=heb"
    const query = new URLSearchParams(window.location.search);
    const lan = query.get('heb')
    if (lan != null) {
      this.props.i18n.changeLanguage("lan");
    }
  }

  componentDidMount() {

    // remove logger on production
    if (process.env === 'production') {
      console.log = function () { };
    } else {
      console.log("on dev");
    }



  }
  //   document.onkeydown = function (e) {
  //     if (e.which === 123) {
  //       return false;
  //     }
  //   };
  // }

  render() {
    return (
      <BrowserRouter>

        <div className="App">
          {/* <Navbar /> */}

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/dashboard" component={Dashboard} />

            <Route exact path='/vertical-bar' component={VerticalBar} />
            <Route exact path='/horizontal-bar' component={HorizontalBar} />
            <Route exact path='/stacked-bar' component={StackedBar} />
            <Route exact path='/grouped-bar' component={GroupedBar} />
            <Route exact path='/line' component={Line} />
            <Route exact path='/multi-axis-line' component={MultiAxisLine} />
            <Route exact path='/scatter' component={Scatter} />
            <Route exact path='/doughnut' component={Doughnut} />
            <Route exact path='/pie' component={Pie} />
            <Route exact path='/polar' component={Polar} />
            <Route exact path='/radar' component={Radar} />
            <Route exact path='/dynamic-bar' component={Dynamic} />
            <Route exact path='/multi' component={MultiType} />
            <Route exact path='/crazy' component={Crazy} />
            <Route exact path='/click-events' component={ClickEvents} />

            <Route path='/card_copy' component={CopyCard} />
            <Route path='/card_edit/:id' component={TabsLayout} />
            <Route path='/payment/:id' component={PaymentComponent} />
            <Route path='/thanks' component={ThanksPaymentComponent} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/createNew' component={CreateNewCard} />
            <Route path='/vip/:id' component={CardSelectorComponent} />
            <Route path='/assuta' component={assutaTest} />
            <Route path='/statistics/:id' component={CardSelectorComponent} />



            <Route component={Home} />
          </Switch>

        </div>

      </BrowserRouter>
    );
  }
}

export default withTranslation()(App);

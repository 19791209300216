import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CopyrightsImage from '../../../images/icons/copyrights.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.paper,
    },
    gridList: {

    },
}));

export default function ImageGridList({ gallery, showImage }) {
    const classes = useStyles();
    // const [selectedUrl, setUrl] = React.useState(null);

    function openImage(index) {
        showImage(gallery.list, index)
        // setUrl(gallery.list[index])
    }

    return (
        <div
            style={{
                marginTop: 20, marginBottom: 20,
                marginLeft: 10, marginRight: 10
            }}>

            <div className="center">
                {
                    gallery.hide ? null :
                        <div dir="auto" style={{ color: gallery.color, fontSize: gallery.fontSize, fontFamily: gallery.fontName }}>
                            {gallery.title}
                        </div>
                }
            </div>
            <div className={classes.root}
                style={{
                    border: `${gallery.borderWidth}px solid`,
                    borderColor: gallery.borderColor
                }}>
                <GridList cellHeight={160} className={classes.gridList} cols={3}>
                    {gallery.list.map((imgStringUrl, index) => (
                        <GridListTile key={index} cols={1}
                            onClick={() => openImage(index)}>
                            <img src={imgStringUrl} alt="כרטיס ביקור דיגיטלי" />
                        </GridListTile>
                    ))}
                </GridList>
            </div>

            {gallery.Copyright && gallery.Copyright !== "" ?
                < div dir="auto" className="center"
                    style={{ color: "red", fontSize: 10 }}>
                    <span>
                        <img src={CopyrightsImage} style={{ width: 10, height: 10, marginTop: 4 }} alt={"כרטיס ביקור דיגיטלי"} />
                    </span>

                    <span style={{ marginLeft: 5, marginRight: 5 }}>
                        {gallery.Copyright}
                    </span>
                </div>
                : null
            }
        </div>

    );
}

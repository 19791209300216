import React, { Component } from 'react'
import { connect } from "react-redux"
import { getUserCards, getUserStatistics, deleteCard } from '../../store/actions/cardActions'
import { Redirect } from 'react-router-dom'
import Navbar from "../../components/layout/Navbar";
import { NavLink } from "react-router-dom"; // For linking
import LoaderView from '../SpinnerLoader/LoaderView';
import moment from 'moment'
import 'moment/locale/he'
import { Link } from 'react-router-dom'
import {
    canCreateNewCard,
    incrementCardUdisNewVisitors,
} from '../../Utils/UtilMethods'
import cardUdisLogo from '../../images/Logos/cardudis_logo.png'
import Favicon from 'react-favicon';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import ContactCardUdisWhatsapp from '../ContactCardUdisWhatsapp';
import { AlertOkCancelComponent } from '../AlertsComponents/AlertsComponent';
import PaymentInfo from '../Payment/PaymentInfo';
import CardStatisticsComponent from '../Statistics/CardStatisticsComponent';
import CardPreviewComponent from './CardPreviewComponent';
import User from '../../Singleton/UserAndCard'
class Dashboard extends Component {
    selectedCardToDelete = null
    alertTitle = ""
    alertSubTitle = ""

    state = {
        error: null,
        showingAlert: false

    }

    constructor(props) {
        super(props);
        this.props.clearSelectedCard()
        if (this.props.reduxDashboard.cards == null && this.props.auth.uid) {
            this.props.getUserCards()
        }
        // updateUserSettings()
        document.body.classList.add('cardudisBG');
    }

    saveUserSeenPage = () => {
        localStorage.setItem("cardUdis_newVisitor", true);
    }

    didUserVisitThisCardIdBefore() {
        return localStorage.getItem("cardUdis_newVisitor")
    }

    componentDidMount() {
        if (!this.didUserVisitThisCardIdBefore()) {
            this.saveUserSeenPage()
            incrementCardUdisNewVisitors()
        }


    }
    createNewCard = (profile, cards) => {
        var canCreate = canCreateNewCard(profile, cards)
        if (canCreate.result) {
            this.props.history.push('/createNew')
        } else {
            this.setState({
                error: canCreate.description
            })
        }
    }

    getDesignedDeleteButton(card) {

        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "red"
        }
        return (

            <div className="center" style={{ width: "50px" }}>
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.showDeleteAlert(e, card)}
                >
                    <i className="fa fa-trash-o iconCenter" aria-hidden="true"
                        style={{ color: "red", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", color: "red", display: "block", marginTop: "4px" }}>{this.props.t("Delete")}</span>
            </div>

        )
    }

    showDeleteAlert(e, card) {
        e.preventDefault()
        this.selectedCardToDelete = card
        this.alertTitle = this.props.t("DeleteButton?")
        this.alertSubTitle = this.props.t("SureDelete?")
        this.setState({
            showingAlert: true
        })
    }

    openPage(e, id) {
        e.stopPropagation()
        console.log("Page open");
        const newWindow = window.open("https://cardudis.co.il/vip/" + id, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    render() {
        const { i18n, t, auth, reduxDashboard, profile } = this.props;
        if (!auth.uid || !reduxDashboard) return <Redirect to='/' />
        // var day = moment.unix(this.props.card.info.createdAt.seconds).format('DD/MM/YYYY, h:mm:ss a');
        var style = {
            backgroundColor: "white",
            width: "35px",
            height: "35px",
            border: "2px solid",
            borderColor: "blue",
        }

        moment.locale(i18n.language)
        var statistics = reduxDashboard.statistics
        return (
            <div >
                <Favicon url={cardUdisLogo} />
                <Navbar />

                {reduxDashboard.error ?
                    <div>
                        {reduxDashboard.error}
                    </div>
                    : null}

                {this.state.showingAlert ?
                    < AlertOkCancelComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={(confirm) => {

                        if (confirm) {
                            this.props.deleteCard(this.selectedCardToDelete)
                        }

                        this.setState({
                            showingAlert: false
                        })
                    }} />
                    : null}

                {reduxDashboard.isLoading ?
                    <LoaderView text={reduxDashboard.loadingTitle} />
                    : null}

                <div className="dashboard container navbar_padding">
                    <div className="edit_button_card_container " style={{ marginTop: "40px" }}>
                        {/* <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Folamhabalonim%2Fvideos%2F1076668439093556%2F&amp;show_text=0&amp;width=700" ></iframe> */}
                        <div className="roundedButtons">
                            <ul>
                                <li style={{ padding: "2px" }}>
                                    <div>
                                        <a href="#"
                                            className="btn-floating btn waves-effect waves-light "
                                            data-target='select_button_type'
                                            style={style}
                                            onClick={(e) => this.createNewCard(profile, reduxDashboard.cards)}>
                                            <i className={"fa fa-plus iconCenter"} aria-hidden="true"
                                                style={{ color: "blue", fontSize: "16px" }}></i>
                                        </a>
                                        <br />
                                        <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{t("AddNewCard")}</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="center red-text">
                                {this.state.error ? <p>{this.state.error} </p> : null}
                            </div>
                        </div>
                    </div>



                    <div className="center" dir="auto">
                        {
                            profile.type != "client" && profile.firstName != undefined ?
                                <div>
                                    {t("Profile")} {`: ${profile.type}, ${profile.firstName} ${profile.lastName}`}
                                </div>
                                :
                                null
                        }
                        <div>
                            {t("User")}: {profile.email}
                        </div>

                    </div>

                    {reduxDashboard.cards && reduxDashboard.cards.length > 0 ?

                        reduxDashboard.cards.map(card => {
                            return (

                                <div className="row" style={{ marginTop: "20px", marginBottom: 10 }}>
                                    <div className="col s12 m6" style={{ padding: 0 }}>
                                        <h5 className="center" dir="auto"> {t("CardInfo")} - {card.id}</h5>

                                        <Link onClick={() => {

                                            this.props.setSelectedCard(card)
                                        }
                                        }
                                            to={{
                                                pathname: '/card_edit/' + card.id,
                                                state: {
                                                    cardDetails: card
                                                }
                                            }} key={card.id}>
                                            <div className="card " >

                                                <div className="card-content grey-text text-darken-3">
                                                    {/* <span className="card-title" dir="auto"> */}
                                                    <h5 className="center">
                                                        {card.description.title.text}
                                                    </h5>
                                                    <h6 className="center">
                                                        {card.description.subtitle.text}
                                                    </h6>
                                                    <div className="center">
                                                        <a href="/#"
                                                            onClick={(e) => this.openPage(e, card.id)}>
                                                            {t("ClickWatchCard")}
                                                        </a>

                                                        {/* <NavLink to={"/vip/" + card.id}  ><div dir="auto"> {t("ClickWatchCard")}</div></NavLink> */}
                                                    </div>
                                                    <br />
                                                    <div dir="auto">
                                                        {t("CardId")}: {card.id}
                                                    </div>
                                                    <div dir="auto">
                                                        {t("CreatedAt")}: {moment.unix(card.info.createdAt.seconds).format('DD/MM/YYYY')}
                                                    </div>
                                                    <div dir="auto">
                                                        {t("Type")}: {card.info.cardStyle}
                                                    </div>

                                                    <PaymentInfo card={card} />

                                                    <CardStatisticsComponent card={card} statistics={statistics} />

                                                    <div>
                                                        {profile.type != "client" ?
                                                            <Link to={{
                                                                pathname: '/card_copy',
                                                                state: {
                                                                    card: card
                                                                }
                                                            }} >Copy Card</Link>
                                                            : null}

                                                        {profile.type !== "client" ?
                                                            this.getDesignedDeleteButton(card)
                                                            : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                    <div className="col s12 m6"  >
                                        <div className="center">
                                            <h5 dir="auto"> {t("Preview")} - {card.info.cardStyle}</h5>
                                        </div>
                                        <div className="center fullWidth" >

                                            <CardPreviewComponent card={card} />
                                            {/* <div id="container" style={{
                                                height: "600px",
                                                minWidth: "250px", maxWidth: "350px",
                                                backgroundColor: "white",
                                                margin: "auto",
                                                overflow: shouldBeLocked(card) ? "hidden" : "scroll",
                                            }}
                                                onClick={() => {
                                                    if (shouldBeLocked(card)) {
                                                        this.props.history.push({
                                                            pathname: '/payment/' + card.id,
                                                            // search: '?query=abc',
                                                            state: { card: card }
                                                        })
                                                    }

                                                }}
                                            >
                                                {
                                                    shouldBeLocked(card) ?
                                                        <div id="infoi">
                                                            <i className="fa fa-lock"
                                                                aria-hidden="true"
                                                                style={{ marginTop: 100, fontSize: 180, color: "red" }}
                                                            ></i>
                                                            <div style={{ color: "white", fontSize: 30, padding: 5 }}>
                                                                {t("CardLocked")}
                                                                <br />
                                                                {t("ClickToPayment")}
                                                            </div>
                                                        </div>

                                                        :
                                                        null
                                                }


                                                <div >
                                                    <CardSelectorComponent card={card} isFromDashboard={true} />
                                                </div>

                                            </div>
                                        */}
                                        </div>

                                    </div>

                                </div>
                            )
                        })
                        :
                        <div className="col s12 m6" style={{ backgroundColor: "red", marginTop: "40px", marginBottom: 10 }} >
                            No cards !!!
                            {/* <NavLink to="/createNew">Create new card</NavLink> */}
                        </div>
                    }
                </div>

                <ContactCardUdisWhatsapp />
            </div >

        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCard: (cardId) => dispatch(deleteCard(cardId)),
        getUserCards: () => dispatch(getUserCards()),
        getUserStatistics: (cardID) => dispatch(getUserStatistics(cardID)),
        setSelectedCard: (card) => { dispatch({ type: "SET_SELECTED_CARD", card }) },
        clearSelectedCard: () => { dispatch({ type: "CLEAR_SELECTED_CARD" }) }
    }
}

// map state from store to props
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        reduxDashboard: state.redux.dashboardComponentState,
        profile: state.firebase.profile
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Dashboard)

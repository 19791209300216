import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { generateStringId_NoNumbers } from '../../../Utils/UtilMethods'

export class DropDownComponent extends Component {
    dropDownId = generateStringId_NoNumbers()
    targetId = generateStringId_NoNumbers()

    componentDidMount() {
        console.log("did nount");
        const M = window.M;
        var elems = document.querySelectorAll(`.${this.dropDownId}`);
        // var elems = document.querySelectorAll('.dropdown-trigger-gallery');
        M.Dropdown.init(elems, { constrainWidth: false });
    }

    render() {
        const { dict, list, title } = this.props
        return (
            <div>
                <a className={this.dropDownId + ' btn'}
                    dir="auto"
                    data-target={this.targetId}>{title}
                    <i className="fa fa-angle-down " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}></i>
                </a>
                {
                    list !== undefined ?
                        <ul
                            style={{ padding: 0 }}
                            id={this.targetId}
                            className='dropdown-content'>
                            {
                                list.map((key, index) => {
                                    return <li ><a dir="auto" onClick={() => this.props.selectedIndex(index)}>{key}</a></li>
                                })
                            }
                        </ul>
                        :
                        <ul
                            style={{ padding: 0 }}
                            id={this.targetId}
                            className='dropdown-content'>
                            {
                                Object.keys(dict).map((key) => {
                                    return <li ><a href="#" dir="auto" onClick={(e) => this.props.selectedId(e, key)}>{dict[key]}</a></li>
                                })
                            }
                        </ul>

                }

            </div>

        )
    }
}
export default withTranslation()(DropDownComponent)
import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

export default function LoaderView(props) {
    const { text } = props
    return (
        <div className="row fullDiv" style={{ marginRight: 0, marginLeft: 0 }}>
            <div className=" col s12 theLoaderDiv ">
                <ClipLoader
                    size={120}
                    color={"#ee33ff"}
                    loading={true}
                />
                <div className="center whiteText" dir="auto" style={{ whiteSpace: "pre-wrap" }}>{text}</div>
            </div>
        </div>
    )
}

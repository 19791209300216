import i18n from 'i18next';

//https://www.npmjs.com/package/aos
export var cardAnimationDictionary = {
    Fader: {
        topImage: {
            animation: "fade-down",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "fade",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "fade-right",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "fade-left",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        rectangleGroup: {
            animation: "fade-right",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            // animation: "flip-right",
            0: "fade-right",
            1: "fade-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            // animation: "fade-right",
            0: "fade-down-right",
            1: "fade-down-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
        ,
        shareGroup: {
            animation: "fade-up",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
    },
    Zoomer: {
        topImage: {
            animation: "zoom-out",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "zoom-out-up",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "zoom-in-right",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "zoom-in-down",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        rectangleGroup: {
            animation: "zoom-in-up",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            animation: "flip-right",
            0: "zoom-in-right",
            1: "zoom-in-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            animation: "fade-right",
            0: "zoom-out-right",
            1: "zoom-out-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
        ,
        shareGroup: {
            animation: "zoom-in",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
    },
    Fliper: {
        topImage: {
            animation: "flip-down",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "flip-up",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "flip-right",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "flip-down",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        rectangleGroup: {
            animation: "flip-down",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            animation: "flip-right",
            0: "flip-right",
            1: "flip-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            animation: "fade-right",
            0: "flip-right",
            1: "flip-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
        ,
        shareGroup: {
            animation: "flip-down",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
    },
    Slider: {
        topImage: {
            animation: "slide-down",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "slide-up",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "slide-right",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "slide-left",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        rectangleGroup: {
            animation: "slide-up",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            animation: "flip-right",
            0: "slide-right",
            1: "slide-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            animation: "fade-right",
            0: "slide-right",
            1: "slide-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
        ,
        shareGroup: {
            animation: "slide-up",
            duration: "2000",
            offset: "-50",
            once: "true",
        }
    },
    Fader_inside_left: {
        topImage: {
            animation: "fade-down",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "fade",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "",
            duration: "",
            offset: "",
            once: "",
        },
        rectangleGroup: {
            animation: "",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            // animation: "flip-right",
            0: "fade-right",
            1: "fade-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            // animation: "fade-right",
            0: "fade-up-right",
            1: "fade-up-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        shareGroup: {
            animation: "",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        descriptionSingles: {
            0: "fade-right",
            1: "fade-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        roundedSingles: {
            animation: "fade-up-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        rectangleSingles: {
            animation: "fade-left",
            delay: 250,
            duration: 1000,
            offset: "200",
            once: "true",
        },
        shareSingles: {
            animation: "fade-up-left",
            delay: 250,
            duration: 1000,
            offset: "-50",
            once: "true",
        }

    },
    Zoomer_inside_left: {
        topImage: {
            animation: "zoom-out",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "zoom-in",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "",
            duration: "",
            offset: "",
            once: "",
        },
        rectangleGroup: {
            animation: "",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            // animation: "flip-right",
            0: "zoom-in-right",
            1: "zoom-in-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            // animation: "fade-right",
            0: "zoom-out-right",
            1: "zoom-out-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        shareGroup: {
            animation: "",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        descriptionSingles: {
            0: "zoom-in-right",
            1: "zoom-in-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        roundedSingles: {
            animation: "zoom-in-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        rectangleSingles: {
            animation: "zoom-left",
            delay: 250,
            duration: 1000,
            offset: "200",
            once: "true",
        },
        shareSingles: {
            animation: "zoom-in-left",
            delay: 250,
            duration: 1000,
            offset: "-50",
            once: "true",
        }
    },
    Fliper_inside_left: {
        topImage: {
            animation: "flip-down",
            duration: "3000",
            offset: "-50",
            once: "true"
        },
        logoCenter: {
            animation: "flip-up",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        descriptionGroup: {
            animation: "",
            duration: "3000",
            offset: "-50",
            once: "true",
        },
        roundedGroup: {
            animation: "",
            duration: "",
            offset: "",
            once: "",
        },
        rectangleGroup: {
            animation: "",
            duration: "3000",
            offset: "150",
            once: "true",
        },
        galleryGroup: {
            // animation: "flip-right",
            0: "flip-right",
            1: "flip-left",
            duration: "1500",
            offset: "100",
            once: "true",
        },
        frameGroup: {
            // animation: "fade-right",
            0: "slide-right",
            1: "slide-left",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        shareGroup: {
            animation: "",
            duration: "2000",
            offset: "-50",
            once: "true",
        },
        descriptionSingles: {
            0: "flip-right",
            1: "flip-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        roundedSingles: {
            animation: "flip-left",
            delay: 200,
            duration: 1000,
            offset: "-50",
            once: "true",
        },
        rectangleSingles: {
            animation: "flip-right",
            delay: 250,
            duration: 1000,
            offset: "200",
            once: "true",
        },
        shareSingles: {
            animation: "flip-left",
            delay: 250,
            duration: 1000,
            offset: "-50",
            once: "true",
        }

    }
}

export function animationListDict() {
    return ({
        "None": i18n.t("None"),
        "Fader": i18n.t("Fader"),
        "Zoomer": i18n.t("Zoomer"),
        "Fliper": i18n.t("Fliper"),
        "Slider": i18n.t("Slider"),
        "Fader_inside_left": i18n.t("Fader_inside_left"),
        "Zoomer_inside_left": i18n.t("Zoomer_inside_left"),
        "Fliper_inside_left": i18n.t("Fliper_inside_left"),
    })
}
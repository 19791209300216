
import React, { useState } from 'react'
import { addToCart, removeFromCart, isItemInCart } from '../../Utils/ShopUtils'
import RoundButtonBorderTextIcon from '../Buttons/RoundButtonBorderTextIcon'
import RoundButtonBorderTextAndCenterText from '../Buttons/RoundButtonBorderTextAndCenterText'
import Emitter from '../../Utils/emitter'
import { ITEM_ROUNDED_CORNERS, SALE_ITEM_HEIGHT } from '../../Utils/Constants'
import Divider from '../views/Divider'
// import { useTranslation } from 'react-i18next';

export default function SaleItemCard({ item, large }) {
    // const { t, } = useTranslation();
    const [refresh, setRefresh] = useState(false)

    function addItem(e) {
        e.stopPropagation()
        addToCart(item)
        setRefresh(!refresh) // just to reload function
        Emitter.emit('REFRESH_CART_LIST', "dsadsa  dsa asd das");
    }

    function removeItem(e) {
        e.stopPropagation()
        removeFromCart(item)
        setRefresh(!refresh) // just to reload function
        Emitter.emit('REFRESH_CART_LIST', "dsadsa  dsa asd das");
    }

    return (

        <div className=" center"
            style={{
                backgroundColor: item.backgroundColor ? item.backgroundColor : "#a4a4a4",
                padding: 6,
                maxWidth: 500,
                borderStyle: "solid",
                borderWidth: item.borderWidth,
                borderColor: item.borderColor,
                borderRadius: ITEM_ROUNDED_CORNERS,
                height: large ? null : SALE_ITEM_HEIGHT,
            }}>
            <u>
                <h6 className={large === true ? "" : "textLongDots"}
                    dir="auto"
                    style={{
                        // backgroundColor: "white",
                        minHeight: 30,
                        marginTop: 0,
                        padding: 5
                        // textAlign: "center"
                    }}>
                    {item.title}
                </h6>
            </u>


            <img className="fullWidth"
                src={item.images ? item.images[0] : null} alt="כרטיס ביקור דיגיטלי"
                style={{
                    borderRadius: 10,
                    height: 100,
                    objectFit: "contain",
                    backgroundColor: "white"
                }} />

            <div dir="auto">
                <u>
                    <h6 className={large === true ? "" : "textLongDots"}
                        dir="auto"
                        style={{
                            // backgroundColor: "white",

                            marginTop: 0,
                            padding: 5
                            // textAlign: "center"
                        }}>
                        תיאור המוצר
                </h6>
                </u>
                <div
                    style={{
                        // backgroundColor: "white",
                        height: large === true ? null : 60,
                        overflow: "scroll"
                    }}
                >
                    {item.description}

                </div>
                <div style={{ marginTop: 8 }}>
                    <Divider
                        color={item.borderColor}
                    />
                </div>

                <div
                    style={{
                        // backgroundColor: "white",
                        height: 32,
                        marginTop: 4,
                        fontFamily: "Varela Round",
                        fontSize: 24
                        // textAlign: "center"
                    }}>
                    ₪{item.price}
                    {/* {t("ILS")}{item.price} */}
                </div>

            </div>

            <div className="row" style={{ marginTop: 4 }}>
                <div className="roundedButtons">
                    <ul>

                        <li style={{ width: "50px" }}>

                            <RoundButtonBorderTextIcon
                                text={"הוסף"}
                                iconName={"fa fa-cart-plus"}
                                color={"green"}
                                buttonClick={(e) => addItem(e)}
                            />

                        </li>
                        {
                            isItemInCart(item) && isItemInCart(item).length > 0 ?
                                <li style={{ width: "50px" }}
                                    data-aos="zoom-in-right"
                                    data-aos-duration="2000"
                                    data-aos-offset="-50"
                                    data-aos-once="false">

                                    <RoundButtonBorderTextAndCenterText
                                        text={"הסר"}
                                        textCenter={isItemInCart(item).length}
                                        color={"#2DC6F5"}
                                        buttonClick={(e) => removeItem(e)}
                                    />

                                </li>
                                :
                                null
                        }
                    </ul>



                </div>
            </div>

        </div >







    )
}

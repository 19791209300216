import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
import Navbar from "../../components/layout/Navbar";
import LoaderView from '../SpinnerLoader/LoaderView';
import ModalViewWithTextFieldComponent from '../user_cards/ModalViewWithTextFieldComponent'
import { generateStringNumbers } from '../../Utils/UtilMethods'
import { SERVER_SIDE_BASE_URL } from '../../Utils/Constants'

import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import ContactCardUdisWhatsapp from '../ContactCardUdisWhatsapp';
import { getFirebase } from 'react-redux-firebase'

export class SignUp extends Component {
    code = ""
    state = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        error: null,
        showModalAuthentication: false
    }

    // constructor(props) {
    //     super(props)
    //     this.checkIfEmailAvailable = this.checkIfEmailAvailable.bind(this)
    // }

    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }

    componentWillReceiveProps(np) {

        if (!np.login.isLoading) {
            this.setState({
                isLoading: false,
                error: np.login.authError
            })
        }
    }
    //When using ES6 code in React always use arrow functions, because the this context is automatically binded with it
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()

        this.setState({
            isLoading: true
        })

        // this.sendMailAuthenticationCode()
        this.checkIfEmailAvailable()
    }

    checkIfEmailAvailable = () => {
        const firebase = getFirebase();

        firebase.auth().fetchSignInMethodsForEmail(this.state.email)
            .then((signInMethods) => {
                if (signInMethods.length > 0) {
                    this.setState({
                        isLoading: false,
                        error: this.props.t("EmailExist"),

                    })
                }
                else {
                    this.sendMailAuthenticationCode()
                }
                // This returns the same array as fetchProvidersForEmail but for email
                // provider identified by 'password' string, signInMethods would contain 2
                // different strings:
                // 'emailLink' if the user previously signed in with an email/link
                // 'password' if the user has a password.
                // A user could have both.
                if (signInMethods.indexOf(
                    firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
                    // User can sign in with email/password.
                }
                if (signInMethods.indexOf(
                    firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
                    // User can sign in with email/link.
                }

            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: error.message
                })
                // Some error occurred, you can inspect the code: error.code
            });
    }

    sendMailAuthenticationCode() {
        this.code = generateStringNumbers()

        let data = {
            code: this.code,
            to: this.state.email
        }

        let testing = false

        if (testing) {
            console.log("code", data.code);

            this.setState({
                isLoading: false,
                error: null,
                showModalAuthentication: true,
            })
        }
        else {
            axios.post(SERVER_SIDE_BASE_URL + 'authenticate', data)
                .then(res => {

                    // this.resetForm();  // should take this down and test
                    // this.setState({ sent: true }, this.resetForm())
                    if (res.data.success) {
                        this.setState({
                            isLoading: false,
                            error: null,
                            showModalAuthentication: true,
                        })
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            error: this.props.t("CantEmail"),
                            showModalAuthentication: false,
                        })
                    }

                })
                .catch((err) => {

                    this.setState({
                        error: this.props.t("CantEmail"),
                        isLoading: false
                    })
                })
        }


    }

    render() {
        const { t, auth } = this.props;
        if (auth.uid) return <Redirect to='/dashboard' />
        return (
            <div>
                <ModalViewWithTextFieldComponent
                    show={this.state.showModalAuthentication}
                    title={t("MailValidation!")}
                    description={t("PleaseCheckEmail")}
                    subtitle={t("EnterYourCode!")}
                    code={this.code}
                    textResponse={(text) => {

                        var error = null
                        var isLoading = false

                        if (!text) {
                            error = t("MissingPassword")
                        }
                        else {
                            if (text == this.code) {

                                error = null
                                isLoading = true
                                this.props.signUp(this.state);
                            }
                            else {

                                error = t("WrongCode")
                            }
                        }

                        this.setState({
                            showModalAuthentication: false,
                            error: error,
                            isLoading: isLoading
                        })

                    }}

                    canceled={() => {

                        this.setState({ showModalAuthentication: false })
                    }}
                />

                <Navbar />

                {this.state.isLoading ?
                    <LoaderView text={t("CreatingNewUser") + "..."} />
                    : null}

                <div className="container navbar_padding"
                    style={{ marginBottom: 40 }}>
                    <form className="white whiteForm rounded" onSubmit={this.handleSubmit}>
                        <h5 className="grey-text text-darken-3 center" dir="auto">{t("SignUp")}</h5>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("FirstName")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="text" id='firstName' onChange={this.handleChange} required />
                        </div>


                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("lName")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="text" id='lastName' onChange={this.handleChange} required />
                        </div>
                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Email")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="email" id='email' onChange={this.handleChange} required />
                        </div>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("PhoneNumber")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="tel" id='phoneNumber' onChange={this.handleChange} required />
                        </div>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Password")}:</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="password" id='password' onChange={this.handleChange} required />
                        </div>
                        <div className="input-field">
                            <button className="curdudis_button">{t("ClickSignUp")}</button>
                            <div className="center red-text">
                                {this.state.error ? <p>{this.state.error} </p> : null}
                            </div>
                        </div>
                    </form>
                </div>

                <ContactCardUdisWhatsapp />
            </div>
        )
    }


}
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        login: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (creds) => dispatch(signUp(creds))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())
    (SignUp)
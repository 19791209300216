import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { createCardNew, isCardNameAvailable } from '../../store/actions/cardActions'
import { saveCurrentCardInfo } from '../../store/actions/newCardAction'
import Navbar from "../layout/Navbar";
import { generateStringId_NoNumbers } from '../../Utils/UtilMethods'
import ImageUploaderComponent from "./ImageUploaderComponent"
import DragAndDropImageComponent from "./DragAndDropImageComponent"

import { getUserCardName } from '../../store/actions/cardActions'
import { signUp } from '../../store/actions/authActions'
import { SignUp } from '../auth/SignUp'
export class CopyCard extends Component {
    state = {
        card: this.props.location.state.card,
        isNameAvailable: this.props.utils.isCardNameAvailable
    }

    checkIfNameAvailable = (e) => {
        this.props.isCardNameAvailable(this.state.cardName);
    }

    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            ...this.state,
            isNameAvailable: newProps.utils.isCardNameAvailable
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state);
        var card = this.state.card
        card.id = this.state.cardName
        // card.counters = {}
        card.messages = {}
        card.uniqueId = generateStringId_NoNumbers()

        // this.setState({
        //     ...this.state,
        //     card: {
        //         ...this.state.card,
        //         counters: {},
        //         id: this.state.cardName,
        //         messages: {},
        //         uniqueId: generateStringId_NoNumbers(),
        //     }
        // })
        var value;
        Object.keys(card.gallerys).forEach(function (key) {
            card.gallerys[key].list = []
        });


        this.props.createCardNew(card);
        // this.props.history.push('/');
    }

    render() {
        const { auth } = this.props;
        console.log("render state", this.state)
        console.log("render props", this.props)
        if (!auth.uid || !this.state.card) return <Redirect to='/' />
        return (
            <div>
                <Navbar />

                <div className="container navbar_padding">


                    <form className="white whiteForm" onSubmit={this.handleSubmit}>
                        <h5 className="grey-text text-darken-3">Card Copy {this.state.card.id} With : </h5>


                        <div className="row">
                            <div className="col s8 m8 l8" style={{ padding: 0 }}>
                                <div className="input-field">
                                    <label htmlFor="cardName">Card Url Name</label>
                                    <input type="text" id='cardName' onChange={(e) => {
                                        this.setState({
                                            ...this.state,
                                            cardName: e.target.value,
                                            isNameAvailable: false
                                        })

                                    }} required />
                                </div>
                            </div>
                            <div className="col s4 m4 l4">
                                <div className="btn " onClick={this.checkIfNameAvailable}>
                                    Check
                                </div>
                            </div>

                        </div>


                        {this.state.isNameAvailable ?
                            <div className="input-field">
                                Available
                                <button className="btn pink lighten-1 z-depth-0" onClick={this.handleSubmit}>Copy the card</button>
                            </div>
                            : "not avialable Name"}

                    </form>
                </div>

            </div>

        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            isCardNameAvailable: (cardName) => dispatch(isCardNameAvailable(cardName)),
            createCardNew: (card) => dispatch(createCardNew(card)),
            saveCurrentCardInfo: (key, value) => dispatch(saveCurrentCardInfo(key, value)),
        })
}

const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        auth: state.firebase.auth,
        card: state.card,
        utils: state.utils
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyCard)

// importing my reducers
import cardReducer from './cardReducer';
// import from redux a class that bind me reducers into one reducer
import { combineReducers } from 'redux';
import authReducer from './authReduder';
import utilsReducer from './utilsReducer';
import reduxGlobalReducer from './reduxGlobalReducer'
import { firestoreReducer } from 'redux-firestore';

import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    card: cardReducer,
    utils: utilsReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    redux: reduxGlobalReducer,

})

export default rootReducer
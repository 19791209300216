import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"; // For linking
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { useTranslation } from 'react-i18next';

const SignedInLinks_SideMenu = (props) => {
    const { t } = useTranslation();


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

    });

    return (
        <span >
            {/* <a className='sidenav-trigger' href='#' data-target='mobile-links'>
                <i className="fa fa-bars" aria-hidden="true" style={{ fontSize: 30 }}></i>
            </a> */}

            <ul className="sidenav mySideNavBar" id="mobile-links" >

                <li>
                    <div dir="auto" className="whiteTextVarela center"> {t("Hello")} {props.profile.firstName} {props.profile.lastName}</div>
                </li>

                <li className="">
                    <div className="whiteTextVarela">
                        <a className="sidenav-close " onClick={props.signOut}> {t("Logout")}</a>
                    </div>
                </li>
                <li>
                    <div className="whiteTextVarela">
                        <NavLink className="sidenav-close" to={{
                            pathname: '/dashboard',
                            state: {
                                fromInside: true
                            }
                        }} >{t("Dashboard")}</NavLink>
                    </div>
                </li>

            </ul>



        </span>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks_SideMenu)

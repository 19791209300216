import React, { Component } from 'react';
import axios from 'axios';
import { getFirestore } from 'redux-firestore'
import {
    getContactFormInstructionsForLanguage,
    generateStringId_NoNumbers
} from '../../../Utils/UtilMethods'
import {
    SERVER_SIDE_BASE_URL
} from '../../../Utils/Constants'

class ContactForm extends Component {


    componentDidMount() {
        console.log("componentDidMount");
        const M = window.M;
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});
    }

    state = {
        fullName: '',
        user_message: '',
        email: '',
        phoneNumber: '',
        sent: false,

    }

    resetForm = () => {
        this.setState({
            fullName: '',
            user_message: '',
            email: '',
            phoneNumber: '',

        })
    }

    formSubmit = (e) => {
        e.preventDefault()

        console.log(this.state);

        console.log("form submit");
        let messageId = generateStringId_NoNumbers()
        let data = {
            name: this.state.fullName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            message: this.state.user_message,
            to: this.props.button.mail,
            date: new Date(),
            id: messageId,
            // check the button mail!!!!
        }

        var message = {
            [generateStringId_NoNumbers()]: {
                name: this.state.fullName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                message: this.state.user_message,
                to: this.props.button.mail,
                date: new Date(),
                id: messageId,
                didRead: false,
            }
        }

        this.updateCardMessageList(message)

        var testingMode = false

        if (!testingMode) {
            axios.post(SERVER_SIDE_BASE_URL + 'mailer', data)
                .then(res => {
                    console.log('Message  sent')
                    this.resetForm();  // should take this down and test
                    this.setState({ sent: true }, this.resetForm())
                })
                .catch(() => {
                    console.log('Message not sent')
                })
            this.setState({ sent: true }, this.resetForm())
        }

    }

    updateCardMessageList(message) {
        if (this.props.cardID) {
            const firestore = getFirestore();
            firestore.collection('cards').doc(this.props.cardID).set({
                "messages": message
            }, { merge: true }).then(() => {
                console.log("succes in dispatch");

            }).catch(err => {
                console.log("error in dispatch");

            });
        }

    }

    render() {
        const { button } = this.props;

        const sent = this.state.sent;
        const iconSize = button.iconSize;
        let style = {
            width: "100%",
            textAlign: "justify",
            color: button.textColor,
            backgroundColor: button.color,
            fontFamily: button.fontName,
            fontSize: button.fontSize,
            margin: 0,
            textTransform: "none",
            // padding: "0px 14px"
        }

        let iconStyle = {
            color: button.iconColor,
            fontSize: iconSize,
            width: iconSize + 10,
            margin: 0,
            textAlign: "center",

        }

        return (
            <ul className="collapsible "
                style={{ marginBottom: "10px", marginRight: 10, marginLeft: 10, marginTop: 0 }}>
                <li>
                    <div dir="auto" style={{
                        backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor,
                        padding: 0
                    }} className="collapsible-header">

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            onClick={() => this.props.didClick()}
                        >
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>
                    {/* <div className="collapsible-header "
                        style={{ padding: 0, border: "2px solid", color: button.borderColor }}>

                        <a className="waves-effect waves-light btn-large " style={{ width: "100%", padding: 0 }}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={{ color: button.iconColor }}

                                ></i>
                                {button.title}

                            </div>
                        </a>

                    </div> */}
                    <div className="collapsible-body"
                        style={{
                            backgroundColor: button.color,
                            borderWidth: button.borderWidth,
                            borderStyle: "solid",
                            borderColor: button.borderColor,

                        }}>

                        <div dir="auto" style={{ backgroundColor: button.color, textAlign: "justify" }}>

                            {sent ? <h2 className="center" dir="auto">{getContactFormInstructionsForLanguage(button.formLanguage, "messageSent")}</h2> :
                                <form onSubmit={this.formSubmit}>

                                    <div className="input-field">
                                        <div className="edit_button_card_subtitle" dir="auto" style={{ color: button.textColor }} >{getContactFormInstructionsForLanguage(button.formLanguage, "name")}</div>
                                        <input dir="auto"
                                            // style={{ textAlign: "justify" }}
                                            className="browser-default edit_button_card_input"
                                            type="text"
                                            id='fullName'
                                            placeholder={getContactFormInstructionsForLanguage(button.formLanguage, "nameHolder")}
                                            onChange={e => this.setState({ [e.target.id]: e.target.value })}

                                            required />
                                    </div>

                                    <div className="input-field">
                                        <div className="edit_button_card_subtitle" dir="auto" style={{ color: button.textColor }}>{getContactFormInstructionsForLanguage(button.formLanguage, "phone")} </div>
                                        <input dir="auto" className="browser-default edit_button_card_input" type="phone" id='phoneNumber' placeholder={getContactFormInstructionsForLanguage(button.formLanguage, "phoneHolder")} onChange={e => this.setState({ [e.target.id]: e.target.value })} required />
                                    </div>

                                    <div className="input-field">
                                        <div className="edit_button_card_subtitle" dir="auto" style={{ color: button.textColor }}>{getContactFormInstructionsForLanguage(button.formLanguage, "email")} </div>
                                        <input dir="auto" className="browser-default edit_button_card_input" type="email" id='email' placeholder={getContactFormInstructionsForLanguage(button.formLanguage, "emailHolder")} onChange={e => this.setState({ [e.target.id]: e.target.value })} required />
                                    </div>

                                    <div className="input-field">
                                        <div className="edit_button_card_subtitle" dir="auto" style={{ color: button.textColor }}>{getContactFormInstructionsForLanguage(button.formLanguage, "message")}</div>
                                        <textarea
                                            className="browser-default edit_button_card_input"
                                            type="text"
                                            id='user_message' dir="auto" placeholder={getContactFormInstructionsForLanguage(button.formLanguage, "messageHolder")}
                                            style={{ minHeight: 100, backgroundColor: "white" }}
                                            onChange={e => this.setState({ [e.target.id]: e.target.value })}
                                            required />
                                    </div>




                                    <div className="center-content">
                                        <input type="submit" value={getContactFormInstructionsForLanguage(button.formLanguage, "sendMessage")}
                                            className="btn larg-btn "
                                            style={{
                                                color: button.textColor,
                                                backgroundColor: button.color,
                                                height: "50px",
                                                textTransform: "none",
                                                width: "100%",
                                                fontSize: 20,
                                                borderRadius: 10,
                                                borderWidth: button.borderWidth,
                                                borderStyle: "solid",
                                                borderColor: button.borderColor,
                                            }} />
                                    </div>
                                </form>
                                // height: 50px;
                                // background: #e0356e;
                                // border: none;
                                // text-transform: none;
                                // margin-top: 24px;
                                // width: 100%;
                                // border-radius: 10px;
                                // font-family: "Varela Round", sans-serif;
                                // font-size: 20px;
                                // color: white;
                            }
                        </div>
                    </div>
                </li>
            </ul >
        );


    }
}

export default ContactForm;
import React, { Component } from 'react'
import { ChromePicker } from 'react-color';
import { rgba2hex } from '../../../Utils/UtilMethods'

export default class ColorPickerComponent extends Component {
    // color picker
    state = {
        colorPicker: {
            lastSelectedColor: this.props.color,
        },
    }

    handlePickerChangeComplete = (color) => {
        console.log(" handlePickerChangeComplete", color.hex);
    };

    handlePickerChange = (name) => (color) => {
        var hex = "#" + rgba2hex(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a},)`)
        // this.handleChangeForItemInList(
        //     {
        //         target:
        //         {
        //             id: this.state.colorPicker.targetId,
        //             value: hex
        //         }
        //     }, this.state.colorPicker.openInIndex)
        // color = {
        //   hex: '#333',
        //   rgb: {
        //     r: 51,
        //     g: 51,
        //     b: 51,
        //     a: 1,
        //   },
        //   hsl: {
        //     h: 0,
        //     s: 0,
        //     l: .20,
        //     a: 1,
        //   },
        // }

        this.props.pickerChanged(hex)
        this.setState({ colorPicker: { ...this.state.colorPicker, lastSelectedColor: hex } });
    }

    handlePickerClose = () => {
        this.props.closePicker()
        this.setState({
            colorPicker:
            {
                lastSelectedColor: "",
            }
        }
        );
    }

    render() {

        const popover = {
            position: 'fixed',
            zIndex: '2',

            top: "100px"
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',

        }
        return (
            <div>
                <div style={popover}>
                    <div style={cover} onClick={this.handlePickerClose} />
                    <ChromePicker
                        color={this.state.colorPicker.lastSelectedColor}
                        onChangeComplete={this.handlePickerChangeComplete}
                        onChange={this.handlePickerChange("color")} />
                </div>
            </div>
        )
    }
}

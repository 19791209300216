import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { FREE_TRAIL_DAYS } from '../../Utils/Constants'
import PriceCardComponent from './PriceCardComponent'

class PriceListComponent extends Component {
    render() {
        const { i18n, t } = this.props;
        return (

            <div className="priceList_grd"
                style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 40, paddingBottom: 40 }}>
                <div className="row center" style={{ margin: 0 }}>
                    <div
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        data-aos-offset="-50"
                        data-aos-once="true" dir="auto" className=" center"
                        style={{ color: " #e03577", marginBottom: 0, lineHeight: 1, fontFamily: "Heebo-ExtraBold", whiteSpace: "pre-wrap", marginTop: 0, fontSize: 100 }}>
                        {t("TryFree")}
                    </div>
                    <div
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                        data-aos-offset="-50"
                        data-aos-once="true"
                        dir="auto" className=" center"
                        style={{ fontFamily: "Heebo-ExtraBold", fontSize: 40, color: "white", marginBottom: 30, marginTop: 0, whiteSpace: "pre-wrap" }}>
                        {t("RegisterAndTry", { value: FREE_TRAIL_DAYS })} </div>

                    <div className=" col s12 m4 center" style={{ padding: 0 }}>
                        <PriceCardComponent buttonText={t("RegisterNow")} title={t("Large")} index={2} />
                    </div>

                    <div className=" col s12 m4 center" style={{ padding: 0 }}>
                        <PriceCardComponent buttonText={t("RegisterNow")} title={t("Medium")} index={1} />   </div>

                    <div className=" col s12 m4 center" style={{ padding: 0 }}>
                        <PriceCardComponent buttonText={t("RegisterNow")} title={t("Small")} index={0} />
                    </div>


                </div>

            </div>
        )
    }
}
export default withTranslation()(PriceListComponent)
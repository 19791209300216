import React, { Component } from 'react'
import { storage } from '../../config/fbConfig'
import { saveCurrentCardInfo } from '../../store/actions/newCardAction'
import { connect } from "react-redux"

export class ImageUploaderComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            url: "",
            topImagePreview: null
        };
    }

    handleChange = e => {

        if (e.target.files[0]) {
            const image = e.target.files[0];
            const topImagePreview = URL.createObjectURL(image);
            this.setState(() => ({ image }));
            this.setState(() => ({ topImagePreview }));
            this.props.saveCurrentCardInfo(this.props.location, image);
        }
    };

    handleUpload = (e) => {
        e.preventDefault();
        const { image } = this.state;
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                console.log("progress", progress);
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({ progress });
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ url });
                    });
            }
        );
    };
    render() {

        return (
            <div className="center">
                <div className="row">
                    <div className="col s12 m6 l6 " >
                        <div className="file-field input-field">
                            <div className="btn">
                                <span>{this.props.location} </span>
                                <input type="file" onChange={this.handleChange} required />
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" type="text" placeholder={"Select " + this.props.location} />
                            </div>
                        </div>
                    </div>
                    Card {this.props.location} Preview
                    <div className="col s12 m6 l6 center">

                        {this.state.topImagePreview ?
                            <img
                                src={this.state.topImagePreview}
                                alt={"כרטיס ביקור דיגיטלי"}
                                height="200"
                                width="200"

                            />
                            :
                            null
                        }

                    </div>
                </div>

                <button
                    onClick={this.handleUpload}
                    className="waves-effect waves-light btn"
                >
                    Uploading {this.state.progress}%
                </button>
            </div>
        );
    }
}


// adding to the props a dispatch function with card object
// 
const mapDispatchToProps = (dispatch) => {
    return (
        {
            saveCurrentCardInfo: (key, value) => dispatch(saveCurrentCardInfo(key, value)),
        })
}

const mapStateToProps = (state) => {
    return {
        card: state.card
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploaderComponent)


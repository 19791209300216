import Resizer from 'react-image-file-resizer';
import React from 'react'
import AboutMeCollapseComponent from "../components/user_cards/v_card_components/AboutMeCollapseComponent"
import ContactForm from "../components/user_cards/v_card_components/ContactForm"
import moment from 'moment'
import { getFirestore } from 'redux-firestore'
import i18n from 'i18next';
import AboutCollapseCustomEditorComponent from '../components/user_cards/v_card_components/AboutCollapseCustomEditorComponent';




export function generateStringId() {
    const length = 9;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateStringId_NoNumbers() {
    const length = 9;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateStringNumbers() {
    const length = 5;
    var result = '';
    var characters = '123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}




export function getStylesForPackageType(packageType) {

    switch (packageType) {
        case "BC":
            return (
                [
                    "Classic",
                    "Upside Down",
                    "Restaurant Menu",
                ])


        case "CONTACT":
            return (
                [
                    "Classic",
                ])


        case "IVITATION":
            return (
                [
                    "Classic",
                ])


        default:
            return (["Unknown Package"])

    }

}


export function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

export function rgba2hex(orig) {
    var a, isPercent,
        rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = (rgb && rgb[4] || "").trim(),
        hex = rgb ?
            (rgb[1] | 1 << 8).toString(16).slice(1) +
            (rgb[2] | 1 << 8).toString(16).slice(1) +
            (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

    if (alpha !== "") {
        a = alpha;
    } else {
        a = 1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    hex = hex + a;
    console.log("the hexa is:", hex);

    return hex;
}

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
};

export function getResizedImage(file, callback, height, width, quality) {
    callback(file, `${generateStringId()}.jpg`)

    // var fileSizeMbBefore = (file.size / 1024 / 1024).toFixed(4);
    // Resizer.imageFileResizer(
    //     file,
    //     height ? height : 600, // maxHeight
    //     width ? width : 600, // maxWidth
    //     'JPEG',
    //     quality ? quality : 100,
    //     0,
    //     blob => {
    //         const convertedFile = new File([blob], `${generateStringId()}.jpg`, {
    //             type: 'image/jpeg',
    //             lastModified: Date.now()
    //         });
    //         var fileSizeMbAfter = (convertedFile.size / 1024 / 1024).toFixed(4);
    //         console.log(`File size before:${fileSizeMbBefore}, File size after${fileSizeMbAfter}`);
    //         callback(blob, convertedFile.name)
    //     },
    //     'blob'
    // );
    // console.log("method ende");
}

export function getResizedImageBackground(file, callback, height, width, quality) {

    var fileSizeMbBefore = (file.size / 1024 / 1024).toFixed(4);
    Resizer.imageFileResizer(
        file,
        10000, // maxHeight
        10000, // maxWidth
        'JPEG',
        60,//quality
        0,
        blob => {
            const convertedFile = new File([blob], `${generateStringId()}.jpg`, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            var fileSizeMbAfter = (convertedFile.size / 1024 / 1024).toFixed(4);
            console.log(`File size before:${fileSizeMbBefore}, File size after${fileSizeMbAfter}`);
            callback(blob, convertedFile.name)
        },
        'blob'
    );
    console.log("method ende");
}

export function getResizedImageWithSetting(file, callback, height, width, quality) {

    var fileSizeMbBefore = (file.size / 1024 / 1024).toFixed(4);
    Resizer.imageFileResizer(
        file,
        height ? height : 600, // maxHeight
        width ? width : 1000, // maxWidth
        'JPEG',
        quality ? quality : 90,
        0,
        blob => {
            const convertedFile = new File([blob], `${generateStringId()}.jpg`, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            var fileSizeMbAfter = (convertedFile.size / 1024 / 1024).toFixed(4);
            console.log(`File size before:${fileSizeMbBefore}, File size after${fileSizeMbAfter}`);
            callback(blob, convertedFile.name)
        },
        'blob'
    );

}

export function getResizedImageForGallery(file, callback, height, width, quality) {

    var fileSizeMbBefore = (file.size / 1024 / 1024).toFixed(4);
    Resizer.imageFileResizer(
        file,
        height ? height : 700, // maxHeight
        width ? width : 700, // maxWidth
        'JPEG',
        quality ? quality : 60,
        0,
        blob => {
            const convertedFile = new File([blob], `${generateStringId()}.jpg`, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            var fileSizeMbAfter = (convertedFile.size / 1024 / 1024).toFixed(4);
            console.log(`File size before:${fileSizeMbBefore}, File size after${fileSizeMbAfter}`);
            callback(blob, convertedFile.name)
        },
        'blob'
    );
    // console.log("method ende");
}

export function getResizedImageForForShopItem(file, callback, height, width, quality) {
    var compressFormat = file.type == "image/png" ? "PNG" : "JPEG"
    var fileSizeMbBefore = (file.size / 1024 / 1024).toFixed(4);
    Resizer.imageFileResizer(
        file,
        height ? height : 700, // maxHeight
        width ? width : 700, // maxWidth
        compressFormat,
        quality ? quality : 60,
        0,
        blob => {
            const convertedFile = new File([blob], `${"only1Image"}.${compressFormat}`, {
                type: file.type,
                lastModified: Date.now()
            });
            var fileSizeMbAfter = (convertedFile.size / 1024 / 1024).toFixed(4);
            console.log(`File size before:${fileSizeMbBefore}, File size after${fileSizeMbAfter}`);
            callback(blob, convertedFile.name)
        },
        'blob'
    );
    // console.log("method ende");
}

export function copyObject(object) {
    return JSON.parse(JSON.stringify(object));
}

// returns a list of possible button in list
export function getRoundedButtonsTypeList(isForRectangle = false) {
    var buttonsType = [];
    console.log("getRoundedButtonsTypeList called");

    if (isForRectangle) {
        buttonsType.push(
            {
                type: "contactForm",
                description: i18n.t("ClickSendEmailForm"),
                icon: "fa fa-envelope-o"
            },
            {
                type: "about",
                description: i18n.t("InfoButton"),
                icon: "fa fa-info"
            },
            {
                type: "about_custom",
                description: i18n.t("InfoButtonCustom"),
                icon: "fa fa-info"
            })
    }

    buttonsType.push(
        {
            type: "tel",
            description: i18n.t("CallTo"),
            icon: "fa fa-mobile"
        },
        {
            type: "tel",
            description: i18n.t("CallTo"),
            icon: "fa fa-phone"
        },
        {
            type: "sms",
            description: i18n.t("SMS_Message"),
            icon: "fa fa-commenting-o"
        },
        {
            type: "whatsApp",
            description: i18n.t("WhatsAppMessage"),
            icon: "fa fa-whatsapp"
        },
        {
            type: "waze",
            description: i18n.t("WazeButton"),
            icon: "fa fa-map-marker"
        },
        {
            type: "mailto",
            description: i18n.t("MailButton"),
            icon: "fa fa-envelope-o"
        },
        {
            type: "contact",
            description: i18n.t("ContactButton"),
            icon: "fa fa-user"
        },
        {
            type: "link",
            description: i18n.t("LinkButton"),
            icon: "fa fa-youtube"
        },
        {
            type: "link",
            description: i18n.t("LinkButton"),
            icon: "fa fa-globe"
        },
        {
            type: "link",
            description: i18n.t("LinkButton"),
            icon: "fa fa-facebook"
        },
        {
            type: "link",
            description: i18n.t("LinkButton"),
            icon: "fa fa-instagram"
        },
        {
            type: "link",
            description: i18n.t("LinkButton"),
            icon: "fa fa-link"
        }
    )


    return buttonsType;
}

export function getBasicGalleryObjectWithTitle(title) {
    return {
        [generateStringId()]: {
            title: title,
            list: [],
            hide: false,
            borderColor: "black",
            borderWidth: 2,
            color: "black",
            fontName: "Heebo-Regular",
            fontSize: 32,
        }
    }
}
export function getBasicGalleryListObjectWithTitle() {
    return {
        title: "",
        list: [],
        hide: false,
        borderColor: "black",
        borderWidth: 2,
        color: "black",
        fontName: "Heebo-Regular",
        fontSize: 32,
        id: generateStringId()

    }
}
// get base object to work on 
export function getBasicButtonsObjectForType(buttonSelected, isRectangle) {

    console.log("getRoundedButtonsTypeList called", buttonSelected);

    switch (buttonSelected.type) {
        case 'tel':
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    number: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )

        case "whatsApp":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),
                    number: "",
                    id: generateStringId_NoNumbers(),
                }

            )
        case "sms":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )

        case "about":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    aboutTitle: "",
                    aboutText: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn",// circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )

        case "about_custom":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    text: "",
                    aboutTitle: "",
                    aboutText: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn",// circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )

        case "waze":

            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )
        case "mailto":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),
                    mail: "",
                    id: generateStringId_NoNumbers(),
                }

            )

        case "contact":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    cellNumber: "",
                    contactTitle: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )

        case "contactForm":
            return (
                {
                    type: buttonSelected.type,
                    icon: buttonSelected.icon,
                    title: "",
                    mail: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    formLanguage: "en",
                    fontSize: getDefaultSizes("fontSize", isRectangle),

                    id: generateStringId_NoNumbers(),
                }

            )


        case "link":
            return (
                {
                    icon: buttonSelected.icon,
                    type: buttonSelected.type,
                    title: "",
                    textColor: "red",
                    iconColor: "red",
                    color: "white",
                    borderColor: "red",
                    shape: "btn-floating btn", // circle
                    iconSize: getDefaultSizes("iconSize", isRectangle),
                    borderWidth: getDefaultSizes("borderWidth", isRectangle),
                    fontSize: getDefaultSizes("fontSize", isRectangle),
                    url: "",
                    id: generateStringId_NoNumbers(),
                }

            )

        default:
            return null;
    }

}


export function getTargetNameDesctiption(targetName, t) {
    var description = ""
    switch (targetName) {
        case 'color':
            description = t('Button')
            break;

        case 'iconColor':
            description = t('Icon')
            break;

        case 'borderColor':
            description = t('Border')
            break;

        case 'textColor':
            description = t('Text')
            break;

        case 'fontName':
            description = t('Fonts')
            break;

        case 'fontSize':
            description = t('FontSize')
            break;
        default:
            description = t("missing desc")
            break;
    }
    return description
}

export var FontList =
    [
        "Varela Round",
        "Heebo-Regular",
        "Heebo-Black",
        "Heebo-Bold",
        "Heebo-ExtraBold",
        "Heebo-Light",
        "Heebo-Medium",
        "Heebo-Thin",
        "Durian Dfus HEB",
        "Durian Ktav HEB",
        "AmaticaSC-Regular",
        "AmaticaSC-Bold",
        "ankaclm-bold-webfont HEBREW",
        "Cafe",
        "Hebrew Hand Wright",
        "makabiyg-webfont",
        "Chocolate ENGLISH",
        "Breathing ENGLISH",
    ]


export function GalleryStyleDict() {
    return ({
        "Carusel": i18n.t("Carusel"),
        "GridList": i18n.t("GridList"),
    })
}

function getDefaultSizes(tag, isRectangle) {
    switch (tag) {
        case "iconSize":
            return isRectangle ? 25 : 30


        case "borderWidth":
            return isRectangle ? 2 : 2


        case "fontSize":
            return isRectangle ? 20 : 14


        default:
            return null

    }
}



// get designed rounded button
export function getRoundedContactDesignedButton(button, clickCallBackFunction, animations, index) {
    const buttonSize = 58;
    const iconSize = button.iconSize;
    const bottomTextStyle = {
        color: button.textColor,
        fontFamily: button.fontName,
        fontSize: button.fontSize
    }
    console.log("rounded index", index, button.type);
    var animation = {
        animation: "",
        delay: (index) * 200,
        duration: 1000,
        offset: "-50",
        once: "true",
    }
    if (animations) {
        animation.animation = animations.animation
        animation.delay = (index) * animations.delay
        animation.duration = animations.duration
    }

    console.log("animation data", { ...animation });
    switch (button.type) {

        case 'tel':
            return (
                <li>
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}
                    >
                        <a href={"tel:" + button.number}
                            className={button.shape + " waves-effect waves-light  "}

                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>

                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>
                </li>
            )

        case 'sms':
            return (
                <li>
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <a href={"sms:" + button.number}
                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>

                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>
                </li>
            )

        case "whatsApp":
            return (
                <li>
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay} >
                        <a href={"https://api.whatsapp.com/send?phone=" + button.number}

                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>
                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>

                </li>)


        case "waze":
            return (
                <li>
                    <div style={{}}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <a href={button.wazePath ? button.wazePath : "waze://?q=" + button.city + " " + button.address}
                            // <a href={"waze://?q=" + button.city + " " + button.address}
                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>
                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>                        </div>
                    </div>

                </li>
            )

        case "mailto":
            return (
                <li>
                    <div data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <a href={"mailto:" + button.mail}
                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>
                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>

                </li>)

        case "contact":
            return (
                <li>

                    <div data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <a onClick={() => { vcfFile(button); clickCallBackFunction() }}
                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),
                            }}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>
                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto" >
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>

                </li>
            )

        case "link":
            return (
                <li>
                    <div data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <a href={button.url}
                            className={button.shape + " waves-effect waves-light  "}
                            style={{
                                backgroundColor: button.color,
                                borderWidth: button.borderWidth,
                                borderStyle: "solid",
                                borderColor: button.borderColor,
                                width: Number(buttonSize),
                                height: Number(buttonSize),

                            }}
                            onClick={() => clickCallBackFunction()}
                        >
                            <i className={button.icon + " iconCenter"} aria-hidden="true"
                                style={{ color: button.iconColor, fontSize: iconSize }}></i>
                        </a>
                        <div style={{ lineHeight: 1.3, marginTop: 5 }} dir="auto">
                            <span style={bottomTextStyle}>{button.title}</span>
                        </div>
                    </div>

                </li>)
        default:
            return null;
    }
}

// get desined rectangle button
export function getRectangleContactDesignedButton(button, clickCallBackFunction, cardID, animations, index) {

    const iconSize = button.iconSize;

    let style = {
        width: "100%",
        textAlign: "justify",
        color: button.textColor,
        backgroundColor: button.color,
        fontFamily: button.fontName,
        fontSize: button.fontSize,
        margin: 0,
        textTransform: "none",
        // padding: "0px 14px"
    }

    let iconStyle = {
        color: button.iconColor,
        fontSize: iconSize,
        width: iconSize + 10,
        textAlign: "center"
    }

    var animation = {
        animation: "",
        delay: (index) * 200,
        duration: 1000,
        offset: "-50",
        once: "true",
    }
    if (animations) {
        animation.animation = animations.animation
        animation.delay = (index) * animations.delay
        animation.duration = animations.duration
    }

    switch (button.type) {
        case 'tel':
            return (
                <li>

                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10,
                        backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={"tel:" + button.number}
                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>
                </li>
            )
        case 'sms':
            return (
                <li>

                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={"sms:" + button.number}
                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>
                </li>
            )

        case "whatsApp":
            return (
                <li >
                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={"https://api.whatsapp.com/send?phone=" + button.number}
                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}

                            </div>
                        </a>
                    </div>
                </li >)
        case "about":
            return (
                <li >
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <AboutMeCollapseComponent button={button} didClick={() => {
                            clickCallBackFunction()
                        }} />
                    </div>

                </li>
            )
        case "about_custom":
            return (
                <li >
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <AboutCollapseCustomEditorComponent button={button} didClick={() => {
                            clickCallBackFunction()
                        }} />
                    </div>

                </li>
            )

        case "waze":
            return (
                <li>
                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={button.wazePath ? button.wazePath : "waze://?q=" + button.city + " " + button.address}

                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>
                </li>
            )

        case "mailto":
            return (
                <li>

                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={"mailto:" + button.mail}
                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>

                </li>)

        case "contact":
            return (
                <li>

                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            onClick={() => { vcfFile(button); clickCallBackFunction() }}
                        >
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>

                </li>)

        case "contactForm":
            return (
                <li>
                    <div
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>
                        <ContactForm button={button} cardID={cardID} didClick={() => {
                            clickCallBackFunction()
                        }} />
                    </div>
                </li>
            )

        case "link":
            return (
                <li>
                    <div dir="auto" style={{
                        marginRight: 10, marginLeft: 10, marginBottom: 10, backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: button.borderColor
                    }}
                        data-aos={animation.animation}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay}>

                        <a className="waves-effect waves-light btn-large "
                            style={{ width: "100%", padding: 0 }}
                            href={button.url}
                            onClick={() => clickCallBackFunction()}>
                            <div dir="auto" style={style}>
                                <i className={`${button.icon} `}
                                    aria-hidden="true"
                                    style={iconStyle}
                                ></i>
                                {button.title}
                            </div>
                        </a>
                    </div>

                </li>)
        default:
            return null;
    }
}

export function getContactFormInstructionsForLanguage(language, word) {

    switch (language) {
        case "heb":

            switch (word) {
                case "name":
                    return "שם מלא:"


                case "nameHolder":
                    return "הכנס שם פרטי ומשפחה"


                case "phone":
                    return "מספר טלפון:"


                case "phoneHolder":
                    return "הכנס מספר טלפון"


                case "email":
                    return "המייל שלך:"


                case "emailHolder":
                    return "הכנס את המייל שלך"


                case "message":
                    return "ההודעה שלך:"


                case "messageHolder":
                    return "הכנס הודעה"


                case "messageSent":
                    return "ההודעה נשלחה!"


                case "sendMessage":
                    return "שלח הודעה!"


                default:
                    return ("unKnown Word")

            }



        case "en":
            switch (word) {
                case "name":
                    return "Full Name:"


                case "nameHolder":
                    return "Enter first and last name"


                case "phone":
                    return "Phone Number:"


                case "phoneHolder":
                    return "Enter phone number"


                case "email":
                    return "Your Email:"


                case "emailHolder":
                    return "Enter your email"


                case "message":
                    return "Your Message:"


                case "messageHolder":
                    return "Add your message"


                case "messageSent":
                    return "Message sent!"


                case "sendMessage":
                    return "Send message!"


                default:
                    return ("unKnown Word")

            }

        default:
            return ("unKnown Language")

    }
}

function vcfFile(card) {
    console.log("VCF FILE");

    var vCardsJS = require('vcards-js');

    //create a new vCard
    var vCard = vCardsJS();

    //set properties
    vCard.firstName = card.firstName;
    vCard.lastName = card.lastName;
    // vCard.organization = 'ACME Corporation';
    // vCard.photo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');
    vCard.cellPhone = card.cellNumber;
    // vCard.birthday = new Date(1985, 0, 1);
    vCard.title = card.contactTitle;
    vCard.email = card.email
    vCard.url = window.location.href;
    // vCard.note = 'Notes on Eric';

    //save to file
    const FileSaver = require('file-saver');
    const blob = new Blob([vCard.getFormattedString()], { type: "text/vcard;charset=utf-8" });
    const fileName = card.firstName + ".vcf"
    FileSaver.saveAs(blob, fileName);

    //get as formatted string
    console.log("vcard info", vCard.getFormattedString());
}

export function getTitleForCode(code) {
    if (!code) {
        code = "cover"
    }
    var newArray = getTopHeaderImageStyleList().filter(function (el) {
        return el.code == code
    });
    return newArray[0].title
}

export function getTopHeaderImageStyleList() {
    return ([
        {
            title: "cover",
            code: "cover"
        },
        {
            title: "contain",
            code: "contain"
        },
        {
            title: "streach",
            code: "100% 100%"
        },
        {
            title: "streach Width",
            code: "100%"
        },
        {
            title: "center",
            code: "length"
        },
    ])
}

// export function backGroundImageOption() {
//     return ([        
//         {
//             title: "streach",
//             code: "100% 100%"
//         },
//         {
//             title: "center",
//             code: "length"
//         },
//     ])
// }

export function canCreateNewCard(userProfile, cards) {
    switch (userProfile.type) {
        case "admin":
            return { result: true, description: "" }

        case "agent":
            return { result: true, description: "" }

        case "client":

            if (hasOneUnPaidOpen(cards)) {
                return { result: false, description: "You already have one un paid card, Pay for your un paid card, and than open new" }
            }
            else {
                return { result: true, description: "" }
            }

        default:
            return false

    }
}

export function getUserTypeList() {
    return ["client", "agent", "admin"]
}



export function getPackageList() {
    return [
        {
            type: "BC",
            size: i18n.t("Small"),
            title: i18n.t("SmallBc"),
            price: 299,
            maxFileUploads: 5,
            maxRectangleButtons: 2,
            maxRoundedButtons: 4,
            maxNumberOfGallery: 1,
            maxVideoShare: 1,
            period: 365,
            description: "",
        },
        {
            type: "BC",
            size: i18n.t("Medium"),
            title: i18n.t("MediumBc"),
            price: 349,
            maxFileUploads: 25,
            maxRectangleButtons: 4,
            maxRoundedButtons: 8,
            maxNumberOfGallery: 4,
            maxVideoShare: 4,
            period: 365,
            description: "",
        }
        ,
        {
            type: "BC",
            size: i18n.t("Large"),
            title: i18n.t("LargeBc"),
            price: 449,
            maxFileUploads: 100,
            maxRectangleButtons: 100,
            maxRoundedButtons: 100,
            maxNumberOfGallery: 100,
            maxVideoShare: 100,
            period: 365,
            description: "",
        },
        {
            type: "CONTACT",
            size: "small",
            title: "Contact List Dialer",
            price: 120,
            maxFileUploads: 50,
            period: 366,
            description: "",
        },
        {
            type: "IVITATION",
            size: "small",
            title: "Invitation Card",
            price: 90,
            maxFileUploads: 5,
            period: 184,
            description: "",
        },
        {
            type: "IVITATION",
            size: "medium",
            title: "Invitation Card",
            price: 180,
            maxFileUploads: 50,
            period: 184,
            description: "",
        }
        ,
        {
            type: "IVITATION",
            size: "small",
            title: "Contact List Dialer",
            price: 150,
            maxFileUploads: 100,
            period: 184,
            description: "",
        },
    ]
}

function hasOneUnPaidOpen(cards) {

    var card = cards.find(function (card) {
        return card.info.payment.paidOnDate == null || paidCardTimeExpiered(card)
    });
    return card ? true : false

}

export function paidCardTimeExpiered(card) {

    if (card.info.payment.paidOnDate) {
        var startDate = moment.unix(card.info.payment.paidOnDate.seconds)
        var today = moment(new Date)
        var days = moment.duration(today.diff(startDate)).asDays()
        if (days > card.info.package.period) {
            return true
        }
        else {
            return false
        }
    }
    else {
        return true
    }
}


export function packageThatFitsTheCard(card) {
    var arrResult = getPackageList().filter(function (el) {
        return el.type == "BC" &&
            el.maxFileUploads >= numberOfUploadedImagesFromLists(card) &&
            el.maxNumberOfGallery >= Object.keys(card.gallerys).length &&
            el.maxRectangleButtons >= card.rectangleButtons.list.length &&
            el.maxRoundedButtons >= card.roundedButtons.list.length &&
            el.maxVideoShare >= card.videos.list.length
    });
    // console.log("packageThatFitsTheCard: " + card.id + " arrResults: " + arrResult)
    if (arrResult.length > 1) {
        var theLowestPrice = arrResult.reduce((min, p) => p.price < min ? p.price : min, arrResult[0]);
        // console.log("value: " + theLowestPrice);
        return theLowestPrice
    }
    else if (arrResult.length == 1) {
        return arrResult[0];
    }
    else {
        return null
    }

}


export function numberOfUploadedImages(card) {
    var imagesCounter = 0
    var dict = card.gallerys
    for (const [key, value] of Object.entries(dict)) {
        // console.log(key, value);
        if (dict[key].list != null) {
            imagesCounter += dict[key].list.length
        }
    }
    return imagesCounter;
}

export function numberOfUploadedImagesFromLists(card) {
    var imagesCounter = 0
    var list = card.gallerys.list

    if (list != null) {
        for (let indexList = 0; indexList < list.length; indexList++) {
            const element = list[indexList];
            if (element.list != null) {
                imagesCounter += element.list.length
            }
        }
    }
    return imagesCounter;
}

export function didEndTrialPeriod(card) {

    var startDate = moment.unix(card.info.createdAt.seconds)
    var today = moment(new Date)
    var daysTrail = moment.duration(today.diff(startDate)).asDays()
    if (daysTrail > card.info.cardTrailPeriod) {
        return true
    }
    else {
        return false
    }
}

export function daysLeftForTrail(card) {

    var startDate = moment.unix(card.info.createdAt.seconds)
    var today = moment(new Date)
    var daysTrail = moment.duration(today.diff(startDate)).asDays()
    if (card.info.cardTrailPeriod - daysTrail > 0) {
        return Math.ceil(card.info.cardTrailPeriod - daysTrail)
    }
    else {
        return 0

    }
}
export function daysLeftForPaid(card) {

    var startDate = moment.unix(card.info.payment.paidOnDate.seconds)
    var today = moment(new Date)
    var daysPayment = moment.duration(today.diff(startDate)).asDays()
    if (card.info.cardTrailPeriod - daysPayment > 0) {
        return Math.ceil(card.info.package.period - daysPayment)
    }
    else {
        return 0

    }
}

export function shouldBeLocked(card) {
    if (paidCardTimeExpiered(card) && didEndTrialPeriod(card)) {
        return true
    }
    return false
}


export function getExpirationDate(card) {

    if (card.info.payment.paidOnDate) {
        var startDate = moment.unix(card.info.payment.paidOnDate.seconds)
        var expirationDate = moment(startDate, "DD/MM/YYYY").add(card.info.package.period, 'days');
        return expirationDate.format('DD/MM/YYYY');


    }
    else {
        return null
    }
}


export function incrementCardUdisNewVisitors() {
    var d = new Date();
    const firestore = getFirestore();
    firestore.collection('cardudis').doc("data").update(
        `newVisitors.${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`, firestore.FieldValue.increment(1)
    ).then(() => {
        console.log("succes in dispatch");
    }).catch(err => {
        console.log("error in dispatch", err);
    });
}

export function incrementUserNewVisitors(cardId) {
    var d = new Date();
    const firestore = getFirestore();
    firestore.collection('userVisitors').doc(cardId).update(
        `newVisitors.${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`, firestore.FieldValue.increment(1)
    ).then(() => {
        console.log("succes in dispatch");
    }).catch(err => {
        console.log("error in dispatch", err);
        if (err.code == "not-found") {
            firestore.collection('userVisitors').doc(cardId).set({
                "newVisitors":
                {
                    [d.getFullYear()]:
                    {
                        [d.getMonth() + 1]:
                            { [d.getDate()]: 1 }
                    }
                }
            }, { merge: true }).then(() => {
                console.log("succes in dispatch");
            }).catch(err => {
                console.log("error in dispatch", err);

            });
        }
    });

}

export function incrementUserNumberOfEntriesToCard(cardId) {
    var d = new Date();
    const firestore = getFirestore();
    firestore.collection('userVisitors').doc(cardId).update(
        `entriesToPage.${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`, firestore.FieldValue.increment(1)
    ).then(() => {
        console.log("succes in dispatch");
    }).catch(err => {
        console.log("error in dispatch", err);
        if (err.code == "not-found") {
            firestore.collection('userVisitors').doc(cardId).set({
                "entriesToPage":
                {
                    [d.getFullYear()]:
                    {
                        [d.getMonth() + 1]:
                            { [d.getDate()]: 1 }
                    }
                }
            }, { merge: true }).then(() => {
                console.log("succes in dispatch");
            }).catch(err => {
                console.log("error in dispatch", err);

            });
        }
    });

}

export function getThisMonthNumberOfEntries(statistics, cardId) {
    var d = new Date();
    try {
        if (statistics[cardId].entriesToPage[d.getFullYear()][d.getMonth() + 1] != null) {
            var dict = statistics[cardId].entriesToPage[d.getFullYear()][d.getMonth() + 1];
            var counter = 0;
            for (const [key, value] of Object.entries(dict)) {
                // console.log(key, value);
                counter = counter + dict[key];
            }
            // get this month sum value 
            return counter
        }

        else {
            return null
        }
    }
    catch (err) {
        return null
    }
}

export function getThisMonthNewVisitors(statistics, cardId) {
    var d = new Date();
    try {
        if (statistics[cardId].newVisitors[d.getFullYear()][d.getMonth() + 1] != null) {
            var dict = statistics[cardId].newVisitors[d.getFullYear()][d.getMonth() + 1];
            var counter = 0;
            for (const [key, value] of Object.entries(dict)) {
                // console.log(key, value);
                counter = counter + dict[key];
            }
            // get this month sum value 
            return counter
        }
        else {
            return null
        }
    }
    catch (err) {
        return null
    }
}

export function getStatisticsForRoundedButtonWithId(statistics, cardId, buttonId) {

    try {
        if (statistics[cardId].roundedButtons.buttonsClicks[buttonId] != null) {
            return statistics[cardId].roundedButtons.buttonsClicks[buttonId]
        }
        else {
            return null
        }
    }
    catch (err) {
        return null
    }
}

export function getStatisticsForRectangleButtonWithId(statistics, cardId, buttonId) {

    try {
        if (statistics[cardId].rectangleButtons.buttonsClicks[buttonId] != null) {
            return statistics[cardId].rectangleButtons.buttonsClicks[buttonId]
        }
        else {
            return null
        }
    }
    catch (err) {
        return null
    }
}

export function formatCurrency(value) {
    return Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });
}
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

export class WhatWeHaveInCard extends Component {
    render() {
        const { t } = this.props
        return (
            <section id="works" className="center-content gradColor">
                {/* IS IOS */}
                {/* <a href="com.assuta://deeplink?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJ1bmlxdWVfbmFtZSI6InRva18wNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJjaWQiOiJlb3FrYXIyU09iRS9IYWZ6citQWkdqdytHalp5dEVZZzRuY0hrMS9LRlovTkdwWFd0VW5MTVpvTThDSUkrNzRLIiwibmJmIjoxNTkzNDE5MzE1LCJleHAiOjE1OTM0MjExNDUsImlhdCI6MTU5MzQxOTMxNiwiaXNzIjoiaHR0cHM6Ly9Eb2NQQS5hc3N1dGEuY28uaWwiLCJhdWQiOiJodHRwczovL0RvY1BBLmFzc3V0YS5jby5pbCJ9.oo6nrh74UCIs0NouiS1KrVDY6esIL-qHQFP2eiY7Q5Y">click deep link</a> */}
                {/* <br /> */}
                {/* is android */}
                {/* <a href="intent://deeplink/#Intent;scheme=com.assuta;package=com.smartx.assuta_doctors;S.token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJ1bmlxdWVfbmFtZSI6InRva18wNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJjaWQiOiJlb3FrYXIyU09iRS9IYWZ6citQWkdqdytHalp5dEVZZzRuY0hrMS9LRlovTkdwWFd0VW5MTVpvTThDSUkrNzRLIiwibmJmIjoxNTkzNDE5MzE1LCJleHAiOjE1OTM0MjExNDUsImlhdCI6MTU5MzQxOTMxNiwiaXNzIjoiaHR0cHM6Ly9Eb2NQQS5hc3N1dGEuY28uaWwiLCJhdWQiOiJodHRwczovL0RvY1BBLmFzc3V0YS5jby5pbCJ9.oo6nrh74UCIs0NouiS1KrVDY6esIL-qHQFP2eiY7Q5Y;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.smartx.assuta_doctors;end"> click deep link </a> */}
                <div className="row homeSecondSection" style={{ marginBottom: 0 }}>
                    <h1
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        data-aos-offset="-50"
                        data-aos-once="true"
                        dir="auto"
                        style={{ marginTop: 20, marginBottom: 40, fontFamily: "Heebo-ExtraBold" }}>
                        {t("WhatDoWeHaveInCard")}
                    </h1>

                    <div className="works_content text-center">

                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-mobile"></i>
                                <h5>{t("DialButton")}</h5>
                                <p dir="auto">{t("DialButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-address-book"></i>
                                <h5>{t("SaveContactButton")}</h5>
                                <p dir="auto">{t("SaveContactButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  ">
                                <i className="fa fa-map-marker"></i>
                                <h5>{t("NavButton")}</h5>
                                <p dir="auto">{t("NavButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-whatsapp"></i>
                                <h5>{t("WhatsAppButton")}</h5>
                                <p dir="auto">{t("WhatsAppButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  ">
                                <i className="fa fa-envelope-o"></i>
                                <h5>{t("SendEmailButton")}</h5>
                                <p dir="auto">{t("SendEmailButtonDescription")}</p>

                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-link"></i>
                                <h5>{t("WebLinkButton")}</h5>
                                <p dir="auto">{t("WebLinkButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  ">
                                <i className="fa fa-share-square-o"></i>
                                <h5>{t("Shares")}</h5>
                                <p dir="auto">{t("ShareLinkNetworsButtonDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-paint-brush"></i>
                                <h5>{t("PersonalDesignButton")}</h5>
                                <p dir="auto">{t("PersonalDesignButtonDescription")}</p>
                            </div>
                        </div>

                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  ">
                                <i className="fa fa-youtube"></i>
                                <h5>{t("YouTubeShareVideos")}</h5>
                                <p dir="auto" >{t("YouTubeShareVideosDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-picture-o"></i>
                                <h5>{t("Gallerys")}</h5>
                                <p dir="auto">{t("GallerysDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  ">
                                <i className="fa fa-bandcamp"></i>
                                <h5>{t("MainTopImage")}</h5>
                                <p dir="auto">{t("MainTopImageDescription")}</p>
                            </div>
                        </div>
                        <div className=" col s6 m4 l3" style={{ height: 280, overflow: "none" }}>
                            <div className="single_works_text  " >
                                <i className="fa fa-user-circle-o"></i>
                                <h5>{t("LogoBusiness")}</h5>
                                <p dir="auto">{t("LogoBusinessDescription")}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        )
    }
}
export default withTranslation()(WhatWeHaveInCard)
import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import ClipLoader from "react-spinners/ClipLoader";
import LoaderView from "../SpinnerLoader/LoaderView";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

// import Car from "../assets/img/car.jpg";
// import Spinner from "./Spinner";

const CLIENT = {
  sandbox: "AbH_gQ53dBFNZT8CgUrZtiObVOy2K-TP85TfnL6rNi2F9-wiupWOEmSMjP6nP-xjpIrRCrsBEvPaocEZ",
  production: "Ad7YApY-7_maDLyxrlg4qYtlVMFq-qrQnmArRqQD2hlqQb7BSZgnGVu7Oy2OOf0DqWVU2PdWRgCNMsaB"
};

const CLIENT_ID =
  process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      loadingFull: false

    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {


    return actions.order.create({
      purchase_units: [
        {
          description: +this.props.package.description,
          amount: {
            currency_code: "ILS",
            value: this.props.package.price
          }
        }
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  };

  onApprove = (data, actions) => {
    this.setState({
      loadingFull: true
    })

    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      this.setState({ showButtons: false, paid: true, loadingFull: false });
      details.package = this.props.package
      this.props.successResponse(details)
      // this.savePaymentInfo(details)
    });
  };

  render() {
    const { showButtons, loading, paid } = this.state;
    const { t } = this.props

    return (
      <div className="main">
        <h3> {process.env.NODE_ENV} </h3>

        {loading && <div className=" center ">
          <ClipLoader
            size={60}
            color={"#ee33ff"}
            loading={true}
          />
          <div className="center" dir="auto">Loading Secure paypal payment</div>
        </div>}

        {this.state.loadingFull ?
          <LoaderView text={t("OrderApproved_fetchInfo") + "..."} />
          : null}

        {showButtons && (
          <div className="center">
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}

        {paid && (
          <div className="main">
            {/* <img alt="Mercedes G-Wagon" src={Car} /> */}
            <h2>
              Congrats! you just paid for that picture. Work a little harder and
              you'll be able to afford the car itself{" "}
              <span role="img" aria-label="emoji">
                {" "}
                😉
              </span>
            </h2>
          </div>
        )}
      </div>
    );
  }
}

export default compose(scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=ILS&locale=he_IL`), withTranslation())(PaypalButton);

import React, { Component } from 'react'
import CardSelectorComponent from '../user_cards/CardSelectorComponent'
import { withTranslation } from 'react-i18next';
import { shouldBeLocked } from '../../Utils/UtilMethods'
export class CardPreviewComponent extends Component {
    render() {
        const { t, card } = this.props;
        return (
            <div id="container" style={{
                height: "600px",
                minWidth: "250px", maxWidth: "350px",
                backgroundColor: "white",
                margin: "auto",
                overflow: shouldBeLocked(card) ? "hidden" : "scroll",
            }}
                onClick={() => {
                    if (shouldBeLocked(card)) {
                        this.props.history.push({
                            pathname: '/payment/' + card.id,
                            // search: '?query=abc',
                            state: { card: card }
                        })
                    }

                }}
            >
                {
                    shouldBeLocked(card) ?
                        <div id="infoi">
                            <i className="fa fa-lock"
                                aria-hidden="true"
                                style={{ marginTop: 100, fontSize: 180, color: "red" }}
                            ></i>
                            <div style={{ color: "white", fontSize: 30, padding: 5 }}>
                                {t("CardLocked")}
                                <br />
                                {t("ClickToPayment")}
                            </div>
                        </div>
                        :
                        null
                }
                <div >
                    <CardSelectorComponent card={card} isFromDashboard={true} />
                </div>

            </div>

        )
    }
}
export default withTranslation()(CardPreviewComponent)

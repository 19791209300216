import React, { Component } from 'react'
import { connect } from 'react-redux'
import Favicon from 'react-favicon';
import TopImage from "./v_card_components/TopImage"
import LogoCenterImage from "./v_card_components/LogoCenterImage"
import CardDescriptionComponent from './v_card_components/CardDescriptionComponent'
import RoundedContactButtons2 from "./v_card_components/RoundedContactButtons2"
import RectangleButtons from "./v_card_components/RectangleButtons"
import CaruselImageComponent from './v_card_components/CaruselImageComponent'
import IFrameComponent from './v_card_components/IFrameComponent'
import ShareComponent from "./v_card_components/ShareComponent"
import { Helmet } from "react-helmet";
import { shouldBeLocked } from '../../Utils/UtilMethods'
import { cardAnimationDictionary } from '../../Utils/AnimationData'
import CreateYourOwnBusinessCardRefComponent from './Editors/CreateYourOwnBusinessCardRefComponent';
import { AlertLayerLockMissingPayment } from '../AlertsComponents/AlertsComponent';
import ImageGridList from './v_card_components/ImageGridList';
import AOS from 'aos';
import "aos/dist/aos.css"
import ModalImageView from './v_card_components/ModalImageView';
import UserShopLayout from '../Sale/UserShopLayout';

export class UserCardClassicAosComponent extends Component {

    state = {
        card: this.props.theCard,
        isFromDashboard: this.props.isFromDashboard ? true : false,
        url: null
    }

    constructor(props, context) {
        super(props, context);
        AOS.init();
    }

    // local storage 
    componentWillReceiveProps(newProps) {
        console.log("new props");
        AOS.refresh();
        this.setState({
            isFromDashboard: newProps.isFromDashboard,
            card: newProps.theCard
        })
    }
    lastSelectedIndex = - 1
    gallery = null

    nextModalImage = () => {
        if (this.lastSelectedIndex + 1 < this.gallery.length) {
            this.lastSelectedIndex++;
        }
        else {
            this.lastSelectedIndex = 0
        }
        this.openImage(this.lastSelectedIndex)
    }

    prevModalImage = () => {
        if (this.lastSelectedIndex - 1 > -1) {
            this.lastSelectedIndex--;
        }
        else {
            this.lastSelectedIndex = this.gallery.length - 1
        }
        this.openImage(this.lastSelectedIndex)
    }

    openImage = (index) => {
        console.log("open image", index);
        this.lastSelectedIndex = index

        this.setState({
            url: this.gallery[index]
        })

    }

    getCardLayout = () => {

        var animationStyle = this.props.theCard.animation.style
        return (
            <div
                style={{
                    backgroundColor: this.state.isFromDashboard ? this.state.card.background.color : null,
                    backgroundImage: this.state.card.background.url && !this.state.card.background.hideImage ? "url(" + this.state.card.background.url + ")" : null,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: "100% ",
                }}
            >
                {!this.state.isFromDashboard ?
                    <Favicon url={this.state.card.logo.url} />
                    : null}

                <div
                // style={{ backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/cardudis.appspot.com/o/ABAU3XQltlc2WwCKnj29rw9xTIu2%2FbackgrounImage%2Fback.png?alt=media&token=87bec4e3-d07b-4be8-af87-cc9c281e4a67)" }}
                >
                    <title>{this.state.card.description.title.text}</title>

                    <div
                        data-aos={cardAnimationDictionary[animationStyle].topImage.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].topImage.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].topImage.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].topImage.once}
                    >

                        < TopImage
                            header={this.state.card.header}
                            isFromDashboard={this.state.isFromDashboard}
                            isFromEditor={false} />
                    </div>


                    <div style={{ position: "relative" }}
                        data-aos={cardAnimationDictionary[animationStyle].logoCenter.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].logoCenter.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].logoCenter.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].logoCenter.once}
                    >
                        <LogoCenterImage logo={this.state.card.logo} animated={false} isFromDashboard={this.state.isFromDashboard} />
                    </div>
                    <div
                        data-aos={cardAnimationDictionary[animationStyle].descriptionGroup.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].descriptionGroup.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].descriptionGroup.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].descriptionGroup.once}>
                        <CardDescriptionComponent
                            description={this.state.card.description}
                            animations={cardAnimationDictionary[animationStyle].descriptionSingles} />
                    </div>

                    <div
                        data-aos={cardAnimationDictionary[animationStyle].roundedGroup.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].roundedGroup.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].roundedGroup.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].roundedGroup.once}>
                        <RoundedContactButtons2
                            buttons={this.state.card.roundedButtons}
                            buttonClicked={(id) => {
                                this.props.didClick("roundedButtons", id)
                            }}
                            animations={cardAnimationDictionary[animationStyle].roundedSingles}
                        />
                    </div>

                    <div
                        data-aos={cardAnimationDictionary[animationStyle].rectangleGroup.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].rectangleGroup.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].rectangleGroup.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].rectangleGroup.once}>

                        <RectangleButtons
                            buttons={this.state.card.rectangleButtons}
                            cardID={this.state.card.id} buttonClicked={(id) => {
                                this.props.didClick("rectangleButtons", id)
                            }}
                            animations={cardAnimationDictionary[animationStyle].rectangleSingles}
                        />
                    </div>
                    {this.state.url ?
                        <ModalImageView url={this.state.url}
                            leftClick={() => {
                                // set grid view callback                                                                                                     
                                this.prevModalImage()
                            }}
                            rightClick={() => {
                                // set grid view callback                                                                                                     
                                this.nextModalImage()
                            }}

                            closeClick={() => {
                                // set grid view callback     
                                this.gallery = null
                                this.setState({
                                    url: null
                                })
                            }}
                        />
                        :
                        null
                    }

                    {
                        this.state.card.info.allowShop &&
                            this.state.card.shop
                            ?
                            <div>
                                <UserShopLayout
                                    isFromDashboard={this.state.isFromDashboard}
                                    shop={this.state.card.shop}
                                    buttonClicked={(id) => {
                                        this.props.didClick("shopButtons", id)
                                    }} />
                            </div>
                            :
                            null
                    }

                    {
                        this.state.card.gallerys.list && this.state.card.gallerys.list.map((gallery, index) => {

                            if (gallery.settings != null && gallery.settings.style === "GridList") {
                                return (
                                    <div
                                        data-aos={cardAnimationDictionary[animationStyle].galleryGroup[index % 2]}
                                        data-aos-duration={cardAnimationDictionary[animationStyle].galleryGroup.duration}
                                        data-aos-offset={cardAnimationDictionary[animationStyle].galleryGroup.offset}
                                        data-aos-once={cardAnimationDictionary[animationStyle].galleryGroup.once}>
                                        <ImageGridList gallery={gallery}
                                            id={gallery.id}
                                            isFromDashboard={this.state.isFromDashboard}
                                            showImage={(galleryList, index) => {
                                                this.gallery = galleryList
                                                this.openImage(index)
                                            }} />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div
                                        data-aos={cardAnimationDictionary[animationStyle].galleryGroup[index % 2]}
                                        data-aos-duration={cardAnimationDictionary[animationStyle].galleryGroup.duration}
                                        data-aos-offset={cardAnimationDictionary[animationStyle].galleryGroup.offset}
                                        data-aos-once={cardAnimationDictionary[animationStyle].galleryGroup.once}>
                                        <CaruselImageComponent
                                            gallery={gallery}
                                            id={gallery.id}
                                            isFromDashboard={this.state.isFromDashboard}
                                            showImage={(galleryList, index) => {
                                                this.gallery = galleryList
                                                this.openImage(index)
                                            }} />
                                    </div>

                                )
                            }
                        })
                    }

                    {
                        this.state.card.videos && this.state.card.videos.list.map((video, index) => {
                            return (


                                <div className="center"
                                    data-aos={cardAnimationDictionary[animationStyle].frameGroup[index % 2]}
                                    data-aos-duration={cardAnimationDictionary[animationStyle].frameGroup.duration}
                                    data-aos-offset={cardAnimationDictionary[animationStyle].frameGroup.offset}
                                    data-aos-once={cardAnimationDictionary[animationStyle].frameGroup.once}>
                                    {console.log("index:", index)}
                                    <IFrameComponent video={video} isFromDashboard={this.state.isFromDashboard} />
                                </div>

                            )
                        })
                    }

                    {/* <Share /> */}
                    <div
                        data-aos={cardAnimationDictionary[animationStyle].shareGroup.animation}
                        data-aos-duration={cardAnimationDictionary[animationStyle].shareGroup.duration}
                        data-aos-offset={cardAnimationDictionary[animationStyle].shareGroup.offset}
                        data-aos-once={cardAnimationDictionary[animationStyle].shareGroup.once}>

                        <ShareComponent shares={this.state.card.shares}
                            title={this.state.card.description.title.text}
                            subtitle={this.state.card.description.subtitle.text}
                            cardID={this.state.card.id}
                            animations={cardAnimationDictionary[animationStyle].shareSingles} />
                    </div>

                    <CreateYourOwnBusinessCardRefComponent />

                    {/* <AudioPlayer url={"https://firebasestorage.googleapis.com/v0/b/cardudis.appspot.com/o/cow.mp3?alt=media&token=bf35a9b0-4c44-4495-b952-1e4e1327cff4"} /> */}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div >

                {!this.state.isFromDashboard ?
                    <div>
                        {shouldBeLocked(this.state.card) ?
                            < AlertLayerLockMissingPayment />
                            : null
                        }
                        <Helmet>
                            <title>{this.state.card.description.title.text}</title>
                            <meta property="og:image" content={this.state.card.logo.url} />
                            <meta property="og:image:secure_url" content={this.state.card.logo.url} />
                            <meta property="og:title" content={this.state.card.description.title.text} />
                            <meta property="og:description" content={this.state.card.description.title.text + " " + this.state.card.description.subtitle.text} />
                            <meta name="description" content={this.state.card.description.title.text + " " + this.state.card.description.subtitle.text} />
                            <meta name="keywords" content={this.state.card.description.title.text} />
                            <link rel="canonical" href={"https://cardudis.com/vip/" + this.state.card.id} />
                        </Helmet>
                    </div>
                    : null}

                {this.state.isFromDashboard ?
                    this.getCardLayout()
                    :
                    <div className="row"
                        style={{ margin: 0 }}>
                        <div className="col s12 m12 offset-l2 l8"
                            style={{ padding: 0 }} >

                            {this.getCardLayout()}
                        </div>
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        // theCard: state.card.doc,
        // reduxSelectedCard: state.redux.visualCard,
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            // getUserCardName: (cardName) => dispatch(getUserCardName(cardName)),
        })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCardClassicAosComponent)

import React, { Component } from 'react'

export default class ThanksPaymentComponent extends Component {
    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }
    render() {
        return (
            <div>
                Thanks!!!
            </div>
        )
    }
}

import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { getFirestore } from 'redux-firestore'
import ChangeShapeListComponent from './ChangeShapeListComponent'
import { ChromePicker } from 'react-color';
import { rgba2hex } from '../../../../Utils/UtilMethods'
import { AlertOkCancelComponent } from '../../../AlertsComponents/AlertsComponent'
import TopGlobalCustomButtonsColor from './TopGlobalCustomButtonsColor'
import FontListSelectComponent from '../../FontListSelectComponent'
import { getRoundedContactDesignedButton } from '../../../../Utils/UtilMethods'

import arrayMove from 'array-move';
import NumberStepperComponent from '../../NumberStepperComponent';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

export class EditRoundedContactButtons extends Component {
    selectedIndexToDelete = -1
    alertTitle = ""
    alertSubTitle = ""

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });

    SortableItem = sortableElement(({ value, myIndex }) => this.getRoundedButtonEditCard(value, myIndex));

    state = {
        ...this.props,
        didMakeChange: false,
        isUploadingNow: false,
        showingAlert: false,
        disableSorting: true,
        colorPicker: {
            lastSelectedColor: "#fff",
            isOpen: false,
            openInIndex: -1,
            targetId: ""
        },
    }

    componentWillReceiveProps(np) {
        if (np) {
            this.setState({
                ...this.state,
                roundedButtons: np.roundedButtons,
            })
        }
    }
    componentDidMount() {
        const M = window.M;
        var elems = document.querySelector('.create-card-select-language');
        M.FormSelect.init(elems, {});
        M.updateTextFields();
    }

    handleChangeForItemInList = (e, index) => {
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value, index);
        // 1. Make a shallow copy of the items
        let listCopy = [...this.state.roundedButtons.list];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...listCopy[index] };
        // 3. Replace the property you're intested in

        item = {
            ...item,
            [e.target.id]: e.target.value
        }
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        listCopy[index] = item;
        // 5. Set the state to our new copy

        // this.setState({ list: listCopy });
        this.setState({
            roundedButtons: { ...this.state.roundedButtons, list: listCopy },
            didMakeChange: true
        });
        console.log("the list is ", listCopy);
    }

    handleChangeForBlock = (e) => {

        this.setState({
            roundedButtons: {
                ...this.state.roundedButtons,
                [e.target.id]: e.target.value
            },

            didMakeChange: true
        });

    }

    handleChangeFor_All_ItemInList = (e) => {
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value);
        // 1. Make a shallow copy of the items
        if (this.state.roundedButtons) {
            let listCopy = [...this.state.roundedButtons.list];

            for (let index = 0; index < listCopy.length; index++) {
                var item = listCopy[index];
                item = {
                    ...item,
                    [e.target.id]: e.target.value
                }
                listCopy[index] = item
            }

            this.setState({
                roundedButtons: {
                    ...this.state.roundedButtons
                    , list: listCopy,
                    lastGlobalSelections: {
                        ...this.state.roundedButtons.lastGlobalSelections,
                        [e.target.id]: e.target.value
                    }
                },

                didMakeChange: true
            });
        }


    }

    addNewItemInList = (button) => {
        console.log("create new button clicked");
        // 1. Make a shallow copy of the items
        var listCopy = [];
        if (!this.state.roundedButtons) {
            listCopy = [button];
        }
        else {
            listCopy = [button, ...this.state.roundedButtons.list];
        }

        // 2. Make a shallow copy of the item you want to mutate        
        this.setState({
            roundedButtons: { ...this.state.roundedButtons, list: listCopy },
            didMakeChange: true
        });
    }

    deleteButton(index) {
        var array = [...this.state.roundedButtons.list];
        array.splice(index, 1);
        this.setState({ roundedButtons: { ...this.state.roundedButtons, list: array } });
        this.saveChanges({ ...this.state.roundedButtons, list: array });
        array = [...this.state.roundedButtons.list];
        var buttonsClickDictionary = this.state.roundedButtons.buttonsClicks
        if (!buttonsClickDictionary) {
            buttonsClickDictionary = {}
        }
        var selectedButtonToDeleteId = this.state.roundedButtons.list[index].id
        // delete the button click info for delete button
        if (buttonsClickDictionary[selectedButtonToDeleteId]) {
            delete buttonsClickDictionary[selectedButtonToDeleteId]
        }


        array.splice(index, 1);
        var newRoundedDict = {
            ...this.state.roundedButtons,
            list: array,
            buttonsClicks: buttonsClickDictionary,
        }

        this.setState(
            {
                roundedButtons: newRoundedDict
            });
        this.saveChanges(newRoundedDict);


    }

    saveChanges = (object) => {
        var sendValue = object ? object : this.state.roundedButtons
        this.setState({
            isUploadingNow: true
        });

        const firestore = getFirestore();
        firestore.collection('cards').doc(this.props.cardID).set({
            "roundedButtons": sendValue,

        }, { merge: true }).then(() => {
            console.log("succes in dispatch");
            this.props.updateReduxRoundedButtonsList(sendValue)
            this.setState({
                didMakeChange: false,
                isUploadingNow: false
            });

        }).catch(err => {
            console.log("error in dispatch");
            this.setState({
                didMakeChange: false,
                isUploadingNow: false
            });
        });
    }

    // color picker
    handlePickerChangeComplete = (color) => {
        console.log(" handlePickerChangeComplete", color.hex);
    };

    handlePickerChange = (name) => (color) => {
        var hex = "#" + rgba2hex(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a},)`)
        this.handleChangeForItemInList(
            {
                target:
                {
                    id: this.state.colorPicker.targetId,
                    value: hex
                }
            }, this.state.colorPicker.openInIndex)

        this.setState({ colorPicker: { ...this.state.colorPicker, lastSelectedColor: hex } });
    }

    handlePickerOpen = (color, index, targetName, e) => {
        e.preventDefault()
        this.setState({
            colorPicker:
            {
                isOpen: true,
                lastSelectedColor: color,
                openInIndex: index,
                targetId: targetName
            }
        }
        );
    }

    handlePickerClose = () => {
        this.setState({
            colorPicker:
            {
                isOpen: false,
                lastSelectedColor: "",
                openInIndex: -1,
                targetId: ""
            }
        }
        );
    }

    showDeleteAlert(e, buttonIndex) {
        e.preventDefault()
        this.selectedIndexToDelete = buttonIndex
        this.alertTitle = this.props.t("DeleteButton?")
        this.alertSubTitle = this.props.t("SureDelete?")
        this.setState({
            showingAlert: true
        })
    }

    render() {
        const popover = {
            position: 'fixed',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        const bootomFixedForUpdateButton = {
            position: 'fixed',
            zIndex: '2',
            backgroundColor: "red",
            height: "40px",
            right: '0px',
            bottom: '0px',
            left: '0px',
        }


        const { t, auth, } = this.props;
        if (!auth.uid) return <Redirect to='/' />
        return (
            <div className="container" style={{ marginBottom: "45px" }}>

                {this.state.showingAlert ?
                    < AlertOkCancelComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={(confirm) => {

                        if (confirm) {
                            this.deleteButton(this.selectedIndexToDelete)
                        }

                        this.setState({
                            showingAlert: false
                        })

                    }} />
                    : null}

                {this.state.colorPicker.isOpen ? <div style={popover}>
                    <div style={cover} onClick={this.handlePickerClose} />
                    <ChromePicker

                        color={this.state.colorPicker.lastSelectedColor}
                        onChangeComplete={this.handlePickerChangeComplete}
                        onChange={this.handlePickerChange("color")} />
                </div> : null}

                <div>


                    <TopGlobalCustomButtonsColor
                        marginTopBlockValue={this.state.roundedButtons.marginTopBlock ? this.state.roundedButtons.marginTopBlock : 0}
                        isForRectangle={false}
                        setAllWithData={(data) => {
                            console.log("sent data", data);
                            this.handleChangeFor_All_ItemInList(data)
                        }}
                        newButton={button => {
                            this.addNewItemInList(button)
                        }}

                        enterSortMode={checked => {
                            this.setState({
                                ...this.state,
                                disableSorting: !checked
                            })
                        }}

                        marginTopBlock={(value) => {
                            this.handleChangeForBlock(value)
                        }

                        }
                        lastGlobalSelections={this.state.roundedButtons.lastGlobalSelections}
                    />


                    {this.state.didMakeChange ?
                        <div style={bootomFixedForUpdateButton}>
                            <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")} onClick={() => this.saveChanges(null)} >{t("SaveChanges")}
                                <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                            </button>
                        </div> : null}

                    <this.SortableContainer onSortEnd={this.onSortEnd} >
                        {this.state.roundedButtons && this.state.roundedButtons.list.map((value, index) => (
                            <this.SortableItem key={`item-${index}`} index={index} value={value} myIndex={index} disabled={this.state.disableSorting} />
                        ))}
                    </this.SortableContainer>

                </div>

            </div>

        )
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        var myList = [...this.state.roundedButtons.list];
        var newList = arrayMove(myList, oldIndex, newIndex)
        console.log("list", myList);
        console.log("newList", newList);
        this.setState({
            roundedButtons: { ...this.state.roundedButtons, list: newList },
            didMakeChange: true
        });
    };

    getEditCardTopOptionsBar(button, index) {
        return (

            <div className="center" >
                <div style={{ marginBottom: 10 }}>
                    {getRoundedContactDesignedButton(button, () => { })}
                </div>

                <div className="roundedButtons">
                    <ul>

                        <li>
                            <NumberStepperComponent
                                value={button.borderWidth}
                                max={20}
                                min={0}
                                step={1}
                                title={this.props.t("BorderWidth")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "borderWidth",
                                            value: value
                                        }
                                    }
                                    this.handleChangeForItemInList(newValue, index)


                                }} />
                        </li>
                        <li>

                            <NumberStepperComponent
                                value={button.fontSize}
                                max={30}
                                min={0}
                                step={1}
                                title={this.props.t("FontSize")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "fontSize",
                                            value: value
                                        }
                                    }
                                    this.handleChangeForItemInList(newValue, index)
                                }} />
                        </li>

                        <li style={{ width: "50px", }}>
                            <div style={{ width: "50px" }}>
                                <FontListSelectComponent fontName={button.fontName} selectedFont={(fontName) => {
                                    console.log("font selected");

                                    let value = {
                                        target: {
                                            id: "fontName",
                                            value: fontName
                                        }
                                    }
                                    this.handleChangeForItemInList(value, index)
                                }} />
                            </div>
                        </li>

                        {this.getCutomizeButtons("fa fa-paint-brush", button.color, index, "color")}
                        {this.getCutomizeButtons("fa fa-paint-brush", button.borderColor, index, "borderColor")}
                        {this.getCutomizeButtons("fa fa-paint-brush", button.iconColor, index, "iconColor")}
                        {this.getCutomizeButtons("fa fa-paint-brush", button.textColor, index, "textColor")}
                        <ChangeShapeListComponent selectedButton={button} selectedIndex={index}
                            selectedShape={(shape) => {
                                this.handleChangeForItemInList(shape, index)
                            }
                            } />


                        {this.getDesignedDeleteButton(index)}

                    </ul>
                </div>
            </div>
            // <div className="row">
            //     <div className="col s5 m5 l5" style={{ padding: "0px" }}>
            //         <div className="roundedButtons">
            //             <ul>
            //                 {this.getCutomizeButtons("fa fa-paint-brush", button.color, index, "color")}
            //                 {this.getCutomizeButtons("fa fa-square-o", button.borderColor, index, "borderColor")}
            //                 {this.getCutomizeButtons("fa fa-info", button.iconColor, index, "iconColor")}
            //             </ul>
            //         </div>
            //     </div>

            //     <div className="col s2 m2 l2 " style={{ textAlign: "center", padding: "0px" }}>
            //         {this.getCenterDesignedButton(button)}
            //     </div>

            //     <div className="col s5 m5 l5" style={{ padding: "0px" }}>
            //         <div className="roundedButtons">
            //             <ul>
            //                 {this.getCutomizeButtons("fa fa-font", button.textColor, index, "textColor")}
            //                 <ChangeShapeListComponent selectedButton={button} selectedIndex={index}
            //                     selectedShape={(shape) => {
            //                         this.handleChangeForItemInList(shape, index)
            //                     }
            //                     } />
            //                 {this.getDesignedDeleteButton(index)}
            //             </ul>
            //         </div>
            //     </div>
            // </div>
        )
    }

    getRoundedButtonEditCard(button, index) {
        // imported react for this //
        console.log("the button is:", button);
        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "3px solid",
            borderColor: "red",
        }
        switch (button.type) {
            case 'tel':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}


                            <div >
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto">{this.props.t("WillCall")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="number" id='number' placeholder={this.props.t("EnterAnyPhone")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.number} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>

                    </li>
                )
            case 'whatsApp':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}


                            <div >
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto">{this.props.t("WillOpenChat")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="number" id='number' placeholder={this.props.t("PhoneWithCountry")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.number} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>

                    </li>
                )
            case 'sms':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }} >
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}


                            <div >
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto">{this.props.t("WillSend_sms")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="number" id='number' placeholder={this.props.t("PhoneNumber")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.number} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>

                    </li>
                )

            case 'mailto':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }}>
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' dir="auto" placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />

                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("WillOpenEmail")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="email" id='mail' placeholder={this.props.t("EnterE-Mail")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.mail} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>
                    </li>
                )

            case 'contact':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }}>
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >VCard Title:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='contactTitle' placeholder="Title of the V-Card" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.contactTitle} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >First Name:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='firstName' placeholder="First name" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.firstName} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >Last Name:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='lastName' placeholder="Last name" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.lastName} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >Cell Number:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='cellNumber' placeholder="Enter any phone number to call" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.cellNumber} />
                            </div>

                            <div className="input-field">

                                <div className="edit_button_card_subtitle" dir="auto"  >E-Mail:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="email" id='email' placeholder="Enter business E-Mail" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.email} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>
                    </li>

                )
            case 'link':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }}>
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >Will open browser with Url:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='url' placeholder="Enter web site like www.udisapp.com" onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.url} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>
                    </li>

                )

            case 'waze':
                return (
                    <li key={`item-${index}`} style={{ listStyleType: "none" }}>
                        <div className="edit_button_card_container " style={cardStyle}>

                            {this.getEditCardTopOptionsBar(button, index)}

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("ButtonTitle")}:</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' placeholder={this.props.t("ButtonTitle")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.title} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("City")}</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='city' placeholder={this.props.t("City")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.city} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  >{this.props.t("Address")}</div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='address' placeholder={this.props.t("Address")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.address} />
                            </div>

                            <div className="input-field">
                                <div className="edit_button_card_subtitle" dir="auto"  ><a href="https://www.waze.com/livemap"> {this.props.t("Waze.comPath")}</a></div>
                                <input dir="auto" className="browser-default edit_button_card_input" type="text" id='wazePath' placeholder={this.props.t("Waze.comPath")} onChange={(e) => this.handleChangeForItemInList(e, index)} value={button.wazePath} />
                            </div>
                            <div className="edit_button_card_title" dir="auto"> {this.props.t("Index")} {index} {this.props.t("ButtonTitle")} {button.type}</div>
                        </div>
                    </li>
                )

            default:
                return null;
        }
    }

    getDesignedDeleteButton(buttonIndex) {

        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "red"
        }
        return (
            <li style={{ width: "50px" }}>
                <div style={{ width: "50px" }}>
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light "
                        style={style}
                        onClick={(e) => this.showDeleteAlert(e, buttonIndex)}
                    >
                        <i className="fa fa-trash-o iconCenter" aria-hidden="true"
                            style={{ color: "red", fontSize: "16px" }}></i>
                    </a>
                    <br />
                    <span className="center" style={{ color: "black", }}></span>
                    <span style={{ fontSize: "12px", color: "red", display: "block", marginTop: "4px" }}>{this.props.t("Delete")}</span>
                </div>
            </li>
        )
    }

    getCutomizeButtons(iconName, color, index, targetName) {
        var style = {
            backgroundColor: color,
            width: "38px",
            height: "38px",
        }

        var description = ""

        switch (targetName) {
            case 'color':
                description = this.props.t('Button')
                break;

            case 'iconColor':
                description = this.props.t("Icon")
                break;

            case 'borderColor':
                description = this.props.t('Border')
                break;

            case 'textColor':
                description = this.props.t('Text')
                break;

            default:
                break;
        }

        return (
            <li style={{ width: "50px", }}>
                <div style={{ width: "50px" }}>
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light "
                        style={style}
                        onClick={(e) => this.handlePickerOpen(color, index, targetName, e)}
                    >
                        <i className={iconName + " iconCenter"} aria-hidden="true"
                            style={{ color: "white", fontSize: "16px" }}></i>
                    </a>
                    <br />
                    <span className="center" style={{ color: "black", }}></span>
                    <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{description}</span>
                </div>
            </li>

        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateReduxRoundedButtonsList: (roundedButtons) => { dispatch({ type: "UPDATE_ROUNDED_LIST", roundedButtons: roundedButtons }) }
        })
}



const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        auth: state.firebase.auth,
    }
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EditRoundedContactButtons)

import React from 'react'
import TextInputComponent from '../../../InputComponents/TextInputComponent'
import { useTranslation } from 'react-i18next';
import CheckboxComponent from '../../CheckboxComponent';
import CenterText from '../../../View_Text/CenterText';

export default function ShopSellerInfo({ seller, updateSellerInfo }) {
    const { t } = useTranslation();

    function setSellerInfo(e) {
        if (seller) {
            seller[e.target.id] = e.target.value
            updateSellerInfo(seller)
        }
        else {
            var newSeller = {
                [e.target.id]: e.target.value
            }
            updateSellerInfo(newSeller)
        }


    }

    return (
        <div className="edit_button_card_container " >

            <CenterText
                textColor={"black"}
                fontSize={28}
                // fontFamily={this.state.gallery.fontName}
                text={"פרטי מוכר"}
            />


            <TextInputComponent
                title={t("Email")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.email : null}
                textId="email"
                handleChange={(e) => setSellerInfo(e)}
            />

            <CheckboxComponent
                checked={seller ? seller.useMail : null}
                title={t("EnableShopListEmail")} selected={(checked) => {
                    var e = {
                        target: {
                            id: "useMail",
                            value: checked
                        }
                    }
                    setSellerInfo(e)
                }} />

            <TextInputComponent
                title={t("WhatsappNumber")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.phoneNumberWhatsapp : null}
                textId="phoneNumberWhatsapp"
                handleChange={(e) => setSellerInfo(e)}
            />

            <CheckboxComponent
                checked={seller ? seller.useWhatsapp : null}
                title={t("EnableShopListWhatsapp")} selected={(checked) => {
                    var e = {
                        target: {
                            id: "useWhatsapp",
                            value: checked
                        }
                    }
                    setSellerInfo(e)
                }} />

            <TextInputComponent
                title={t("SmsNumber")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.phoneNumberSms : null}
                textId="phoneNumberSms"
                handleChange={(e) => setSellerInfo(e)}
            />

            <CheckboxComponent
                checked={seller ? seller.useSms : null}
                title={t("EnableShopListWithSms")} selected={(checked) => {
                    var e = {
                        target: {
                            id: "useSms",
                            value: checked
                        }
                    }
                    setSellerInfo(e)
                }} />

            <TextInputComponent
                title={t("ShippingTitle")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.ShippingTitle : null}
                textId="ShippingTitle"
                handleChange={(e) => setSellerInfo(e)}
            />

            <TextInputComponent
                title={t("ShippingPrice")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.ShippingPrice : null}
                textId="ShippingPrice"
                handleChange={(e) => setSellerInfo(e)}
            />

            <TextInputComponent
                title={t("ShippingFreeFromPrice")} //{this.props.t("ShopCategoryName")}
                value={seller ? seller.ShippingFreeFromPrice : null}
                textId="ShippingFreeFromPrice"
                handleChange={(e) => setSellerInfo(e)}
            />

            <CheckboxComponent
                checked={seller ? seller.useShiping : null}
                title={t("EnableShipping")} selected={(checked) => {
                    var e = {
                        target: {
                            id: "useShiping",
                            value: checked
                        }
                    }
                    setSellerInfo(e)
                }} />
        </div>
    )
}

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import {
    getStatisticsForRoundedButtonWithId,
    getStatisticsForRectangleButtonWithId,
    getThisMonthNumberOfEntries,
    getThisMonthNewVisitors,
} from '../../Utils/UtilMethods'

import {
    COLOR_ICON_MAIL_ENVELOPE, COLOR_ICON_WHATSAPP, COLOR_ICON_SMS_BUBBLE,
    ICON_SMS_BUBBLE, ICON_WHATSAPP, ICON_MAIL_ENVELOPE
} from '../../Utils/Constants'

export class CardStatisticsComponent extends Component {
    render() {
        const { card, t, statistics } = this.props
        return (
            <div>
                <h5 className="center" dir="auto">
                    <Link to={{
                        pathname: '/stacked-bar',
                        state: {
                            card: card,
                            statistics: statistics
                        }
                    }} >
                        <div>
                            לחץ לצפייה
                        </div>

                        {t("Statistics")}</Link>

                </h5>

                <div dir="auto">
                    {t("TotalEntriesMonth")}: {statistics ? getThisMonthNumberOfEntries(statistics, card.id) ? getThisMonthNumberOfEntries(statistics, card.id) : "0" : "0"}
                </div>
                <div dir="auto">
                    {t("NumberDifferentVisitors")}: {statistics ? getThisMonthNewVisitors(statistics, card.id) ? getThisMonthNewVisitors(statistics, card.id) : "0" : "0"}
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                    <div className="col s6 m6 l6" style={{ padding: 0 }}>
                        <div>
                            <div className="center" dir="auto" style={{ color: "blue" }}>
                                {t("RoundedClick")}
                            </div>

                            {card.roundedButtons.list && card.roundedButtons.list.map(function (button, index) {
                                return (
                                    <div>
                                        <div className="center" style={{ marginTop: 5 }}>
                                            <div className="btn-floating btn "
                                                style={{ width: 25, height: 25, backgroundColor: button.color, marginLeft: 10, marginRight: 10 }}>
                                                <i className={button.icon + " iconCenter "}
                                                    aria-hidden="true"
                                                    style={{
                                                        color: button.iconColor,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                </i>
                                            </div>
                                            <span style={{ color: "black" }} dir="auto">
                                                {statistics ? getStatisticsForRoundedButtonWithId(statistics, card.id, button.id) ? getStatisticsForRoundedButtonWithId(statistics, card.id, button.id) : "0" : "0"}
                                                {" " + t("Clicks")}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="col s6 m6 l6">
                        <div>
                            <div className="center" dir="auto" style={{ color: "blue" }}>
                                {t("Rect_Click")}
                            </div>

                            {card.rectangleButtons.list && card.rectangleButtons.list.map(function (button, index) {
                                return (
                                    <div>
                                        <div className="center" style={{ marginTop: 5 }}>
                                            <div className="btn-floating btn " style={{ width: 25, height: 25, backgroundColor: button.color, marginLeft: 10, marginRight: 10 }}>
                                                <i className={button.icon + " iconCenter "}
                                                    aria-hidden="true"
                                                    style={{
                                                        color: button.iconColor,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                </i>
                                            </div>
                                            <span style={{ color: "black" }} dir="auto">
                                                {statistics ? getStatisticsForRectangleButtonWithId(statistics, card.id, button.id) ? getStatisticsForRectangleButtonWithId(statistics, card.id, button.id) : "0" : "0"}
                                                {" " + t("Clicks")}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>

                    </div>
                    {
                        card.info.allowShop && statistics ?
                            <div className="row">
                                <div className="col s6 m6 l6">
                                    <div>
                                        <div className="center" dir="auto" style={{ color: "blue" }}>
                                            {t("Shop_Buttons_Click")}
                                        </div>

                                        <div>
                                            <div className="center" style={{ marginTop: 5 }}>
                                                <div className="btn-floating btn " style={{ width: 25, height: 25, backgroundColor: "white", marginLeft: 10, marginRight: 10 }}>
                                                    <i className={ICON_WHATSAPP + " iconCenter "}
                                                        aria-hidden="true"
                                                        style={{
                                                            color: COLOR_ICON_WHATSAPP,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                    </i>
                                                </div>


                                                {statistics[card.id] && statistics[card.id].shopButtons !== undefined ?
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {statistics[card.id].shopButtons.buttonsClicks.Whatsapp}
                                                        {" " + t("Clicks")}
                                                    </span>
                                                    :
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {"0 " + t("Clicks")}
                                                    </span>}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="center" style={{ marginTop: 5 }}>
                                                <div className="btn-floating btn " style={{ width: 25, height: 25, backgroundColor: "white", marginLeft: 10, marginRight: 10 }}>
                                                    <i className={ICON_SMS_BUBBLE + " iconCenter "}
                                                        aria-hidden="true"
                                                        style={{
                                                            color: COLOR_ICON_SMS_BUBBLE,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                    </i>
                                                </div>
                                                {statistics[card.id] && statistics[card.id].shopButtons ?
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {statistics[card.id].shopButtons.buttonsClicks.SMS}
                                                        {" " + t("Clicks")}
                                                    </span>
                                                    :
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {"0 " + t("Clicks")}
                                                    </span>}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="center" style={{ marginTop: 5 }}>
                                                <div className="btn-floating btn " style={{ width: 25, height: 25, backgroundColor: "white", marginLeft: 10, marginRight: 10 }}>
                                                    <i className={ICON_MAIL_ENVELOPE + " iconCenter "}
                                                        aria-hidden="true"
                                                        style={{
                                                            color: COLOR_ICON_MAIL_ENVELOPE,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                    </i>
                                                </div>
                                                {statistics[card.id] && statistics[card.id].shopButtons ?
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {statistics[card.id].shopButtons.buttonsClicks.Mail}
                                                        {" " + t("Clicks")}
                                                    </span>
                                                    :
                                                    <span style={{ color: "black" }} dir="auto">
                                                        {"0 " + t("Clicks")}
                                                    </span>}
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                            :
                            null
                    }

                </div>


            </div>
        )
    }
}
export default withTranslation()(CardStatisticsComponent)
import React, { Component } from 'react'

export default class CheckboxComponent extends Component {
    state = {
        checked: this.props.checked
    }

    handleChange = () => {
        this.props.selected(!this.state.checked)
        this.setState({
            checked: !this.state.checked
        })
    }

    componentWillReceiveProps(np) {
        this.setState({
            checked: np.checked
        })
    }

    render() {

        return (
            <div className="edit_button_card_subtitle" dir="auto">
                <label >
                    <input type="checkbox" id="hide" checked={this.state.checked}
                        onClick={(e) => this.handleChange()}
                    />
                    <span style={{ paddingLeft: 20 }} >{this.props.title}</span>
                </label>

            </div>
        )
    }
}

import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/he'
import { NavLink } from "react-router-dom"; // For linking
import { withTranslation } from 'react-i18next';
import {
    daysLeftForTrail,
    daysLeftForPaid,
    getExpirationDate,
    paidCardTimeExpiered,
    packageThatFitsTheCard,
    numberOfUploadedImagesFromLists,
}
    from '../../Utils/UtilMethods'

export class PaymentInfo extends Component {
    render() {
        const { card, t } = this.props

        return (

            <div dir="auto">
                <h5 className="center" dir="auto">
                    {t("Payments")}
                </h5>
                {t("Paid")}:{paidCardTimeExpiered(card) ? t("No") : t("Yes")}


                {/* <NavLink to={"/payment/" + card.id}  ><div dir="auto"> {t("ClickToPayment")}</div></NavLink> */}

                <NavLink to={{
                    pathname: '/payment/' + card.id,
                    state: {
                        cardDetails: card
                    }
                }} ><div dir="auto"> {t("ClickToPayment")}</div></NavLink>
                {!paidCardTimeExpiered(card) ?
                    <div>
                        <div>
                            {t("PaymentDate")}: {moment.unix(card.info.payment.paidOnDate.seconds).format('DD/MM/YYYY')}
                        </div>
                        <div>
                            {t("ExparationDate")}: {getExpirationDate(card)}
                        </div>
                        {t("DayForPaidCard")}: {daysLeftForPaid(card)}
                    </div>

                    :
                    <div dir="auto">
                        {t("DaysForTrail")}: {daysLeftForTrail(card)}
                    </div>
                }
                <div>
                    <br />
                    <div>
                        {packageThatFitsTheCard(card) ?
                            <div
                                style={{ whiteSpace: "pre-wrap" }}>
                                {t("CardFitsToPackage") + packageThatFitsTheCard(card).title}
                            </div>
                            : "Couldn't find a package that fits you card "}
                    </div>
                    <div>
                        {t("Number_ImageUpload")}: {numberOfUploadedImagesFromLists(card)}
                    </div>

                    <div>
                        {t("Number_OpenGallerys")}: {Object.keys(card.gallerys).length}
                    </div>

                    <div>
                        {t("Number_WideButtons")}:{card.rectangleButtons.list.length}
                    </div>

                    <div>
                        {t("Number_RoundedButtons")}: {card.roundedButtons.list.length}
                    </div>

                    <div>
                        {t("Number_VideoShares")}: {card.videos.list.length}
                    </div>
                </div>

            </div>

        )
    }
}
export default withTranslation()(PaymentInfo)
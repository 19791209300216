import PropTypes from 'prop-types';
import React from 'react';

import Gallery from './Gallery';
import CheckButton from './CheckButton';
import { withTranslation } from 'react-i18next';

class ImageGalleryWithSelection extends React.Component {
    state = {
        images: [],
        selectAllChecked: false
    }
    constructor(props) {
        super(props);
        this.setImageListState(true, props)
        this.onSelectImage = this.onSelectImage.bind(this);
        this.getSelectedImages = this.getSelectedImages.bind(this);
        this.onClickSelectAll = this.onClickSelectAll.bind(this);
        // this.setImageListState = this.setImageListState.bind(this)
    }


    setImageListState(initialize, newProps) {
        var tempList = newProps.images
        var selected = newProps.imagesSelected

        var imagesArr = []; // create an empty array
        for (const index in tempList) {
            //  console.log(`${property}: ${object[property]}`);
            var isSelected = false;
            for (const indexSelected in selected) {
                if (index == selected[indexSelected]) {
                    isSelected = true
                }
            }
            imagesArr.push({
                src: tempList[index],
                thumbnail: tempList[index],
                isSelected: isSelected
            });
        }

        if (initialize) {
            this.state = {
                images: imagesArr,
                selectAllChecked: false
            };
        }
        else {
            this.setState({
                images: imagesArr,
                // selectAllChecked: false
            })
        }
    }


    allImagesSelected(images) {

        var f = images.filter(
            function (img) {
                return img.isSelected == true;
            }
        );
        return f.length == images.length;
    }

    onSelectImage(index, image) {
        var images = this.state.images.slice();
        var img = images[index];
        if (img.hasOwnProperty("isSelected"))
            img.isSelected = !img.isSelected;
        else
            img.isSelected = true;

        this.setState({
            images: images
        });

        if (this.allImagesSelected(images)) {
            this.setState({
                selectAllChecked: true
            });
        }
        else {
            this.setState({
                selectAllChecked: false
            });
        }
        this.props.selectedIndexes(this.getSelectedImages())
    }

    getSelectedImages() {

        var selected = [];
        for (var i = 0; i < this.state.images.length; i++)
            if (this.state.images[i].isSelected == true)
                selected.push(i);


        return selected;
    }

    onClickSelectAll() {
        var selectAllChecked = !this.state.selectAllChecked;
        this.setState({
            selectAllChecked: selectAllChecked
        });

        var images = this.state.images.slice();
        if (selectAllChecked) {
            for (var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = true;
        }
        else {
            for (var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = false;

        }
        this.setState({
            images: images
        });
        this.props.selectedIndexes(this.getSelectedImages())
    }

    componentWillReceiveProps(newProps) {

        this.setImageListState(false, newProps);
    }

    render() {
        const { i18n, t } = this.props;

        return (
            <div>
                <CheckButton
                    index={0}
                    isSelected={this.state.selectAllChecked}
                    onClick={this.onClickSelectAll}
                    parentHover={true}
                    color={"rgba(0,0,0,0.54)"}
                    selectedColor={"#4285f4"}
                    hoverColor={"rgba(0,0,0,0.54)"} />
                <div style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center"
                }}>
                    {t("SelectAll")}
                </div>
                <div style={{
                    padding: "2px",
                    color: "#666"
                }}>{t("SelectedImages")}: {this.getSelectedImages().toString()}</div>
                <div style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    border: "1px solid #ddd",
                    overflow: "auto"
                }}>
                    <Gallery
                        images={this.state.images}
                        onSelectImage={this.onSelectImage}
                        showLightboxThumbnails={true} />
                </div>
            </div>
        );
    }
}

ImageGalleryWithSelection.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            srcset: PropTypes.array,
            caption: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            thumbnailWidth: PropTypes.number.isRequired,
            thumbnailHeight: PropTypes.number.isRequired,
            isSelected: PropTypes.bool
        })
    ).isRequired
};

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}



export default withTranslation()(ImageGalleryWithSelection)

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import ColorPickerComponent from '../ColorPickerComponent'
import CardDescriptionComponent from '../../v_card_components/CardDescriptionComponent'
import CheckboxComponent from '../../CheckboxComponent'
import FontListSelectComponent from '../../FontListSelectComponent'
import NumberStepperComponent from '../../NumberStepperComponent';
import { TextEditor } from '../../../InputComponents/joditEditor';

export class DescriptionComponent extends Component {
    selectedPickerTag = 0

    state = {
        description: this.props.description,
        didMakeChange: false,
        pickerShow: false
    }

    //When using ES6 code in React always use arrow functions, because the this context is automatically binded with it
    handleChange = (e) => {

        this.setState({
            didMakeChange: true,
            description: {
                ...this.state.description,
                [e.target.id]: {
                    ...this.state.description[e.target.id],
                    text: e.target.value
                }
            }
        })
    }

    handleHide = (e) => {
        this.setState({
            didMakeChange: true,
            description: {
                ...this.state.description,
                [e.target.id]: {
                    ...this.state.description[e.target.id],
                    hide: e.target.value
                }
            }
        })

    }

    handlePickerOpen = (tag, e) => {
        this.selectedPickerTag = tag
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }

    getColorPicker(tag) {
        var style = {
            backgroundColor: tag == 1 ? this.state.description.title.color : this.state.description.subtitle.color,
            width: "38px",
            height: "38px",
        }

        return (
            <div>
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={tag == 1 ? (e) => this.handlePickerOpen(1, e) : (e) => this.handlePickerOpen(2, e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.t("Text")}</span>
            </div>
        )
    }

    getCustomEditButtons() {
        return (
            <ul>
                <li>
                    {this.getColorPicker(1)}
                </li>
                <li>
                    <FontListSelectComponent fontName={this.state.description.title.fontName} selectedFont={(fontName) => {
                        console.log("font selected");
                        this.setState(state => {
                            state.didMakeChange = true
                            state.description.title.fontName = fontName
                            return state
                        })
                    }} />
                </li>
            </ul>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.selectedPickerTag === 1 ? this.state.description.title.color : this.state.description.subtitle.color}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {

                            if (this.selectedPickerTag === 1) {

                                this.setState({
                                    description: {
                                        ...this.state.description,
                                        title: {
                                            ...this.state.description.title,
                                            color: color
                                        }
                                    },
                                    didMakeChange: true
                                })
                            }
                            else {
                                this.setState({
                                    description: {
                                        ...this.state.description,
                                        subtitle: {
                                            ...this.state.description.subtitle,
                                            color: color
                                        }
                                    },
                                    didMakeChange: true
                                })
                            }

                        }}
                    />
                    :
                    null}

                <div className="edit_button_card_container">
                    <h5 className="">{t("PersonalDesc")} </h5>
                    <CardDescriptionComponent description={this.state.description}
                        isFromDashboard={true} />
                    <div>
                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Title")}</div>
                            <input dir="auto"
                                className="browser-default edit_button_card_input"
                                type="text"
                                id='title'
                                onChange={this.handleChange} required
                                placeholder="Enter title - first name and last name"
                                value={this.state.description.title.text}
                            />

                            <CheckboxComponent checked={this.state.description.title.hide} title={t("Hide")} selected={(checked) => {
                                this.setState(state => {
                                    state.didMakeChange = true
                                    state.description.title.hide = checked
                                    return state
                                })

                            }} />
                        </div>
                        <div className="roundedButtons">
                            <ul>
                                <li>
                                    {this.getColorPicker(1)}
                                </li>
                                <li>
                                    <FontListSelectComponent fontName={this.state.description.title.fontName} selectedFont={(fontName) => {
                                        console.log("font selected");
                                        this.setState(state => {
                                            state.didMakeChange = true
                                            state.description.title.fontName = fontName
                                            return state
                                        })
                                    }} />
                                </li>

                                <li>
                                    <NumberStepperComponent
                                        value={this.state.description.title.fontSize}
                                        max={60}
                                        min={10}
                                        step={1}
                                        title={t("FontSize")}
                                        didMakeChange={(value) => {
                                            this.setState(state => {
                                                state.didMakeChange = true
                                                state.description.title.fontSize = value
                                                return state
                                            })
                                        }} />
                                </li>
                                <li>
                                    <NumberStepperComponent
                                        value={this.state.description.title.marginTop ? this.state.description.title.marginTop : 0}
                                        max={200}
                                        min={-200}
                                        step={1}
                                        title={t("MarginTop")}
                                        didMakeChange={(value) => {
                                            this.setState(state => {
                                                state.didMakeChange = true
                                                state.description.title.marginTop = value
                                                return state
                                            })
                                        }} />
                                </li>
                            </ul>
                        </div>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("Subtitle")}</div>
                            <input dir="auto" className="browser-default edit_button_card_input" type="text" id='subtitle' onChange={this.handleChange} required
                                placeholder="Enter subtitle - proffesion" value={this.state.description.subtitle.text} />
                            <CheckboxComponent checked={this.state.description.subtitle.hide} title={t("Hide")} selected={(checked) => {
                                this.setState(state => {
                                    state.didMakeChange = true
                                    state.description.subtitle.hide = checked
                                    return state
                                })
                            }} />
                        </div>

                        <div className="roundedButtons">
                            <ul>
                                <li>
                                    {this.getColorPicker(2)}
                                </li>
                                <li>
                                    <FontListSelectComponent fontName={this.state.description.subtitle.fontName} selectedFont={(fontName) => {
                                        console.log("font selected");
                                        this.setState(state => {
                                            state.didMakeChange = true
                                            state.description.subtitle.fontName = fontName
                                            return state
                                        })
                                    }} />
                                </li>
                                <li>
                                    <NumberStepperComponent
                                        value={this.state.description.subtitle.fontSize}
                                        max={60}
                                        min={10}
                                        step={1}
                                        title={t("FontSize")}
                                        didMakeChange={(value) => {
                                            this.setState(state => {
                                                state.didMakeChange = true
                                                state.description.subtitle.fontSize = value
                                                return state
                                            })
                                        }} />
                                </li>

                                <li>
                                    <NumberStepperComponent
                                        value={this.state.description.subtitle.marginTop ? this.state.description.subtitle.marginTop : 0}
                                        max={200}
                                        min={-200}
                                        step={1}
                                        title={t("MarginTop")}
                                        didMakeChange={(value) => {
                                            this.setState(state => {
                                                state.didMakeChange = true
                                                state.description.subtitle.marginTop = value
                                                return state
                                            })
                                        }} />
                                </li>
                            </ul>
                        </div>


                        {this.state.didMakeChange ?
                            <div>
                                <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")}
                                    onClick={() => {
                                        this.props.saveChanges(this.state.description)
                                        this.setState(state => {
                                            state.didMakeChange = false
                                            return state
                                        })
                                    }} >{t("SaveChanges")}
                                    <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                                </button>
                            </div> : null}
                    </div>
                </div>

                <div className="edit_button_card_container">
                    <h5 className="">{t("PersonalDesc")} </h5>
                    <TextEditor
                        text={this.state.description.htmlDescription ? this.state.description.htmlDescription.text : null}
                        handleChange={(value) => {

                            var val = {
                                target: {
                                    id: "htmlDescription",
                                    value: value
                                }
                            }
                            this.handleChange(val)

                        }}
                    />

                    <CheckboxComponent checked={this.state.description.htmlDescription ? this.state.description.htmlDescription.hide : false} title={t("Hide")} selected={(checked) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.description.htmlDescription.hide = checked
                            return state
                        })
                    }} />

                    <NumberStepperComponent
                        value={this.state.description.htmlDescription && this.state.description.htmlDescription.marginTop ? this.state.description.htmlDescription.marginTop : 0}
                        max={200}
                        min={-200}
                        step={1}
                        title={t("MarginTop")}
                        didMakeChange={(value) => {
                            var html = this.state.description.htmlDescription
                            if (!html) {
                                html = {
                                    htmlDescription: "",
                                }
                            }

                            html.marginTop = value
                            this.setState(state => {
                                state.didMakeChange = true
                                state.description.htmlDescription = { ...html }
                                return state
                            })
                        }} />
                </div>
            </div>
        )
    }
}

export default
    withTranslation()(DescriptionComponent)

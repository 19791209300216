import React, { Component } from 'react'
import logo_bottom from '../../../images/Logos/logo_bottom.png'
import whiteBG from '../../../images/BGs/nice_shaysh_bg.jpg'
import AOS from 'aos';
import "aos/dist/aos.css"

export default class CreateYourOwnBusinessCardRefComponent extends Component {
    render() {
        return (
            <div>
                <div className="center"
                    style={{
                        paddingTop: 15,
                        backgroundColor: "white",
                        backgroundImage: `url(${whiteBG})`
                    }}>

                    <div className="center" >
                        <img
                            style={{ height: 40, maxWidth: "100%", }}
                            src={logo_bottom} alt="cardudis - כרטיס ביקור לקידום ושיווק העסק"
                            data-aos="fade-left"
                            data-aos-duration="3000"
                            data-aos-offset="-50"
                            data-aos-once="true" />
                        <h5
                            style={{ marginTop: 0, marginBottom: 0 }}
                            data-aos="fade-right"
                            data-aos-duration="3000"
                            data-aos-offset="-50"
                            data-aos-once="true"
                        >כרטיס ביקור דיגיטלי לעסק</h5>
                        <div style={{ paddingBottom: 10 }}
                            data-aos="fade"
                            data-aos-duration="3000"
                            data-aos-offset="-50"
                            data-aos-once="true">

                            <a
                                href="https://cardudis.com/"  >
                                {/* כרטיס ביקור דיגיטלי לעסק, רוצה גם ? לחץ או התקשר */}
                                <div>רוצה גם ? לחץ כאן או התקשר</div>
                            </a>
                            <a href={"tel:0544334590"}>
                                0544334590
                            </a>
                        </div>

                    </div>

                </div>

            </div>

        )
    }
}

import React, { Component } from 'react'
import { connect } from "react-redux"
import { getFirestore } from 'redux-firestore'
import { AlertOkComponent } from '../../../AlertsComponents/AlertsComponent'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import CheckboxComponent from '../../CheckboxComponent'
import FontListSelectComponent from '../../FontListSelectComponent'
import ColorPickerComponent from '../ColorPickerComponent'
import NumberStepperComponent from '../../NumberStepperComponent'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import CenterText from '../../../View_Text/CenterText';

export class GalleryNameComponent extends Component {
    selectedTargetName = ""
    state = {
        gallery: this.props.gallery,
        didMakeChange: false,
        showAlert: false,
        isLoading: false,
        pickerShow: false
    }

    handleChange(e) {
        this.props.updateGalleryValues({
            ...this.state.gallery,
            [e.target.id]: e.target.value,
        })
    }

    componentWillReceiveProps(np) {
        this.setState({
            gallery: np.gallery
        })
    }

    saveChanges() {
        if (this.props.saveChangesClicked) {
            this.props.saveChangesClicked(this.state.gallery)
        }
    }

    updateGallaryInfo = (gallery, callback) => {

        const firestore = getFirestore();
        firestore.collection('cards').doc(this.props.reduxSelectedCard.id).update({
            [`gallerys.${this.props.galleryId}`]: gallery,
        }).then(() => {
            callback(true)
            console.log("succes in dispatch");
        }).catch(err => {
            callback(true)
            // this.props.reloadParentState()
            console.log("error in dispatch");
        });
    }

    render() {
        const { t } = this.props;
        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "red"
        }
        return (
            <div className="center ">
                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.state.gallery[this.selectedTargetName]}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {
                            this.props.updateGalleryValues({
                                ...this.state.gallery,
                                [this.selectedTargetName]: color,
                            })
                        }}
                    />
                    :
                    null}

                {this.state.isLoading ?
                    <LoaderView text={t("UpdatingGallery")} />
                    : null}

                {this.state.showAlert ?
                    < AlertOkComponent title={t("NameMissing!")} subtitle={t("ChangeToValid")} closeAlert={() => {
                        this.setState({
                            showAlert: false
                        })
                    }} />
                    : null}

                <br />

                <div>
                    {
                        this.state.gallery.hide ? null :
                            <CenterText
                                textColor={this.state.gallery.color}
                                fontSize={this.state.gallery.fontSize}
                                fontFamily={this.state.gallery.fontName}
                                text={this.state.gallery.title}
                            />
                    }
                </div>

                <div className="input-field">
                    <div className="edit_button_card_subtitle" dir="auto"  >{t("GalleryName")}:</div>
                    <input dir="auto" className="browser-default edit_button_card_input"
                        type="text"
                        id='title'
                        style={{ textAlign: "center" }}
                        placeholder={t("GalleryName")}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.gallery.title} />


                    <CheckboxComponent title={t("HideTitle")}
                        checked={this.state.gallery.hide}
                        selected={(checked) => {
                            this.props.updateGalleryValues({
                                ...this.state.gallery,
                                hide: checked,
                            })
                        }} />
                </div>

                <div className="input-field">
                    <div className="edit_button_card_subtitle" dir="auto"  >{t("Copyright")}:</div>
                    <input dir="auto" className="browser-default edit_button_card_input"
                        type="text"
                        id='Copyright'
                        style={{ textAlign: "center" }}
                        placeholder={t("Copyright")}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.gallery.Copyright} />
                </div>

                <div className="roundedButtons">
                    <ul>
                        <li style={{ width: "50px" }}>
                            {this.getColorPicker()}
                        </li>
                        <li style={{ width: "50px" }}>
                            <div style={{ width: "50px" }}>
                                <a href="#"
                                    className="btn-floating btn waves-effect waves-light "
                                    style={{
                                        backgroundColor: this.state.gallery.borderColor,
                                        width: "38px",
                                        height: "38px",

                                    }}
                                    onClick={(e) => this.handlePickerOpen("borderColor", e)}
                                >
                                    <i className="fa  fa-square-o iconCenter" aria-hidden="true"
                                        style={{ color: "white", fontSize: "16px" }}></i>
                                </a>
                                <br />
                                <span style={{ fontSize: "12px", style: "bold", color: "black", display: "block", marginTop: "4px" }}>{t("Border")}</span>
                            </div>
                        </li>
                        <li style={{ width: "50px" }}>
                            <FontListSelectComponent fontName={this.state.gallery.fontName} selectedFont={(fontName) => {
                                this.props.updateGalleryValues({
                                    ...this.state.gallery,
                                    fontName: fontName,
                                })
                            }} />
                        </li>

                        <li style={{ width: "50px" }}>
                            <div style={{ width: "50px" }}>
                                <a href="#"
                                    className="btn-floating btn waves-effect waves-light "
                                    style={style}
                                    onClick={(e) => this.props.deleteGalleryClicked()}
                                >
                                    <i className="fa fa-trash-o iconCenter" aria-hidden="true"
                                        style={{ color: "red", fontSize: "16px" }}></i>
                                </a>
                                <br />
                                <span className="center" style={{ color: "black", }}></span>
                                <span style={{ fontSize: "12px", style: "bold", color: "red", display: "block", marginTop: "4px" }}>{this.props.t("Delete")}</span>
                            </div>
                        </li>
                        <li>
                            <NumberStepperComponent
                                value={this.state.gallery.fontSize}
                                max={60}
                                min={10}
                                step={1}
                                title={t("FontSize")}
                                didMakeChange={(value) => {

                                    this.props.updateGalleryValues({
                                        ...this.state.gallery,
                                        fontSize: value,
                                    })
                                }} />
                        </li>
                    </ul>
                </div>

                {this.state.didMakeChange ?
                    <div style={{ marginBottom: 10 }}>
                        <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")}
                            onClick={() => this.saveChanges()} >{t("SaveChanges")}
                            <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                        </button>
                        <br />
                    </div>
                    : null}
            </div>

        )
    }

    getColorPicker() {
        // tag 1 title , 2 is subtitle 

        var style = {
            backgroundColor: this.state.gallery.color,
            width: "38px",
            height: "38px",
        }

        return (
            <div style={{ width: "50px" }}>
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.handlePickerOpen("color", e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />
                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.t("Text")}</span>

            </div>
        )
    }

    handlePickerOpen = (target, e) => {

        e.preventDefault()
        this.selectedTargetName = target
        this.setState({
            pickerShow: true
        }
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateGalleryInRedux: (gallery, galleryId) => { dispatch({ type: "UPDATE_GALLARY_LIST_NAME", gallery, galleryId }) }
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(GalleryNameComponent)
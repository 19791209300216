import React, { useState } from 'react'

import RoundButtonBorderTextIcon from '../../Buttons/RoundButtonBorderTextIcon'
import BottomSumForList from './BottomSumForList'
import HeaderCols from './HeaderCols'
import ItemListSumerized from './ItemListSumerized'
import { getTextCartList } from '../../../Utils/ShopUtils'
import User from '../../../Singleton/UserAndCard'
import LongRoundedButtonIconAndText from '../../Buttons/LongRoundedButtonIconAndText'
import Emitter from '../../../Utils/emitter'
import {
    ALERT_DELETE_CART,
    EMITTER_CART_SEND_WITH_CLICKED,
    ICON_MAIL_ENVELOPE, ICON_WHATSAPP, ICON_SMS_BUBBLE,
    COLOR_ICON_MAIL_ENVELOPE, COLOR_ICON_SMS_BUBBLE, COLOR_ICON_WHATSAPP
} from '../../../Utils/Constants'

export default function SaleItemList({ close }) {

    const [refresh, setRefresh] = useState(false)
    var card = User.getSelectedCard()
    function orderWithWhatsapp() {
        // var card = User.getSelectedCard()
        Emitter.emit(EMITTER_CART_SEND_WITH_CLICKED, "Whatsapp")
        window.location.href = 'https://api.whatsapp.com/send?phone=' + card.shop.seller.phoneNumberWhatsapp + '&text=' + encodeStr(getTextCartList())
    }

    function orderWithEmail() {

        Emitter.emit(EMITTER_CART_SEND_WITH_CLICKED, "Mail")
        window.location.href = "mailto:" + card.shop.seller.email + "?subject=" + "הזמנת מוצרים" + "&body=" + encodeStr(getTextCartList())
    }

    function orderWithSms() {

        Emitter.emit(EMITTER_CART_SEND_WITH_CLICKED, "SMS")
        window.location.href = "sms:" + card.shop.seller.phoneNumberSms + "?&body=" + encodeStr(getTextCartList())
    }


    const encodeStr = (str) => {
        let encoded = encodeURIComponent(str)
        return encoded
    }

    return (
        <div className="fullDivShopingList">

            <div className="floatingLefTopButton"
                style={{ top: 8 }}>

                <RoundButtonBorderTextIcon
                    iconName={"fa fa-times"}
                    color={"black"}
                    buttonClick={() => close()
                    }
                />
            </div>

            <div className="floatingRightTopButton">

                <RoundButtonBorderTextIcon

                    iconName={"fa fa-trash-o"}
                    color={"red"}
                    buttonClick={(e) => Emitter.emit(ALERT_DELETE_CART)}
                />
            </div>

            <div
                dir="auto"
                style={{ marginTop: 20, marginBottom: 10 }}
                className="edit_button_card_subtitle_center">
                <u>
                    <h5>
                        רשימת הזמנה:
                    </h5>
                </u>
            </div>

            <div className="container"
                style={{
                    // backgroundColor: "green" 
                }}>
                <HeaderCols />
            </div>
            <div className=" center container "
                style={{
                    // backgroundColor: "red",
                    maxHeight: "50%", overflowY: "auto",
                    overflowX: "hidden",
                }}>
                <ItemListSumerized
                    refresh={() => setRefresh(!refresh)} />
            </div>

            <div className=" center container ">
                <BottomSumForList
                />
            </div>

            <div
                dir="auto"
                className=" container center"
                style={{ marginTop: 8 }}
            >
                <div className="edit_button_card_subtitle_center">
                    ניתן להזמין בדרכים הבאות :
                    </div>

                {
                    card.shop.seller.useMail ?

                        <LongRoundedButtonIconAndText
                            text={"אימייל"}
                            textColor={"blue"}
                            iconName={ICON_MAIL_ENVELOPE}
                            iconColor={COLOR_ICON_MAIL_ENVELOPE}
                            buttonColor={"white"}
                            borderColor={COLOR_ICON_MAIL_ENVELOPE}
                            buttonClick={() => orderWithEmail()}
                        />
                        : null
                }

                {
                    card.shop.seller.useSms ?
                        <LongRoundedButtonIconAndText
                            text={"S.M.S"}
                            textColor={"black"}
                            iconName={ICON_SMS_BUBBLE}
                            iconColor={COLOR_ICON_SMS_BUBBLE}
                            buttonColor={"white"}
                            borderColor={COLOR_ICON_SMS_BUBBLE}
                            buttonClick={() => orderWithSms()}
                        />
                        : null
                }

                {
                    card.shop.seller.useWhatsapp ?
                        <LongRoundedButtonIconAndText
                            text={"וואטסאפ"}
                            textColor={"green"}
                            iconName={ICON_WHATSAPP}
                            iconColor={COLOR_ICON_WHATSAPP}
                            buttonColor={"white"}
                            borderColor={COLOR_ICON_WHATSAPP}
                            buttonClick={() => orderWithWhatsapp()}
                        />
                        : null
                }
            </div>
        </div>
    )
}

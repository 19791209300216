import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { getFirestore } from 'redux-firestore'
import axios from 'axios';
import {
    generateStringId_NoNumbers,
    getContactFormInstructionsForLanguage,
} from '../../Utils/UtilMethods'

import {
    SERVER_SIDE_BASE_URL,
} from '../../Utils/Constants'

import LoaderView from '../SpinnerLoader/LoaderView';

export class ContactCardudisFormComponent extends Component {
    state = {
        my_message: "",
        email: '',
        name: "",
        sent: false,
        error: "",
        isLoading: false,
    }

    formSubmit = (e) => {
        e.preventDefault()

        let messageId = generateStringId_NoNumbers()
        let data = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.my_message,
            to: "cardudis.service@gmail.com",
            date: new Date(),
            id: messageId,
            // check the button mail!!!!
        }

        var message = {
            [generateStringId_NoNumbers()]: {
                name: this.state.name,
                email: this.state.email,
                message: this.state.my_message,
                to: "cardudis.service@gmail.com",
                date: new Date(),
                id: messageId,
                didRead: false,
            }
        }

        this.updateCardMessageList(message)

        var testingMode = false

        if (!testingMode) {
            this.setState({
                error: "",
                isLoading: true
            })
            axios.post(SERVER_SIDE_BASE_URL + 'mailer', data)
                .then(res => {
                    this.setState({
                        sent: true,
                        error: "",
                        isLoading: false
                    })
                    this.resetForm();  // should take this down and test
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        error: this.props.t("CouldNotEmail")
                    })

                })
            this.setState({ sent: true }, this.resetForm())
        }

    }

    updateCardMessageList(message) {
        if (this.props.cardID) {
            const firestore = getFirestore();
            firestore.collection('cardudis').doc("homeMessages").set({
                "messages": message
            }, { merge: true }).then(() => {
                console.log("succes in dispatch");

            }).catch(err => {
                console.log("error in dispatch");

            });
        }

    }
    resetForm = () => {
        this.setState({
            name: '',
            email: '',
            my_message: '',
            phoneNumber: '',

        })

    }

    render() {
        const { t, i18n } = this.props
        return (
            <section id="message">
                {this.state.isLoading ?

                    <LoaderView text={t("SendingEmailTo", { value: t("CardUdis") }) + "..."} />
                    : null}
                <div className="container">
                    <div className="row">
                        <div className="col m12 s12">
                            <div className="message_content">
                                <div
                                    className="message_heading_text center-content">
                                    <h2 data-aos="zoom-in"
                                        data-aos-duration="2000"
                                        data-aos-offset="-50"
                                        data-aos-once="true"
                                        dir="auto">{t("GetInTouch")}</h2>
                                    <p dir="auto" className="whiteTextVarela"
                                        style={{ whiteSpace: "pre-wrap", fontSize: 24 }}>{t("GetInTouchDescription")}</p>
                                </div>
                                {this.state.sent ?
                                    <h2 className="center" dir="auto">{getContactFormInstructionsForLanguage(i18n.language, "messageSent")}</h2> :
                                    <form onSubmit={this.formSubmit} id="formid">

                                        <div className="col s12">

                                            <div className="form-group">
                                                <input dir="auto" type="text" id="name" className="form-control browser-default" name="name" placeholder={t("FirstName")} required
                                                    onChange={e => this.setState({ [e.target.id]: e.target.value })} />
                                            </div>

                                            <div className="form-group">
                                                <input dir="auto" type="email" id="email" className="form-control browser-default" name="email" placeholder={t("Email")} required
                                                    onChange={e => this.setState({ [e.target.id]: e.target.value })} />
                                            </div>

                                            <div className="form-group">
                                                <textarea className="browser-default form-control "
                                                    id="my_message"
                                                    name="message"
                                                    dir="auto"
                                                    rows="8"
                                                    placeholder={t("Message")}
                                                    style={{ backgroundColor: "white", height: "100px" }}
                                                    required
                                                    onChange={e => this.setState({ [e.target.id]: e.target.value })}></textarea>
                                            </div>


                                            <div className="center-content">
                                                <input type="submit" value={t("SendMessage")} className="btn larg-btn curdudis_button" />
                                            </div>
                                            <div dir="auto" className="center" style={{ color: "red", fontSize: 24, marginTop: 10 }}>
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </section>


        )
    }
}
export default withTranslation()(ContactCardudisFormComponent)
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom"; // For linking

export class Tabs extends React.Component {
    state = {
        activeTab: this.getlastSelectedTab()
    };

    componentDidMount() {
        document.body.classList.add('cardudisBG');

    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab.id });
        this.setSelectedTab(tab.id)
    };

    // local storage 

    setSelectedTab = (tab) => {
        localStorage.setItem('lastSelectedTab', tab);
    }

    getlastSelectedTab() {
        var selectedOption = localStorage.getItem('lastSelectedTab') || "1";
        return selectedOption
    }

    render() {
        const { t, cardId } = this.props;
        let content;
        let buttons = [];
        const list = {
            "1": t("TopArea"),
            "2": t("Rounded"),
            "3": t("Rectangles"),
            "4": t("Gallerys"),
            "5": t("Video"),
            "6": t("Shares"),
            "7": t("Shop"),
        }

        return (
            <div>

                {React.Children.map(this.props.children, (child) => {
                    if (child) {
                        buttons.push(
                            {
                                id: child.props.id,
                                value: list[child.props.id]
                            }
                        );
                        if (child.props.id === this.state.activeTab)
                            content = child.props.children;
                    }

                })}

                <TabButtons
                    activeTab={this.state.activeTab}
                    buttons={buttons}
                    changeTab={this.changeTab}
                />
                <div className="center">
                    <NavLink to={"/vip/" + cardId}  ><div dir="auto"> {t("ClickWatchCard")} {cardId}</div></NavLink>
                </div>
                <div className="tab-content">{content}</div>
            </div>
        );
    }
}



const TabButtons = ({ buttons, changeTab, activeTab }) => {
    return (
        <div className="tab-buttons">
            {buttons.map((button) => {
                return (
                    <tab_button
                        className={button.id === activeTab ? "activeTab" : ""}
                        onClick={() => changeTab(button)}
                    >
                        {button.value}
                    </tab_button>
                );
            })}
        </div>
    );
};
export default withTranslation()(Tabs)

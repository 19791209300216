import React, { useEffect } from "react";
const AboutCollapseCustomEditorComponent = ({ button, didClick }) => {
    useEffect(() => {
        console.log("use effect called");
        const M = window.M;
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});
    });

    const iconSize = button.iconSize;
    let style = {
        width: "100%",
        textAlign: "justify",
        color: button.textColor,
        backgroundColor: button.color,
        fontFamily: button.fontName,
        fontSize: button.fontSize,
        margin: 0,
        textTransform: "none",
        // padding: "0px 14px"
    }

    let iconStyle = {
        color: button.iconColor,
        fontSize: iconSize,
        width: iconSize + 10,
        margin: 0,
        textAlign: "center"
    }

    return (
        <ul className="collapsible "
            style={{ marginBottom: "10px", marginRight: 10, marginLeft: 10, marginTop: 0 }}>
            <li>
                <div dir="auto" style={{
                    backgroundColor: button.color,
                    borderWidth: button.borderWidth,
                    borderStyle: "solid",
                    borderColor: button.borderColor,
                    padding: 0
                }} className="collapsible-header">

                    <a className="waves-effect waves-light btn-large "
                        style={{ width: "100%", padding: 0 }}
                        onClick={() => didClick()}>
                        <div dir="auto" style={style}>
                            <i className={`${button.icon} `}
                                aria-hidden="true"
                                style={iconStyle}
                            ></i>
                            {button.title}
                        </div>
                    </a>
                </div>
                {/* <p>dasdsadsa</p>
                <p>dsadsadsa</p>
                <p style=\"text-align: left;\">dsadsadsadsa</p>
                <p style=\"text-align: right;\">dsadsadasdasa</p>
                <p style=\"text-align: right;\"><br></p> */}
                <div dir="auto"
                    className="center collapsible-body myCollapseBody"
                    style={{
                        backgroundColor: button.color,
                        borderWidth: button.borderWidth,
                        borderStyle: "solid",
                        borderColor: String(button.borderColor),
                        padding: "0px",
                    }}>
                    <div dangerouslySetInnerHTML={{ __html: button.text ? button.text : null }} />

                </div>

            </li>
        </ul >
    );
};
export default AboutCollapseCustomEditorComponent;


import React, { Component } from 'react'
import { connect } from "react-redux"
import TextInputHideTextBgColorEditComponent from './TextInputHideTextBgColorEditComponent'
import TextInputComponent from '../../../InputComponents/TextInputComponent'
import TextInputAreaComponent from '../../../InputComponents/TextInputAreaComponent'
import LoaderView from '../../../SpinnerLoader/LoaderView';
import { updateShareButtonsSettings, updateSeoData } from '../../../../store/actions/cardActions'
import FontListSelectComponent from '../../FontListSelectComponent'
import CheckboxComponent from '../../CheckboxComponent'
import NumberStepperComponent from '../../NumberStepperComponent'
import ColorPickerComponent from '../ColorPickerComponent'
import { SERVER_SIDE_BASE_URL } from '../../../../Utils/Constants'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

export class ShareEditComponent extends Component {
    state = {
        shares: this.props.reduxSelectedCard.shares,
        seo: this.props.reduxSelectedCard.seo,
        pickerShow: false
    }

    componentDidMount() {
        console.log("did mount ShareEditComponent");
    }

    saveChanges() {
        this.props.updateShareButtonsSettings(this.state.shares)
        this.setState({
            didMakeChange: false
        });
    }

    saveSeoChanges() {
        this.props.updateSeoData(this.state.seo)
        this.setState({
            didMakeChange: false
        });
    }

    handlePickerOpen = (e) => {
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }

    handleChange = (e) => {
        this.setState({
            shares: {
                ...this.state.shares,
                [e.target.id]: e.target.value,
            },
            didMakeChange: true,
            didMakeSeoChange: true,
        })
    }

    handleSeoChange = (e) => {
        this.setState({
            seo: {
                ...this.state.seo,
                [e.target.id]: e.target.value,
            },
            didMakeSeoChange: true,
        })
    }

    render() {
        const { i18n, t, reduxSelectedCard, sharesEditComponent } = this.props
        var styleButton = {
            backgroundColor: this.state.shares.textColor,
            width: "38px",
            height: "38px",

        }
        const bootomFixedForUpdateButton = {
            position: 'fixed',
            zIndex: '2',
            backgroundColor: "red",
            height: "40px",
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (

            <div className="container">
                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>

                    <h5 className="">{t("ShareArea")}</h5>
                    <div dir="auto">{t("YourShareLink")}:
                        <div>
                            <a href={SERVER_SIDE_BASE_URL + "vip/" + reduxSelectedCard.id}>{SERVER_SIDE_BASE_URL + "vip/" + reduxSelectedCard.id}</a>
                        </div>
                    </div>
                    <CheckboxComponent checked={this.state.shares.hideAllShare} title={this.props.t("HideShareArea")} selected={(checked) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.shares.hideAllShare = checked
                            return state
                        })
                    }} />

                    {!this.state.shares.hideTitle ?
                        <div className="center"
                            style={{
                                width: "100%",
                                fontFamily: this.state.shares.fontName,
                                color: this.state.shares.textColor,
                                fontSize: this.state.shares.fontSize
                            }}>
                            {this.state.shares.title}
                        </div>
                        : null}

                    {this.state.pickerShow ?
                        <ColorPickerComponent
                            color={this.state.shares.textColor}
                            closePicker={() => {
                                this.setState({
                                    pickerShow: false
                                })
                            }}
                            pickerChanged={(color) => {
                                this.setState(state => {
                                    state.didMakeChange = true
                                    this.state.shares.textColor = color
                                    return state
                                })
                            }}
                        />
                        : null}

                    {sharesEditComponent.isLoading ?
                        <LoaderView text={sharesEditComponent.loadingTitle} />
                        : null}

                    <div className="input-field">
                        <div className="edit_button_card_subtitle" dir="auto">{t("Title")}</div>
                        <input dir="auto" className="browser-default edit_button_card_input" type="text" id='title' onChange={this.handleChange} required
                            placeholder={t("EnterShareButtonsTitle")} value={this.state.shares.title} />
                        <CheckboxComponent
                            checked={this.state.shares.hideTitle}
                            title={t("HideTitle")} selected={(checked) => {
                                this.setState(state => {
                                    state.didMakeChange = true
                                    state.shares.hideTitle = checked
                                    return state
                                })
                            }} />
                    </div>

                    <div className="roundedButtons">
                        <ul>
                            <li style={{ width: "50px" }}>
                                <div style={{ width: "50px" }}>
                                    <a href="#"
                                        className="btn-floating btn waves-effect waves-light "
                                        style={styleButton}
                                        onClick={(e) => this.handlePickerOpen(e)}
                                    >
                                        <i className="fa fa-font iconCenter" aria-hidden="true"
                                            style={{ color: "white", fontSize: "16px" }}></i>
                                    </a>
                                    <br />
                                    <span style={{ fontSize: "12px", style: "bold", color: this.state.shares.textColor, display: "block", marginTop: "4px" }}>
                                        {t("Text")}
                                    </span>
                                </div>
                            </li>
                            <li>

                                <FontListSelectComponent fontName={this.state.shares.fontName} selectedFont={(fontName) => {
                                    console.log("font selected");
                                    this.setState(state => {
                                        state.didMakeChange = true
                                        this.state.shares.fontName = fontName
                                        return state
                                    })
                                }} />
                            </li>
                            <li>
                                <NumberStepperComponent
                                    value={this.state.shares.fontSize ? this.state.shares.fontSize : 14}
                                    max={60}
                                    min={10}
                                    step={1}
                                    title={t("FontSize")}
                                    didMakeChange={(value) => {
                                        this.setState(state => {
                                            state.didMakeChange = true
                                            this.state.shares.fontSize = value
                                            return state
                                        })
                                    }} />
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                    <h5 className="" style={{ color: this.state.shares.sms.buttonColor }}>{t("sms")}</h5>
                    <TextInputHideTextBgColorEditComponent share={this.state.shares.sms} inputDescription={t("TextForShare") + ":"} colorPickerDecsription={t("ButtonColor")} didMakeChange={(target, value) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.shares.sms[target] = value
                            return state
                        })
                    }} />
                </div>
                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                    <h5 className="" style={{ color: this.state.shares.email.buttonColor }}>{t("Email")}</h5>
                    <TextInputHideTextBgColorEditComponent share={this.state.shares.email} inputDescription={t("TextForShare") + ":"} colorPickerDecsription={t("ButtonColor")} didMakeChange={(target, value) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.shares.email[target] = value
                            return state
                        })
                    }} />
                </div>
                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                    <h5 className="" style={{ color: this.state.shares.facebook.buttonColor }}>{t("Facebook")}</h5>
                    <TextInputHideTextBgColorEditComponent share={this.state.shares.facebook} inputDescription={t("TextForShare") + ":"} colorPickerDecsription={t("ButtonColor")} didMakeChange={(target, value) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.shares.facebook[target] = value
                            return state
                        })
                    }} />
                </div>
                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                    <h5 className="" style={{ color: this.state.shares.whatsapp.buttonColor }}>{t("Whatsapp")}</h5>
                    <TextInputHideTextBgColorEditComponent share={this.state.shares.whatsapp} inputDescription={t("TextForShare") + ":"} colorPickerDecsription={t("ButtonColor")} didMakeChange={(target, value) => {
                        this.setState(state => {
                            state.didMakeChange = true
                            state.shares.whatsapp[target] = value
                            return state
                        })
                    }} />
                </div>

                <div className="edit_button_card_container" style={{ backgroundColor: "white" }}>
                    <h5 className="" style={{ color: "black" }}>{t("SEO")}</h5>
                    <div style={{ margin: 10 }}>
                        <TextInputAreaComponent
                            title={t("Keywords")} //{this.props.t("ShopCategoryName")}
                            value={this.state.seo ? this.state.seo.keywords : ""}
                            textId="keywords"
                            handleChange={(e) => this.handleSeoChange(e)}
                        />
                    </div>

                    <div style={{ margin: 10 }}>
                        <TextInputAreaComponent
                            title={t("Description")} //{this.props.t("ShopCategoryName")}
                            value={this.state.seo ? this.state.seo.description : ""}
                            textId="description"
                            handleChange={(e) => this.handleSeoChange(e)}
                        />
                    </div>
                    {this.state.didMakeSeoChange ?
                        <div >
                            <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeSeoChange ? "" : "disabled")}
                                onClick={() => this.saveSeoChanges(null)} >Save SEO Changes
                                <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                            </button>
                        </div> : null}
                </div>

                {this.state.didMakeChange ?
                    <div style={bootomFixedForUpdateButton}>
                        <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")} onClick={() => this.saveChanges(null)} >Save Changes
                            <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                        </button>
                    </div> : null}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateShareButtonsSettings: (shares) => dispatch(updateShareButtonsSettings(shares)),
            updateSeoData: (seo) => dispatch(updateSeoData(seo))
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        sharesEditComponent: state.redux.sharesEditComponent
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ShareEditComponent)

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// data base rules examples
//  https://medium.com/@juliomacr/10-firebase-realtime-database-rule-templates-d4894a118a98
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyC9Wi8LeKw5tB3we2s9BqPVU5YBQ1IGfBs",
    authDomain: "cardudis.firebaseapp.com",
    databaseURL: "https://cardudis.firebaseio.com",
    projectId: "cardudis",
    storageBucket: "cardudis.appspot.com",
    messagingSenderId: "1072300322879",
    appId: "1:1072300322879:web:413ba92169c56560a8488a",
    measurementId: "G-ZPEPTX964L"
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});
const storage = firebase.storage();
export {
    storage, firebase as default
}
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // For linking
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import SignedInLinks_SideMenu from './SignedInLinks_SideMenu'
import SignedOutLinks_SideMenu from './SignedOutLinks_SideMenu'
import logoTop from '../../images/Logos/logoTop.png'
import { connect } from 'react-redux'
import { buildID } from '../../Utils/Constants'

const Navbar = (props) => {
    console.log("navbar");
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const M = window.M;
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    });

    const { auth, profile } = props;
    const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
    const links_sideMenu = auth.uid ? <SignedInLinks_SideMenu profile={profile} /> : <SignedOutLinks_SideMenu profile={profile} />;
    return (
        <nav className="nav-wrapper myNavBar sticky">
            <span>
                <a className='sidenav-trigger ' href='#' data-target='mobile-links'>
                    <i className="fa fa-bars" aria-hidden="true" style={{ fontSize: 30 }}></i>
                </a>
                <div className="container" >
                    <Link to={{
                        pathname: '/dashboard',
                        state: {
                            fromInside: true
                        }
                    }} className="brand-logo">

                        <img style={{ height: 55, marginTop: 2 }} className="responsive-img" src={logoTop} alt={"כרטיס ביקור דיגיטלי"} />
                    </Link>
                    {links}
                </div>

                {links_sideMenu}

            </span>
            <span style={{ fontSize: 10 }}>  v:{buildID} </span>
        </nav>
    );
};


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps, null)(Navbar)
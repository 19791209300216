import React, { Component } from 'react'
import { connect } from "react-redux"
import { storage } from '../../../../config/fbConfig'
import { getFirestore } from 'redux-firestore'
import DragAndDropImageComponent from "../../DragAndDropImageComponent"
import ImageGalleryWithSelection from '../../../ImageSelectorComponent/ImageGalleryWithSelection'
import { getResizedImageForGallery } from '../../../../Utils/UtilMethods'
import LoaderView from '../../../SpinnerLoader/LoaderView'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
export class EditCardImages extends Component {

    serverProccessDescription = ""
    state = {
        ...this.props,
        selectedImageFilesGallery1ToUpload: [],
        selectedImageFilesGalleryIndexsToDelete: [],
        isUploadingNow: false
    }

    componentDidMount() {
        const M = window.M;
    }

    updateGallaryUrlList = (gallery, callback) => {
        const firestore = getFirestore();
        firestore.collection('cards').doc(this.props.reduxSelectedCard.id).update({
            [`gallerys.${this.props.galleryId}.list`]: gallery.list,
        }).then(() => {
            callback(true)
            console.log("succes in dispatch");
        }).catch(err => {
            callback(true)
            // this.props.reloadParentState()
            console.log("error in dispatch");

        });
    }

    dropzoneUpdate = (selectedFilesUrl) => {

        console.log("dropzone update");

        var imagesSelected = this.state.selectedImageFilesGallery1ToUpload

        for (const index in selectedFilesUrl) {
            imagesSelected.push(selectedFilesUrl[index]);
        }

        this.setState({
            selectedImageGalleryToUpload: imagesSelected
        })
    }

    getImagePathList = () => {
        console.log("getImagePathList");

        var imagesSelected = []
        var fileArray = this.state.selectedImageFilesGallery1ToUpload
        for (const index in fileArray) {
            const image = fileArray[index];
            const imagePath = URL.createObjectURL(image);

            imagesSelected.push(imagePath);

        }
        return imagesSelected
    }

    // selectedFilesToDeleteUpdate = (selected) => {
    //     this.setState({
    //         selectedImageFilesGalleryIndexsToDelete: selected
    //     })
    // }

    deleteSelectedFiles = () => {
        let selected = this.state.selectedImageFilesGallery1ToUpload
        let indexs = this.state.selectedImageFilesGalleryIndexsToDelete

        for (let index = indexs.length - 1; index >= 0; index--) {
            let indexToRemove = indexs[index];
            selected.splice(indexToRemove, 1)
        }

        this.setState({
            selectedImageFilesGalleryIndexsToDelete: []
        })
    }


    handleUpload = (e) => {
        if (this.state.selectedImageFilesGallery1ToUpload.length > 0 && !this.state.isUploadingNow) {
            this.setState({ isUploadingNow: true })
        }

        getResizedImageForGallery(this.state.selectedImageFilesGallery1ToUpload[0], (blob, imageName) => {
            const uploadTask = storage.ref(`${this.props.auth.email}/${this.props.reduxSelectedCard.id}/gallerys/${this.props.galleryId}/${imageName}`).put(blob);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    console.log("progress", progress);
                },
                error => {
                    console.log(error);
                },
                () => {
                    storage
                        .ref(`${this.props.auth.email}/${this.props.reduxSelectedCard.id}/gallerys/${this.props.galleryId}`)
                        .child(imageName)
                        .getDownloadURL()
                        .then(url => {
                            let gallery = JSON.parse(JSON.stringify(this.props.reduxSelectedCard.gallerys.list[this.props.galleryIndex]));
                            gallery.list.push(url)
                            this.props.updateGalleryList(gallery)
                            this.setState({ isUploadingNow: false })
                            var listFiles = this.state.selectedImageFilesGallery1ToUpload
                            listFiles.splice(0, 1);
                            this.setState({
                                ...this.state,
                                selectedImageFilesGallery1ToUpload: listFiles
                            });
                            if (listFiles.length > 0) {
                                this.handleUpload()
                            }
                            else {
                                this.setState({
                                    selectedImageFilesGalleryIndexsToDelete: [],
                                    selectedImageGalleryToUpload: [],
                                    isUploadingNow: false,
                                })
                            }
                        })
                }
            );
        });


    };

    render() {
        const { i18n, t, galleryId } = this.props;
        console.log("state values", this.state);

        return (
            <div>
                {this.state.isUploadingNow ?
                    <LoaderView text={t("UploadingImages")} />
                    : null}

                <h5>{t("UploadNewImages")}</h5>
                <DragAndDropImageComponent filesSelected={selectedFilesUrl => {
                    // set grid view callback
                    console.log("selection");
                    if (selectedFilesUrl) {
                        this.dropzoneUpdate(selectedFilesUrl)
                    }
                }
                }
                />
                {this.state.selectedImageFilesGallery1ToUpload.length ?
                    <div>
                        <ImageGalleryWithSelection images={this.getImagePathList()} imagesSelected={this.state.selectedImageFilesGalleryIndexsToDelete}
                            selectedIndexes={selected => {
                                // set grid view callback
                                console.log("selection of files", selected);
                                this.setState({
                                    selectedImageFilesGalleryIndexsToDelete: selected
                                })
                            }} />

                        <button className="btn blue lighten-1 z-depth-0" onClick={this.handleUpload}>{t("UploadAll")}</button>
                        {this.state.selectedImageFilesGalleryIndexsToDelete.length ?
                            <button className="btn red lighten-1 z-depth-0" onClick={this.deleteSelectedFiles}>{t("DeleteSelectedFiles")}</button>
                            :
                            null}
                    </div>
                    : null}


            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return (
        {
            addUrlToGalleryList: (gallery, galleryId) => { dispatch({ type: "ADD_URL_TO_GALLARY_LIST", gallery, galleryId }) }
        })
}

const mapStateToProps = (state, ownProps) => {

    return {
        auth: state.firebase.auth,
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EditCardImages)

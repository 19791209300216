import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

import { AlertOkComponent } from '../../../AlertsComponents/AlertsComponent'
import LoaderView from '../../../SpinnerLoader/LoaderView'
import BackgroundEditComponent from './BackgroundEditComponent'
import CardAnimation from './CardAnimation'
import DescriptionComponent from './DescriptionComponent'
import HeaderImageEditComponent from './HeaderImageEditComponent'
import LogoImageEditComponent from './LogoImageEditComponent'
import { updateDescription, updateAnimation } from '../../../../store/actions/cardActions'


export class TopAreaEditorContainer extends Component {

    state = {
        isLoading: false,
        loadingTitle: ""
    }

    saveDescriptionChanges(description) {
        this.props.updateDescription(this.props.reduxSelectedCard.id, description)
    }

    saveAnimationChanges(animationStyle) {
        var animation = this.state.animation

        if (animation) {
            animation.style = animationStyle
        }
        else {
            animation = {
                style: animationStyle
            }
        }
        // let data = { title: this.state.title, subtitle: this.state.description.subtitle }
        this.props.updateAnimation(animation)
    }

    render() {
        const { t, auth, descriptionComponent } = this.props;
        if (!auth.uid) return <Redirect to='/' />
        return (
            <div>
                {this.state.showAlert ?
                    < AlertOkComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={() => {
                        this.setState({
                            showAlert: false
                        })
                    }} />
                    : null}

                {descriptionComponent.isLoading ?
                    <LoaderView text={descriptionComponent.loadingTitle} />
                    : null}

                <CardAnimation
                    animation={this.props.reduxSelectedCard.animation}
                    saveChanges={(animationStyle) => {
                        this.saveAnimationChanges(animationStyle)
                    }
                    } />

                <HeaderImageEditComponent />
                <LogoImageEditComponent />
                <BackgroundEditComponent />
                <DescriptionComponent
                    description={this.props.reduxSelectedCard.description} saveChanges={(description) => {
                        this.saveDescriptionChanges(description)
                    }}
                    descriptionComponent={descriptionComponent} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        auth: state.firebase.auth,
        descriptionComponent: state.redux.descriptionComponent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateDescription: (cardId, data) => dispatch(updateDescription(cardId, data)),
        updateAnimation: (animation) => dispatch(updateAnimation(animation)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps))(TopAreaEditorContainer)
import React from 'react'

export default function HeaderCols() {
    return (
        <div className="row center "
            style={{ margin: 0 }}
        >
            <div className="col s2 m2 l3 noPadding">
                תמונה
        </div>
            <div className="col s4 m4 l2 noPadding">
                תיאור
        </div>
            <div className="col s1 m1 l2 noPadding">
                מחיר
        </div>

            <div className="col s3 m3 l3 noPadding">
                כמות
         </div>

            <div className="col s2 m2 l2 noPadding">
                סה״כ
                    </div>
        </div>
    )
}

import React, { Component } from 'react'
import ReactPlayer from 'react-player/youtube'


export default class TopImage extends Component {
    state = {
        width: 0, height: 0,
        isFromDashboard: this.props.isFromDashboard,
        showVideo: false,
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        var element = document.getElementById('imageBorder');
        if (element != null) {
            var positionInfo = element.getBoundingClientRect();
            var height = positionInfo.height;
            var width = positionInfo.width;
            this.setState({
                divWidth: width, divHeight: height
            });
        }
    };

    handleVideoStarted = () => {
        console.log("on start video");
        if (this.props.header.userShowVideo && !this.props.isFromEditor) {
            this.setState({ showVideo: true })
        }
    }

    render() {
        // console.log("------TOP HEADER-----------");
        // console.log("window width", this.state.width);
        // console.log("div width", this.state.divWidth);
        // console.log("div height", this.state.divHeight);
        var height = this.state.isFromDashboard ? 200 : this.state.width / 2.2;
        height = height > 300 ? 300 : height
        console.log("div calculated height", height);
        var makebigger = 130
        if (this.props.hideTopImage) {
            makebigger = 0
        }

        const { header } = this.props

        var opacityOfTopImage;
        var transition = null
        if (!this.state.showVideo) {
            // style = { opacity: 0, marginTop: 15, display: "block" }
            opacityOfTopImage = 1

        }
        else {
            // style = { opacity: 1, marginTop: logo.marginTop ? logo.marginTop : -25, display: "block", transition: "all 2s" }
            opacityOfTopImage = 0
            transition = "all 2s"
        }

        return (
            <div
                style={{}}>
                {
                    header.hide ?
                        null
                        :
                        <div
                            style={{ position: header.userShowVideo ? "relative" : null }}>
                            <div style={{
                                borderBottomWidth: header.bottomBorderWidth ? header.bottomBorderWidth : 0,
                                borderBottomStyle: "solid",
                                borderBottomColor: header.bottomBorderColor,
                                zIndex: "-2",
                                opacity: this.props.hideTopImage === true ? 0 : opacityOfTopImage,
                                transition: transition,
                                // border: `${header.bordetWidth}`,
                                // borderColor: header.borderColor,
                                backgroundImage: "url(" + header.url + ")",
                                backgroundRepeat: 'no-repeat',
                                // height: header.height,
                                height: this.state.isFromDashboard ? 200 : this.state.width / 2.2,
                                maxHeight: 300,
                                minHeight: header.minHeight,
                                width: "100%",
                                marginBottom: "10px",
                                marginTop: header.marginTop,
                                backgroundPosition: "top",
                                // backgroundSize: "300px"
                                backgroundSize: header.style // cover - cool 
                                // backgroundSize: "contain"
                                // backgroundSize: "100% 100%" // streach
                                // backgroundSize: "length" // center

                            }}>

                            </div>
                            {
                                this.props.header.userShowVideo && this.props.isFromEditor === false ?
                                    <div id="navi">
                                        <div
                                            id="imageBorder"
                                            style={{
                                                overflow: "hidden",
                                                height: this.state.isFromDashboard ? 200 : this.state.width / 2.2,
                                                maxHeight: 300,
                                                minHeight: header.minHeight,
                                                width: "100%",
                                                top: 0,
                                            }}>

                                            <div className="reactPlayer"
                                                style={{
                                                    height: height + makebigger,
                                                    width: this.state.divWidth + makebigger,
                                                    top: -(makebigger / 2), left: -(makebigger / 2),
                                                    position: "relative",
                                                    //   zIndex: this.state.isFromDashboard ? null : "-1",

                                                }}>
                                                <ReactPlayer
                                                    config={{
                                                        youtube: {
                                                            playerVars: { showinfo: 0 }
                                                        },
                                                    }}
                                                    ref={this.ref}
                                                    className='react-player'
                                                    width='100%'
                                                    height='100%'
                                                    url={header.videoUrl}
                                                    pip={false}
                                                    playing={true}
                                                    controls={false}
                                                    // light={light}
                                                    loop={true}
                                                    // playbackRate={playbackRate}
                                                    // volume={volume}
                                                    muted={true}
                                                    onReady={() => console.log('onReady')}
                                                    onStart={this.handleVideoStarted}
                                                    onPlay={this.handlePlay}
                                                    onEnablePIP={this.handleEnablePIP}
                                                    onDisablePIP={this.handleDisablePIP}
                                                    onPause={this.handlePause}
                                                    onBuffer={() => console.log('onBuffer')}
                                                    onSeek={e => console.log('onSeek', e)}
                                                    onEnded={this.handleEnded}
                                                    onError={e => console.log('onError', e)}
                                                    onProgress={this.handleProgress}
                                                    onDuration={this.handleDuration}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }
            </div>
        )
    }
}


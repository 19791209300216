import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"; // For linking
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { useTranslation } from 'react-i18next';
import LanguageSelectorComponent from "../LanguageSelectorComponent";

const SignedOutLinks_SideMenu = (props) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {

    });


    return (
        <span>
            {/* <a className='sidenav-trigger' href='#' data-target='mobile-links'>
                <i className="fa fa-bars" aria-hidden="true" style={{ fontSize: 30 }}></i>
            </a> */}

            <ul className="sidenav mySideNavBar " id="mobile-links" >

                <li >
                    <div className="whiteTextVarela">
                        <NavLink className="sidenav-close" to="/signin"> {t("LogIn")}</NavLink>
                    </div>
                </li>

                <li>
                    <div className="whiteTextVarela">
                        <NavLink className="sidenav-close" to="/signup">{t("SignUp")}</NavLink>
                    </div>
                </li>
                {/* <li >
                    <LanguageSelectorComponent />
                </li> */}

            </ul>

        </span>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedOutLinks_SideMenu)

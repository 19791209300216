import React from 'react'

export const CardDescriptionComponent = ({ description, animations, isFromDashboard }) => {

    var animation = {

        animation: "",
        0: "",
        1: "",
        // delay: (index) * 200,
        duration: 1000,
        offset: "-50",
        once: "true",
        delay: 0
    }

    if (animations) {
        animation.animation = animations.animation
        animation[0] = animations[0]
        animation[1] = animations[1]
        animation.duration = animations.duration
        animation.offset = animations.offset
        animation.once = animations.once
        animation.delay = animations.delay
    }
    console.log("html is : ", description)
    return (


        <div className="center" style={{ marginBottom: "10px", marginLeft: 5, marginRight: 5, }}>

            {
                description.title.hide ? null :
                    <div style={{
                        color: description.title.color,
                        fontSize: description.title.fontSize,
                        fontFamily: description.title.fontName,
                        marginTop: description.title.marginTop,
                    }}
                        data-aos={animation[0]}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay * 1}
                    >
                        {description.title.text}
                    </div>
            }
            {
                description.subtitle.hide ? null :
                    <div style={{
                        color: description.subtitle.color,
                        fontSize: description.subtitle.fontSize,
                        fontFamily: description.subtitle.fontName,

                        marginTop: description.subtitle.marginTop,
                        // lineHeight: 1
                    }}
                        data-aos={animation[1]}
                        data-aos-duration={animation.duration}
                        data-aos-offset={animation.offset}
                        data-aos-once={animation.once}
                        data-aos-delay={animation.delay * 2}>
                        {description.subtitle.text}
                    </div>
            }
            <div dir="auto">
                {
                    isFromDashboard ? null :
                        <div>
                            {
                                description.htmlDescription ?
                                    <div>
                                        {
                                            !description.htmlDescription.hide ?
                                                <div style={{ marginTop: description.htmlDescription.marginTop ? description.htmlDescription.marginTop : 0 }}>
                                                    <div dangerouslySetInnerHTML={{ __html: description.htmlDescription.text }} />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                }
            </div>
        </div >
    )
}

export default CardDescriptionComponent

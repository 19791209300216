import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import RoundButtonBorderTextIcon from '../Buttons/RoundButtonBorderTextIcon'
import TextInputComponent from './TextInputComponent'
import FontListSelectComponent from '../user_cards/FontListSelectComponent'
import NumberStepperComponent from '../user_cards/NumberStepperComponent'
import ColorPickerComponent from '../user_cards/Editors/ColorPickerComponent';
import CenterText from '../View_Text/CenterText';

export default function TextInputComponentWithStyle({ titleInput,
    textInput,
    textColor,
    fontName,
    fontSize,
    marginTop,
    handleChange,
    showPreview = true }) {
    const { t } = useTranslation();
    const [pickerShow, setPickerShow] = useState(false)

    function handlePickerOpen(target, e) {
        e.preventDefault()
        // this.selectedTargetName = target
        setPickerShow(true)

    }

    return (
        <div>

            {pickerShow ?
                <ColorPickerComponent
                    color={textColor}
                    closePicker={() => {
                        setPickerShow(false)
                    }}

                    pickerChanged={(color) => {
                        var event = {
                            target: {
                                id: "textColor",
                                value: color
                            }
                        }
                        handleChange(event)
                    }}
                />
                :
                null}
            <div className="edit_button_card_container" style={{}}>
                {
                    showPreview ?
                        <CenterText
                            textColor={textColor}
                            fontSize={fontSize}
                            fontFamily={fontName}
                            text={textInput}
                            marginTop={marginTop}
                        />
                        : null
                }


                <TextInputComponent
                    title={titleInput}
                    value={textInput}
                    textId="title"
                    handleChange={(e) => {
                        var event = {
                            target: {
                                id: "text",
                                value: e.target.value
                            }
                        }
                        handleChange(event)
                    }

                    }
                />

                <div className="roundedButtons">
                    <ul >
                        <li style={{ width: "50px" }}>
                            <div >
                                <RoundButtonBorderTextIcon
                                    text={t("Text")}
                                    iconName={"fa fa-font"}
                                    color={textColor}
                                    iconColor="white"
                                    backgroundColor={textColor}
                                    buttonClick={(e) => handlePickerOpen("color", e)}
                                />
                            </div>
                        </li>
                        {/* <li style={{ width: "50px" }}>
                        <div >
                            <RoundButtonBorderTextIcon
                                text={t("Border")}
                                iconName={"fa fa-square-o"}
                                color={category.borderColor}
                                iconColor="white"
                                backgroundColor={category.borderColor}
                                buttonClick={(e) => this.handlePickerOpen("borderColor", e)}
                            />
                        </div>
                    </li> */}

                        <li style={{ width: "50px", marginTop: 6 }}>
                            <FontListSelectComponent
                                fontName={fontName}
                                selectedFont={(fontName) => {
                                    var event = {
                                        target: {
                                            id: "fontName",
                                            value: fontName
                                        }
                                    }
                                    handleChange(event)
                                }} />
                        </li>

                        {/* <li style={{ width: "50px" }}>
                        <div>
                            <RoundButtonBorderTextIcon
                                text={t("DeleteCategory")}
                                iconName={"fa fa-trash-o"}
                                color={"red"}
                                buttonClick={(e) => categoryDeleteAlert()}
                            />
                        </div>
                    </li> */}

                        <li>
                            <NumberStepperComponent
                                value={fontSize ? fontSize : 32}
                                max={70}
                                min={0}
                                step={1}
                                title={t("FontSize")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "fontSize",
                                            value: value
                                        }
                                    }
                                    handleChange(newValue)
                                }} />
                        </li>
                        <li>
                            <NumberStepperComponent
                                value={marginTop ? marginTop : 0}
                                max={200}
                                min={-200}
                                step={1}
                                title={t("MarginTop")}
                                didMakeChange={(value) => {
                                    let newValue = {
                                        target: {
                                            id: "marginTop",
                                            value: value
                                        }
                                    }
                                    handleChange(newValue)
                                }} />
                        </li>
                        {/* <li>
                        <NumberStepperComponent
                            value={category.borderWidth !== undefined ? category.borderWidth : 2}
                            max={20}
                            min={0}
                            step={1}
                            title={t("BorderWidth")}
                            didMakeChange={(value) => {
                                let newValue = {
                                    target: {
                                        id: "borderWidth",
                                        value: value
                                    }
                                }
                                handleChangeCategorySetting(newValue)
                            }} />
                    </li> */}
                    </ul>
                </div>

            </div>



        </div>
    )
}

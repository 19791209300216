import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

export class SwitchButton extends Component {

    state = {
        checked: false
    }

    handleChange = (e) => {
        this.props.selected(!this.state.checked)
        this.setState({
            checked: !this.state.checked
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div dir="">
                {t("Sort")}
                <div className="switch">
                    <label>
                        {t("Off_flip")}
                        <input type="checkbox"
                            //   checked={this.state.checked} 
                            onClick={(e) => this.handleChange(e)}
                        />
                        <span className="lever"></span>
                        {t("On_flip")}
                    </label>
                </div>
            </div>
        )
    }
}
export default withTranslation()(SwitchButton)
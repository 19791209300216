import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-react";

export const TextEditor = ({ handleChange, text }) => {
    const [config, setConfig] = useState({
        readonly: false,
        toolbar: true,
    })



    const handleBlurAreaChange = () => {
        console.log('Blur')
    };

    return (
        <div dir="auto">
            <JoditEditor
                value={text}
                config={config}
                onChange={(value) => handleChange(value)}
                onBlur={handleBlurAreaChange}
            />

        </div>
    )
}
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import iphoneImage from '../../images/iphone.png'
import logoBottom from '../../images/Logos/logo_bottom.png'

export class HomeTopView extends Component {
    render() {
        const { t } = this.props
        return (
            <div className="  navbar_padding home ">
                <div className="container">
                    <div className="center">
                        <img
                            style={{ marginTop: 25, paddingLeft: 20, paddingRight: 20, maxHeight: 80 }}
                            className="responsive-img"
                            src={logoBottom} alt="" />
                    </div>
                    <div className="row  " style={{ marginBottom: 0 }}>

                        <div className="col s12 m5 center ">
                            <div className="iphoneAllBloack ">
                                <img src={iphoneImage} className="iphone" style={{ marginTop: 10 }} alt={"כרטיס ביקור דיגיטלי"} />
                            </div>
                        </div>
                        <div className="col s12 m7 center navbar_padding"
                            style={{ color: "#263238" }}
                        >
                            <h3 dir="auto"
                                style={{ fontFamily: "Heebo-ExtraBold" }}
                                className="digitalCardHeader"> {t("DigitalCard")}<br />{t("DigitalCardHeader")}</h3>
                            <p dir="auto" style={{ fontSize: 20 }}><b> {t("Welcome")}<br />{t("AboutCardUdis")} </b></p>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
export default withTranslation()(HomeTopView)
import React, { Component } from 'react'

export default class assutaTest extends Component {
    render() {
        return (
            <div>
                {/* IS IOS */}
                {/* <a href="com.assuta://deeplink?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJ1bmlxdWVfbmFtZSI6InRva18wNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJjaWQiOiJlb3FrYXIyU09iRS9IYWZ6citQWkdqdytHalp5dEVZZzRuY0hrMS9LRlovTkdwWFd0VW5MTVpvTThDSUkrNzRLIiwibmJmIjoxNTkzNDE5MzE1LCJleHAiOjE1OTM0MjExNDUsImlhdCI6MTU5MzQxOTMxNiwiaXNzIjoiaHR0cHM6Ly9Eb2NQQS5hc3N1dGEuY28uaWwiLCJhdWQiOiJodHRwczovL0RvY1BBLmFzc3V0YS5jby5pbCJ9.oo6nrh74UCIs0NouiS1KrVDY6esIL-qHQFP2eiY7Q5Y">click deep link</a> */}
                {/* <br /> */}
                {/* is android */}
                {/* <a href="intent://deeplink/#Intent;scheme=com.assuta;package=com.smartx.assuta_doctors;S.token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIwNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJ1bmlxdWVfbmFtZSI6InRva18wNTAwMDgzZC05ZWEyLTRlMDctYmM4Mi0zYTRkYjgzNmE3OGMiLCJjaWQiOiJlb3FrYXIyU09iRS9IYWZ6citQWkdqdytHalp5dEVZZzRuY0hrMS9LRlovTkdwWFd0VW5MTVpvTThDSUkrNzRLIiwibmJmIjoxNTkzNDE5MzE1LCJleHAiOjE1OTM0MjExNDUsImlhdCI6MTU5MzQxOTMxNiwiaXNzIjoiaHR0cHM6Ly9Eb2NQQS5hc3N1dGEuY28uaWwiLCJhdWQiOiJodHRwczovL0RvY1BBLmFzc3V0YS5jby5pbCJ9.oo6nrh74UCIs0NouiS1KrVDY6esIL-qHQFP2eiY7Q5Y;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.smartx.assuta_doctors;end"> click deep link </a> */}
                <a href="intent://deeplink/#Intent;scheme=vayyar-provisioning;package=com.espressif.wifi_provisioning;S.key=https://someurl/someusr/blabla;end"> click here </a>
            </div>
        )
    }
}

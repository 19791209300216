import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateShop } from '../../../../store/actions/cardActions'
import { storage } from '../../../../config/fbConfig'
import arrayMove from 'array-move';
import LoaderView from '../../../SpinnerLoader/LoaderView';
import { AlertOkComponent, AlertOkCancelComponent } from '../../../AlertsComponents/AlertsComponent'
import {
    getResizedImageForForShopItem
} from '../../../../Utils/UtilMethods'
import {
    getBasicShopCategoryObject,
    getBasicShopItemObject
} from '../../../../Utils/ShopUtils'
import TextInputComponentWithStyle from '../../../InputComponents/TextInputComponentWithStyle';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import TopGlobalCustomVideoEditor from '../AudioVideoEditors/TopGlobalCustomVideoEditor'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import ShopCategoryEditor from './ShopCategoryEditor';
import ShopSellerInfo from './ShopSellerInfo';

export class ShopCreateCatAndItems extends Component {

    alertDict = {
        alertTitle: "",
        alertSubTitle: "",
        alertState: "",
        categoryIndex: "",
        itemIndex: ""
    }

    state = {
        ...this.props,
        selectedCategoryIndex: null,
        showAlert: false,
        showAlertOkCancel: false,
        didMakeChange: false,
        disableSorting: true,
    }

    SortableContainer = sortableContainer(({ children }) => {
        return <ul >{children}</ul>;
    });

    SortableItem = sortableElement(({ value, myIndex }) => this.getShopCategoryEditLayout(value, myIndex));


    handleChangeCategorySetting(e, indexCategory) {
        let listCopy = [...this.state.shop.listCategory];
        let category = listCopy[indexCategory]
        category[e.target.id] = e.target.value
        listCopy[indexCategory] = category
        this.setState({
            shop: { ...this.state.shop, listCategory: listCopy },
            didMakeChange: true
        });
    }

    updateCategory(category, indexCategory) {
        let listCopy = [...this.state.shop.listCategory];
        listCopy[indexCategory] = category
        this.setState({
            shop: { ...this.state.shop, listCategory: listCopy },
            didMakeChange: true
        });
    }

    updateSellerInfo(seller) {
        this.setState({
            shop: { ...this.state.shop, seller: seller },
            didMakeChange: true
        });
    }

    updateShopExtraInfo(extras) {
        this.setState({
            shop: { ...this.state.shop, extras: extras },
            didMakeChange: true
        });
    }

    itemDeleteAlert(indexCategory, indexItem) {
        this.alertDict = {
            alertTitle: this.props.t("DeleteItem"),
            alertSubTitle: this.props.t("SureDeleteItem") + "?",
            alertState: "delete_item",
            categoryIndex: indexCategory,
            itemIndex: indexItem
        }

        this.setState({
            showAlertOkCancel: true,
        });
    }

    itemDelete() {
        var category = this.state.shop.listCategory[this.alertDict.categoryIndex]
        this.deleteSelectedFiles(category, this.alertDict.itemIndex)
    }


    uploadImages(imageList, categoryIndex, itemIndex) {
        // currently only one image to upload
        var category = this.state.shop.listCategory[categoryIndex]
        var item = category.items[itemIndex]

        getResizedImageForForShopItem(imageList[0], (blob, imageName) => {
            const uploadTask = storage.ref(`${this.props.auth.email}/${this.props.reduxSelectedCard.id}/shop/${category.id}/${item.id}/${imageName}`).put(blob);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    console.log("progress", progress);
                },
                error => {
                    console.log(error);
                },
                () => {
                    storage
                        .ref(`${this.props.auth.email}/${this.props.reduxSelectedCard.id}/shop/${category.id}/${item.id}`)
                        .child(imageName)
                        .getDownloadURL()
                        .then(url => {
                            console.log("url", url);
                            item.images = [url]
                            category.items[itemIndex] = item
                            this.updateCategory(category)
                            this.saveChanges()

                        })
                }
            );
        });


    }

    deleteSelectedFiles = (category, itemIndex) => {

        if (category.items[itemIndex].images && category.items[itemIndex].images.length > 0) {
            var desertRef = storage.refFromURL(category.items[itemIndex].images[0])

            desertRef.delete().then(() => {
                this.continueToUrlDelete(category);
            }).catch((error) => {
                this.continueToUrlDelete(category);

            });
            return
        }
        this.continueToUrlDelete(category);
    }

    continueToUrlDelete(category) {
        category.items.splice(this.alertDict.itemIndex, 1)
        this.updateCategory(category)
        this.saveChanges()
    }

    alertOkCancelConfirmed(confirm) {

        if (confirm) {
            switch (this.alertDict.alertState) {
                case "delete_item":
                    this.itemDelete()
                    break;

                case "delete_category":
                    this.categoryDelete()
                    this.saveChanges()
                    break;

                default:
                    break;
            }
        }
        this.setState({
            showAlertOkCancel: false,
        });

    }

    getShopCategoryEditLayout(shopCategory, categoryIndex) {

        const cardStyle = {
            border: this.state.disableSorting ? "0px solid" : "3px solid",
            borderColor: "red",
        }

        return (
            <li key={`item-${categoryIndex}`} style={{ listStyleType: "none" }} >
                <div className="edit_button_card_container " style={cardStyle}>
                    <div className="row">
                        <div >
                            <ShopCategoryEditor
                                categoryIndex={categoryIndex}
                                category={shopCategory}
                                handleChangeCategorySetting={(e) => {
                                    this.handleChangeCategorySetting(e, categoryIndex)
                                }}
                                createNewItem={() => {
                                    this.createNewItem(categoryIndex)
                                }}
                                updateCategory={(category) => this.updateCategory(category, categoryIndex)}
                                itemDelete={(itemIndex) => this.itemDeleteAlert(categoryIndex, itemIndex)}
                                uploadImages={(listImages, itemIndex) => this.uploadImages(listImages, categoryIndex, itemIndex)}
                                categoryDeleteAlert={() => {
                                    this.categoryDeleteAlert(categoryIndex)
                                }}
                            />
                        </div>
                    </div>
                </div>

            </li >
        )
    }

    createNewShopCategory() {

        let newCategory = getBasicShopCategoryObject()
        console.log("create new shop category clicked");
        // 1. Make a shallow copy of the items
        var listCopy = [];
        if (!this.state.shop || !this.state.shop.listCategory) {
            listCopy = [newCategory];
        }
        else {
            listCopy = [newCategory, ...this.state.shop.listCategory];
        }

        // 2. Make a shallow copy of the item you want to mutate        
        this.setState({
            shop: { ...this.state.shop, listCategory: listCopy },
            didMakeChange: true
        });

    }

    createNewItem(categoryIndex) {
        let newItem = getBasicShopItemObject()

        var listCopy = this.state.shop.listCategory
        var category = listCopy[categoryIndex];
        category.items = [newItem, ...category.items];
        listCopy[categoryIndex] = category
        this.updateShopCatList(listCopy)
    }

    updateShopCatList(listCopy) {
        this.setState({
            shop: { ...this.state.shop, listCategory: listCopy },
            didMakeChange: true
        });
    }

    categoryDeleteAlert(categoryIndex) {

        this.alertDict = {
            alertTitle: this.props.t("DeleteCategory"),
            alertSubTitle: this.props.t("SureDeleteCategory") + "\n" + this.state.shop.listCategory[categoryIndex].title + "?",
            alertState: "delete_category",
            categoryIndex: categoryIndex,
            itemIndex: ""
        }

        this.setState({
            showAlertOkCancel: true,
        })
    }

    categoryDelete() {

        var listCategory = this.state.shop.listCategory
        var category = listCategory[this.alertDict.categoryIndex];
        let path = this.props.auth.email + "/" + this.props.reduxSelectedCard.id + "/shop/" + category.id
        this.deleteFolderContents(path)

        listCategory.splice(this.alertDict.categoryIndex, 1)
        this.setState({
            shop: { ...this.state.shop, listCategory: listCategory },
            didMakeChange: true
        });

        // this.deleteSelectedFiles(category, this.alertDict.itemIndex)
        // category.items.splice(this.alertDict.itemIndex, 1)
        // this.updateCategory(category)
    }

    deleteFolderContents = (path) => {
        const ref = storage.ref(path);
        ref.listAll()
            .then(dir => {
                dir.items.forEach(fileRef => {
                    this.deleteFile(ref.fullPath, fileRef.name);
                });
                dir.prefixes.forEach(folderRef => {
                    this.deleteFolderContents(folderRef.fullPath);
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    deleteFile = (pathToFile, fileName, firebase) => {
        const ref = storage.ref(pathToFile);
        const childRef = ref.child(fileName);
        childRef.delete()
    }

    saveChanges = () => {
        this.props.updateShop(this.state.shop)
        this.setState({
            didMakeChange: false
        })
    }

    handleChangeFor_All_CategoryInList = (e) => {
        // this.saveLastGlobalSelectionForRectangle(e)
        console.log("handleChangeForItemInList : id %s value %s index %s", e.target.id, e.target.value);
        // 1. Make a shallow copy of the items
        if (this.state.shop) {
            let listCopy = [...this.state.shop.listCategory];

            for (let index = 0; index < listCopy.length; index++) {
                var item = listCopy[index];
                item = {
                    ...item,
                    [e.target.id]: e.target.value
                }
                listCopy[index] = item
            }

            this.setState({
                shop: {
                    ...this.state.shop,
                    lastGlobalSelections: {
                        ...this.state.shop.lastGlobalSelections,
                        [e.target.id]: e.target.value
                    },
                    listCategory: listCopy
                },
                didMakeChange: true
            });
        }
    }

    render() {
        const { shopReduxComponent, t, } = this.props;
        const bootomFixedForUpdateButton = {
            position: 'fixed',
            zIndex: '2',
            backgroundColor: "red",
            height: "40px",
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        let shopTitle = {
            marginTop: 0,
            fontSize: 32,
            fontName: "Varela Round",
            textColor: "black"

        }

        if (this.state.shop && this.state.shop.extras
            && this.state.shop.extras.title) {
            shopTitle = this.state.shop.extras.title
        }

        return (
            <div className="container" style={{ marginBottom: "45px" }}>

                {shopReduxComponent.isLoading ?
                    <LoaderView text={shopReduxComponent.loadingTitle} />
                    : null}

                {this.state.showAlert ?
                    < AlertOkComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={() => {
                        this.setState({
                            showAlert: false
                        })
                    }} />
                    : null}

                {this.state.showAlertOkCancel ?
                    < AlertOkCancelComponent
                        title={this.alertDict.alertTitle}
                        subtitle={this.alertDict.alertSubTitle}
                        closeAlert={(confirm) => {
                            this.alertOkCancelConfirmed(confirm)
                        }} />
                    : null}

                <TopGlobalCustomVideoEditor
                    addTitle={t("AddShop")}
                    setAllWithData={(data) => {
                        console.log("sent data", data);
                        data.target.id = data.target.id == "textColor" ? "color" : data.target.id
                        this.handleChangeFor_All_CategoryInList(data)
                    }}
                    createNew={() => {
                        this.createNewShopCategory()
                    }}
                    enterSortMode={checked => {
                        this.setState({
                            ...this.state,
                            disableSorting: !checked
                        })
                    }}
                    lastGlobalSelections={this.state.shop ? this.state.shop.lastGlobalSelections : undefined}
                />


                {this.state.didMakeChange ?
                    <div style={bootomFixedForUpdateButton}>
                        <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (this.state.didMakeChange ? "" : "disabled")}
                            onClick={() => this.saveChanges()} >{t("SaveChanges")}
                            <i className={(this.state.isUploadingNow ? "fa fa-refresh fa-spin" : "")} style={{ marginLeft: 10 }} ></i>
                        </button>
                    </div> : null}

                <ShopSellerInfo
                    seller={this.state.shop ? this.state.shop.seller : null}
                    updateSellerInfo={(seller) => this.updateSellerInfo(seller)} />


                <TextInputComponentWithStyle
                    titleInput={t("Title")}
                    textInput={shopTitle.text}
                    textColor={shopTitle.textColor}
                    fontName={shopTitle.fontName}
                    fontSize={shopTitle.fontSize}
                    marginTop={shopTitle.marginTop}
                    handleChange={(value) => {
                        console.log("the change ", value);
                        shopTitle = {
                            ...shopTitle,
                            [value.target.id]: value.target.value
                        }
                        let extra = this.state.shop && this.state.shop.extras ? this.state.shop.extras : {}
                        extra = {
                            ...extra,
                            title: { ...shopTitle }
                        }
                        this.updateShopExtraInfo(extra)
                    }} />

                <this.SortableContainer onSortEnd={this.onSortEnd} >
                    {this.state.shop && this.state.shop.listCategory && this.state.shop.listCategory.map((value, index) => (
                        <this.SortableItem key={`item-${index}`} index={index} value={value}
                            myIndex={index}
                            disabled={this.state.disableSorting} />
                    ))}
                </this.SortableContainer>
            </div>
        )
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        var myList = [...this.state.shop.listCategory];
        var newList = arrayMove(myList, oldIndex, newIndex)
        console.log("list", myList);
        console.log("newList", newList);
        this.setState({
            shop: { ...this.state.shop, listCategory: newList },
            didMakeChange: true
        });
    };
}

const mapDispatchToProps = (dispatch) => {
    return (
        {
            updateShop: (shop) => dispatch(updateShop(shop)),
        })
}

const mapStateToProps = (state, ownProps) => {
    console.log("mapStateToProps", state);
    // here we have card.cards array
    return {
        reduxSelectedCard: state.redux.tabsEditorComponent.selectedCard,
        shopReduxComponent: state.redux.shopComponent,
        auth: state.firebase.auth,
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ShopCreateCatAndItems)

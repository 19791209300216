import React from 'react'

export default function RoundButtonBorderTextIcon({ color, text, iconName, iconColor, buttonClick, borderColor, backgroundColor }) {

    var style = {
        backgroundColor: backgroundColor ? backgroundColor : "white",
        width: "35px",
        height: "35px",
        border: "2px solid",
        color: color,
        marginTop: 6,
        borderColor: borderColor ? borderColor : color
    }

    return (
        <div>
            <button href="#"
                className="btn-floating btn waves-effect waves-light "
                style={style}
                onClick={(e) => buttonClick(e)}
            >
                <i className={iconName + " iconCenter"} aria-hidden="true"
                    style={{ color: iconColor ? iconColor : color, fontSize: "16px" }}></i>
            </button>
            <br />
            <span className="center" style={{ color: "black", }}></span>
            <span style={{ fontSize: "12px", style: "bold", color: "black", display: "block", marginTop: "4px" }}>
                {text}
            </span>
        </div>
    )
}

import React from 'react'
import { DropDownComponent } from '../DropDownComponent'
import { animationListDict } from '../../../../Utils/AnimationData'
import { useTranslation } from 'react-i18next';

export default function CardAnimation({ animation, props, saveChanges }) {
    const { t } = useTranslation();
    var style = animation !== undefined ? animation.style : "None"
    const [selectedAnimation, setAnimation] = React.useState(style);
    const [didMakeChange, setMadeChange] = React.useState(false);

    return (
        <div className="edit_button_card_container">
            <h5 className="" style={{ marginBottom: 20 }}>{t("AnimationSetting")}</h5>

            <DropDownComponent
                // list={["Carusel", "GridList"]}
                title={selectedAnimation}
                dict={animationListDict()}
                selectedIndex={(index) => {

                }}
                selectedId={(event, id) => {
                    setAnimation(id)
                    setMadeChange(true)
                    console.log("selected", id);
                    event.preventDefault()
                    // this.setSettingsToGallery(gallery, id, index)
                }} />

            {didMakeChange ?
                <div style={{ marginTop: 20 }}>
                    <button className={"btn btnUpdateServer z-depth-0 fullWidth  " + (didMakeChange ? "" : "disabled")}
                        onClick={() => {
                            saveChanges(selectedAnimation)
                            setMadeChange(false)
                        }}
                    >{t("SaveChanges")}
                    </button>
                </div> : null}
        </div>
    )
}

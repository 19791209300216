import React, { useEffect, useState } from "react";
import israelFlag from '../images/flags/israel.png'
import usaFlag from '../images/flags/usa.png'
import { useTranslation } from 'react-i18next';
import { generateStringId_NoNumbers } from '../Utils/UtilMethods'

export default function LanguageSelectorComponent() {
    const targetId = generateStringId_NoNumbers()
    const { t, i18n } = useTranslation();
    const [flag, setFlag] = useState(getFlagFileName);
    useEffect(() => {

        const M = window.M;
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, { constrainWidth: false });
    });
    function handleClick(language, e) {
        e.preventDefault()
        i18n.changeLanguage(language);
        setFlag(getFlagFileName);
    }

    function getFlagFileName() {
        if (i18n.language === "heb") {
            return israelFlag
        }
        else {
            return usaFlag
        }

    }

    return (
        <div>
            <a className='dropdown-trigger ' href='#' data-target={targetId} style={{ backgroundColor: "#00000000", padding: "0px", marginTop: "12px" }}>
                <img src={flag} style={{ width: 30, height: 30 }} alt={"כרטיס ביקור דיגיטלי"} /></a>

            <ul id={targetId} className='dropdown-content'>
                <li>
                    <a href="#" onClick={(e) => handleClick("heb", e)} >
                        <img src={israelFlag} style={{ width: 30, height: 30 }} alt={"כרטיס ביקור דיגיטלי"} />
                    </a>
                </li>
                <li>
                    <a href="#" onClick={(e) => handleClick("en", e)} >
                        <img src={usaFlag} style={{ width: 30, height: 30 }} alt={"כרטיס ביקור דיגיטלי"} />
                    </a>
                </li>

            </ul>
        </div>
    )
}

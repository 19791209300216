import React, { Component } from 'react'

export default class NumberStepperComponent extends Component {
    state = {
        number: this.props.value
    }

    componentWillReceiveProps(np) {
        this.setState({
            number: np.value
        })
    }

    handleClick = (direction, e) => {
        e.stopPropagation()

        if (direction === "up") {
            if (this.state.number + this.props.step <= this.props.max) {
                this.setState({
                    number: this.state.number + this.props.step
                })
                this.props.didMakeChange(this.state.number + this.props.step)
            }

        }
        else {
            if (this.state.number - this.props.step >= this.props.min) {
                this.setState({
                    number: this.state.number - this.props.step
                })
                this.props.didMakeChange(this.state.number - this.props.step)
            }
        }
    }
    render() {
        return (
            <div >
                {
                    this.props.fromToToBottom === false ?
                        <div>
                            <div>{this.props.title}</div>
                            <div>
                                <i className="fa fa-angle-up " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}
                                    onClick={(e) => this.handleClick("up", e)}></i>
                            </div>

                            <div>
                                <span>{this.state.number}</span>
                            </div>
                            <div>
                                <i className="fa fa-angle-down " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}
                                    onClick={(e) => this.handleClick("down", e)}></i>
                            </div>
                        </div>
                        :
                        <div>
                            <div>{this.props.title}</div>
                            <i className="fa fa-angle-up " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}
                                onClick={(e) => this.handleClick("up", e)}></i>

                            <span>{this.state.number}</span>
                            <i className="fa fa-angle-down " aria-hidden="true" style={{ marginLeft: 8, marginRight: 8 }}
                                onClick={(e) => this.handleClick("down", e)}></i>
                        </div>

                }
            </div>
        )
    }
}

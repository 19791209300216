import React from 'react'
import RoundButtonBorderTextIcon from '../Buttons/RoundButtonBorderTextIcon'
import SaleItemCard from './SaleItemCard'

export default function SaleItemDetails({ item, close }) {
    return (
        <div className="fullDiv">
            <div className="floatingLefTopButton">
                <RoundButtonBorderTextIcon
                    iconName={"fa fa-times"}
                    color={"black"}
                    buttonClick={() => close()}
                />
            </div>


            <div className="centerDivShop">
                <SaleItemCard
                    item={item}
                    large={true}
                    buttonClick={() => null}
                />
            </div>
        </div>
    )
}

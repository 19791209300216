import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ContactCardUdisWhatsapp() {
    const { t, } = useTranslation();
    return (
        <div>
            <a href={"https://api.whatsapp.com/send?phone=972544334590&text=" + t("ContactWhatsappBtnSendText")}
                class="floatWhatsaap z-depth-4" target="_blank">
                <i class="fa fa-whatsapp my-float"></i>
            </a>
        </div>
    )
}

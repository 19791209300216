import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

class ChangeShapeListComponent extends Component {

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.dropdown-trigger-shape');
        M.Dropdown.init(elems, { constrainWidth: true });
        console.log("new props", this.props.selectedIndex);
    }

    shapeSelected = (shapeValue) => {

        console.log("index is ", this.props.selectedIndex);
        var selected = {
            target:
            {
                id: "shape",
                value: shapeValue
            }
        }

        this.props.selectedShape(selected, this.props.selectedIndex)
    }
    render() {
        const { i18n, t, selectedButton, selectedIndex } = this.props;

        var style = {
            backgroundColor: "white",
            width: "34px",
            height: "34px",
            border: "2px solid",
            borderColor: "black"
        }
        var iconName = "";

        if (selectedButton.shape === "") {

            iconName = "fa fa-square"
        }
        else {

            iconName = "fa fa-circle"
        }


        return (
            <li style={{ width: "50px" }}>
                <div style={{ width: "50px" }}>
                    <a href="#"
                        className="btn-floating btn waves-effect waves-light dropdown-trigger-shape"
                        style={style}
                        data-target={'select_shape' + selectedIndex}
                    >
                        <i className={iconName + " iconCenter"} aria-hidden="true"
                            style={{ color: "black", fontSize: "16px" }}></i>
                    </a>
                    <br />
                    <span className="center" style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{t("Shape")}</span>

                    <ul id={'select_shape' + selectedIndex} className='dropdown-content'>

                        <li className="">
                            <a href="#" style={{ width: "100%", margin: 0 }}
                                onClick={(e) => this.shapeSelected("btn-floating btn ")}>
                                <i className={"fa fa-circle-thin  "} aria-hidden="true"
                                    style={{ color: "red", fontSize: "20px", width: "100%", margin: 0 }}> {t("Circle")}</i>
                            </a>

                        </li>
                        <li>
                            <a href="#" style={{ width: "100%", margin: 0 }}
                                onClick={(e) => this.shapeSelected("")}>
                                <i className={"fa fa-square-o  "} aria-hidden="true"
                                    style={{ color: "red", fontSize: "20px", width: "100%", margin: 0 }}> {t("Square")}</i>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }
}
export default withTranslation()(ChangeShapeListComponent);
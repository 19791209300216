import React, { Component } from 'react'
import CheckboxComponent from '../../CheckboxComponent'
import ColorPickerComponent from '../ColorPickerComponent'
import { withTranslation } from 'react-i18next';

export class TextInputHideTextBgColorEditComponent extends Component {

    state = {

        share: this.props.share,
        didMakeChange: false,
        pickerShow: false
    }

    getColorPicker() {

        var style = {
            backgroundColor: this.state.share.buttonColor,
            width: "38px",
            height: "38px",
        }

        return (
            <div className="center">
                <a href="#"
                    className="btn-floating btn waves-effect waves-light "
                    style={style}
                    onClick={(e) => this.handlePickerOpen(this.state.share.buttonColor, e)}
                >
                    <i className={"fa fa-paint-brush iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />
                <span className="center" style={{ color: "black", }}></span>
                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>{this.props.colorPickerDecsription}</span>
            </div>
        )
    }

    handlePickerOpen = (color, e) => {
        e.preventDefault()
        this.setState({
            pickerShow: true
        }
        );
    }

    handleChange = (e) => {
        this.props.didMakeChange(e.target.id, e.target.value)
    }

    render() {
        const { t } = this.props;
        return (
            <div>

                {this.state.pickerShow ?
                    <ColorPickerComponent
                        color={this.state.share.buttonColor}
                        closePicker={() => {
                            this.setState({
                                pickerShow: false
                            })
                        }}
                        pickerChanged={(color) => {
                            this.props.didMakeChange("buttonColor", color)
                        }}
                    />
                    :
                    null}

                <div className="input-field">
                    <div className="edit_button_card_subtitle" dir="auto">{this.props.inputDescription}</div>
                    <textarea dir="auto" className="browser-default edit_button_card_input"
                        type="text" id='text'
                        style={{ backgroundColor: "white", height: "60px" }}
                        onChange={this.handleChange} required
                        placeholder="" value={this.state.share.text} />

                    <CheckboxComponent checked={this.state.share.hideButton}
                        title={t("HideButton")} selected={(checked) => {
                            this.props.didMakeChange("hideButton", checked)
                        }} />
                </div>
                <div className="roundedButtons">
                    <ul>
                        <li>
                            {this.getColorPicker()}
                        </li>

                    </ul>
                </div>

            </div>
        )
    }
}

export default withTranslation()(TextInputHideTextBgColorEditComponent)
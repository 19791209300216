import React, { Component } from 'react'
import { getRoundedContactDesignedButton } from '../../../Utils/UtilMethods'
export default class RoundedContactButtons2 extends Component {
    render() {
        const { buttons, animations } = this.props
        return (
            <div className="roundedButtons" style={{ marginTop: buttons.marginTopBlock ? buttons.marginTopBlock : 0 }}>
                <ul >
                    {buttons && buttons.list.map((button, index) => {
                        return (
                            getRoundedContactDesignedButton(button, () => {
                                console.log("the index", index);
                                this.props.buttonClicked(button.id)
                            }, animations, index)
                        )
                    })
                    }
                </ul>
            </div >
        )
    }

}

import React, { Component } from 'react'
import PaypalButtons from "./PaypalButtons";
import { packageThatFitsTheCard } from '../../Utils/UtilMethods'
import { withTranslation } from 'react-i18next';
import Navbar from '../layout/Navbar';
import { getFirestore } from 'redux-firestore'
import LoaderView from '../SpinnerLoader/LoaderView';

export class PaymentComponent extends Component {
    state = {
        isLoading: false
    }

    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }

    paymentHandler = (details, data) => {
        /** Here you can call your backend API
          endpoint and update the database */
        console.log(details, data);
    }

    savePaymentInfo = (details) => {
        this.setState({
            isLoading: true
        });


        const firestore = getFirestore();
        firestore.collection('Payments').doc(this.props.location.state.cardDetails.id).set({
            [`${details.id}`]: details,
        }, { merge: true }).then(() => {
            console.log("succes in dispatch");
            this.updateCardInfoPaymentID(details.id)
        }).catch(err => {
            console.log("error in dispatch", err);
            this.setState({
                isLoading: false
            });
        });
    }

    updateCardInfoPaymentID = (paymentID) => {
        const firestore = getFirestore();
        firestore.collection('cards').doc(this.props.location.state.cardDetails.id).update({
            [`info.paymentID`]: paymentID,
        }).then(() => {
            this.setState({
                isLoading: false
            });
        }).catch(err => {
            console.log("error in dispatch", err);
            this.setState({
                isLoading: false
            });
        });
    }

    render() {
        const { cardDetails } = this.props.location.state
        const { t } = this.props
        // this.props.location.state.cardDetails
        return (
            <div>
                {/* {process.env.NODE_ENV}
                
                 type: "BC",
            size: i18n.t("Small"),
            title: i18n.t("SmallBc"),
            price: 299,
            maxFileUploads: 5,
            maxRectangleButtons: 2,
            maxRoundedButtons: 4,
            maxNumberOfGallery: 1,
            maxVideoShare: 1,
            period: 365,
            description: "",
            
            */}

                <Navbar />

                {this.state.isLoading ?
                    <LoaderView text={t("UpdateCardInfo") + "..."} />
                    : null}

                <div className="edit_button_card_container container  " style={{ marginTop: "80px" }}>
                    <h5 className="center">{t("Payment")}</h5>
                    <div dir="auto">
                        <div>
                            {t("Description")}: {packageThatFitsTheCard(cardDetails).title}
                        </div>

                        <div>
                            {t("Price")}: {packageThatFitsTheCard(cardDetails).price}
                        </div>

                    </div>

                    <PaypalButtons
                        cardID={cardDetails.id}
                        package={packageThatFitsTheCard(cardDetails)}
                        successResponse={(details) => {
                            this.savePaymentInfo(details)
                        }} />

                </div>

                {/* <div>Online Payment Demo</div>
                <PayPalBtn
                    amount={200}
                    currency={'ILS'}
                    onSuccess={this.paymentHandler} /> */}
            </div>
        )
    }
}


export default withTranslation()(PaymentComponent)
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// using redux for data saving local
import { createStore } from 'redux'
// using rootReducer to bind all reducers together
import rootReducer from './store/reducers/rootReducer';
// provider component will sround are app so the app has access to the store 
import { Provider } from 'react-redux'; // this is the binding layer between react and redux
// include a middle ware this is for making async tasks when retrive data
import thunk from 'redux-thunk'
// in order to add the thunk we need a middle ware
import { applyMiddleware } from 'redux'

// firebase imports
import { getFirestore } from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'
// in order to have the ability to add more store haanncer 
// where the middleware
import { compose } from 'redux'
import { reduxFirestore } from 'redux-firestore';
import { reactReduxFirebase } from 'react-redux-firebase';
import fbConfig from './config/fbConfig'
// import i18n (needs to be bundled ;)) 
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
// creating multiple reducer for each issue and than bind then all togeteher in to root reducer
// adding a root reducer to assosiate with the store
// applyMiddleWare can have a list of misddle ware
// apply middle ware enhance the store functionality
const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        // redux binding for firebase
        reactReduxFirebase(fbConfig, { userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true }),

        reduxFirestore(fbConfig) // redux bindings for firestore
    )
);

store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(<Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>, document.getElementById('root'));
    serviceWorker.unregister();
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


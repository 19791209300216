import React, { Component } from 'react'
import { FontList, generateStringId_NoNumbers } from '../../Utils/UtilMethods'
import { withTranslation } from 'react-i18next';
export class FontListSelectComponent extends Component {

    dropDownId = generateStringId_NoNumbers()
    targetId = generateStringId_NoNumbers()

    state = {
        fontName: this.props.fontName ? this.props.fontName : ""
    }

    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll(`.${this.dropDownId}`);
        M.Dropdown.init(elems, { constrainWidth: false, coverTrigger: false });
    }

    fontSelected = (fontName, e) => {
        e.stopPropagation()
        this.props.selectedFont(fontName)
        this.setState({ fontName: fontName })
    }


    componentWillReceiveProps(np) {
        this.setState({
            fontName: np.fontName
        })
    }

    render() {
        const { t } = this.props;
        var style = {
            backgroundColor: "black",
            width: "38px",
            height: "38px",
        }

        return (
            <div >
                <a href="#"
                    className={"btn-floating btn waves-effect waves-light " + this.dropDownId}
                    data-target={this.targetId}
                    style={style}
                >
                    <i className={"fa fa-font iconCenter"} aria-hidden="true"
                        style={{ color: "white", fontSize: "16px" }}></i>
                </a>
                <br />

                <span style={{ fontSize: "12px", color: "black", display: "block", marginTop: "4px" }}>
                    {t("Font")}
                </span>
                <span style={{ fontSize: "8px", color: "black", display: "block", marginTop: "0px" }}>
                    {this.state.fontName}
                </span>

                <ul id={this.targetId} className='dropdown-content'>
                    {FontList.map((fontName) => {
                        return (

                            <div className=" bottom_border center "
                                onClick={(e) => this.fontSelected(fontName, e)}
                                style={{
                                    height: 30, backgroundColor: "lightgrey",
                                    fontFamily: fontName, padding: 10

                                }}>
                                {fontName}
                            </div>
                        )
                    })
                    }
                </ul>
            </div>
        )
    }
}

export default withTranslation()(FontListSelectComponent)
import React, { useState } from 'react'
import { sumerizeCart, addToCart, removeFromCart } from '../../../Utils/ShopUtils'
import NumberStepperComponent from '../../user_cards/NumberStepperComponent'
import Divider from '../../views/Divider';
import SaleItemDetails from '../SaleItemDetails';

export default function ItemListSumerized({ refresh }) {

    const [itemDetails, setItemToShowDetails] = useState(null);

    return (
        <div>

            {
                itemDetails ?
                    <SaleItemDetails
                        item={itemDetails}
                        close={() => setItemToShowDetails(null)} />
                    :
                    null
            }

            {Object.keys(sumerizeCart()).map((key) => {
                return (
                    <div>
                        <div className="row parent"
                            style={{ margin: 0 }}
                            onClick={() => setItemToShowDetails(sumerizeCart()[key])}
                        >
                            <div className="col s2 m2 l3 noPadding"
                                style={{}}>
                                <img className="fullWidth"
                                    src={sumerizeCart()[key].images[0]} alt="כרטיס ביקור דיגיטלי"
                                    style={{ height: 100, objectFit: "contain", backgroundColor: "white" }} />
                            </div>
                            <div className="col s4 m4 l2 noPadding"
                                style={{ margin: "auto" }}>

                                {sumerizeCart()[key].title}
                            </div>
                            <div className="col s1 m1 l2 noPadding"
                                style={{ margin: "auto" }}>
                                {sumerizeCart()[key].price}
                            </div>
                            <div className="col s3 m3 l3 noPadding"
                                style={{ margin: "auto" }}>
                                <NumberStepperComponent
                                    value={sumerizeCart()[key].sum}
                                    max={99}
                                    min={0}
                                    step={1}
                                    fromToToBottom={false}
                                    // title={this.props.t("FontSize")}
                                    didMakeChange={(value) => {
                                        if (value > sumerizeCart()[key].sum) {
                                            addToCart(sumerizeCart()[key])
                                        }
                                        else {
                                            removeFromCart(sumerizeCart()[key])
                                        }

                                        refresh()

                                    }} />
                            </div>

                            <div className=" childTest col s2 m2 l2 noPadding"
                                style={{ margin: "auto" }}>
                                {sumerizeCart()[key].price * sumerizeCart()[key].sum}
                            </div>
                        </div>
                        <Divider
                        />
                    </div>

                )
            })}

        </div >

    )
}

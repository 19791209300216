import React from 'react'
import { getTotalCartPrice, } from '../../../Utils/ShopUtils'
import User from '../../../Singleton/UserAndCard'

export default function BottomSumForList() {
    var card = User.getSelectedCard()

    function getShipingTotalPrice() {
        if (card.shop.seller.ShippingFreeFromPrice === "")
            return card.shop.seller.ShippingPrice

        if (getTotalCartPrice() < card.shop.seller.ShippingFreeFromPrice) {
            return card.shop.seller.ShippingPrice
        } else {
            return 0
        }
    }

    return (
        <div>

            {
                card.shop.seller.useShiping ?
                    <div>
                        <div className="" style={{ textAlign: "right", color: "blue" }}>
                            {card.shop.seller.ShippingTitle}
                        </div>
                        <div className="row center parent"
                            style={{ margin: 0 }}
                        >
                            <div className="col s2 m2 l3 noPadding">

                            </div>
                            <div className="col s4 m4 l2 noPadding">

                            </div>
                            <div className="col s1 m1 l2 noPadding">

                            </div>

                            <div className="col s3 m3 l3 noPadding" style={{ color: "blue" }}>
                                <b>
                                    משלוח
                                </b>
                            </div>

                            <div
                                dir="auto"
                                className="col s2 m2 l2 noPadding"
                                style={{ margin: "auto", color: "blue" }}>
                                ₪{getShipingTotalPrice()}
                                {/* "סה״כ לתשלום" */}
                            </div>


                        </div>

                    </div>

                    : null
            }
            <div className="row center parent"
                style={{ margin: 0 }}
            >
                <div className="col s2 m2 l3 noPadding">

                </div>
                <div className="col s4 m4 l2 noPadding">

                </div>
                <div className="col s1 m1 l2 noPadding">

                </div>

                <div className="col s3 m3 l3 noPadding">
                    <b>
                        סה״כ לתשלום
                    </b>
                </div>

                <div
                    dir="auto"
                    className="col s2 m2 l2 noPadding"
                    style={{ margin: "auto" }}>
                    ₪{getTotalCartPrice() + parseInt(getShipingTotalPrice())}
                    {/* "סה״כ לתשלום" */}
                </div>


            </div>

        </div>
    )
}

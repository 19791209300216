const initState =
{
    isLoading: false,
    isCardNameAvailable: false,
    showNotAvailableTitle: false
}

const utilsReducer = (state = initState, action) => {

    switch (action.type) {
        case 'CLEAR_CARD_NAME_AVAILABLE':
            return {
                isLoading: false,
                isCardNameAvailable: true,
                showNotAvailableTitle: false,
            }
        case 'CARD_NAME_AVAILABLE':
            console.log("in SAVE_CARD_INFO");
            return {
                isLoading: false,
                isCardNameAvailable: true,
                showNotAvailableTitle: false,
            }

        case 'CARD_NAME_NOT_AVAILABLE':
            console.log("in SAVE_CARD_INFO");
            return {
                isLoading: false,
                isCardNameAvailable: false,
                showNotAvailableTitle: true,
            }

        case "IS_LOADING_CARD_ID_AVAILABILITY":
            return { isLoading: true, showNotAvailableTitle: false, }

        default:
            return state;
    }
}

export default utilsReducer
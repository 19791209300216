import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Navbar from "../../layout/Navbar";
import LoaderView from '../../SpinnerLoader/LoaderView';
import { FREE_TRAIL_DAYS, SERVER_SIDE_BASE_URL } from '../../../Utils/Constants'
import { withTranslation } from 'react-i18next';

import { compose } from 'redux'

import {
    isCardNameAvailable,
    createCardNew,
    isCardNameAvailable_SetToNo
} from '../../../store/actions/cardActions'

import { AlertOkComponent } from '../../AlertsComponents/AlertsComponent'

import {
    isEmptyOrSpaces,
    getBasicButtonsObjectForType,
    generateStringId_NoNumbers,
    getPackageList
} from '../../../Utils/UtilMethods'

export class CreateNewCard extends Component {
    alertTitle = ""
    alertSubTitle = ""
    loadingTitle = ""
    notAvailableTitle = ""
    showAvailableButton = false
    state = {
        cardId: "",
        showAlert: false,
        description: {
            title: {
                text: "",
                color: "black",
                hide: false,
                fontName: "Heebo-Regular",
                fontSize: 32,
                marginTop: 0
            },
            subtitle: {
                text: "",
                color: "black",
                hide: false,
                fontName: "Heebo-Regular",
                fontSize: 32,
                marginTop: -15
            },
        },
        extra: {
            phoneNumber: "",
            email: "",
            cardLanguage: "",
        }
    }

    componentDidMount() {
        document.body.classList.add('cardudisBG');
    }

    checkIfNameAvailable = (e) => {
        if (isEmptyOrSpaces(this.state.cardId)) {
            this.alertTitle = "Missing card id!"
            this.alertSubTitle = "Please enter a new valid card id \n and check if available"
            this.setState({
                showAlert: true,
                cardId: ""
            })
        }
        else {
            this.loadingTitle = `Checking if ${this.state.cardId} id is avilable`
            this.notAvailableTitle = "This card id is already taken"
            this.showAvailableButton = false
            this.props.isCardNameAvailable(this.state.cardId);
        }
    }

    //When using ES6 code in React always use arrow functions, because the this context is automatically binded with it
    handleChange = (e) => {
        if (e.target.id == "cardId") {
            this.showAvailableButton = true
            this.notAvailableTitle = ""
            var noSpaces = e.target.value.replace(" ", "").toLowerCase();
            this.setState({
                [e.target.id]: noSpaces,
            })
            this.props.isCardNameAvailable_SetToNo()
        }
        else if (e.target.id == "phoneNumber" ||
            e.target.id == "email" ||
            e.target.id == "cardLanguage") {
            this.setState({
                ...this.state,
                extra: {
                    ...this.state.extra,
                    [e.target.id]: e.target.value,
                }
            })
        }
        else {


            this.setState({
                ...this.state,
                description: {
                    ...this.state.description,
                    [e.target.id]: {
                        ...this.state.description[e.target.id],
                        text: e.target.value
                    }
                }

            })
            // this.setState(state => {
            //     state.description[target].text = e.target.value

            //     return state
            // })


        }
    }

    getRoundedButtonsExampleButtons() {
        var roundedButtons = []
        var button = getBasicButtonsObjectForType({ type: "tel" }, false)
        button.title = this.props.t("Call")
        button.number = this.state.extra.phoneNumber
        button.icon = "fa fa-mobile"
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "sms" }, false)
        button.title = this.props.t("sms")
        button.number = this.state.extra.phoneNumber
        button.icon = "fa fa-commenting-o"
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "whatsApp" }, false)
        button.title = this.props.t("Whatsapp")
        button.icon = "fa fa-whatsapp"
        button.number = this.state.extra.phoneNumber
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "waze" }, false)
        button.title = this.props.t("Navigate")
        button.icon = "fa fa-map-marker"
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "mailto" }, false)
        button.title = this.props.t("Email")
        button.icon = "fa fa-envelope-o"
        button.mail = this.state.extra.email
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "contact" }, false)
        button.title = this.props.t("SaveToContacts")
        button.icon = "fa fa-user"
        button.email = this.state.extra.email
        button.cellNumber = this.state.extra.phoneNumber
        button.contactTitle = this.state.description.title.text + " " + this.state.description.subtitle.text
        roundedButtons.push(button)


        return roundedButtons
    }

    getRectangleButtonsExampleButtons() {
        var roundedButtons = []

        var button = getBasicButtonsObjectForType({ type: "about" }, true)
        button.title = this.props.t("AboutMe")
        button.icon = "fa fa-info"
        button.abotText = this.props.t("txtAboutMeDesc")
        button.abotTitle = this.props.t("Hey")
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "contactForm" }, true)
        button.title = this.props.t("SendMessageForm")
        button.icon = "fa fa-envelope-o"
        button.language = "heb"
        button.mail = this.state.extra.email
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "whatsApp" }, true)
        button.title = this.props.t("WhatsappFast")
        button.number = this.state.extra.phoneNumber
        button.icon = "fa fa-whatsapp"
        roundedButtons.push(button)

        button = getBasicButtonsObjectForType({ type: "waze" }, true)
        button.title = this.props.t("Navigate")
        button.icon = "fa fa-map-marker"
        roundedButtons.push(button)

        return roundedButtons
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            isLoading: true,
            error: null
        })

        var card = {
            info: {
                allowShop: false,
                cardStyle: "Classic", // classic card component
                package: getPackageList()[0], //  one month , small,big,unlimited
                createdAt: new Date(),
                cardTrailPeriod: FREE_TRAIL_DAYS,
                payment: {
                    paidOnDate: null,  //if paid then count with package period for end 
                    paidAmountOff: 0,
                    dicount: 0,
                    transactionId: null,
                    paidWith: null,
                    agentName: null,
                },
            },
            description: this.state.description,
            background: {
                color: "#E9E9FF",
                hideImage: "",
                url: null

            },
            gallerys: {},
            messages: {},
            header: {

                url: "https://firebasestorage.googleapis.com/v0/b/cardudis.appspot.com/o/CardUdis%2FUdisApp%2Flogo%20wide%20up.png?alt=media&token=7e692e4a-bc88-4edf-bf50-fabfedb0838a",
                bottomBorderColor: "white",
                bottomBorderWidth: 2,
                minHeight: 200,
                hide: false,
                style: "cover",
                marginTop: 0
            },
            logo: {
                borderColor: "white",
                url: "https://firebasestorage.googleapis.com/v0/b/cardudis.appspot.com/o/CardUdis%2FUdisApp%2Fimg_avatar.png?alt=media&token=fdc3c7e3-abc2-4215-affe-fad97d3253b1",
                hide: false,
                marginTop: -25
            },
            roundedButtons: {
                list: this.getRoundedButtonsExampleButtons()
                // list: []
            },
            rectangleButtons: {
                list: this.getRectangleButtonsExampleButtons()
            },
            videos: {
                list: []
            },
            shares: {
                title: this.props.t("ShareWithFriends"),
                hideAllShares: false,
                hideTitle: false,
                fontName: "Cafe",
                textColor: "black",
                fontSize: 35,
                facebook: {
                    hideButton: false,
                    text: `${this.props.t("ClickTheLink")}*${this.state.description.title.text} - ${this.state.description.subtitle.text}* \n${SERVER_SIDE_BASE_URL}vip/${this.state.cardId}`,

                    buttonColor: "#3b5998"
                },
                email: {
                    hideButton: false,
                    text: `${this.props.t("ClickTheLink")}*${this.state.description.title.text} - ${this.state.description.subtitle.text}* \n${SERVER_SIDE_BASE_URL}vip/${this.state.cardId}`,
                    // title: ` ${this.state.description.title.text} ${this.state.description.subtitle.text}`,

                    buttonColor: "#C10841"
                },
                whatsapp: {
                    hideButton: false,
                    text: `${this.props.t("ClickTheLink")}*${this.state.description.title.text} - ${this.state.description.subtitle.text}* \n${SERVER_SIDE_BASE_URL}vip/${this.state.cardId}`,


                    // code:"whatsapp://send?text=",
                    buttonColor: "#25d366"
                },
                sms: {
                    hideButton: false,
                    text: `${this.props.t("ClickTheLink")}*${this.state.description.title.text} - ${this.state.description.subtitle.text}* \n${SERVER_SIDE_BASE_URL}vip/${this.state.cardId}`,

                    // code:"sms://?&body=",
                    buttonColor: "#ffbd00"
                },
            },
            uniqueId: generateStringId_NoNumbers(),
            id: this.state.cardId,
            authorId: this.props.auth.uid,
            email: this.props.auth.email,
            // counters: {
            //     nubmerOfNewVisitors: 0,
            //     numberOfEntries: 0,
            // },
        }
        this.loadingTitle = this.props.t("CreatingCard!")
        this.props.createCardNew(card)
    }

    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }

    render() {
        const { i18n, t, auth, authError } = this.props;

        if (this.props.card.createdNewCard) {
            // clear created new for onother new one
            setTimeout(function () { //Start the timer
                this.props.Clear_createCardNew()
                this.props.Clear_CardNameAvailable()
            }.bind(this), 2000)

            return <Redirect to='/dashboard' />
        }


        if (!auth.uid) return <Redirect to='/' />
        return (
            <div>
                {this.state.showAlert ?
                    < AlertOkComponent title={this.alertTitle} subtitle={this.alertSubTitle} closeAlert={() => {
                        this.setState({
                            showAlert: false
                        })
                    }} />
                    : null}

                <Navbar />

                {this.props.utils.isLoading || this.props.card.isLoading ?
                    <LoaderView text={this.loadingTitle} />
                    : null}

                <div className="container navbar_padding ">
                    <div className="white whiteForm rounded">
                        <h5 className="grey-text text-darken-3" dir="auto">{t("CreateNewCard")}</h5>

                        <div className="input-field">
                            <div className="edit_button_card_subtitle" dir="auto">{t("CardName")}:</div>
                            <input dir="auto"
                                value={this.state.cardId}
                                className="browser-default edit_button_card_input"
                                type="text"
                                id='cardId'
                                onChange={this.handleChange}
                                required />
                            {this.props.utils.showNotAvailableTitle ?
                                <div style={{ color: "red" }}>
                                    {this.notAvailableTitle}
                                </div>
                                :
                                null}
                            {
                                this.showAvailableButton ?
                                    <button className="curdudis_button"
                                        onClick={this.checkIfNameAvailable}
                                        style={{ marginTop: 5 }}>
                                        {t("CheckAvailable")}
                                    </button>
                                    :
                                    null
                            }

                        </div>
                        <form className=" " onSubmit={this.handleSubmit}
                            onKeyPress={this.onKeyPress}>
                            {
                                this.props.utils.isCardNameAvailable ?
                                    <div>

                                        <div className="input-field">
                                            <div className="edit_button_card_subtitle" dir="auto">{t("CardTitle")}:</div>
                                            <input dir="auto"
                                                placeholder={t("CardTitle")}
                                                className="browser-default edit_button_card_input"
                                                type="text"
                                                id='title'
                                                onChange={this.handleChange}
                                                required />
                                        </div>
                                        <div className="input-field">
                                            <div className="edit_button_card_subtitle" dir="auto">{t("CardSubtitle")}:</div>
                                            <input dir="auto"
                                                placeholder={t("CardSubtitle")}
                                                className="browser-default edit_button_card_input"
                                                type="text"
                                                id='subtitle'
                                                onChange={this.handleChange}
                                                required />

                                        </div>

                                        <div className="input-field">
                                            <div className="edit_button_card_subtitle" dir="auto">{t("CellPhoneNumber")}:</div>

                                            <input dir="auto"
                                                placeholder={t("PhoneWithCountry")}
                                                className="browser-default edit_button_card_input"
                                                type="phone"
                                                id='phoneNumber'
                                                onChange={this.handleChange}
                                                required />
                                        </div>
                                        <div className="input-field">
                                            <div className="edit_button_card_subtitle" dir="auto">{t("Email")}:</div>
                                            <input dir="auto"
                                                className="browser-default edit_button_card_input"
                                                type="email"
                                                id='email'
                                                onChange={this.handleChange}
                                                placeholder={t("Email")}
                                                required />

                                        </div>

                                        {/* <LanguageSelectDropDownComponent translate={t} formLanguage={i18n.language} selectedLanguage={(language) => {
                                            let value = {
                                                target: {
                                                    id: "cardLanguage",
                                                    value: language
                                                }
                                            }
                                            this.handleChange(value)
                                        }} /> */}


                                        <div className="input-field">
                                            <button className="curdudis_button">{t("CreateNew")}</button>
                                            <div className="center red-text">
                                                {this.state.error ? <p>{this.state.error} </p> : null}
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        utils: state.utils,
        card: state.card
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isCardNameAvailable: (cardName) => dispatch(isCardNameAvailable(cardName)),
        createCardNew: (card) => dispatch(createCardNew(card)),
        Clear_createCardNew: () => { dispatch({ type: "CLEAR_CREATED_NEW_CARD" }) },
        Clear_CardNameAvailable: () => { dispatch({ type: "CLEAR_CARD_NAME_AVAILABLE" }) },
        isCardNameAvailable_SetToNo: () => dispatch(isCardNameAvailable_SetToNo())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(CreateNewCard)

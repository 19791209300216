import React from 'react'
import CenterText from '../View_Text/CenterText'
import SaleGrid from './SaleGrid'

export default function UserShopLayout({ shop, buttonClicked, isFromDashboard }) {
    return (
        <div>
            <div>
                {
                    shop.extras && shop.extras.title ?
                        <CenterText
                            textColor={shop.extras.title.textColor}
                            fontSize={shop.extras.title.fontSize}
                            fontFamily={shop.extras.title.fontName}
                            text={shop.extras.title.text}
                            marginTop={shop.extras.title.marginTop}
                        />
                        :
                        null
                }
            </div>
            { shop.listCategory &&
                shop.listCategory.map((category, index) => (
                    <SaleGrid
                        isFromDashboard={isFromDashboard}
                        category={category}
                        buttonClicked={(id) => {
                            buttonClicked(id)
                        }}
                    />
                ))
            }
        </div>
    )
}
